<mat-form-field *ngIf="canAddMediaToGallery">
  <mat-label class="quick-look-label"><i class="material-icons">
      folder_special
    </i> Add to Gallery</mat-label>
    <div [class]="classValue">

  
  <mat-select #gallerySelect
            class="quick-look-select"
              matNativeControl
              [formControl]="selectedGalleriesForm"
              (openedChange)="updateGalleries($event)"
              multiple>
    <mat-select-trigger>
      {{selectedGalleryNames && selectedGalleryNames.length ? selectedGalleryNames[0]  : ''}}
      <span *ngIf="selectedGalleryNames && selectedGalleryNames.length > 1"
            class="additional-content">
        (+{{selectedGalleryNames ? selectedGalleryNames.length - 1 : ''}}
        {{selectedGalleryNames && selectedGalleryNames.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option class="create-option"
                (click)="createGallery($event)"
                [value]="null">+ Create New Gallery</mat-option>
    <mat-option *ngFor="let gallery of filteredGalleries"
                [value]="gallery.id">
      {{gallery.name.substring(0, 20) + (gallery.name.length > 20 ? "..." : "") + " : " +  gallery.officeName}}
    </mat-option>
  </mat-select>
</div>
</mat-form-field>