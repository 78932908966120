import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { supplierModel } from "../../../models/supplier.model";
import { tagItem, tagModel } from "../../../models/tagModel";
import { MediaUploadState } from "../../../ngrx/reducers/media.reducer";
import { MediaLightboxDialogComponent } from "../media-lightbox-dialog/media-lightbox-dialog.component";

@Component({
  selector: "app-media-upload-item",
  templateUrl: "./media-upload-item.component.html",
  styleUrls: ["./media-upload-item.component.scss"]
})
export class MediaUploadItemComponent implements OnInit {
  private _uploadItem: MediaUploadState;
  get uploadItem(): MediaUploadState {
    return this._uploadItem;
  }
  @Input("uploadItem")
  set uploadItem(value: MediaUploadState) {
    this._uploadItem = value;
  }

  @Input() media;
  @Input() forceUpdate;
  @Input() selectiveBulkTagging: boolean = false;

  @Output() helloTagsAdded = new EventEmitter<{
    tags: tagModel[];
    mediaId: number;
  }>();
  @Output() helloTagsRemoved = new EventEmitter<{
    tags: tagModel[];
    mediaId: number;
  }>();
  @Output() supplierAdd = new EventEmitter<{
    supplier: supplierModel;
    mediaId: number;
  }>();
  @Output() supplierRemove = new EventEmitter<{
    supplier: supplierModel;
    mediaId: number;
  }>();
  @Output() removeAzureTagFromMedia = new EventEmitter<{
    name: string;
    mediaId: number;
  }>();
  @Output() selectiveBulkTaggingChange = new EventEmitter<{
    status: boolean;
    mediaId: number;
  }>();
  @Output() archiveMediaItem = new EventEmitter<number>();
  @Output() publishMediaItem = new EventEmitter<number>();

  sizeInMb: string;
  published=false;

  public isMediaArchived = false; //TODO: Handle

  private manualTags: tagModel[] = [];
  private ogManualTags: tagModel[] = [];
  private supplierTags: supplierModel[] = [];
  private azureTags: tagItem[] = [];
  constructor(public lightboxDialog: MatDialog) {}

  ngOnInit() {
    if (this.uploadItem && this.uploadItem.media && this.uploadItem.media.metaData){
      this.published=this.uploadItem.media.metaData.statusId===5;
    }else{
      this.published=false;
    }
   
  }
  ngOnChanges(changes) {
    if (this.media) {
      this.filterTags();
      const metadata = this.media.metaData;
      this.sizeInMb = metadata
        ? (parseFloat(metadata.fileSize || "0.0") / 1000).toFixed(2).toString()
        : "0.0";
    }
  }
  filterTags() {
    // TODO: Fix tag model and properly enum this
    // actually probably fix this in model/reducer and just do this work there.
    /*
    const AUTO_TAG: any = 1;
    const MANUAL_TAG: any = 2;
    const SUPPLIER_TAG: any = 3;
    */
    // Double awkward that we have to switch the type here because the dang
    // backend returns different shapes for tags
    this.manualTags = this.media.metaData.tags
      .filter(t => t.type == 2)
      .map(t => {
        const result: tagModel = {
          tagId: t.tagId,
          tagName: t.name,
          id: t.tagId,
          tagCategoryId: 0,
          tagCategoryName: t.tagCategoryName
        };
        return result;
      });
    this.ogManualTags = this.manualTags.slice();
    this.supplierTags = this.media.metaData.suppliers.map(t => {
      const result: supplierModel = {
        id: t.supplierId,
        name: t.supplierName,
        supplierCompanies: []
      };
      return result;
    });
    this.azureTags = this.media.metaData.tags.filter(t => t.type == 1);
  }

  openFullSizeLightbox() {
    const lightbox = this.lightboxDialog.open(MediaLightboxDialogComponent, {
      data: {
        media: this.media
      }
    });
  }

  helloTagsChanged(tags: tagModel[]) {
    //Dispatch Tag Update Request
    const MANUAL_TAG: any = 2; //TODO: Fix
    // Map over our tags to get the new ones.
    const newTags = tags.filter(t => {
      const exists = this.ogManualTags.filter(m => m.tagId == t.id);
      if (exists.length) {
        return false;
      } else {
        return true;
      }
    });
    const removedTags = this.ogManualTags.filter(t => {
      const exists = tags.filter(m => m.id == t.tagId);
      if (!exists.length) {
        return true;
      } else {
        return false;
      }
    });
    this.ogManualTags = this.manualTags.slice();
    this.helloTagsAdded.emit({
      tags: newTags,
      mediaId: +this.uploadItem.media.mediaId
    });
    this.helloTagsRemoved.emit({
      tags: removedTags,
      mediaId: +this.uploadItem.media.mediaId
    });
  }

  supplierAdded(supplier) {
    this.supplierAdd.emit({
      supplier: supplier,
      mediaId: +this.uploadItem.media.mediaId
    });
  }

  supplierRemoved(supplier) {
    this.supplierRemove.emit({
      supplier: supplier,
      mediaId: +this.uploadItem.media.mediaId
    });
  }

  removeAzureTag(tag) {
    this.removeAzureTagFromMedia.emit({
      name: tag.name,
      mediaId: +this.uploadItem.media.mediaId
    });
  }

  selectiveBulkTaggingClick() {
    this.selectiveBulkTaggingChange.emit({
      status: this.selectiveBulkTagging,
      mediaId: +this.uploadItem.media.mediaId
    });
  }

  archiveMedia() {
    this.isMediaArchived = true;
    this.archiveMediaItem.emit(+this.uploadItem.media.mediaId);
  }

  publishMedia(){
    this.published=true;
this.publishMediaItem.emit(+this.uploadItem.media.mediaId);
  }
}
