import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Office } from 'src/app/models/office.model';
import { Gallery } from '../../../models/gallery.model';
import { GalleriesState } from '../../../ngrx/reducers/galleries.reducer';

@Component({
  selector: 'app-set-gallery-select',
  templateUrl: './set-gallery-select.component.html',
  styleUrls: ['./set-gallery-select.component.css']
})
export class SetGallerySelectComponent implements OnInit {
  private galleries$: Observable<GalleriesState>;
  _selectedGalleries;
  @Input()
  galleries: Gallery[];
  

  @Input()
  get selectedGalleries(){
    return this._selectedGalleries;
  }
  set selectedGalleries(value){
    this._selectedGalleries=value;
    if (this._selectedGalleries && this._selectedGalleries.length > 0) {
      let galleryIds = Array.from(new Set(this._selectedGalleries.map(a => { return +a.mediaGroupId })));
      this.selectedGalleryNames = this._selectedGalleries.map(g => g.name);
      this.selectedGalleriesForm.setValue(galleryIds);
    }else{
      this.selectedGalleriesForm.setValue([]);
    }
  }
  @Input()
  canAddMediaToGallery: boolean;
  @Input()
  userOffices: Office[];
  @Output() selectedGalleriesEvent: EventEmitter<{ addedGalleryIds: number[], removedGalleryIds: number[] }> = new EventEmitter();
  @Output() createGalleryEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('gallerySelect') gallerySelect;

  selectedGalleriesForm: FormControl = new FormControl();

  selectedGalleryNames: string[];

  filteredGalleries: Gallery[];

  userOfficeIds: number[];
  @Input()
  classValue = '';
  constructor() { }

  ngOnInit() {
    if (this.userOffices) {
      this.userOfficeIds = this.userOffices.map(o => o.officeId);
      this.filteredGalleries = this.galleries.filter(g => this.userOfficeIds.indexOf(g.officeId) >= 0 &&
        (g.statusCode === "DFT" || g.statusCode === "PBH") && g.totalMediaCount<100).sort(this.sortGalleriesByOffice);

     
    }

  }
  ngOnChanges(changes) {
    if(!this.userOfficeIds && this.userOffices) {
      this.userOfficeIds = this.userOffices.map(o => o.officeId);

    }
    if (changes.selectedGalleries && changes.selectedGalleries.currentValue && changes.selectedGalleries.currentValue.length > 0) {
      this.selectedGalleries = changes.selectedGalleries.currentValue;
      this.filteredGalleries = this.galleries.filter(g => this.userOfficeIds.indexOf(g.officeId) >= 0 &&
        (g.statusCode === "DFT" || g.statusCode === "PBH") && g.totalMediaCount<100).sort(this.sortGalleriesByOffice);
      let galleryIds = Array.from(new Set(this.selectedGalleries.map(a => { return +a.mediaGroupId })));
      let matches = this.galleries.filter(ab => galleryIds.indexOf(+ab.id) !== -1);
      if (matches && matches.length) {
        this.selectedGalleryNames = matches.map(a => { return a.name });
        this.selectedGalleriesForm.setValue(galleryIds);
      }
    }
  }
  createGallery(event) {
    let currentValue = this.selectedGalleriesForm.value
    if (currentValue.indexOf(null) !== -1) {
      this.createGalleryEvent.emit(true);
      this.gallerySelect.close();
    }
  }

  updateGalleries(isOpen: boolean) {
    if (!isOpen) {

      if (this.selectedGalleriesForm.value) {
        if (this.selectedGalleriesForm.value.indexOf(null) === -1) {
          this.selectedGalleryNames = [];
          this.selectedGalleries = this.selectedGalleriesForm.value;
          let selectedGalleryIds = <number[]>this.selectedGalleries.filter(x => x !== null);
          let unselectedGalleries = this.galleries.filter(g => selectedGalleryIds.indexOf(g.id) === -1);
          let unselectedGalleryIds = unselectedGalleries.map(gallery => { return gallery.id });
          selectedGalleryIds.forEach(id => {
            let gallery = this.galleries.find(g => g.id == id);
            if (gallery) {
              this.selectedGalleryNames.push(gallery.name);
            }
          })
          this.selectedGalleriesEvent.emit({ addedGalleryIds: selectedGalleryIds, removedGalleryIds: unselectedGalleryIds });
        }
      }
    }
  }
  sortGalleriesByOffice(a: Gallery, b: Gallery) {
    if (a.officeName.toUpperCase() < b.officeName.toUpperCase()) {
      return -1
    }
    else if (a.officeName.toUpperCase() > b.officeName.toUpperCase()) {
      return 1;
    }
    else {
      return 0;
    }
  }
}
