<div class="header-left">
  <a class="header-logo-wrap" routerLink="/dashboard">
    <p class="header-logo">Media Library</p>
  </a>
</div>

<div class="header-right">
  <!-- <button *ngIf="!login$ | async as login" class="button-blue button-text-white" routerLink="/login">Login</button> -->
  <div *ngIf="login$ | async as login" class="header-user-icon">
    <p class="user-icon">{{ login | slice:0:1 }}</p>
  </div>
</div>