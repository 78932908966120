<mat-form-field 
  class="tag-chip-list">
  <mat-chip-list #chipList>
    <mat-chip
      matChipRemove
      *ngFor="let tag of tags"
      [selectable]="true"
      [removable]="canAddTags"
      (removed)="removeTag(tag)">
      {{tag.tagName}}
      <mat-icon matChipRemove *ngIf="canAddTags">cancel</mat-icon>
    </mat-chip>
    <input
      [disabled]="!canAddTags"
      placeholder="Hello! Tags"
      #tagsearchBox 
      id="tag-search-box"
      [formControl]="taggingCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      >
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag($event.option.value)">
    <mat-option *ngFor="let tag of filteredTagOptions$ | async" [value]="tag">
      {{tag.tagName}}
    </mat-option>
  </mat-autocomplete> 
</mat-form-field>