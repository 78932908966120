<mat-form-field
class="type-ahead-component program-type-ahead-component">
    <input matInput
           placeholder="Program"
           #programSearchBox
           id="program-search-box"
           [formControl]="programFormControl"
           [matAutocomplete]="autoProgram">
    <mat-autocomplete #autoProgram="matAutocomplete"
                      (optionSelected)="selectedProgramEvent($event.option.value)"
                      [displayWith]="programDisplayFn">
        <mat-option *ngIf="programsLoading"
                    class="is-loading">
            <ng-container class="loading-indicator">
                <mat-spinner class="spin-center">
                </mat-spinner>
            </ng-container>
        </mat-option>
        <div>
            <mat-option class="uploader-auto-option"
                        *ngFor="let program of filteredProgramOptions$ | async"
                        [value]="program">
                <ng-container *ngIf="program">
                    <p>{{ program.number }}</p>
                    <p class="text-light">{{ program.name}}</p>
                    <p class="text-light">{{ program.clientName }}</p>
                </ng-container>
            </mat-option>
        </div>
    </mat-autocomplete>
</mat-form-field>