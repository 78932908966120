<form [formGroup]="uploadContextForm" (ngSubmit)="onSubmit()" class="upload-context-form">
  <!-- Office -->
  <mat-form-field>
    <mat-select placeholder="Office" formControlName="office" (selectionChange)="officeSelected($event)">
      <mat-option *ngFor="let office of offices" [value]="office.officeId">
        {{ office.officeName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Curator Type -->
  <mat-form-field *ngIf="showCurators && userCuratorTypes !== undefined && userCuratorTypes.length > 0">
    <mat-select placeholder="Curator Types" formControlName="curatorType">
      <mat-option *ngFor="let curatorType of userCuratorTypes" [value]="curatorType">
        {{ curatorType.typeName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Option... (means like Program or Supplier) todo: fix this-->
  <mat-form-field>
    <mat-select
      class="search-filter"
      placeholder="Program/Supplier"
      formControlName="optionValue"
      (selectionChange)="programSupplierSelected($event.value)"
    >
      <mat-option *ngFor="let option of optionTypes" [value]="option.value">
        {{ option.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Supplier -->
  <app-supplier-type-ahead
    *ngIf="uploadContextForm.value.optionValue == 'SUP'"
    [value]="uploadContextForm.value.supplier"
    [isCreative]="uploadContextForm.value.curatorType ? uploadContextForm.value.curatorType.isCreative : false"
    (supplierSelected)="supplierSelected($event)"
  ></app-supplier-type-ahead>
  <!-- Program -->
  <app-program-type-ahead
    *ngIf="uploadContextForm.value.optionValue == 'PSR'"
    [value]="uploadContextForm.value.program"
    [officeId]="uploadContextForm.value.office"
    (programSelected)="programSelected($event)"
  ></app-program-type-ahead>
  <!-- Service -->
  <ng-container *ngIf="uploadContextForm.value.optionValue == 'PSR' && uploadContextForm.value.program">
    <app-service-select
      [value]="uploadContextForm.value.service"
      [programId]="uploadContextForm.value.program.id"
      [isCreative]="uploadContextForm.value.curatorType ? uploadContextForm.value.curatorType.isCreative : null"
      (serviceSelected)="serviceSelected($event)"
    ></app-service-select>
  </ng-container>
  <!-- Service Venue (venues are apparently actually suppliers)-->
  <app-supplier-type-ahead
    *ngIf="uploadContextForm.value.optionValue == 'PSR' && uploadContextForm.value.service"
    [value]="uploadContextForm.value.venue"
    placeholder="Venue"
    (supplierSelected)="venueSelected($event)"
  ></app-supplier-type-ahead>

  <mat-form-field>
    <mat-label>Note</mat-label>
    <textarea
      matTextareaAutosize
      matAutosizeMinRows="1"
      matInput
      title="note"
      type="text"
      formControlName="note"
      cdkTextareaAutosize
      cdkAutosizeMaxRows="10"
    ></textarea>
  </mat-form-field>
</form>
