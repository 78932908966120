<div class="media-grid-wrap">


  <!-- loop through user's albums-->
  <div *ngFor="let album of albums"
       class="folder-item-wrap">
    <img class="folder-item-image"
         [src]="album.coverImageUrl">

    <div class="folder-hover">
      <button class="button-border-white folder-hover-button"
              (click)="viewAlbum(album)">
        View Album
      </button>
    </div>

    <div class="folder-meta-wrap">
      <div class="folder-meta-info">
        <div class="folder-meta-title">{{album.name}}</div>
        <div class="folder-meta-created">{{album.description}}</div>
      </div>

      <div class="folder-meta-count">
        {{album.totalMediaCount}}
      </div>
    </div>
  </div>
</div>