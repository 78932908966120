<ng-container *ngIf="loggedIn">
  <div class="navbar-left">
    <a class="navbar-search"
       routerLink="/search"
       routerLinkActive="navbar-search-active"
       matTooltip="Search">
      <i class="material-icons">search</i>
    </a>
  </div>
  
  <div class="navbar-right">
    <div *ngIf="canAddMedia">
      <a class="button-purple button-text-white navbar-button"
         routerLink="/upload">
        +Add
        Media
      </a>
    </div>
  
    <div class="navbar-link"
         *ngIf="login"
         routerLink="/users-content"
         matTooltip="My Media">
      <p class="navbar-link-text">{{ login | firstword }}</p>
    </div>
  
    <div *ngIf="canViewAdmin"
         class="navbar-link"
         routerLink="/admin"
         matTooltip="Admin">
      <i class="material-icons">settings</i>
    </div>
  
    <div *ngIf="canViewDraft"
         class="navbar-link navbar-alerts"
         routerLink="/drafted-media"
         matTooltip="Drafted Media">
      <i class="material-icons">art_track</i>
      <div class="navbar-alert-wrap" *ngIf="draftedMediaExists">
        <span class="alert-amount">!</span>
      </div>
    </div>
  
    <div class="navbar-link"
         matTooltip="My Pocket"
         (click)="pocketToggle()">
      <i class="material-icons">collections_bookmark</i>
    </div>
  </div>
</ng-container>
 
    <pocket-container *ngIf="isPocketOpen" (pocketClosed)="pocketToggle()">
  
    </pocket-container>

