import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { supplierModel } from '../models/supplier.model';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private http: HttpClient) {}

  getSuppliersByOffice(officeId: number): Observable<supplierModel[]> {
    return this.http.get<supplierModel[]>(environment.mediaApiEndpoint + 'Supplier?officeId=' + officeId);
  }

  getSuppliersByCorrelationKey(correlationKey: number) {
    return this.http.get<supplierModel[]>(environment.mediaApiEndpoint + 'Supplier?correlationKey=' + correlationKey).pipe(
      tap(_ => this.log('found Suppliers')),
      catchError(this.handleError<supplierModel[]>(`getSuppliersByCorrelationKey key = ${correlationKey}`))
    );
  }

  searchSupplierByOffice(offficeId: number, searchTerm, isCreative: any): Observable<supplierModel[]> {
    let url = environment.mediaApiEndpoint + 'Supplier';
    url += '?SupplierName=' + encodeURIComponent(searchTerm);
    if (offficeId && offficeId > 0) {
      url += '&OfficeId=' + offficeId;
    }
    if (isCreative !== undefined && isCreative !== null) {
      url += '&IsCreative=' + isCreative;
    }
    return this.http.get<supplierModel[]>(url).pipe(
      tap(_ => this.log('found Suppliers')),
      catchError(this.handleError<supplierModel[]>(`searchSupplierByProgram search term = ${searchTerm}`))
    );
  }

  getAllSuppliers(): Observable<supplierModel[]> {
    return this.http.get<supplierModel[]>(environment.mediaApiEndpoint + 'Supplier');
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string): void {
    console.log(message);
  }
}
