


    <div class="media-content-wrap medoa-content-wrap-dash"
        *ngIf="canViewDashboardItem">
        <div class="media-section-header">
            <p class="media-section-title">
                {{ sectionTitle }}
                <mat-slide-toggle *ngIf="filter" #isActive
                [(ngModel)]="onlyShared"               
                color="primary"
                (change)="onSharedAlbumChange()">
                {{ !isActive.checked ? 'All' : 'Shared Only' }}
              </mat-slide-toggle>
            </p>

            <a *ngIf="!showSeeMore"
            class="media-section-view"
            (click)="seeMore()">
                {{seeMoreText}}
            </a>
            <a *ngIf="showSeeMore"
            class="media-section-view"
            (click)="seeLess()">
                SEE LESS
            </a>
        </div>
        <ng-container *ngIf="isLoading">
            <div class="media-grid-wrap hide-overflow">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <div *ngIf="noMediaToShow"
                class="section-empty-small">
                <ng-container>
                    <i class="material-icons section-empty-icon">{{ emptyIcon }}</i>
                    <p class="section-empty-text-small">{{ emptyText }}</p>
                </ng-container>
            </div>

            <div class="media-grid-wrap">
                <div class="folder-item-wrap"
                    *ngFor="let item of items">

                    <app-collection-tile [id]="item.id"
                                        [thumbNailUrl]="item.coverImageUrl"
                                        [count]="item.totalMediaCount"
                                        [created]="item.description"
                                        [title]="item.name"
                                        [type]="type"
                                        [album]="item">
                    </app-collection-tile>
                </div>
            </div>
        </ng-container>
    </div>