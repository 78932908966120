import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { unionBy } from 'lodash';
import { Media } from '../../models/media.model';
import { SearchRequest } from '../../models/search.model';
import { UploadClusters } from '../../models/upload-clusters.model';
import * as mediaActions from '../actions/media.actions';
import * as officeActions from '../actions/offices.actions';
import * as pocketActions from '../actions/pocket.actions';

export interface MediaState {
  items: Media[];
  recentlyPublishedMediaIds?: number[];
  mostActiveMediaIds?: number[];
  mostViewMediaIds?: number[];
  searchResultIds: number[];
  isMediaLiked: { mediaId: number; isLiked: boolean }[];
  recentlyAddedMediaIds?: number[];
  mediaPocketIds: number[];
  uploadQueue: MediaUploadState[];
  supplierDraftedMedia: UploadClusters[];
  programDraftedMedia: UploadClusters[];
  libraryServiceDraftedMedia: UploadClusters[];
  iPadMigrationMedia: UploadClusters[];
  miscDraftedMedia: UploadClusters[];
  editedImageId?: number;
  originalImageId?: number;
  lastSearchRequest?: SearchRequest;
}

export interface MediaUploadState {
  fileName: string;
  id?: number;
  uploadProgressPercent?: number;
  inProgress?: boolean;
  message?: string;
  errorCode?: number;
  order?: number;
  media?: Media; //Never stored in state. Here so view can use it. Awkward. TODO: Fix.
}

const initialState: MediaState = {
  items: [],
  recentlyPublishedMediaIds: null,
  mostActiveMediaIds: null,
  mostViewMediaIds: null,
  recentlyAddedMediaIds: null,
  searchResultIds: [],
  mediaPocketIds: [],
  uploadQueue: [],
  isMediaLiked: [],
  supplierDraftedMedia: [],
  programDraftedMedia: [],
  libraryServiceDraftedMedia: [],
  iPadMigrationMedia: [],
  miscDraftedMedia: [],
  lastSearchRequest: null,
};

const mediaReducer = createReducer(
  initialState,
  on(mediaActions.getRecentlyPublishedMediaSuccess, (state, action) => {
    const currentItems = state.items.slice();
    const result = updateExistingMedia(currentItems, action.media);
    const recentlyPublishedMediaIds = extractIds(action.media, 'mediaId');
    return { ...state, items: result, recentlyPublishedMediaIds: recentlyPublishedMediaIds };
  }),
  on(officeActions.selectOffice, (state, action) => {
    return { ...state, recentlyAddedMediaIds: null, mostActiveMediaIds: null, recentlyPublishedMediaIds: null, mostViewMediaIds: null };
  }),
  on(mediaActions.searchMediaByLibraryServiceCorrelationKey, (state, action) => {
    return { ...state, lastSearchRequest: null, searchResultIds: [] };
  }),
  on(mediaActions.searchMediaByLibraryServicecorrelationKeySuccess, (state, action) => {
    const resultingMedia = updateExistingMedia(state.items, action.media);
    const searchIds = extractIds(action.media, 'mediaId');
    return { ...state, items: resultingMedia, searchResultIds: searchIds };
  }),
  on(mediaActions.getMediaMetadataByIdSuccess, (state, action) => {
    let matchingMedia = state.items.filter(item => item.mediaId == action.metaData.id);
    matchingMedia=JSON.parse(JSON.stringify(matchingMedia));
    if (matchingMedia.length) {
      matchingMedia[0].metaData = action.metaData;
    } else {
      //We didn't already have media in state, so we have to map metadata back to create the full object
      //this is necessary because the backend returns a stupid half model on getAll/search operations,
      //but the metadata contains the actual full data
      matchingMedia = [];
      const newMedia: Media = {
        mediaId: action.metaData.id.toString(),
        name: action.metaData.name,
        description: action.metaData.description,
        fileName: action.metaData.fileName,
        fileName_Thumbnail: action.metaData.fileNameThumbnail,
        status: setMediaStatus(action.metaData.statusDescription),
        type: action.metaData.mediaType,
        tags: [], //We never use this, can probably be removed from model
        links: action.metaData.links,
        metaData: action.metaData,
      };
      matchingMedia.push(newMedia);
    }
    const updatedMedia = updateExistingMedia(state.items.slice(), matchingMedia);
    return { ...state, items: updatedMedia };
  }),
  on(mediaActions.getRelatedMediaSuccess, (state: MediaState, action) => {
    const matchingMedia = state.items.filter(item => item.mediaId == action.mediaId);
    matchingMedia[0].relatedMedia = action.media;
    let updatedMedia = updateExistingMedia(state.items.slice(), matchingMedia);
    return { ...state, items: updatedMedia };
  }),
  on(mediaActions.editMediaSuccess, (state: MediaState, action) => {
    const currentItemms = state.items.slice();
    // new image added to state
    const result = updateExistingMedia(currentItemms, [action.media]);
    // the original image has been archived
    _.find(result, { mediaId: action.originalMediaId }).status = 'ARC';
    // pass original image id and new image so we can swap out the relevant bits in the UI
    return { ...state, items: result, editedImageId: +action.media.mediaId, originalImageId: action.originalMediaId };
  }),
  on(mediaActions.updateMediaStatusSuccess, (state: MediaState, action) => {
    const currentItems = state.items.slice();
    const result = updateExistingMedia(currentItems, [action.media]);
    return { ...state, items: result };
  }),
  on(mediaActions.getMediaByGalleryIdSuccess, (state: MediaState, action) => {
    const currentItems = state.items.slice();
    const result = unionBy(currentItems, action.media, 'mediaId');
    return { ...state, items: result };
  }),
  on(mediaActions.getMediaByAlbumIdSuccess, (state: MediaState, action) => {
    const currentItems = state.items.slice();
    const result = unionBy(currentItems, action.media, 'mediaId');
    return { ...state, items: result };
  }),
  on(mediaActions.getMostActiveMediaSuccess, (state: MediaState, action) => {
    const currentMedia = state.items.slice();
    const newIds = action.media.map(m => {
      if (m != null) {
        return m.mediaId;
      }
    });
    const newItems = unionBy(currentMedia, action.media, 'mediaId');
    return { ...state, items: newItems, mostActiveMediaIds: newIds };
  }),
  on(mediaActions.getRecentlyAddedMediaSuccess, (state: MediaState, action) => {
    const currentMedia = state.items.slice();
    const newIds = action.media.map(m => {
      if (m != null) {
        return m.mediaId;
      }
    });
    const newItems = unionBy(currentMedia, action.media, 'mediaId');
    return { ...state, items: newItems, recentlyAddedMediaIds: newIds };
  }),
  on(mediaActions.getMostViewedMediaSuccess, (state: MediaState, action) => {
    const currentMedia = state.items.slice();
    const newIds = action.media.map(m => {
      if (m != null) {
        return m.mediaId;
      }
    });
    const newItems = unionBy(currentMedia, action.media, 'mediaId');
    return { ...state, items: newItems, mostViewMediaIds: newIds };
  }),
  on(mediaActions.searchMediaSuccess, (state: MediaState, action) => {
    let searchResultIds = action.media.map(m => parseInt(<string>m.mediaId));
    if (!action.clear) {
      searchResultIds = state.searchResultIds.concat(searchResultIds);
    }
    const resultingMedia = updateExistingMedia(state.items, action.media);
    return { ...state, items: resultingMedia, searchResultIds: searchResultIds, lastSearchRequest: action.searchRequest };
  }),
  on(pocketActions.addMediaToPocketSuccess, (state: MediaState, action) => {
    let currentPocket = state.mediaPocketIds.slice();
    currentPocket.push(+action.mediaId);
    return { ...state, mediaPocketIds: currentPocket };
  }),
  on(pocketActions.removeMediaFromPocketSuccess, (state: MediaState, action) => {
    let currentPocket = state.mediaPocketIds.slice();
    let newPocketIds = currentPocket.filter(id => id != action.mediaId);
    return { ...state, mediaPocketIds: newPocketIds };
  }),
  on(pocketActions.getMediaForPocketSuccess, (state: MediaState, action) => {
    let usersPocketMedia = action.media;
    let pocketedMedia = [];
    let pocketedMediaIds = [];
    // Not a fan of this approach, but because the backend returns a different object than the Media
    // object we use on the frontend, we have to manually assign each value individually to a new ^ object.
    // Same approach as the 'getMediaMetadataByIdSuccess' call in this file.
    // Mapping over each media and transforming it from the MediaMetadata object to just Media.
    usersPocketMedia.map(media => {
      const newMedia: Media = {
        mediaId: media.id.toString(),
        name: media.name,
        description: media.description,
        fileName: media.fileName,
        fileName_Thumbnail: media.fileNameThumbnail,
        status: setMediaStatus(media.statusDescription),
        type: media.mediaType,
        tags: [], //We never use this, can probably be removed from model
        links: media.links,
        metaData: media,
      };

      // pocketedMedia array for the metadata of the media
      // pocketedMediaIds for the ids of the media
      pocketedMedia.push(newMedia);
      pocketedMediaIds.push(media.id.toString());
    });

    const updatedMedia = updateExistingMedia(state.items.slice(), pocketedMedia);

    return { ...state, mediaPocketIds: pocketedMediaIds, items: updatedMedia };
  }),
  on(mediaActions.uploadMediaMessage, (state: MediaState, action) => {
    const uploadQueue = state.uploadQueue;
    let matchingItem = uploadQueue.filter(i => i.fileName == action.mediaUploadState.fileName);
    action=JSON.parse(JSON.stringify(action));
    if (matchingItem.length) {
      action.mediaUploadState.order = matchingItem[0].order;
    } else {
      action.mediaUploadState.order = uploadQueue.length + 1;
    }
    const newUploadQueue = unionBy([action.mediaUploadState], uploadQueue, 'fileName');
    return { ...state, uploadQueue: newUploadQueue };
  }),
  on(mediaActions.updateSupplierOnMediaSuccess, (state: MediaState, action) => {
    let currentMedia = state.items.filter(i => i.mediaId == action.mediaId);
    let resultingMedia = state.items;
    currentMedia=JSON.parse(JSON.stringify(currentMedia));
    if (currentMedia.length) {
      if (action.add) {
        currentMedia[0].metaData.suppliers.push(action.supplier);
      } else {
        currentMedia[0].metaData.suppliers = currentMedia[0].metaData.suppliers.filter(s => s.supplierId != action.supplier.supplierId);
      }
      resultingMedia = updateExistingMedia(state.items, currentMedia);
    }

    return { ...state, items: resultingMedia };
  }),
  on(mediaActions.removeAzureTagFromMedia, (state: MediaState, action) => {
    action.media.metaData.tags = action.media.metaData.tags.filter(t => !(t.type == 3 && t.name == action.name));
    const resultingMedia = updateExistingMedia(state.items, [action.media]);
    return { ...state, items: resultingMedia };
  }),
  on(mediaActions.addTagToMediaOptimisticUpdate, (state: MediaState, action) => {
    const mediaIds = action.criteria.mediaAssets;
    const matchingMedia = mediaIds.map(id => {
      const itemArray = state.items.filter(item => {
        return item.mediaId == id;
      });
      const newTag = {
        tagId: action.tag.id,
        type: 2,
        name: action.tag.tagName,
        tagCategoryName: action.tag.tagCategoryName,
      };
      itemArray[0].metaData.tags.push(newTag);
      return itemArray[0];
    });
    const resultingMedia = updateExistingMedia(state.items, matchingMedia);
    return { ...state, items: resultingMedia };
  }),
  on(mediaActions.removeTagFromMediaOptimisticUpdate, (state: MediaState, action) => {
    const matchingMedia = state.items
      .filter(item => item.mediaId == action.mediaId)
      .map(item => {
        item.metaData.tags = item.metaData.tags.filter(t => t.tagId != action.tag.id);
        return item;
      });
    const resultingMedia = updateExistingMedia(state.items, matchingMedia);
    return { ...state, items: resultingMedia };
  }),
  on(mediaActions.getIsMediaLikedSuccess, (state: MediaState, action) => {
    const likesMapping = state.isMediaLiked.slice();
    const result = unionBy(likesMapping, [{ mediaId: action.mediaId, isLiked: action.isLiked }], 'mediaId');
    return { ...state, isMediaLiked: result };
  }),
  on(mediaActions.getIsMediaLikedFail, (state: MediaState, action) => {
    const likesMapping = state.isMediaLiked.slice();
    const result = unionBy(likesMapping, [{ mediaId: action.mediaId, isLiked: false }], 'mediaId');
    return { ...state, isMediaLiked: result };
  }),
  on(mediaActions.setIsMediaLikedSuccess, (state: MediaState, action) => {
    const likesMapping = state.isMediaLiked.slice();
    let mapping = likesMapping.find(x => x.mediaId == action.mediaId);
    mapping=JSON.parse(JSON.stringify(mapping));
    if (mapping) {
      mapping.isLiked = action.isLiked;
    } else {
      likesMapping.push({ mediaId: action.mediaId, isLiked: action.isLiked });
    }
    let matchingMedia = state.items.filter(m => m.mediaId == action.mediaId);
    matchingMedia=JSON.parse(JSON.stringify(matchingMedia));
    let resultingMedia = state.items;
    if (matchingMedia.length && matchingMedia[0].metaData) {
      matchingMedia[0].metaData.liked = action.isLiked ? 1 : 0;
      resultingMedia = updateExistingMedia(matchingMedia, state.items);
    }
    return { ...state, isMediaLiked: likesMapping, items: resultingMedia };
  }),
  on(mediaActions.clearUploadQueue, (state: MediaState, action) => {
    const uploadQueue = [];
    return { ...state, uploadQueue: uploadQueue };
  }),
  on(mediaActions.getDraftedMediaSuccess, (state: MediaState, action) => {
    return {
      ...state,
      supplierDraftedMedia: action.supplierDraftedMedia,
      programDraftedMedia: action.programDraftedMedia,
      miscDraftedMedia: action.miscDraftedMedia,
      libraryServiceDraftedMedia: action.libraryServiceDraftedMedia,
      iPadMigrationMedia: action.iPadMigrationMedia,
    };
  }),
  on(mediaActions.setUploadQueue, (state: MediaState, action) => {
    return { ...state, uploadQueue: action.uploadQueue };
  }),
  on(mediaActions.removeAzureTagFromMediaSuccess, (state: MediaState, action) => {
    const currentItems = state.items.slice();
    currentItems.map(media => {
      if (media.mediaId == action.mediaId) {
        let changedMedia = media;
        changedMedia.metaData.tags = changedMedia.metaData.tags.filter(tag => {
          if (tag && tag.name !== action.name) {
            return tag;
          }
          // Hello! and Azure Tags can have the same name, but Hello!
          // tags wil always have a tagId
          else if (tag && tag.name === action.name && tag.tagId !== null) {
            return tag;
          }
        });
      }
    });

    return { ...state, items: currentItems };
  }),
  on(mediaActions.bulkUpdateMediaStatusSuccess, (state: MediaState, action) => {
    //update any necessary metadata
    let matchingMedia = state.items.filter(i => action.mediaIds.indexOf(+i.mediaId) > -1);
    matchingMedia.map(m => {
      if (m.metaData) {
        m.metaData.statusId = action.statusId;
      }
    });
    let returnMedia = updateExistingMedia(state.items, matchingMedia);
    //Also clear search result ids that we just archived
    let searchResults = state.searchResultIds.filter(sr => action.mediaIds.indexOf(sr) === -1);
    return { ...state, items: returnMedia, searchResultIds: searchResults };
  }),
  on(pocketActions.clearPocketSuccess, (state: MediaState, action) => {
    return { ...state, mediaPocketIds: [] };
  })
);

// Helper Functions

//Gross. TODO: Fix with enum
const setMediaStatus = (status: string) => {
  let result = 'DFT';
  switch (status.toLowerCase()) {
    case 'published':
      result = 'PBH';
      break;
    case 'archived':
      result = 'ARC';
      break;
  }
  return result;
};

const updateExistingMedia = (currentItems, newItems) => {
  const result = currentItems.map(item => {
    let matchedItem = newItems.filter(newItem => newItem.mediaId == item.mediaId);
    item=JSON.parse(JSON.stringify(item));
    if (matchedItem.length) {
      return Object.assign(item, matchedItem[0]);
    } else {
      return item;
    }
  });
  const itemIds = extractIds(result, 'mediaId');
  const remainingNewItems = newItems.filter(item => itemIds.indexOf(parseInt(item.mediaId)) < 0);
  remainingNewItems.map(item => {
    result.push(item);
  });
  return result;
};

const extractIds = (items: any[], key: string) => {
  return items.map(item => {
    return parseInt(item[key]);
  });
};

// Export Wrapper - necessary because angular...
export function media(state: MediaState, action: Action) {
  return mediaReducer(state, action);
}
