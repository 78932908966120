import { Component, OnInit } from '@angular/core';
import { MediaService } from '../../../services/media.service';
import {AdminService} from '../../../services/admin.service';
import { mediaTypeModel } from '../../../models/media-type.model';
import { AllowedUploadTypesModel } from '../../../models/allowed-upload-types.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-media-formats-management',
  templateUrl: './admin-media-formats-management.component.html',
  styleUrls: ['./admin-media-formats-management.component.scss']
})
export class AdminMediaFormatsManagementComponent implements OnInit {

	public openAddFormat: boolean = false;
	public addEditCaption: string = "Add New File Extension to Support";
	public addEditButtonCaption: string = "ADD";

	public mediaTypes: mediaTypeModel[];
	public selectedType: mediaTypeModel;
	public allowedTypes: AllowedUploadTypesModel[];
	public selectedAllowedType: AllowedUploadTypesModel;

	public selectedIndex: number;

	public size: FormControl;
	public extension: FormControl;
	public fileType: FormControl;

  constructor(
  	private mediaService: MediaService,
  	private adminService: AdminService
  	) { }

  ngOnInit() {
  	this.size = new FormControl();
  	this.extension = new FormControl();
  	this.fileType = new FormControl();
  	this.mediaService.getMediaTypes().subscribe(res => {
  		this.mediaTypes = res;
  	})
  }

  addFormat() {
  	this.openAddFormat = !this.openAddFormat;
  }

  isMediaTypeSelected() {
  	return true;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  getMediaType(event) {
  	if(event.isUserInput && event.source.selected) {
  		this.selectedType = event.source.value;
  		this.adminService.getAllowedUploadTypesByMedia(this.selectedType.id).subscribe(res => {
  			this.allowedTypes = res;
  		})
  	}
  }

  isImage(typeModel: AllowedUploadTypesModel) {
    return typeModel.mediaTypeCode.toLowerCase() === 'img';
  }

  isAudio(typeModel: AllowedUploadTypesModel) {
    return typeModel.mediaTypeCode.toLowerCase() === 'ado';
  }

  isVideo(typeModel: AllowedUploadTypesModel) {
    return typeModel.mediaTypeCode.toLowerCase() === 'vdo';
  }

  onEditClicked(clickedIndex: any) {
    if (!this.openAddFormat){
      this.addFormat();
    }
    this.selectedIndex = clickedIndex;
    this.addEditCaption = 'Edit File Extension ' + this.allowedTypes[this.selectedIndex].fileExtension;
    this.addEditButtonCaption = 'SAVE';
    let editedType = this.allowedTypes[clickedIndex];
    this.size.setValue(editedType.maxSizeInKb);
    this.extension.setValue(editedType.fileExtension);
    this.fileType.setValue(this.selectedType);
  }

  onAddClicked() {
    if  (this.selectedIndex){
      const typeToRemove = this.allowedTypes[this.selectedIndex];
      this.adminService.deleteAllowedUploadTypes(typeToRemove.mediaTypeId,
                  typeToRemove.fileExtension).subscribe();
      this.selectedIndex = null
    }

    this.selectedAllowedType = new AllowedUploadTypesModel();
    this.selectedAllowedType.mediaTypeId = this.selectedType.id;
    this.selectedAllowedType.fileExtension = this.extension.value;
    this.selectedAllowedType.maxSizeInKb = this.size.value;

    this.adminService.postAllowedUploadTypes(this.selectedAllowedType).subscribe(p => {
        this.adminService.getAllowedUploadTypesByMedia(this.selectedType.id)
            .subscribe(u => {
            	this.allowedTypes = u;
            	this.openAddFormat = false;
            });
      });

  }

  onRemoveClicked(clickedIndex: any) {
    const typeToRemove = this.allowedTypes[clickedIndex];
    this.adminService.deleteAllowedUploadTypes(typeToRemove.mediaTypeId,
           typeToRemove.fileExtension).subscribe(d => {
              this.adminService.getAllowedUploadTypesByMedia(this.selectedType.id)
              .subscribe(u => this.allowedTypes = u);
            });
  }

}
