<div class="media-item-wrap">
    <img *ngIf="media && media.links.thumbnailUrl"
         [id]="componentKey"
         class="media-item"
         src="{{media.links.thumbnailUrl}}"
         title="{{media.name}}">
    <i class="material-icons"
       *ngIf="media && !media.links.thumbnailUrl">
        image
    </i>
    <mat-checkbox *ngIf="selectable"
                  (click)="imageSelected()"
                  class="media-item-select-box"></mat-checkbox>

    <div class="media-item-hover">
        <button class="button-border-white media-hover-button"
                (click)="openDialog(media.mediaId)">
            quick look
        </button>
        <a class="media-item-link"
           [routerLink]="['/media', media.mediaId]">
            <button class="button-border-white media-hover-button">
                Detailed View
            </button>
        </a>
        <div class="thumbnail-copy-buttons">
            <mat-icon class="pocket-image-copy-url"
                      matTooltip="{{urlToolTip}}"
                      (click)="copyImageUrl(media.links.fileUrl)">
                link
            </mat-icon>
            <mat-icon *ngIf="isImage"
                      class="pocket-image-copy"
                      matTooltip="Copy To Clipboard"
                      (click)="copyToClipboard(media)">
                filter_none
            </mat-icon>
        </div>


    </div>
</div>