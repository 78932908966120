


  <div class="media-content-wrap media-content-wrap-dash">

    <div class="media-section-header">
      <p class="media-section-title">
        {{ sectionTitle }}
      </p>

      <a *ngIf="showSeeMore"
        class="media-section-view"
        (click)="setSeeMore(false)">
        SEE MORE
      </a>
      <a *ngIf="!showSeeMore"
        class="media-section-view"
        (click)="setSeeMore(true)">
        SEE LESS
      </a>
    </div>

    <ng-container *ngIf="isLoading">
      <div class="media-grid-wrap hide-overflow">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
    <div *ngIf="mediaGroup.length === 0 "
        class="section-empty-small">
      <ng-container>
        <i class="material-icons section-empty-icon">camera_roll</i>
        <p class="section-empty-text-small">No Media</p>
      </ng-container>
    </div>

      <div class="media-grid-wrap">
        <app-media-item *ngFor="let media of mediaGroup | slice:0:resultCount;index as i"
                        [media]="media"
                        [gallery]="mediaGroup"
                        [slideIndex]="i"
                        >
        </app-media-item>
      </div>
    </ng-container>
  </div>
