import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { clientModel } from '../../../../models/client.model';
import { ProgramService } from '../../../../services/program.service';

@Component({
  selector: 'app-client-type-ahead',
  templateUrl: './client-type-ahead.component.html',
  styleUrls: ['./client-type-ahead.component.css']
})
export class ClientTypeAheadComponent implements OnInit {

  @Input() value: clientModel;
  @Input() programId: number;

  @Output() clientSelected = new EventEmitter<clientModel>();
    @Output() filterCleared = new EventEmitter<boolean>();

  public filteredClientOptions$: Observable<clientModel[]>;
  public clientFormControl: FormControl;
  public clientsLoading: boolean = false;

  constructor(private programService: ProgramService) { }

  ngOnInit() {
    this.clientFormControl = new FormControl();
    if (this.value) {
      this.clientFormControl.setValue(this.value);
    }
    this.filteredClientOptions$ = this.clientFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(s => typeof s === 'string'),
      switchMap(s => {
        if (s === '') {
          this.filterCleared.emit(true);
          return of([]);
        }
        this.clientsLoading = true;
        return this.programService.searchClientsByProgram(this.programId, s).pipe(catchError(error => of([])));
      }),
      map(items => {
        this.clientsLoading = false;
        if (items) {
          return items.slice(0, 20);
        } else {
          return [];
        }
      })
    )
  }

  ngOnChanges(simpleChanges) {
    if (this.clientFormControl) {
      this.clientFormControl.setValue(this.value);
    }
  }

  selectedClientEvent(client) {
    this.clientSelected.emit(client);
  }

  clientDisplayFn(client?: clientModel): string | undefined {
    const clientName = (client && client.name) ? client.name : '';
    return client ? clientName : undefined;
  }

}
