import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public userId: number;
    public roleId: number;
    private user;
    constructor(private store: Store<any>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const user = JSON.parse(sessionStorage.getItem('profile_user'));
      if(!user || Object.keys(user).length === 0 || !user.userLoginId) {
        return next.handle(req);
      }
      if (user && Object.keys(user).length && user.userLoginId>0) {

            const headers = new HttpHeaders({
              'userId': String(user.userLoginId),
              'roleId': String(user.roleId),
              'Authorization': 'Basic ' + btoa('ebe8c704c287faf6d3fc4bd7959c9ca6:$c00byD00'),
            });
         
            const authReq = req.clone({headers});
            return next.handle(authReq);
      }
  }
}
