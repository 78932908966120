import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'login-view',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginViewComponent implements OnInit {
	
  constructor(private router: Router,   
    private authService: MsalService,
    private store$: Store<any>
    , private userService: UserService
    ,private broadcastService: MsalBroadcastService,
    private mlAuthService:AuthService,
    ) { }
    public login$: Observable<string>;
    profile: any;
    
    isIframe = false;
    loginDisplay = false;
  ngOnInit() {
    
    const loggedIn$ = this.store$.pipe(select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false || res.user=== null || res.user.userLoginId === null || res.user.userLoginId===0));
    loggedIn$.subscribe(res =>
       {this.router.navigate(['dashboard']);
      });

  }

  setLoginDisplay() {

   

    }

}
