import { createSelector } from '@ngrx/store';
import { Media } from '../../models/media.model';
import { AppState } from '../reducers';
import { AlbumsState } from '../reducers/albums.reducer';


export const albumState = (state: AppState) => state.albums;
export const mediaState = (state: AppState) => state.media.items;

export const selectUserAlbums = createSelector(albumState,
    (albumState: AlbumsState, props) => {
        if (albumState.albums){
            albumState=JSON.parse(JSON.stringify(albumState));
            albumState.albums.sort((a, b) => {
                if (a.isFavorite) return -1;
                if (b.isFavorite) return 1;
                else return 0;
            });
        }
           
        
        
        return albumState.albums;
    })
export const selectAlbumById = createSelector(albumState,
    (albumState: AlbumsState, props) => {
        let mediaId = +props.albumId;
        let album = albumState.albums.filter(a => a.id === mediaId)[0];
        return album;
    })
export const getMediaFromAlbum = createSelector(albumState, mediaState,
    (albumState: AlbumsState, mediaItems: Media[], props) => {
        let mappingObj = albumState.albumMediaMapping.filter(x => x.albumId === +props.albumId);
        let media = [];
        if (mappingObj && mappingObj.length > 0) {
            let mediaIds = mappingObj[0].mediaIds;
            media = mediaItems.filter(mItem => mediaIds.indexOf(+mItem.mediaId) > -1)
        }

        return media;

    })
