export class TagCategoryModel {
    id?: number;
    name: string;
    activeFlag: boolean;
    tags?: TagItems[];
}

export class TagItems {
    id: number;
    tag: string;
    tagCategoryId: number;
    activeFlag: boolean;
}

export class TagCategory {
    id: number;
    name: string;
    activeFlag: boolean;
}

export class TagItemModel {
    id: number;
    tagName: string;
    tagCategory: number;
    activeFlag: boolean;
}
