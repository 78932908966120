import { createAction, props } from '@ngrx/store';
import { Album, AlbumEditDTO } from '../../models/album.model';
import { Media } from '../../models/media.model';
import { AlbumCreateCommand } from '../../services/album.service';

export const getUserAlbums = createAction('[albums] getUserAlbums');
export const getUserAlbumsSuccess = createAction('[albums] getUserAlbumsSuccess', props<{ albums: any[] }>());
export const getUserAlbumsFail = createAction('[albums] getUserAlbumsFail');

export const getUserSharedAlbums = createAction('[albums] getUserSharedAlbums');
export const getUserSharedAlbumsSuccess = createAction('[albums] getUserSharedAlbumsSuccess', props<{ sharedAlbums: any[] }>());
export const getUserSharedAlbumsFail = createAction('[albums] getUserSharedAlbumsFail');

export const getMediaForAlbum = createAction('[albums] getMediaForAlbum', props<{ albumId: number }>());
export const getMediaForAlbumSuccess = createAction('[albums] getMediaForAlbumSuccess', props<{ albumId: number, albumMedia: Media[] }>());
export const getMediaForAlbumFail = createAction('[albums] getMediaForAlbumFail');

export const createUserAlbum = createAction('[albums] createUserAlbum', props<{ newAlbumDto: AlbumCreateCommand }>());
export const createUserAlbumsSuccess = createAction('[albums] createUserAlbumSuccess', props<{ newAlbum: Album }>());
export const createUserAlbumsFail = createAction('[albums] createUserAlbumFail');

export const addMediaToAlbums = createAction('[albums] addMediaToAlbums', props<{ mediaId: number | string, selectedAlbumIds: number[] }>());
export const addMediaToAlbumsSuccess = createAction('[albums] addMediaToAlbumsSuccess', props<{ mediaId: number | string, selectedAlbumIds: number[] }>());

export const removeMediaFromAlbums = createAction('[albums] removeMediaFromAlbums', props<{ mediaId: number, selectedAlbumIds: number[] }>());
export const removeMediaFromAlbumsSuccess = createAction('[albums] removeMediaFromAlbumsSuccess', props<{ mediaId: number, selectedAlbumIds: number[] }>());

export const bulkRemoveMediaFromAlbum = createAction('[albums] bulkRemoveMediaFromAlbum',
	props<{mediaIds: number[], albumId: number}>());
export const bulkRemoveMediaFromAlbumSuccess = createAction('[albums] bulkRemoveMediaFromAlbumSuccess',
	props<{mediaIds: number[], albumId: number}>());

	export const bulkAddMediaFromAlbum = createAction('[albums] bulkAddMediaFromAlbum',
	props<{mediaIds: number[], selectedAlbumIds: number[]}>());
export const bulkAddMediaFromAlbumSuccess = createAction('[albums] bulkAddMediaFromAlbumSuccess',
	props<{mediaIds: number[], selectedAlbumIds: number[]}>());

export const editAlbum = createAction('[albums] editAlbum',
	props<{albumEditDTO: AlbumEditDTO}>());
export const editAlbumSuccess = createAction('[albums] editAlbumSuccess',
	props<{album: Album}>());

export const updateAlbumStatus = createAction('[album] updateAlbumStatus',
	props<{id: number, isPublic: boolean}>());
export const updateAlbumStatusSuccess = createAction('[album] updateAlbumStatusSuccess',
	props<{id: number, isPublic: boolean}>());

export const deleteAlbum = createAction('[album] deleteAlbum',
	props<{id: number}>());
export const deleteAlbumSuccess = createAction('[album] deleteAlbumSuccess',
	props<{id: number}>());