<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb">
        Drafted Media
      </li>
    </ul>
  </div>

  <div class="page-context-wrap">
    <div class="page-context">
      <mat-select class="page-context-filter"
                  placeholder="Office"
                  [formControl]="officeFormControl">
        <mat-option *ngFor="let office of officeList"
                    [value]="office.officeId">
          {{office.officeName}}
        </mat-option>
      </mat-select>
    </div>

    <div class="page-context"
         *ngIf="userCuratorTypes && userCuratorTypes.length > 0">
      <mat-select class="page-context-filter"
                  placeholder="Curator Types"
                  name="CuratorUserMetaData"
                  [formControl]="curatorTypeFormControl">
        <mat-option *ngFor="let curatorType of userCuratorTypes"
                    [value]="curatorType">
          {{curatorType.typeName}}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>
<ng-container *ngIf="isLoading()">
  <div class="media-grid-wrap hide-overflow">
      <mat-spinner></mat-spinner>
  </div>
</ng-container>
<div class="page-content-wrap">
  <mat-accordion *ngIf="!isLoading()" multi>
  <mat-expansion-panel (opened)="setPage()" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="draft-section-title">
          Supplier Uploads
          <span class="draft-section-count"
                *ngIf='supplierDraftedMedia!==undefined'>({{ supplierDraftedMedia.length }})</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    
    <mat-table [dataSource]="supplierDraftedMediaSource" matSort>
       
      <ng-container matColumnDef="date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Upload Date
          
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.date }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sourceLocation">
        <mat-header-cell class="header" mat-sort-header *matHeaderCellDef>Source
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="supplierSourceFilter" matInput [formControl]="supplierSourceLocationFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.sourceLocation }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="supplierName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Supplier
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="supplierNameFilter" matInput [formControl]="supplierNameFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.supplierName }} </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="officeName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Office</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.officeName }} </mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="count">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Count</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.count }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isArchived">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">  
          <a class="cluster-link"
          (click)="editCluster(item,'SUP')"
          *ngIf="!item.isArchived">
         Cluster Overview
       </a> 
       <a class="cluster-link"
       *ngIf="item.isArchived">
      Cluster Archived
    </a>
      </mat-cell>
      </ng-container>
      <ng-container matColumnDef="menu">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">   <mat-menu #clusterMenu="matMenu">
          <button mat-menu-item
                  (click)="publishCluster(item)">Publish Cluster</button>
          <button mat-menu-item
                  (click)="archiveCluster(item)"
                  *ngIf="!item.isArchived">Archive Cluster</button>
          <button mat-menu-item
                  (click)="unarchiveCluster(item)"
                  *ngIf="item.isArchived">Unarchive Cluster</button>
        </mat-menu>
        <button mat-icon-button
                [matMenuTriggerFor]="clusterMenu">
          <mat-icon>more_vert</mat-icon>
        </button> </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedSupplierColumns; sticky: true"> </mat-header-row>
  
      <mat-row *matRowDef="let row; columns: displayedSupplierColumns"></mat-row>
      
    </mat-table>
    <mat-paginator [length]="supplierDraftedMediaSource.data.length" #supplierPaginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="setPage()" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="draft-section-title">
          Program Uploads
          <span class="draft-section-count"
                *ngIf='programDraftedMedia!==undefined'>({{ programDraftedMedia.length }})</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-table [dataSource]="programDraftedMediaSource" matSort>
       
      <ng-container matColumnDef="date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Upload Date
          
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.date }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sourceLocation">
        <mat-header-cell class="header" mat-sort-header *matHeaderCellDef>Source
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="programSourceFilter" matInput [formControl]="programSourceLocationFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.sourceLocation }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="programName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Program
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="programNameFilter" matInput [formControl]="programNameFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.programName }} </mat-cell>
      </ng-container>
     
      <ng-container matColumnDef="programServiceName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Service
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="programServiceFilter" matInput [formControl]="programServiceFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.programServiceName }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="programServiceDate">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Service Date</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.programServiceDate }} </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="officeName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Office</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.officeName }} </mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="count">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Count</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.count }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isArchived">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">  
          <a class="cluster-link"
          (click)="editCluster(item,'PSR')"
          *ngIf="!item.isArchived">
         Cluster Overview
       </a> 
       <a class="cluster-link"
       *ngIf="item.isArchived">
      Cluster Archived
    </a>
      </mat-cell>
      </ng-container>
      <ng-container matColumnDef="menu">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">   <mat-menu #clusterMenu="matMenu">
          <button mat-menu-item
                  (click)="publishCluster(item)">Publish Cluster</button>
          <button mat-menu-item
                  (click)="archiveCluster(item)"
                  *ngIf="!item.isArchived">Archive Cluster</button>
          <button mat-menu-item
                  (click)="unarchiveCluster(item)"
                  *ngIf="item.isArchived">Unarchive Cluster</button>
        </mat-menu>
        <button mat-icon-button
                [matMenuTriggerFor]="clusterMenu">
          <mat-icon>more_vert</mat-icon>
        </button> </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedProgramColumns; sticky: true"> </mat-header-row>
  
      <mat-row *matRowDef="let row; columns: displayedProgramColumns"></mat-row>

    
    </mat-table>
    <mat-paginator  [length]="programDraftedMediaSource.data.length" #programPaginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="setPage()" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="draft-section-title">
          Service Library Uploads
          <span class="draft-section-count"
                *ngIf='supplierDraftedMedia!==undefined'>({{ libraryServiceDraftedMedia.length }})</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-table [dataSource]="libraryServiceDraftedMediaSource" matSort>
       
      <ng-container matColumnDef="date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Upload Date
          
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.date }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sourceLocation">
        <mat-header-cell class="header" mat-sort-header *matHeaderCellDef>Source
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="supplierSourceFilter" matInput [formControl]="supplierSourceLocationFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.sourceLocation }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="libServiceName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Service Name
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="serviceLibraryFilter" matInput [formControl]="serviceLibraryFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.libServiceName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="count">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Count</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.count }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isArchived">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">  
          <a class="cluster-link"
          (click)="editCluster(item,'LSVC')"
          *ngIf="!item.isArchived">
         Cluster Overview
       </a> 
       <a class="cluster-link"
       *ngIf="item.isArchived">
      Cluster Archived
    </a>
      </mat-cell>
      </ng-container>
      <ng-container matColumnDef="menu">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">   <mat-menu #clusterMenu="matMenu">
          <button mat-menu-item
                  (click)="publishCluster(item)">Publish Cluster</button>
          <button mat-menu-item
                  (click)="archiveCluster(item)"
                  *ngIf="!item.isArchived">Archive Cluster</button>
          <button mat-menu-item
                  (click)="unarchiveCluster(item)"
                  *ngIf="item.isArchived">Unarchive Cluster</button>
        </mat-menu>
        <button mat-icon-button
                [matMenuTriggerFor]="clusterMenu">
          <mat-icon>more_vert</mat-icon>
        </button> </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedServiceLibraryColumns; sticky: true"> </mat-header-row>
  
      <mat-row *matRowDef="let row; columns: displayedServiceLibraryColumns"></mat-row>

    
    </mat-table>
    <mat-paginator  [length]="libraryServiceDraftedMediaSource.data.length" #servicePaginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="setPage()" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="draft-section-title">
          iPad Migration Uploads
          <span class="draft-section-count"
                *ngIf='supplierDraftedMedia!==undefined'>({{ iPadMigrationMedia.length }})</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-table [dataSource]="iPadMigrationMediaSource" matSort>
       
      <ng-container matColumnDef="date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Upload Date
          
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.date }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sourceLocation">
        <mat-header-cell class="header" mat-sort-header *matHeaderCellDef>Source
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="supplierSourceFilter" matInput [formControl]="ipadSourceLocationFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.sourceLocation }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="programName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Program
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="programNameFilter" matInput [formControl]="ipadProgramNameFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.programName }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="programServiceName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Service
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="programServiceFilter" matInput [formControl]="ipadProgramServiceFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.programServiceName }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="count">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Count</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.count }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isArchived">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">  
          <a class="cluster-link"
          (click)="editCluster(item,'IPAD')"
          *ngIf="!item.isArchived">
         Cluster Overview
       </a> 
       <a class="cluster-link"
       *ngIf="item.isArchived">
      Cluster Archived
    </a>
      </mat-cell>
      </ng-container>
      <ng-container matColumnDef="menu">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">   <mat-menu #clusterMenu="matMenu">
          <button mat-menu-item
                  (click)="publishCluster(item)">Publish Cluster</button>
          <button mat-menu-item
                  (click)="archiveCluster(item)"
                  *ngIf="!item.isArchived">Archive Cluster</button>
          <button mat-menu-item
                  (click)="unarchiveCluster(item)"
                  *ngIf="item.isArchived">Unarchive Cluster</button>
        </mat-menu>
        <button mat-icon-button
                [matMenuTriggerFor]="clusterMenu">
          <mat-icon>more_vert</mat-icon>
        </button> </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedIpadColumns; sticky: true"> </mat-header-row>
  
      <mat-row *matRowDef="let row; columns: displayedIpadColumns"></mat-row>

    
    </mat-table>
    <mat-paginator  [length]="iPadMigrationMediaSource.data.length" #ipadPaginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="setPage()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="draft-section-title">
          Miscellaneous Uploads
          <span class="draft-section-count"
                *ngIf='supplierDraftedMedia!==undefined'>({{ miscDraftedMedia.length }})</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-table [dataSource]="miscDraftedMediaSource" matSort>
       
      <ng-container matColumnDef="date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Upload Date
          
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.date }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sourceLocation">
        <mat-header-cell class="header" mat-sort-header *matHeaderCellDef>Source
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search</mat-label>
            <input title="supplierSourceFilter" matInput [formControl]="supplierSourceLocationFilter">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.sourceLocation }} </mat-cell>
      </ng-container>
   
      <ng-container matColumnDef="count">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Count</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.count }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isArchived">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">  
          <a class="cluster-link"
          (click)="editCluster(item,'MISC')"
          *ngIf="!item.isArchived">
         Cluster Overview
       </a> 
       <a class="cluster-link"
       *ngIf="item.isArchived">
      Cluster Archived
    </a>
      </mat-cell>
      </ng-container>
      <ng-container matColumnDef="menu">
        <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">   <mat-menu #clusterMenu="matMenu">
          <button mat-menu-item
                  (click)="publishCluster(item)">Publish Cluster</button>
          <button mat-menu-item
                  (click)="archiveCluster(item)"
                  *ngIf="!item.isArchived">Archive Cluster</button>
          <button mat-menu-item
                  (click)="unarchiveCluster(item)"
                  *ngIf="item.isArchived">Unarchive Cluster</button>
        </mat-menu>
        <button mat-icon-button
                [matMenuTriggerFor]="clusterMenu">
          <mat-icon>more_vert</mat-icon>
        </button> </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedMiscColumns; sticky: true"> </mat-header-row>
  
      <mat-row *matRowDef="let row; columns: displayedMiscColumns"></mat-row>

    
    </mat-table>
    <mat-paginator  [length]="miscDraftedMediaSource.data.length" #miscPaginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    
  </mat-expansion-panel>
  </mat-accordion>
    

  <scroll-to-top></scroll-to-top>

</div>