import { Action, createReducer, on } from '@ngrx/store';
import { unionBy } from 'lodash';
// change to gallery model
import { Gallery, GalleryMediaMapping } from '../../models/gallery.model';
// change to gallery actions
import * as MLStatusMapping from '../../models/status.model';
import * as GalleryActions from '../actions/galleries.actions';
import * as officeActions from '../actions/offices.actions';



export interface GalleriesState {
    // change to list of galleries
    items: Gallery[];
    // holds top viewed gallery ids
    topViewed?: number[];
    // this holds a mapping of media associate with a gallery
    mediaMapping: GalleryMediaMapping[];
}

const initialState: GalleriesState = {
    items: [],
    topViewed: null,
    mediaMapping: []
};

// replace with gallery reducer
const galleriesReducer = createReducer(initialState,
    on(GalleryActions.getUserGalleriesSuccess, (state, action) => {
        const galleries = state.items.slice();
        const newGalleries = unionBy(galleries, action.galleries, 'id');
        return ({ ...state, items: newGalleries });
    }),
    on(officeActions.selectOffice, (state, action) => {
        return ({...state, topViewed: null});
    }),
    on(GalleryActions.createUserGallerySuccess, (state, action) => {
        const galleries = state.items.slice();
        const newGalleries = unionBy(galleries, [action.newGallery], 'id');
        return ({ ...state, items: newGalleries });
    }),
    on(GalleryActions.getAllMediaForGallerySuccess, (state, action) => {
        const mappings = state.mediaMapping.slice();

        let mappingObj = mappings.filter(x => x.galleryId == action.galleryId);
        mappingObj=JSON.parse(JSON.stringify(mappingObj));
        if (mappingObj && mappingObj.length > 0) {
            let mediaIds = mappingObj[0].mediaIds;
            let newMediaIds = action.media.map(item => { return item.mediaId });
            let uniqueIds = newMediaIds.filter(newId => mediaIds.indexOf(+newId) == -1).map(x => +x);
            mappingObj[0].mediaIds = mediaIds.concat(uniqueIds);
        }
        else {
            let newMediaMapping: GalleryMediaMapping = new GalleryMediaMapping();
            newMediaMapping.galleryId = action.galleryId;
            newMediaMapping.mediaIds = action.media.map(item => { return +item.mediaId });
            mappings.push(newMediaMapping);
        }

        return ({ ...state, mediaMapping: mappings });
    }),
    on(GalleryActions.getTopViewGalleriesSuccess, (state, action) => {
        const galleries = state.items.slice();
        const newGalleries = unionBy(galleries, action.galleries, 'id');
        const galleryIds = action.galleries.map(g => { return g.id });
        return ({ ...state, items: newGalleries, topViewed: galleryIds });
    }),
    on(GalleryActions.getAllGalleriesForAllOfficesSuccess, (state, action) => {
        const galleries = state.items.slice();
        const newGalleries = unionBy(galleries, action.galleries, 'id');
        return ({ ...state, items: newGalleries });
    }),
    on(GalleryActions.addMediaToGalleriesSuccess, (state, action) => {
        const mappings = state.mediaMapping.slice();
        let currentGalleries = <Gallery[]>state.items;
        action.selectedGalleryIds.forEach(id => {
            const currentGallery = currentGalleries.find(g => g.id === id);
            currentGallery.totalMediaCount += 1;
            const selectedGalleryMapping = mappings.find(x => x.galleryId === id);
            if (selectedGalleryMapping) {
                let mediaAlreadyExists = selectedGalleryMapping.mediaIds.find(m => m === action.mediaId);
                if (!mediaAlreadyExists) {
                    selectedGalleryMapping.mediaIds.push(action.mediaId);
                }
            }
            else {
                let newMediaMapping: GalleryMediaMapping = new GalleryMediaMapping();
                newMediaMapping.galleryId = id;
                newMediaMapping.mediaIds = [action.mediaId];
                mappings.push(newMediaMapping);
            }
        })

        return ({ ...state, mediaMapping: mappings });
    }),
    on(GalleryActions.removeMediaFromGalleriesSuccess, (state, action) => {
        const mappings = state.mediaMapping.slice();
        let currentGalleries = <Gallery[]>state.items;
        action.selectedGalleryIds.forEach(id => {
            const currentGallery = currentGalleries.find(x => x.id == id)
            currentGallery.totalMediaCount -= 1;
            const selectedGalleryMapping = mappings.find(x => x.galleryId === id);
            if (selectedGalleryMapping) {
                let mediaIndex = selectedGalleryMapping.mediaIds.indexOf(action.mediaId);
                selectedGalleryMapping.mediaIds.splice(mediaIndex, 1);
            }

        });
        return ({ ...state, mediaMapping: mappings });
    }),
    on(GalleryActions.bulkRemoveMediaFromGallerySuccess, (state: GalleriesState, action) => {
        let newGallery = state.items.filter(g => g.id == action.galleryId);
        newGallery=JSON.parse(JSON.stringify(newGallery));
        let newMediaMappings = state.mediaMapping.map(m => {
            if(m.galleryId == action.galleryId) {
               for(let i = 0; i < action.mediaIds.length; i++) {
                    let mediaIndex = m.mediaIds.indexOf(+action.mediaIds[i]);
                    if(mediaIndex > -1) {
                       m.mediaIds.splice(mediaIndex, 1); 
                    }
                }
                //Update the media count on the gallery as well
                newGallery[0].totalMediaCount = m.mediaIds.length;
            }
            return m;
        })
        let returnGalleries = updateExistingGalleries(newGallery, state.items);
        return ({...state, items: returnGalleries, mediaMappings: newMediaMappings});
    }),
    on(GalleryActions.editGallerySuccess, (state: GalleriesState, action) => {
        let newGalleries = updateExistingGalleries(state.items, [action.gallery]);
        return ({...state, items: newGalleries});
    }),
    on(GalleryActions.updateGalleryStatusSuccess, (state: GalleriesState, action) => {
        let matchedGallery = state.items.filter(g => g.id == action.id);
        let statusObject: MLStatusMapping.StatusObjects = MLStatusMapping.getStatusObjById(action.statusId);
        matchedGallery[0].statusCode = statusObject.code;
        matchedGallery[0].status = statusObject.description;
        let newGalleries = updateExistingGalleries(state.items, matchedGallery);
        return ({...state, items: newGalleries});
    })


);


// Need to weirdly export this function call because of Angular's AOT compiler - https://ngrx.io/guide/store/reducers
export function galleries(state: GalleriesState, action: Action) {
    return galleriesReducer(state, action);
}

const updateExistingGalleries = (currentItems, newItems) => {
    const result = currentItems.map(item => {
        const matchedItem = newItems.filter(newItem => newItem.id == item.id);
        if (matchedItem.length) {
            return Object.assign(item, matchedItem[0]);
        } else {
            return item;
        }
    });
    const itemIds = extractIds(result, 'id');
    const remainingNewItems = newItems.filter(item => itemIds.indexOf(parseInt(item.id)) < 0);
    remainingNewItems.map(item => {
        result.push(item);
    })
    return result;

}

const extractIds = (items: any[], key: string) => {
    return items.map(item => {
        return parseInt(item[key]);
    });
}