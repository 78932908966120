<mat-form-field class="service-select-component">
    <mat-select placeholder="Service"
                [formControl]="service"
                (selectionChange)="serviceSelectedEvent($event.value)">
        <mat-option *ngFor="let serviceModel of services"
                    [value]="serviceModel">
            {{serviceModel.serviceDate | date}}
            {{serviceModel.name}}
        </mat-option>
    </mat-select>
</mat-form-field>