import { createAction, props } from '@ngrx/store';
import { CuratorUserData } from '../../models/user.model';

export const checkUserLogin = createAction('[user] checkUserLogin');

export const checkUserLoginResult = createAction('[user] checkUserLoginResult', props<{user: any}>());

export const checkUserLoginDecorate = createAction('[user] checkUserLoginDecorate', props<{user: any}>());

export const getUserCuratorTypesSuccess = createAction('[user] getUserCuratorTypesSuccess', props<{items: any[]}>());
export const setCuratorType = createAction('[user] setCuratorType',
	props<{curatorType: CuratorUserData}>());