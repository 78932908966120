<div class="workspace-header-wrap">
    <p class="workspace-header">Manage Tags</p>
</div>

<button mat-fab
        color="primary"
        class="tag-floating-add"
        (click)="isAddTagCategoryOpen = !isAddTagCategoryOpen"
        matTooltip="Add Tag Category">
    <mat-icon *ngIf="!isAddTagCategoryOpen">add</mat-icon>
    <mat-icon *ngIf="isAddTagCategoryOpen">close</mat-icon>
</button>

<div class="workspace-add-wrap"
     *ngIf="isAddTagCategoryOpen">
    <div class="workspace-title">
        {{addEditCaption}}
    </div>
    <form class="workspace-add-form">
        <div class="workspace-add-row">
            <mat-form-field class="form-field-lg">
                <input matInput
                       placeholder="Tag Category"
                       [formControl]="categoryFormControl">
            </mat-form-field>
        </div>

        <button class="button-purple button-text-white"
                (click)="onAddClicked()">{{addEditButtonCaption}}</button>
    </form>
</div>

<div class="workspace-content-wrap">
    <p class="workspace-title">Tag Categories</p>

    <mat-form-field [floatLabel]="'never'">
        <mat-icon matPrefix
                  class="input-icon-prefix">
            search
        </mat-icon>
        <input matInput
               placeholder="Search Tags"
               #catsearchBox
               name="searchCategory"
               [formControl]='searchCategoryFormControl'>
    </mat-form-field>

    <div class="tag-categories-container">
        <div class="tag-category-wrap"
             *ngFor="let activeCat of activeTagCategories; let Index = index; trackBy:trackByIndex;">
            <div class="tag-category-panel">
                <div class="tag-category-panel-title"
                     (click)="expandTagCategory(Index)">
                    <div class="tag-category-toggle">
                        <mat-icon *ngIf="!togglePanel[Index]">chevron_right</mat-icon>
                        <mat-icon *ngIf="togglePanel[Index]">expand_more</mat-icon>
                    </div>
                    <div class="tag-category-title">
                        {{ activeCat.name }}
                    </div>
                </div>
                <div class="tag-category-actions">
                    <a class="tag-category-action"
                       matTooltip="Edit Tag Category">
                        <i class="material-icons"
                           (click)="onEditCategoryClicked(Index,activeCat.name)">edit</i>
                    </a>
                    <a class="tag-category-action"
                       matTooltip="Archive Tag Category">
                        <i class="material-icons"
                           (click)="onArchiveCategoryClicked(Index)">archive</i>
                    </a>
                </div>
            </div>

            <div class="tag-category-content"
                 *ngIf="togglePanel[Index]">
                <div class="content-tag-add-wrap">
                    <mat-form-field floatLabel="never"
                                    class="content-tag-add-input">
                        <input matInput
                               placeholder="Add Tag to Category"
                               [ngModel]="newTagValue"
                               [formControl]="tagCtrl">
                              
                    </mat-form-field>
                   
                    <a [ngClass]="{'content-tag-add' : !addingTag,
                                'diable-link': addingTag}"
                       (click)="onAddTagClicked(Index)">
                        {{addEditTagButtonCaption}}
                    </a>

                </div>
                <div class="content-tag-add-input">   
                    <mat-error *ngIf="errorMessage.length>0">
                    {{errorMessage}}
                  </mat-error>  </div>
             
                <div class="content-scroll-container">
                    <div class="content-tag-wrap"
                         *ngFor="let tagItem of activeCat.tags; let TagIndex = index; trackBy:trackByIndex;"
                         [ngClass]="{'content-tag-wrap-disabled': tagItem.activeFlag == false}">
                        <div class="content-tag-title">
                            {{tagItem.tag}}
                        </div>
                        <div class="content-tag-actions">
                            <a class="content-tag-action"
                               *ngIf="tagItem.activeFlag === true"
                               matTooltip="Edit Tag">
                                <i class="material-icons"
                                   (click)="onEditTagClicked(Index,TagIndex,tagItem.tag)">
                                    edit
                                </i>
                            </a>
                            <a class="content-tag-action content-tag-action-lg"
                               *ngIf="tagItem.activeFlag === true"
                               [ngClass]="{'content-tag-action-abled': tagItem.activeFlag == true}"
                               matTooltip="Disable Tag">
                                <i class="material-icons"
                                   (click)="onArchiveTagClicked(Index,TagIndex)">
                                    toggle_on
                                </i>
                            </a>
                            <a class="content-tag-action content-tag-action-lg"
                               *ngIf="tagItem.activeFlag === false"
                               matTooltip="Enable Tag">
                                <i class="material-icons"
                                   (click)="onArchiveTagClicked(Index,TagIndex)">
                                    toggle_off
                                </i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- archived -->
    <p class="workspace-title">Archived Tag Categories</p>

    <div class="tag-categories-container">
        <div class="tag-category-wrap"
             *ngFor="let inActiveCat of inActiveTagCatergories; let Index = index; trackBy:trackByIndex;">
            <div class="tag-category-panel">
                <div class="tag-category-panel-title">
                    <div class="tag-category-title tag-category-title-archive">
                        {{ inActiveCat.name }}
                    </div>
                </div>
                <div class="tag-category-actions">
                    <a class="tag-category-action"
                       matTooltip="Unarchive Tag Category">
                        <i class="material-icons"
                           (click)="onActivateCategoryClicked(Index)">unarchive</i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>