import { tagItem, tagModel } from '../models/tagModel';
import { UserUploadContext } from '../models/user.model';
import { mediaLinks } from './mediaLinks.model';
import { Office, officeModel } from './office.model';
import { ServiceModel } from './programService.model';
import { SupplierMediaModel, SupplierUploadContext } from './supplier.model';


export class Media {
  mediaId: number | string;
  name: string;
  description: string;
  fileName: string;
  fileName_Thumbnail: string;
  status: string;
  type: string;
  tags: tagModel[];
  links: mediaLinks;
  metaData?: MediaMetadata;
  relatedMedia?: Media[];
  thumbnailFileName?:string;
}

export interface MediaMetadata {
  id: number;
  name: string;
  description: string;
  statusId: number;
  statusDescription: string;
  mediaType: string;
  fileName: string;
  fileNameThumbnail: string;
  fileSize: string;
  source: string;
  user: string;
  uploadedDate: string;

  tags: tagItem[];
  programServices: ServiceModel[];
  office: Office[];
  suppliers: SupplierMediaModel[];
  uploadContext: UploadContext;
  viewed: any;
  liked: any;

  links: mediaLinks;
  albums: any[];
  galleries: any[];
  isCreative: boolean;
}
export class mediaModel {
  id: number;
  fileName: string;
  mediaDescription: string;
  tags: string;
  links: mediaLinks;

  /* deserialize(input: any) {
      Object.assign(this, input);
      this.links = new mediaLinks().deserialize(input.links);
      return this;
    } */
}

export class MediaImportTracking {
  mediaId: number;
  fileName: string;
  status: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class UploadContext {
  office: officeModel | number;
  uploadDate: any;
  program: any;
  programService: any;
  libraryService: any;
  supplier: SupplierUploadContext | number;
  serviceVenue: any;
  user: UserUploadContext;
  sourceId: any;
  source: any;

  note?:string;

}

export class MediaAnalytics {
  id: number;
  name: string;
  fileName: string;
  totalViewed: number;
  totalUsages: number;
  totalLiked: number;
}

export class MediaUsage {
  fileName: string;
  mediaId: number;
  usageTypeCode: string;
  usageDateTime: string;
  sourceCode: string;
}




