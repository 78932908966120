import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { dashboardResult } from '../models/dashboardResult.model';
import { searchResult } from '../models/searchResult.model';
import { GalleryResult } from './gallery.service';

const ALBUM = 'album';
const GALLERY = 'gallery';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DashBoardService {
  private dmcmediaservice = environment.mediaApiEndpoint;
  public type: any;
  public id: any;
  public title: any;

  constructor(private http: HttpClient) {}

  getMediaByAlbum(albumId: number, pageSize: number): Observable<searchResult[]> {
    const url = `${environment.mediaApiEndpoint}Album/` + albumId + '/Media?count=' + pageSize;
    return this.http.get<searchResult>(url).pipe(
      tap(_ => this.log(`get media for albums`)),
      catchError(this.handleError<any>('get media for albums by user has a error!'))
    );
  }

  getMediaByGallery(galleryId: number, pageSize: number): Observable<searchResult[]> {
    const url = `${environment.mediaApiEndpoint}Gallery/` + galleryId + '/Media?count=' + pageSize;
    return this.http.get<searchResult>(url).pipe(
      tap(_ => this.log(`get media for albums`)),
      catchError(this.handleError<any>('get media for albums by user has a error!'))
    );
  }

  getAlbumsByUser(userId: number, pageSize: number): Observable<dashboardResult[]> {
    const url = `${environment.mediaApiEndpoint}Album?UserId=` + userId + '&PageSize=' + pageSize;
    return this.http.get<dashboardResult>(url).pipe(
      tap(_ => this.log(`get albums by user`)),
      catchError(this.handleError<any>('get albums by user has a error!'))
    );
  }

  getFollowingAlbumsByUser(userId: number, pageSize: number): Observable<dashboardResult[]> {
    const dashboardresults = new Observable<dashboardResult[]>();
    return dashboardresults;
  }

  getGalleriesByOffice(officeId: number, pageSize: number): Observable<GalleryResult[]> {
    if (officeId !== undefined && officeId > 0) {
      const url = `${environment.mediaApiEndpoint}Gallery?StatusCode=PBH&OfficeId=` + officeId + '&PageSize=' + pageSize;
      return this.http.get<GalleryResult>(url).pipe(
        tap(_ => this.log(`get galleries by user`)),
        catchError(this.handleError<any>('get galleries by office has a error!'))
      );
    }
  }

  getTopViewdGalleriesByOffice(officeId: number, pageSize: number): Observable<dashboardResult[]> {
    if (officeId !== undefined && officeId > 0) {
      const url = `${environment.mediaApiEndpoint}Gallery?&IncludeAllOffices=true&OfficeId=` + officeId + '&PageSize=' + pageSize;
      return this.http.get<dashboardResult>(url).pipe(
        tap(_ => this.log(`get top viewed galleries by office`)),
        catchError(this.handleError<any>('get top viewed galleries by office has a error!'))
      );
    }
  }

  getRecentlyAddedToAnyGroup(resultSetCount: number): Observable<searchResult[]> {
    const url = `${environment.mediaApiEndpoint}MediaGroup/Media?&Count=` + resultSetCount;
    return this.http.get<searchResult>(url).pipe(
      tap(_ => this.log(`calling get Recently Added To Any Group `)),
      catchError(this.handleError<any>('get get Recently Added To Any Group has a error!'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string): void {
    console.log(message);
  }
}
