<div class="page-content-row">
    <div class="alert alert-warning"
         [hidden]="displaymessage == ''"
         role="alert">
        {{displaymessage}}
    </div>
</div>

<div class="image-editor-wrap">
    <button id="btnSave"
            class="image-editor-button"
            color="primary"
            [disabled]='disabledSave()'
            (click)="btnSaveClick();">
        Save
    </button>

    <button id="btnCancel"
            class="image-editor-button"
            color="primary"
            (click)="btnCancelClick()">
        Cancel
    </button>


    <!-- <tui-image-editor class="image-editor"
                      (undoStackChanged)="forceDetectChange($event)"
                      [options]="options">
    </tui-image-editor> -->

    <div class="image-editor-scale">
        <mat-slide-toggle class="example-margin"
                          color="primary"
                          labelPosition="before"
                          [checked]="false"
                          (change)="scaleChanged($event)">
            Scale Image View:
        </mat-slide-toggle>
    </div>

    <div class="image-editor-resize">
        <label for="multiplier"
               class="label">
            Resize Image:
        </label>
        <span class="input-holder">
            <input type="number"
                   name="multiplier"
                   step="10"
                   max="200"
                   min="1"
                   class="form-control"
                   [(ngModel)]="multiplier">
        </span>
    </div>

</div>