
// tslint:disable-next-line: class-name
export class Album {
    id: number;
    name: string;
    description: string;
    coverImage: string;
    coverImageUrl: string;
    isPublic: boolean;
    type: string;
    totalMediaCount: number;
    isFavorite?: boolean;
    mediaIds?: number[];
    sharedUserIds?:number[];
}
export class AlbumMediaMapping {
    albumId: number;
    mediaIds: number[];
}

export class AlbumEditDTO {
    MediaGroupId: number;
    Name: string;
    Description: string;
    CoverImage: string;
    UserIds?:number[]
}