<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb">{{gallery && gallery.name ? gallery.name : ''}}</li>
    </ul>
  </div>

  <div class="page-action-wrap">
    <!-- delete icon should appear with selected media in album -->
    <!-- <mat-icon
        class="page-action"
        matToolTip="Remove selected Media">
        delete
      </mat-icon> -->
    <!-- select all should toggle multiSelect to allow media in album to be selected -->
    <mat-icon class="page-action" (click)="toggleSelectionMode()"
              matTooltip="Select Media">
      select_all
    </mat-icon>
    <mat-icon class="page-action"
              matTooltip="Gallery Options"
              [matMenuTriggerFor]="galleryMenu">
      more_vert
    </mat-icon>
    <mat-menu #galleryMenu>
      <button mat-menu-item (click)='updateGalleryStatus()'>
        <!-- if archive only show publish -->
        {{galleryStatusActionString}}
      </button>
      <button mat-menu-item
        (click)="editGalleryDialog()">
        <!-- opens content-create-dialog to edit settings -->
        Gallery Settings
      </button>
      <button mat-menu-item
              *ngIf="imagesAreSelectable"
              (click)="removeSelectedMedia()">
        Remove Selected Media
      </button>
    </mat-menu>
  </div>
</div>

<div class="page-content-row content-meta-row">
  <div class="content-meta-wrap">
    <p class="content-meta-title">
      Status
    </p>
    <p class="content-meta">
      <!-- Up date both status circle and Title-->
      <span class="status-cirle status-draft"></span>
      {{gallery && gallery.status ? gallery.status : ''}}
    </p>
  </div>

  <div class="content-meta-wrap">
    <p class="content-meta-title">
      Media in Gallery
    </p>
    <p class="content-meta">
      {{gallery && gallery.totalMediaCount ? gallery.totalMediaCount : ''}}
    </p>
  </div>

  <div class="content-meta-wrap">
    <p class="content-meta-title">
      Office
    </p>
    <p class="content-meta">
      {{gallery && gallery.officeName ? gallery.officeName : ''}}
    </p>
  </div>

  <div class="content-meta-wrap">
    <p class="content-meta-title">
      Description
    </p>
    <p class="content-meta">
      {{gallery && gallery.description ? gallery.description : ''}}
    </p>
  </div>
</div>
<div class="media-grid-wrap">
  <app-media-item *ngFor="let media of galleryMedia$ | async;index as i;"
  [media]="media"
  [selectable]="imagesAreSelectable"
  [gallery]="galleryMedia"
  [slideIndex]="i"
  (onImageSelected)="onImageSelected($event)">

</app-media-item>

</div>