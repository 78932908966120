import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[mlDropzone]',
})
export class MLDropzoneDirective {
  //Accepts an array of string extensions for valid extensions eg: 'doc';
  @Input() allowedExtensions: Array<string> = [];
  //Should we allow multiple files to be uploaded at once?
  @Input() allowMultiple: boolean = false;
  //Default borderColor color
  @Input() defaultBorderColor: string = 'rgb(0,0,0,0.12)';
  //Default dragover color
  @Input() dragoverBorderColor: string = '#7F4797';
  @Input() disabled: boolean = false;
  //Emits list of valid files as long as all are valid
  @Output() fileChangeEmitter: EventEmitter<File[]> = new EventEmitter();
  //Emits list of invalid files if any are present
  @Output() invalidFileEmitter: EventEmitter<File[]> = new EventEmitter();

  @HostBinding('style.border-color') borderColor = this.defaultBorderColor;

  //This simply exists to change the borderColor color on dragover for funsies
  @HostListener('dragover', ['$event']) ondragover(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if(!this.disabled) {
      this.borderColor = this.dragoverBorderColor;
    }
  }

  //This exists to set the borderColor back to default
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = this.defaultBorderColor;
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if(!this.disabled){
      this.borderColor = this.defaultBorderColor;
      let files = evt.dataTransfer.files;
      let valid_files: Array<File> = [];
      let invalid_files: Array<File> = [];
      //this.invalidFileEmitter.emit(invalid_files);
      /**
       * Check if we allow multiple. If not ensure we have exactly 1 file, otherwise ensure we have at least 1
       */
      if ((!this.allowMultiple && files.length == 1) || (this.allowMultiple && files.length > 0)) {
        //If we were given a list of allowed extensions, validate them.
        if (this.allowedExtensions.length) {
          for (let i = 0; i < files.length; i++) {
            let ext = files[i].name.split('.')[files[i].name.split('.').length - 1];
            if (this.allowedExtensions.lastIndexOf(ext) != -1) {
              valid_files.push(files[i]);
            } else {
              invalid_files.push(files[i]);
            }
          }
        }
        //Otherwise just ship everything
        else {
          for (let v = 0; v < files.length; v++) {
            valid_files.push(files[v]);
          }
        }
        //If the files look good, ship em. If we found no nos, tell our parent.
        if (!invalid_files.length) {
          this.fileChangeEmitter.emit(valid_files);
        } else {
          this.invalidFileEmitter.emit(invalid_files);
        }
      }
    }
  }

  constructor() {}
}