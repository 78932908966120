<app-header></app-header>

<app-navbar [refreshHeader]="refresh"></app-navbar>

<router-outlet id="router-space"></router-outlet>

<div class="page-empty"
     *ngIf="!login$ | async as login">
    <i class="material-icons page-empty-icon">photo_library</i>
    <p class="page-empty-text">Login to explore the Media Library</p>
</div>