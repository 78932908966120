import { createSelector } from '@ngrx/store';
import { Gallery } from '../../models/gallery.model';
import { AppState } from '../reducers';

export const selectState = (state: AppState) => state;
export const selectGalleries = (state: AppState) => state.galleries.items;
export const selectGalleryMedia = (state: AppState) => state.galleries.mediaMapping;
export const selectUserGalleries = createSelector(selectState,
      (state: AppState, props) => {
            return state.galleries.items.filter(x => x.createdByUserId === state.user.user.userLoginId);
      })
export const selectGalleryById = createSelector(selectGalleries,
      (galleries: Gallery[], props) => {
            let galleyId = +props.galleryId;
            let gallery = galleries.filter(a => a.id === galleyId)[0];
            return gallery;
      })
export const selectMediaForGallery = createSelector(selectState,
      (state: AppState, props) => {
            const mapping = state.galleries.mediaMapping.filter(x => x.galleryId == props.galleryId)
            if (mapping && mapping.length > 0) {
                  const media = state.media.items.filter(mItem => mapping[0].mediaIds.indexOf(+mItem.mediaId) > -1);
                  return media;
            }
            return [];
      }
)
export const selectAllGalleriesForUserOffices = createSelector(selectState,
      (state: AppState, props) => {
            let userOfficeIds = state.offices.offices.map(o => o.officeId);
            let filterdGalleries = [];
            filterdGalleries = state.galleries.items.filter(g => userOfficeIds.indexOf(g.officeId) > -1);
            return filterdGalleries;
      })
export const selectTopViewedGalleries = createSelector(selectState,
      (state: AppState, props) => {
            let topViewedIds = null;
            if(state.galleries.topViewed){
                topViewedIds = state.galleries.topViewed.slice();  
            }
            
            let topViewedGalleries = null;
            if(topViewedIds) {
                 topViewedGalleries = topViewedIds.map(id => {
                  let match = state.galleries.items.filter(g => g.id === id);
                  if (match && match.length > 0) {
                        return match[0];
                  }
            })
            }
            
            return topViewedGalleries;
      })
