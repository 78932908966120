import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { programModel } from '../../../../models/program.model';
import { ProgramService } from '../../../../services/program.service';

@Component({
  selector: 'app-program-type-ahead',
  templateUrl: './program-type-ahead.component.html',
  styleUrls: ['./program-type-ahead.component.scss'],
})
export class ProgramTypeAheadComponent implements OnInit {
  _officeIds: number[];
  _officeId: number;
  @Input() value: programModel;

  @Output() programSelected = new EventEmitter<programModel>();
  @Output() filterCleared = new EventEmitter<boolean>();

  @Input()
  get officeIds(): number[] {
    return this._officeIds;
  }
  set officeIds(value: number[]) {
    if (this._officeIds !== value) {
      this._officeIds = value;
      this.programFormControl = new FormControl();
      if (this.value) {
        this.programFormControl.setValue(this.value);
      }
      this.filteredProgramOptions$ = this.programFormControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(s => typeof s === 'string'),
        switchMap(s => {
          if (s === '') {
            this.filterCleared.emit(true);
            return of([]);
          }
          this.programsLoading = true;

          return this.programService.getProgramsByOffice(this.officeIds.join(','), s).pipe(catchError(error => of([])));
        }),
        map(items => {
          this.programsLoading = false;
          if (items) {
            return items.slice(0, 20);
          } else {
            return [];
          }
        })
      );
    }
  }

  @Input()
  get officeId(): number {
    return this._officeId;
  }
  set officeId(value: number) {
    if (this._officeId !== value) {
      this._officeId = value;
      this.programFormControl = new FormControl();
      if (this.value) {
        this.programFormControl.setValue(this.value);
      }
      this.filteredProgramOptions$ = this.programFormControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(s => typeof s === 'string'),
        switchMap(s => {
          if (s === '') {
            this.filterCleared.emit(true);
            return of([]);
          }
          this.programsLoading = true;

          return this.programService
            .getProgramsByOffice(this._officeId.toString(), s)
            .pipe(catchError(error => of([])));
        }),
        map(items => {
          this.programsLoading = false;
          if (items) {
            return items.slice(0, 20);
          } else {
            return [];
          }
        })
      );
    }
  }
  public filteredProgramOptions$: Observable<programModel[]>;
  public programFormControl: FormControl;
  public programsLoading: boolean = false;

  constructor(private programService: ProgramService) {}

  ngOnInit() {}

  ngOnChanges(simpleChanges) {
    if (this.programFormControl) {
      this.programFormControl.setValue(this.value);
    }
  }

  selectedProgramEvent(program) {
    this.programSelected.emit(program);
  }

  programDisplayFn(prog?: programModel): string | undefined {
    let clientName = '';
    if (prog && prog.clientName) {
      clientName = ': ' + prog.clientName;
    }
    const returnValue =
      prog == null || prog.id === undefined || prog === undefined
        ? undefined
        : prog.number + ': ' + prog.name + clientName;
    return returnValue;
  }
}
