<mat-form-field
class="type-ahead-component program-type-ahead-component">
    <input matInput
           [placeholder]="placeholder"
           #supplierSearchBox
           id="supplier-search-box"
           [formControl]="supplierFormControl"
           [matAutocomplete]="autoSupplier">
    <mat-autocomplete #autoSupplier="matAutocomplete"
                      (optionSelected)="selectedSupplierEvent($event.option.value)"
                      [displayWith]="supplierDisplayFn">
        <mat-option *ngIf="suppliersLoading"
                    class="is-loading">
            <ng-container class="loading-indicator">
                <mat-spinner class="spin-center">
                </mat-spinner>
            </ng-container>
        </mat-option>
        <div>
            <mat-option class="uploader-auto-option"
                        *ngFor="let supplier of filteredSupplierOptions$ | async"
                        [value]="supplier">
                {{ supplier.name }}
                <p class="uploader-auto-option-text"
                   *ngFor="let company of supplier.supplierCompanies">
                    {{company.name}}
                </p>
            </mat-option>
        </div>
    </mat-autocomplete>
</mat-form-field>