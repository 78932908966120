<div class="dialog-wrap">
  <div class="dialog-title-wrap">
    <p class="dialog-title">
      Share Settings
    </p>
  </div>

  <div class="dialog-content-wrap">
    <form class="dialog-form">
      <mat-form-field class="user-chip-list" appearance="fill">
        <mat-label>Shared With (Count:{{users.length}})</mat-label>
        <mat-chip-list class="chip-overflow" #chipList aria-label="User selection">
          <mat-chip
            *ngFor="let user of users"
            [selectable]="selectable"
          
            (removed)="remove(user)">
            {{user.fullName}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
        <input
        placeholder="Search user..."
        #userInput
        [formControl]="userCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
            {{user.fullName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-label>Email Notification</mat-label>
      <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput placeholder="Subject"  [formControl]="subject" required />
      </mat-form-field>
    
      <!-- <mat-form-field>
        <mat-label>Body</mat-label>
        <input matInput placeholder="Message" [formControl]="body" required />
      </mat-form-field> -->
      <mat-label>Email Body</mat-label>
      <kendo-editor
      [formControl]="body"
        style="height: 200px"
        [pasteCleanupSettings]="{
          convertMsLists: true,
          removeHtmlComments: false,
          removeMsClasses: false,
          removeMsStyles: false,
          removeInvalidHTML: false
        }"
      >
        <!-- <kendo-toolbar>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
          <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
          <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
          <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
          <kendo-toolbar-colorpicker kendoEditorBackColor></kendo-toolbar-colorpicker>
        </kendo-toolbar> -->
      </kendo-editor>
    </form>

    
  </div>

  <div class="dialog-actions">
    <button mat-button
            class="dialog-action dialog-action-cancel"
            (click)="closeDialog()">Cancel</button>
    <!-- should be disabled until title and image are selected -->
    <button mat-button
            class="dialog-action dialog-action-create"
            [disabled]="!formIsValid"
            (click)="onSubmit()">{{actionString}} {{typeString}}</button>
  </div>
</div>