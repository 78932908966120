<div class="dialog-wrap">
   <div class="dialog-title-wrap">
      <div class="dialog-title">Edit Upload Context</div>
   </div>

   <div class="dialog-content-wrap">
      <app-media-upload-context-form [uploadContext]="media.metaData.uploadContext"
                                     [offices]="offices"
                                     [allCuratorTypes]="allCuratorTypes"
                                     [editMode]="true"
                                     [ignoreCuratorType]="true"
                                     (formOutput)="formUpdate($event)"></app-media-upload-context-form>
   </div>

   <div class="dialog-actions">
      <a class="dialog-action dialog-action-cancel"
         (click)="closeDialog()">
         Close
      </a>
      <button class="dialog-action dialog-action-create"
              [disabled]="!validForm"
              (click)="save()">
         Save changes
      </button>
   </div>
</div>