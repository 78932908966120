import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { accountExecutiveModel } from '../../../../models/account-executive.model';
import { ProgramService } from '../../../../services/program.service';


@Component({
  selector: 'app-account-executive-type-ahead',
  templateUrl: './account-executive-type-ahead.component.html',
  styleUrls: ['./account-executive-type-ahead.component.css']
})
export class AccountExecutiveTypeAheadComponent implements OnInit {

  @Input() value: accountExecutiveModel;
  @Input() programId: number;

  @Output() aeSelected = new EventEmitter<accountExecutiveModel>();
  @Output() filterCleared = new EventEmitter<boolean>();

  public filteredAEOptions$: Observable<accountExecutiveModel[]>;
  public aeFormControl: FormControl;
  public aeLoading: boolean = false;

  constructor(private programService: ProgramService) { }

  ngOnInit() {
    this.aeFormControl = new FormControl();
    if (this.value) {
      this.aeFormControl.setValue(this.value);
    }
    this.filteredAEOptions$ = this.aeFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(s => typeof s === 'string'),
      switchMap(s => {
        if (s === '') {
          this.filterCleared.emit(true);
          return of([]);
        }
        this.aeLoading = true;
        return this.programService.searchAEByProgram(this.programId, s).pipe(catchError(error => of([])));
      }),
      map(items => {
        this.aeLoading = false;
        if (items) {
          return items.slice(0, 20);
        } else {
          return [];
        }
      })
    )
  }

  ngOnChanges(simpleChanges) {
    if (this.aeFormControl) {
      this.aeFormControl.setValue(this.value);
    }
  }

  selectedAEEvent(ae) {
    this.aeSelected.emit(ae);
  }
  aeDisplayFn(ae?: accountExecutiveModel) {
    const aeName = (ae && ae.name) ? ae.name : '';
    return ae ? aeName : undefined;
  }

}

