<mat-form-field class="tag-chip-list">
  <mat-chip-list #chipList>
    <mat-chip
      matChipRemove
      *ngFor="let tag of tags"
      [selectable]="true"
      [removable]="canAddTags"
      (removed)="removeTag(tag)">
      {{tag.name}}
      <mat-icon matChipRemove *ngIf="canAddTags">cancel</mat-icon>
    </mat-chip>
    <div *ngIf="isSearching"
    matPrefix>
    <mat-progress-spinner [diameter]="13"
                          mode="indeterminate"
                          strokeWidth="5"></mat-progress-spinner>
</div>
    <input
      #supplierSearch
      class="supplier-tag-input"
      [disabled]="!canAddTags"
      placeholder="Supplier Tags"
      #suppliertagsearchBox
      id="supplier-tag-search-box"
      [formControl]="supplierTaggingCtrl"
      [matAutocomplete]="suppliertagauto"
      [matChipInputFor]="chipList">
     
  </mat-chip-list>

  <mat-autocomplete #suppliertagauto="matAutocomplete" (optionSelected)="selectedSupplier($event)">
   
    <mat-option *ngFor="let suppliertag of filteredSupplierTagOptions$ | async" 
                 [value]="suppliertag"
                 class="uploader-auto-option">
                 {{suppliertag.name}}
                <p class="uploader-auto-option-text"
                 *ngFor="let company of suppliertag.supplierCompanies">
                 {{company.name}}
                </p>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
