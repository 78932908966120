import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, switchMap, catchError, withLatestFrom, take, exhaustMap } from 'rxjs/operators';
import { OfficeService } from '../../services/office.service';
import * as OfficeActions from '../actions/offices.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class OfficeEffects {

    constructor(
        private actions$: Actions,
        private _officeService: OfficeService,
        private store$: Store<any>
    ){}

    getUserOffices$ = createEffect(() => this.actions$.pipe(
       ofType(OfficeActions.getUserOffices),
       withLatestFrom(this.store$),
       exhaustMap((data) => {
           return this._officeService.getUserOffices(data[1].user.user.userLoginId).pipe(take(1), map(res => {
             return [data, res];
           }))
       }),
       switchMap(data => 
         [
         OfficeActions.getUserOfficesSuccess({offices: data[1]}),
         OfficeActions.selectOffice({id: data[0][1].user.user.userOfficeId})
         ]
       )
       ));

    getAllOffices$ = createEffect(() => this.actions$.pipe(
      ofType(OfficeActions.getAllOffices),
      switchMap(data => {
        return this._officeService.getAllOffices().pipe(map(res => {
          return OfficeActions.getAllOfficesSuccess({offices: res});
        }))
      })
     ));

}