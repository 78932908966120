import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TagCategory, TagCategoryModel, TagItemModel } from '../models/tag-category.model';
import { tagModel } from '../models/tagModel';
import { mediaTagUpdate } from '../models/uploadCriteria.model';

const httpOptions = {
  headers: new HttpHeaders({'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private http: HttpClient
  ) { }

  private dmcmediaservice = environment.mediaApiEndpoint;


  getSearchResultTagByName(tagName: string): Observable<tagModel[]> {
    return this.http.get<tagModel[]>
      (this.dmcmediaservice + 'Tags?tag=' + tagName);
  }


  // -----------Get Tag Models by search term ----------------------------------------------
  gettagSearchResultByName(term: string): Observable<tagModel[]> {
    if (!term.trim()) {
      // if not search term, return empty hero array.
      return of([]);
    }

    return this.http.get<tagModel[]>
      (this.dmcmediaservice + 'Tags?tag=' + term + '&onlyAuto=false&onlyManual=true').pipe(
        tap(_ => this.log('tags found')),
        catchError(this.handleError<tagModel[]>(`gettagSearchResultByName search term = ${term}`))
      );
  }
  // ---------------- end search term -------------------------------------------------------

  updateMediaTag(id: number, mediaTagCriteria: mediaTagUpdate): Observable<any> {
    const url = `${this.dmcmediaservice}Tags/${id}/Media`;
    return this.http.put<mediaTagUpdate>(url, mediaTagCriteria, httpOptions).pipe(
      tap(_ => this.log(`updated media id=${mediaTagCriteria.tagName}`)),
      catchError(this.handleError<any>('updateMediaWithTagS'))
    );
  }

  // ------------------------------ Delete Media Tag ---------------------------------------------------
  removeMediaTag(id: number, mediaId: number): Observable<any> {
    const url = `${this.dmcmediaservice}Tag/${id}/Media/${mediaId}`;
    return this.http.delete<mediaTagUpdate>(url, httpOptions).pipe(
      tap(_ => this.log(`remove media id=${mediaId} tag id=${id}`)),
      catchError(this.handleError<any>('removeMediaTag'))
    );
  }
  // ----------------------------------------end---------------------------------------------------------

  // http://localhost:63089/api/v1.0/Tag/Media/1?azureTagName=f
  // For deleting azure tags past tag name, for Hello! tags pass tag Id
  removeTag(id: number, mediaId: number, name: string): Observable<any> {

    let params = new HttpParams();
    if (name !== undefined && name.length > 0) {
      params = params.append('azureTagName', name);
    }

    if (id !== undefined && id !== null) {
      params = params.append('id', id.toString());
    }

    const url = `${this.dmcmediaservice}Tags/Media/${mediaId}?${params}`;
    return this.http.delete<mediaTagUpdate>(url, httpOptions).pipe(
      tap(_ => this.log(`remove tag from media id=${mediaId} tag id=${id}`)),
      catchError(this.handleError<any>('removeMediaTag'))
    );
  }




  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  log(message: string): void {
    console.log(message);
  }

  sendTags(theTags: tagModel[]): Observable<tagModel[]> {
    return of(theTags);
  }

  getTagCategories(searchTerm: string): Observable<TagCategoryModel[]> {
    let url = this.dmcmediaservice + 'TagCategory';
    if (searchTerm !== null && searchTerm !== '') {
      url = url + '?searchTerm=' + searchTerm;
    }
    return this.http.get<TagCategoryModel[]>
      (url)
      .pipe(
        catchError(this.handleError<TagCategoryModel[]>())
      );
  }

  getTagCategoryById(categoryId: number): Observable<TagCategoryModel[]> {
    return this.http.get<TagCategoryModel[]>
      (this.dmcmediaservice + 'TagCategory/' + categoryId)
      .pipe(
        catchError(this.handleError<TagCategoryModel[]>())
      );
  }

  AddTagCategory(tagCategoryModel: TagCategoryModel): Observable<{}> {
    const category = new TagCategory();
    category.name = tagCategoryModel.name;
    category.activeFlag = tagCategoryModel.activeFlag;
    return this.http.post<TagCategory>(environment.mediaApiEndpoint +
      'TagCategory', category, httpOptions).pipe(
        tap(_ => this.log(`updated tag category=${tagCategoryModel.name}`)),
        catchError(this.handleError<any>('AddTagCategory')));
  }

  UpdateTagCategory(categoryId: number, tagCategoryModel: TagCategoryModel): Observable<{}> {
    return this.http.put<TagCategoryModel>(environment.mediaApiEndpoint +
      'TagCategory/' + categoryId, tagCategoryModel, httpOptions).pipe(
        tap(_ => this.log(`updated tag category id=${categoryId}`)),
        catchError(this.handleError<any>('UpdateTagCategory')));
  }

  AddTag(tag: TagItemModel): Observable<{}> {
    return this.http.post<TagItemModel>(environment.mediaApiEndpoint +
      'Tags', tag, httpOptions).pipe(
        tap(_ => this.log(`Added tag =${tag.tagName}`)),
        catchError(this.handleError<any>('AddTag')));
  }

  UpdateTag(tagId: number, tag: TagItemModel): Observable<{}> {
    return this.http.put<TagItemModel>(environment.mediaApiEndpoint +
      'Tags/' + tagId, tag, httpOptions).pipe(
        tap(_ => this.log(`updated tag id=${tagId}`)),
        catchError(this.handleError<any>('UpdateTag')));
  }
}
