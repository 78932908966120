import { createReducer, on, Action } from '@ngrx/store';
import * as OfficeActions from '../actions/offices.actions';
import { concat, unionBy } from 'lodash';
import { Office } from '../../models/office.model';

export interface OfficeState {
    offices?: Office[];
    currentSelectedOfficeId?: number;
    allOffices?: Office[];
}

const initialState: OfficeState = {
    offices: [],
    currentSelectedOfficeId: null,
    allOffices: []
}

const officeReducer = createReducer(initialState,
    on(OfficeActions.getUserOfficesSuccess, (state, action) => {
        if(action.offices) {
            const offices = state.offices.slice();
            let newOffices = unionBy(offices, action.offices, 'officeId');
            newOffices = newOffices.filter(o => o.officeName.toLowerCase() != "all");
            return ({...state, offices: newOffices});
        }
    }),
    on(OfficeActions.selectOffice, (state, action) => {
        let newOfficeId = action.id;
        let matchingOffice = state.allOffices.filter(o => o.officeId == newOfficeId);
        /**
            Because some Hello users have "all" set to their default office, the ML breaks because
            it has no concept of the "all" office. To mitigate this, if the system tries to 
            select an office, and it doesn't exist in our list (we clean the all one out), then we simply
            look for orlando in the all offices list and default to that.
        **/
        if(!matchingOffice.length) {
            let orlandoOffice = state.offices.filter(o => o.officeName.toLowerCase() == "orlando");
            if(orlandoOffice.length) {
                newOfficeId = orlandoOffice[0].officeId;
            }
        }
        return ({...state, currentSelectedOfficeId: newOfficeId});
    }),
    on(OfficeActions.getAllOfficesSuccess, (state, action) => {
        let offices = action.offices.filter(o => o.officeName.toLowerCase() != "all");
        return ({...state, allOffices: offices});
    })
);

export function offices(state: OfficeState, action: Action) {
    return officeReducer(state, action);
}