import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Media } from '../../../models/media.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-media-group',
  templateUrl: './media-group.component.html',
  styleUrls: ['./media-group.component.scss']
})
export class MediaGroupComponent implements OnInit, OnChanges {

  @Input() sectionTitle: string;
  @Input()
  set mediaGroup(val) {
    if (val) {
      this._mediaGroup = val;
    }
  }
  get mediaGroup() {
    if (this._mediaGroup) {
      return this._mediaGroup;
    }
    else {
      return [];
    }
  }
  @Input() isLoading: boolean;

  _mediaGroup: Media[];
  resultCount = 4;
  showSeeMore = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(change) {
    if(change.mediaGroup && change.mediaGroup.currentValue == null) {
      this.isLoading = true;
    }
    if (change.mediaGroup && change.mediaGroup.currentValue != null) {
      this.isLoading = false;
    }
  }

  setSeeMore(value) {
    this.showSeeMore = value;
    if (value) {
      this.resultCount = 4;
    } else {
      this.resultCount = environment.dashboardSeeMoreSize;
    }
  }

}
