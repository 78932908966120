import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MediaGroupType } from '../../../models/media-group-type.enum';
const INITIAL_PAGE_SIZE = 4;
const SEE_MORE_PAGE_SIZE = environment.dashboardSeeMoreSize;

@Component({
  selector: 'app-dashboard-section',
  templateUrl: './dashboard-section.component.html',
  styleUrls: ['./dashboard-section.component.scss']
})

export class DashboardSectionComponent implements OnInit, OnChanges {

  @Input() sectionTitle = 'Recently Uploaded';
  @Input() mode: any;
  @Input() type: any;
  @Input() emptyIcon: any;
  @Input() emptyText: any;
  @Input() userId: any;

  @Input() filter=false;
  @Input() canViewDashboardItem: boolean;
  public isLoading: boolean = true;
  @Input()
  set items(val) {
    this._items = val;
  }
  get items() {
    if (this._items.length) {
      
     
      if (this.sectionTitle==='My Albums' && this.userId>0){
       
        this._items= this._items.filter(a=>parseInt(a.userId)===parseInt(this.userId));
      }
     
      this.noMediaToShow = false;
      if (this.filter && this.onlyShared){
        return this._items.slice(0, this.currentPageSize).filter(i=>i.sharedUserIds.length>0);
      }else{
        return this._items.slice(0, this.currentPageSize)
      }
     
    }
    else {
      this.noMediaToShow = true;
      return [];
    }

  }

  onlyShared=false;
  _items: any;

  albums:any;
  showSeeMore: boolean;
  seeMoreText = 'SEE MORE';
  currentPageSize: number;
  noMediaToShow: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    this.noMediaToShow = true;
    if (this.mode === MediaGroupType.GALLERY) {
      this.seeMoreText = "VIEW ALL GALLERIES"
    }
    this.currentPageSize = INITIAL_PAGE_SIZE;
    this.albums=this._items;
   
 
  }

  ngOnChanges(changes) {
    if(changes.items && changes.items.currentValue == null) {
      this.isLoading = true;
    }
    if (changes.items && changes.items.currentValue != null) {
      this.isLoading = false;
      
    }
  }

  seeMore() {
    if (this.mode === MediaGroupType.GALLERY) {
      this.router.navigate([`/galleries`]);
    }
    this.currentPageSize = SEE_MORE_PAGE_SIZE;
    this.showSeeMore = !this.showSeeMore;
  }

  seeLess() {
    this.currentPageSize = INITIAL_PAGE_SIZE;
    this.showSeeMore = !this.showSeeMore;
  }

  onSharedAlbumChange(){
    if (this.onlyShared){
      this._items=  this.albums.slice(0, this.currentPageSize).filter(i=>i.sharedUserIds.length>0);
    }else{
      this._items= this.albums.slice(0, this.currentPageSize)
    }
  }

}
