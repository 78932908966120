import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-content-delete-dialog',
  templateUrl: './content-delete-dialog.component.html',
})
export class ContentDeleteDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContentDeleteDialogComponent>
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteContent() {
    this.dialogRef.close(true);
  }

}
