import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Album } from '../../models/album.model';
import { Media } from '../../models/media.model';
import * as mediaActions from '../../ngrx/actions/media.actions';
import { AlbumService } from '../../services/album.service';
import { DashBoardService } from '../../services/dashboard.service';
import { MediaService } from '../../services/media.service';
import * as AlbumActions from '../actions/albums.actions';
@Injectable()
export class AlbumEffects {
  constructor(
    private actions$: Actions,
    private _mediaService: MediaService,
    private _albumService: AlbumService,
    private _dashboardService: DashBoardService,
    private store$: Store<any>
  ) {}

  getUserAlbums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.getUserAlbums),
      withLatestFrom(this.store$),
      mergeMap(data => {
        // TODO: page size is hardcoded here. No way in UI to do anything with it anyhow.
        // needs design fix
        return this._albumService.getAlbumsByUser(data[1].user.user.userLoginId, 100).pipe(
          map(albums => {
            return AlbumActions.getUserAlbumsSuccess({ albums: <any[]>albums });
          }),
          catchError(error => {
            return of(error);
          })
        );
      })
    )
  );

  getUserSharedAlbums$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AlbumActions.getUserSharedAlbums),
    withLatestFrom(this.store$),
    mergeMap(data => {
      // TODO: page size is hardcoded here. No way in UI to do anything with it anyhow.
      // needs design fix
      return this._albumService.getSharedAlbumsByUser(data[1].user.user.userLoginId, 100).pipe(
        map(albums => {
          return AlbumActions.getUserSharedAlbumsSuccess({ sharedAlbums: <any[]>albums });
        }),
        catchError(error => {
          return of(error);
        })
      );
    })
  )
);

  getMediaForAlbum = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.getMediaForAlbum),
      withLatestFrom(this.store$),
      exhaustMap(data => {
        return this._dashboardService.getMediaByAlbum(data[0].albumId, 100).pipe(
          map(medias => {
            return AlbumActions.getMediaForAlbumSuccess({
              albumId: data[0].albumId,
              albumMedia: <Media[]>medias,
            });
          }),
          catchError(error => {
            return of(error);
          })
        );
      })
    )
  );

  createUserAlbums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.createUserAlbum),
      withLatestFrom(this.store$),
      switchMap(data => {
        return this._albumService.createAlbum(data[0].newAlbumDto).pipe(
          map(album => {
            return AlbumActions.createUserAlbumsSuccess({ newAlbum: <Album>album });
          }),
          catchError(error => {
            return of(error);
          })
        );
      })
    )
  );
  addMediaToAlbums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.addMediaToAlbums),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const obsArray$ = [];
        let selectedAlbums = data[0].selectedAlbumIds;
        if (data[0].selectedAlbumIds && data[0].selectedAlbumIds.length > 0) {
          selectedAlbums.forEach(id => {
            obsArray$.push(this._mediaService.addMediaToMediaGroup(+data[0].mediaId, id).pipe(take(1)));
          });
          return forkJoin(obsArray$).pipe(
            switchMap(res => {
              return [
                AlbumActions.addMediaToAlbumsSuccess({ mediaId: data[0].mediaId, selectedAlbumIds: selectedAlbums }),
                mediaActions.getMediaMetadataById({ mediaId: +data[0].mediaId, getRelatedImages: false }),
              ];
            })
          );
        } else {
          return of(mediaActions.emptyAction());
        }
      }),
      catchError(error => {
        console.log(error);
        return of(error);
      })
    )
  );

  bulkAddMediaToAlbums$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AlbumActions.bulkAddMediaFromAlbum),
    withLatestFrom(this.store$),
    mergeMap(data => {
      const obsArray$ = [];
      let selectedAlbums = data[0].selectedAlbumIds;
      if (selectedAlbums && selectedAlbums.length > 0) {
        selectedAlbums.forEach(id => {
          obsArray$.push(this._mediaService.bulkAddMediaToMediaGroup(data[0].mediaIds, id).pipe(take(1)));
        });
        return forkJoin(obsArray$).pipe(
          switchMap(res => {
            return [
              AlbumActions.bulkAddMediaFromAlbumSuccess({ mediaIds: data[0].mediaIds, selectedAlbumIds: selectedAlbums }),
              //mediaActions.getMediaMetadataById({ mediaId: +data[0].mediaId, getRelatedImages: false }),
            ];
          })
        );
      } else {
        return of(mediaActions.emptyAction());
      }
    }),
    catchError(error => {
      console.log(error);
      return of(error);
    })
  )
);


  removeMediaFromAlbums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.removeMediaFromAlbums),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const obsArray$ = [];
        let selectedAlbums = data[0].selectedAlbumIds;
        if (data[0].selectedAlbumIds && data[0].selectedAlbumIds.length > 0) {
          selectedAlbums.forEach(id => {
            obsArray$.push(this._mediaService.removeMediaFromMediaGroup(data[0].mediaId, id).pipe(take(1)));
          });
          return forkJoin(obsArray$).pipe(
            switchMap(res => {
              return [
                AlbumActions.removeMediaFromAlbumsSuccess({
                  mediaId: data[0].mediaId,
                  selectedAlbumIds: selectedAlbums,
                }),
                mediaActions.getMediaMetadataById({ mediaId: +data[0].mediaId, getRelatedImages: false }),
              ];
            })
          );
        } else {
          return of(mediaActions.emptyAction());
        }
      }),
      catchError(error => {
        console.log(error);
        return of(error);
      })
    )
  );

  bulkRemoveMediaFromGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.bulkRemoveMediaFromAlbum),
      mergeMap(data => {
        return this._mediaService.bulkRemoveMediaFromMediaGroup(data.mediaIds, data.albumId).pipe(
          map(res => {
            return AlbumActions.bulkRemoveMediaFromAlbumSuccess({ mediaIds: data.mediaIds, albumId: data.albumId });
          })
        );
      })
    )
  );

  editAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.editAlbum),
      mergeMap(data => {
        return this._albumService.editAlbum(data.albumEditDTO).pipe(
          map(res => {
            return AlbumActions.editAlbumSuccess({ album: res });
          })
        );
      })
    )
  );

  updateAlbumStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.updateAlbumStatus),
      mergeMap(data => {
        return this._albumService.updateAlbumStatus(data.id, data.isPublic).pipe(
          map(res => {
            return AlbumActions.updateAlbumStatusSuccess({ id: data.id, isPublic: data.isPublic });
          })
        );
      })
    )
  );

  deleteAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlbumActions.deleteAlbum),
      mergeMap(data => {
        return this._albumService.deleteAlbum(data.id).pipe(
          map(res => {
            return AlbumActions.deleteAlbumSuccess({ id: data.id });
          })
        );
      })
    )
  );
}
