
	const statusObjects: StatusObjects[] = [
		{
			code: "UPD",
			description: "Uploaded",
			id: 1
		},
		{
			code: "ANZ",
			description: "Analyzed",
			id: 2
		},
		{
			code: "PRC",
			description: "Processed",
			id: 3
		},
		{
			code: "DFT",
			description: "Draft",
			id: 4
		},
		{
			code: "PBH",
			description: "Published",
			id: 5
		},
		{
			code: "ARC",
			description: "Archived",
			id: 6
		}
	];

	export function getStatusObjById(id: number){
		let statusObj = statusObjects.filter(o => o.id == id);
		if(statusObj.length) {
			return statusObj[0];
		}
		else {
			return null;
		}

	}

	export function getStatusObjByDescription(description: string)
	{
		let statusObj = statusObjects.filter(o => o.description == description);
		if(statusObj.length) {
			return statusObj[0];
		}
		else {
			return null;
		}
	}

	export function getStatusObjByCode(code: string)
	{
		let statusObj = statusObjects.filter(o => o.code == code);
		if(statusObj.length) {
			return statusObj[0];
		}
		else {
			return null;
		}
	}


export class StatusObjects {
	code: string;
	description: string;
	id: number;
}