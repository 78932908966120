<mat-form-field
  class="type-ahead-component client-type-ahead-component">
    <input matInput
           placeholder="Client"
           #programSearchBox
           id="client-search-box"
           [formControl]="clientFormControl"
           [matAutocomplete]="autoClient">
    <mat-autocomplete #autoClient="matAutocomplete"
                      (optionSelected)="selectedClientEvent($event.option.value)"
                      [displayWith]="clientDisplayFn">
        <mat-option *ngIf="clientsLoading"
                    class="is-loading">
            <ng-container class="loading-indicator">
                <mat-spinner class="spin-center">
                </mat-spinner>
            </ng-container>
        </mat-option>
        <div>
            <mat-option class="uploader-auto-option"
                        *ngFor="let client of filteredClientOptions$ | async"
                        [value]="client">
                <ng-container *ngIf="client">
                    <p class="filter-complete-text">{{ client.name }}</p>
                </ng-container>
            </mat-option>
        </div>
    </mat-autocomplete>
</mat-form-field>
