import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllowedUploadTypesModel } from '../../models/allowed-upload-types.model';
import { UploadContext } from '../../models/media.model';
import { Office } from '../../models/office.model';
import { Permissions } from '../../models/permissions';
import { MediaPatchPayLoad, mediaPatch, mediaTagUpdate } from '../../models/uploadCriteria.model';
import { CuratorTypes } from '../../models/user.model';
import * as mediaActions from '../../ngrx/actions/media.actions';
import { AppState } from '../../ngrx/reducers';
import { MediaUploadState } from '../../ngrx/reducers/media.reducer';
import { selectUploadQueue } from '../../ngrx/selectors/media.selector';
import { doesPermissionExist } from '../../ngrx/selectors/user.selector';
import { AdminService } from '../../services/admin.service';
import { OfficeService } from '../../services/office.service';
import { SupplierService } from '../../services/supplier.service';

@Component({
  selector: 'app-upload-view',
  templateUrl: './upload-view.component.html',
  styleUrls: ['./upload-view.component.scss'],
})
export class UploadViewComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  public canViewAdmin$: Observable<boolean>;
  public offices$: Observable<Office[]>;
  public curators$: Observable<CuratorTypes[]>;
  private uploadQueueItems$: Observable<MediaUploadState[]>;
  public uploadQueueItems: MediaUploadState[];
  private forceQueueUpdateHack; // TODO: Fix

  public uploadContext: MediaPatchPayLoad;
  public bulkTagAllMedia: boolean = true;
  //TODO: correctly pull this from the backend
  private allowedExtensions: AllowedUploadTypesModel[];
  public invalidFiles = <any>[];
  public validExtensions = [];

  public bulkTaggingList: number[] = [];
  public bulkTagsClear = []; // Used to wipe the bulk tagging type ahead
  private bulkSupplierClear = [];

  public uploadContextDefault: UploadContext;

  private userId: number;
  constructor(
    private store$: Store<AppState>,
    private _router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private _officeService: OfficeService,
    private _supplierService: SupplierService
  ) {}

  ngOnInit() {
    this.store$.dispatch(mediaActions.clearUploadQueue());
    this.canViewAdmin$ = this.store$.pipe(select(doesPermissionExist, { permission: Permissions.CanViewAdminArea }));

    this.offices$ = this.store$.pipe(select(state => state.offices.offices));
    this.curators$ = this.store$.pipe(select(state => state.user.curatorTypes));
    this.store$.pipe(select(state => state.user.user.userLoginId)).subscribe(res => {
      this.userId = res;
    });

    this.uploadQueueItems$ = this.store$.pipe(select(selectUploadQueue));
    this.uploadQueueItems$.subscribe(res => {
      this.uploadQueueItems = [];
      res.map(item => {
        this.uploadQueueItems.push(item);
      });
      // This passes a random value to the upload queue items to force them to re-render.
      // should be fixed properly but this was a quick and dirty TODO: fix
      this.forceQueueUpdateHack = Math.random();
    });

    this.adminService.getAllowedUploadTypes().subscribe(res => {
      this.allowedExtensions = res;
      this.validExtensions = res.map(i => i.fileExtension);
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.uploadContextDefault = {
        office: null,
        supplier: null,
        uploadDate: null,
        program: null,
        programService: null,
        libraryService: null,
        serviceVenue: null,
        user: null,
        sourceId: null,
        source: null,
        note: null,
      };
      if (params.officeId) {
        this._officeService.getOfficeIdByCorrelationKey(params.officeId).subscribe(res => {
          this.uploadContextDefault.office = res;
        });
      }
      if (params.supplierId) {
        this._supplierService.getSuppliersByCorrelationKey(params.supplierId).subscribe(res => {
          this.uploadContextDefault.supplier = {
            id: res[0].id,
            name: res[0].name,
            number: 0, //irrelevant key. models are rough...
          };
        });
      }
    });
  }

  publishMedia() {
    const mediaToUpdate = this.uploadQueueItems.filter(i => i.media).filter(i => i.media.metaData.statusId == 4 && !i.errorCode);
    mediaToUpdate.map(media => {
      this.store$.dispatch(mediaActions.updateMediaStatus({ mediaId: +media.media.mediaId, status: 'PBH' }));
    });
  }

  uploadQueueTrackFunction(index, item) {
    if (!item) return null;
    return parseInt(item.mediaId);
  }

  showUploadOverView() {
    if (!this.isUploadDisabled()) {
      if (this.uploadContext.SupplierId) {
        this.store$.dispatch(mediaActions.getDraftedMediaBySupplierId({ supplierId: this.uploadContext.SupplierId }));
        this._router.navigate(['/drafted-media/overview', 'SUP']);
      } else if (this.uploadContext.ProgramId && this.uploadContext.ProgramServiceId) {
        this.store$.dispatch(
          mediaActions.getDraftedMediaByProgramAndServiceId({
            programId: this.uploadContext.ProgramId,
            programServiceId: this.uploadContext.ProgramServiceId,
          })
        );
        this._router.navigate(['/drafted-media/overview', 'PSR']);
      }
    }
  }

  isUploadDisabled() {
    let result = true;
    if (this.uploadContext && this.uploadContext.OfficeId) {
      if (this.uploadContext.SupplierId) {
        result = false;
      } else if (this.uploadContext.ProgramId && this.uploadContext.ProgramServiceId && this.uploadContext.ServiceVenueId) {
        result = false;
      }
    }
    return result;
  }

  uploadContextChange($event) {
    //TODO: Handle if upload is enabled or not here
    this.uploadContext = $event;
  }

  removeError(index) {
    this.invalidFiles.splice(index, 1);
  }
  onFileDrop(files) {
    files.map(file => this.uploadFile(file));
  }

  onInvalidFileDrop(files) {}

  onFileSelectedEvent($event) {
    const files = $event.target.files;
    Array.prototype.forEach.call($event.target.files, file => {
      this.uploadFile(file);
    });
  }

  uploadFile(file: File) {
    //First validate the file type
    let valid = false;
    //Validate Extension
    let ext = file.name.split('.')[file.name.split('.').length - 1];
    let matchingAllowedMediaType = this.allowedExtensions.filter(i => i.fileExtension.toLowerCase() == ext.toLowerCase());
    if (matchingAllowedMediaType.length) {
      if (file.size / 1024 <= matchingAllowedMediaType[0].maxSizeInKb) {
        valid = true;
      }
    }
    if (!valid) {
      let message;
      if (!matchingAllowedMediaType.length) {
        message = 'Invalid Extension';
      } else {
        message = 'File too Large';
      }
      let errorInstance = {
        name: file.name,
        ext: ext,
        sizeKB: file.size / 1024,
        message: message,
      };
      this.invalidFiles.push(errorInstance);
      return;
    }
    //Validate image dimensions
    /**
     This is a bit wacky. So basically in order to validate file dimensions we need to actually
     load the file up into an object to read (as dimensions are not part of file metadata). This means we
     must bind a handelr to the onload event for the image, check the dimensions, and then if they are ok, pass
     it along to another function. It makes it a bit awkward as the last line of this function basically just causes
     the callback on img.onload to fire creating a sort of 1-3-2 structure for this method.
    **/
    if (file && file['type'].split('/')[0] === 'image') {
      valid = true;
      var _URL_ = window.URL;
      let img = new Image();
      img.onload = () => {
        if (img.width < 50 && img.height < 50) {
          let errorInstance = {
            name: file.name,
            ext: ext,
            sizeKb: file.size / 1024,
            message: 'Image is smaller than 50px x 50px. Cannot upload',
          };
          this.invalidFiles.push(errorInstance);
        } else {
          this.completeUpload(file);
        }
      };
      img.src = _URL_.createObjectURL(file);
    } else {
      this.completeUpload(file);
    }
  }
  completeUpload(file: File) {
    //We good. Do the upload
    const PROGRAM = 'PSR';
    const SUPPLIER = 'SUP';
    let uploadSource = PROGRAM;
    if (this.uploadContext && (this.uploadContext.ProgramId || this.uploadContext.SupplierId)) {
      if (this.uploadContext.SupplierId) {
        uploadSource = SUPPLIER;
      }
    }

    this.store$.dispatch(
      mediaActions.uploadMedia({
        file: file,
        payload: this.uploadContext,
        uploadContext: uploadSource,
      })
    );
  }

  helloTagsAddedToItem($event) {
    const MANUAL_TAG: any = 2; //TODO: Fix
    $event.tags.map(tag => {
      const criteria: mediaTagUpdate = {
        mediaAssets: [parseInt(<string>$event.mediaId)],
        tagTypeId: MANUAL_TAG,
        tagName: tag.tagName,
      };
      this.store$.dispatch(mediaActions.addTagToMedia({ tag: tag, criteria: criteria }));
    });
  }
  helloTagsRemovedFromItem($event) {
    $event.tags.map(tag => {
      this.store$.dispatch(mediaActions.removeTagFromMedia({ tag: tag, mediaId: +$event.mediaId }));
    });
  }
  supplierAddedToItem($event) {
    const supplier = $event.supplier;
    const mediaId = +$event.mediaId;
    const addSupplierCmd: mediaPatch = {
      mediaId:mediaId,
      ops: 'Add',
      path: '/Supplier',
      newValue: supplier.id.toString(),
      oldValue: '',
    };
    this.store$.dispatch(mediaActions.updateSupplierOnMedia({ mediaId: mediaId, supplier: supplier, payload: addSupplierCmd }));
  }
  supplierRemovedFromItem($event) {
    const supplier = $event.supplier;
    const mediaId =+$event.mediaId;
    const removeSupplierCmd: mediaPatch = {
      mediaId: mediaId,
      ops: 'Remove',
      path: '/Supplier',
      newValue: supplier.id.toString(),
      oldValue: '',
    };
    this.store$.dispatch(mediaActions.updateSupplierOnMedia({ mediaId: mediaId, supplier: supplier, payload: removeSupplierCmd }));
  }

  removeAzureTagFromItem($event) {
    const name = $event.name;
    const mediaId =+$event.mediaId;
    const mediaItemArray = this.uploadQueueItems.filter(i => i.media && i.media.mediaId == mediaId);
    this.store$.dispatch(mediaActions.removeAzureTagFromMedia({ media: mediaItemArray[0].media, name: name }));
  }

  updateBulkTaggingList($event) {
    const status = $event.status;
    const mediaId = parseInt(<string>$event.mediaId);
    if (status) {
      this.bulkTaggingList.push(mediaId);
    } else {
      this.bulkTaggingList = this.bulkTaggingList.filter(i => i != mediaId);
    }

    if (this.bulkTaggingList.length > 0) {
      this.bulkTagAllMedia = false;
    }
  }

  archiveMediaItem($event) {
    this.store$.dispatch(mediaActions.updateMediaStatus({ mediaId: +$event, status: 'ARC' }));
  }

  bulkHelloTagsChanged(tags) {
    const tag = tags[0];
    let mediaIds = [];
    if (this.bulkTagAllMedia) {
      mediaIds = this.uploadQueueItems
        .filter(i => i.media)
        .filter(i => i.media.metaData.statusId != 6 && !i.errorCode)
        .map(i => {
          return parseInt(<string>i.media.mediaId);
        });
    } else {
      mediaIds = this.bulkTaggingList.slice();
    }

    const MANUAL_TAG: any = 2; //TODO: Fix

    const criteria: mediaTagUpdate = {
      mediaAssets: mediaIds,
      tagTypeId: MANUAL_TAG,
      tagName: tag.tagName,
    };
    this.store$.dispatch(mediaActions.addTagToMedia({ tag: tag, criteria: criteria }));

    this.bulkTagsClear = [];
  }

  bulkSupplierTagsChanged(supplier) {
    let mediaIds = [];
    if (this.bulkTagAllMedia) {
      mediaIds = this.uploadQueueItems
        .filter(i => i.media)
        .filter(i => i.media.metaData.statusId != 6 && !i.errorCode)
        .map(i => {
          return parseInt(<string>i.media.mediaId);
        });
    } else {
      mediaIds = this.bulkTaggingList.slice();
    }

    mediaIds.map(id => {
      const addSupplierCmd: mediaPatch = {
        mediaId:+id,
        ops: 'Add',
        path: '/Supplier',
        newValue: supplier.id.toString(),
        oldValue: '',
      };
      this.store$.dispatch(mediaActions.updateSupplierOnMedia({ mediaId: +id, supplier: supplier, payload: addSupplierCmd }));
    });

    this.bulkSupplierClear = [];
  }
}
