<div *ngIf="loggedIn">
  <div class="page-header-row">
    <div class="breadcrumb-wrap">
      <ul class="breadcrumbs">
        <li class="breadcrumb">{{album && album.name ? album.name : ''}}</li>
      </ul>
    </div>
  
    <div class="page-action-wrap">
      <!-- delete icon should appear with selected media in album -->
      <!-- <mat-icon
          class="page-action"
          matToolTip="Remove selected Media">
          delete
        </mat-icon> -->
      <!-- select all should toggle multiSelect to allow media in album to be selected -->
      <mat-icon class="page-action"
                matTooltip="Select Media"
                (click)="toggleSelectionMode()">
        select_all
      </mat-icon>
      <mat-icon class="page-action"
                matTooltip="Album Options"
                [matMenuTriggerFor]="albumMenu">
        more_vert
      </mat-icon>
      <mat-menu #albumMenu>
        <button *ngIf="album && !album.isFavorite"
                mat-menu-item
                (click)="editAlbumDialog()">
          <!-- opens content-create-dialog to edit settings -->
          Album Settings
        </button>
        <!--TODO: Hiding the 'Public and 'Private' button as follow/unfollow feature is not yet implemented. Will need to uncomment this when follow/unfollow feature is implemented-->
        <!-- <button mat-menu-item (click)="updateAlbumStatus()" *ngIf="!album.isFavorite"> -->
        <!-- should toggle to set private if public -->
        <!-- <ng-container *ngIf="!album.isPublic">Set to Public</ng-container> -->
        <!-- <ng-container *ngIf="album.isPublic">Set to Private</ng-container> -->
        <!-- </button> -->
        <button mat-menu-item
                *ngIf="imagesAreSelectable"
                (click)="removeSelectedMedia()">
          Remove Selected Media
        </button>
        <!-- users cannot delete likes album -->
        <button mat-menu-item
                *ngIf="album && !album.isFavorite"
                (click)="deleteAlbum()">
          Delete Album
        </button>
      </mat-menu>
    </div>
  </div>
  
  <div class="page-content-row content-meta-row">
    <div class="content-meta-wrap">
      <p class="content-meta-title">
        Album Access
      </p>
      <p class="content-meta">
        {{album && album.isPublic? "Public" : "Private"}}
      </p>
    </div>
    <div class="content-meta-wrap">
      <p class="content-meta-title">
        Media in Album
      </p>
      <p class="content-meta">
        {{album ? album.totalMediaCount: ''}}
      </p>
    </div>
    <div class="content-meta-wrap">
      <p class="content-meta-title">
        Album Description
      </p>
      <p class="content-meta">
        {{album ? album.description: ''}}
      </p>
    </div>
  </div>
  
  <div class="media-grid-wrap">
    <app-media-item *ngFor="let media of mediaGallery;index as i"
                    [media]="media"
                    [gallery]="mediaGallery"
                    [slideIndex]="i"
                    [selectable]="imagesAreSelectable"
                    (onImageSelected)="onImageSelected($event)">
    </app-media-item>
  </div>
</div>
<div >

<mat-spinner *ngIf="!loadingMedia"></mat-spinner>
</div>