import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Office, officeModel } from '../models/office.model';
import { programModel } from '../models/program.model';
import { programServiceModel } from '../models/programService.model';
import { searchResult } from '../models/searchResult.model';
import { supplierModel } from '../models/supplier.model';
import { EditMediaModel, MediaModelV2 } from '../models/uploadCriteria.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class MediaUploadToolbarService {
  private toolBarServiceBaseUrl = environment.mediaApiEndpoint;
  private toolBarServiceBaseUrlV2 = environment.mediaApiEndpoint_V2;

  mediaCriteria = MediaModelV2.getInstance();
  public mediaSource: any;
  public uploadContext: string;
  public bulkSelectiveTagging: any;
  mediaIdResult: number;
  mediaAtDraft: number[] = []; // this is a false draft HARD CODED
  mediaAtDraftSelected: number[] = [];

  constructor(private _http: HttpClient) { }

  log(message: string): void {
    console.log(message);
  }

  // Media service
  createdMediaSuccessfulEvent(fileRef: string, initialFileName: string) {
    if (fileRef !== undefined) {
      this.mediaCriteria.id = 0;
      this.mediaCriteria.clientId = ''; // this needs to be populated to get further events back
      this.mediaCriteria.fileName = fileRef;
      this.mediaCriteria.name = initialFileName;
    }
  }

  // Update the list of media for use with Tagging Component
  addMediaId(mediaId: number) {
    this.mediaIdResult = mediaId;
    this.mediaAtDraft.push(mediaId);
  }

  getMediaList(): number[] {
    return this.mediaAtDraft;
  }

  getSelectedMediaList(): number[] {
    return this.mediaAtDraftSelected;
  }

  addSelectedMediaId(mediaId: number) {
    this.mediaAtDraftSelected.push(mediaId);
  }

  removeSelectedMediaId(mediaId: number) {
    const index = this.mediaAtDraftSelected.indexOf(mediaId, 0);
    if (index > -1) {
      this.mediaAtDraftSelected.splice(index, 1);
    }
  }

  mediaCreated(media: MediaModelV2): Observable<MediaModelV2> {
    return this._http.post<MediaModelV2>(this.toolBarServiceBaseUrlV2 + 'Media', media, httpOptions).pipe(
      tap((mediaCreated: MediaModelV2) => this.log(`created media w/ id=${mediaCreated.id}`)),
      catchError(this.handleError<MediaModelV2>('createdMediaUnSuccessFul'))
    );
  }

  mediaEdited(media: EditMediaModel): Observable<searchResult> {
    return this._http.put<searchResult>(this.toolBarServiceBaseUrlV2 + 'Media', media, httpOptions).pipe(
      tap((mediaCreated: searchResult) => this.log(`edited media w/ id=${mediaCreated.mediaId}`)),
      catchError(this.handleError<searchResult>('editedMediaUnSuccessFul'))
    );
  }

  //TODO: Deprecated. Moved to office service
  // Office
  getOfficeModels(userId: number): Observable<Office[]> {
    return this._http.get<Office[]>(this.toolBarServiceBaseUrl + 'user/' + userId + '/office', httpOptions).pipe(
      tap(officeModels => this.log('Getting Office Models')),
      catchError(this.handleError('get Office Models', []))
    );
  }

  // Office by Id
  getOfficeModelsById(id: number): Observable<officeModel[]> {
    const url = `${this.toolBarServiceBaseUrl + 'Office'}/${id}`;
    return this._http.get<officeModel[]>(this.toolBarServiceBaseUrl + 'office', httpOptions).pipe(
      tap(officeModels => this.log('Got the Office Model')),
      catchError(this.handleError('getOfficeModelsById', []))
    );
  }

  // Programs by office id
  getProgramModels(officeid: number): Observable<programModel[]> {
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('officeId', officeid.toString());
    const url = `${this.toolBarServiceBaseUrl + 'Program'}?${params}`;

    return this._http.get<programModel[]>(url, httpOptions).pipe(
      tap(programModels => this.log('Recived Programs Response')),
      catchError(this.handleError('get Programs', []))
    );
  }

  // Supplier by office Id
  getSupplierModels(isCreative: boolean): Observable<supplierModel[]> {
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('IsCreative', isCreative.toString());

    const url = `${this.toolBarServiceBaseUrl + 'Supplier'}?${params}`;
    return this._http.get<supplierModel[]>(url, httpOptions).pipe(
      tap(supplierModels => this.log('Got suppliers')),
      catchError(this.handleError('get SupplierModels', []))
    );
  }

  // Service by programId and userRoleId
  getServiceModels(programid: number, isCreative: boolean, restrictToContract: boolean): Observable<programServiceModel[]> {
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('programId', programid.toString());
    if(restrictToContract){
      params = params.append('isAddedToContract', 'true');
    }
    
    if (isCreative !== null) {
      params = params.append('IsCreative', isCreative.toString());
    }

    const url = `${this.toolBarServiceBaseUrl + 'Service'}?${params}`;

    return this._http.get<programServiceModel[]>(url, httpOptions).pipe(
      tap(serviceModels => this.log('Got Service(s) for the program')),
      catchError(this.handleError('get serviceModels', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
