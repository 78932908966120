import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { mediaTypeModel } from '../models/media-type.model';
import { MediaImportTracking, MediaUsage } from '../models/media.model';
import { UploadClusters } from '../models/upload-clusters.model';
import { uploadTypeModel } from '../models/upload-type.model';
import { mediaPatch } from '../models/uploadCriteria.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private dmcmediaservice = environment.mediaApiEndpoint;
  constructor(private http: HttpClient) { }

  mediaSelected: number[] = [];
  mediaTrackedItems: MediaImportTracking[] = [];
  public cluster: UploadClusters;
  public clusterOffice: any;
  public uploadContext: string;

  public coverThumbnailUrl: any;
  public coverMedidaId: any;
  public coverFileName: any;
  public coverThumbnailFileName: any;

  addSelectedId(mediaId: number) {
    this.mediaSelected.push(mediaId);
  }

  removeSelectedId(mediaId: number) {
    const index = this.mediaSelected.indexOf(mediaId, 0);
    if (index > -1) {
      this.mediaSelected.splice(index, 1);
    }
  }

  addMediaTrackingItem(mediaTrackingItem: MediaImportTracking) {
    this.mediaTrackedItems.push(mediaTrackingItem);
  }

  hasMediaTrackingItem(trackingItemFilename: any): boolean {
    let isInList = true;
    const foundItem = this.mediaTrackedItems.find(trackingItem => trackingItem.fileName === trackingItemFilename);

    if (foundItem === undefined) {
      isInList = false;
    }
    return isInList;
  }

  addMediaToMediaGroup(mediaId: number, mediaGroupId: number) {
    const url = `${environment.mediaApiEndpoint_V2}media/mediaGroup`;
    const data = {
      MediaGroupId: mediaGroupId,
      MediaId: mediaId,
    };
    return this.http.put<boolean>(url, data);
  }

  bulkAddMediaToMediaGroup(mediaIds: number[], mediaGroupId: number) {
    const url = `${environment.mediaApiEndpoint_V2}media/mediaGroup/bulkAdd`;
    const data = {
      MediaGroupId: mediaGroupId,
      MediaIds: mediaIds,
    };
    return this.http.put<boolean>(url, data);
  }


  removeMediaFromMediaGroup(mediaId: number, mediaGroupId: number) {
    const url = `${environment.mediaApiEndpoint_V2}media/mediaGroup/remove`;
    const data = {
      MediaGroupId: mediaGroupId,
      MediaId: mediaId,
    };
    return this.http.patch<boolean>(url, data);
  }

  bulkRemoveMediaFromMediaGroup(mediaIds: number[], mediaGroupId: number) {
    const url = `${environment.mediaApiEndpoint_V2}media/mediaGroup/bulkRemove`;
    const data = {
      MediaGroupId: mediaGroupId,
      MediaIds: mediaIds
    };
    return this.http.patch<boolean>(url, data);
  }

  bulkUpdateMediaStatus(mediaIds: number[], statusId: number,userId:number)
  {
    const url = `${environment.mediaApiEndpoint_V2}media/bulkstatusupdate`;
    const data = {
      mediaIds: mediaIds,
      statusId: statusId,
      userId:userId
    };
    return this.http.patch<boolean>(url, data);
  }

  getUploadTypes(): Observable<uploadTypeModel[]> {
    return this.http.get<uploadTypeModel[]>(environment.mediaApiEndpoint + 'MediaSource');
  }

  getMediaTypes(): Observable<mediaTypeModel[]> {
    return this.http.get<mediaTypeModel[]>(environment.mediaApiEndpoint + 'MediaType');
  }

  updateSingleProperty(id: number, patchcmd: mediaPatch): Observable<any> {
    const url = `${environment.mediaApiEndpoint}Media/${id}`;
    return this.http.patch(url, patchcmd, httpOptions).pipe(
      tap(_ => this.log(`updated media id=${patchcmd.mediaId}`)),
      catchError(this.handleError<any>('updateMediaWithTagS'))
    );
  }

  updateSingleOrObject(id: number, patchcmd: mediaPatch): Observable<any> {
    const url = `${environment.mediaApiEndpoint_V2}Media/${id}`;
    return this.http.patch(url, patchcmd, httpOptions).pipe(
      tap(_ => this.log(`updated media id=${patchcmd.mediaId}`)),
      catchError(this.handleError<any>('updateMediaWithTagS'))
    );
  }
  //https://dmcmediaservice.azurewebsites.net/api/v1.0/Media/upload/groups?officeId=6&statusCode=DFT&isCreative=true&uploadTypeCode=SUP

  getUploadClusters(officeId: number, status: string, isCreative: boolean, uploadType: any): Observable<UploadClusters[]> {
    return this.http
      .get<UploadClusters[]>(
        environment.mediaApiEndpoint +
        'Media/Upload/groups?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&isCreative=' +
        isCreative +
        '&uploadTypeCode=' +
        uploadType
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusters'))
      );
  }

  async getUploadClusterForPSvc(officeId: number, status: string, uploadType: any, programId: number, serviceId: number) {
    return await this.http
      .get<UploadClusters>(
        environment.mediaApiEndpoint +
        'Media/Upload/group?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&uploadTypeCode=' +
        uploadType +
        '&programServiceCorrelationKey=' +
        serviceId +
        '&ProgramCorrelationKey=' +
        programId
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusterForPsvc'))
      )
      .toPromise();
  }

  async getUploadClusterForPSvcById(officeId: number, status: string, uploadType: any, programId: number, serviceId: number) {
    return await this.http
      .get<UploadClusters>(
        environment.mediaApiEndpoint +
        'Media/Upload/group?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&uploadTypeCode=' +
        uploadType +
        '&programServiceId=' +
        serviceId +
        '&ProgramId=' +
        programId
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusterForPsvcById'))
      )
      .toPromise();
  }

  getUploadClusterForPSvcByIdObs(
    officeId: number,
    status: string,
    uploadType: any,
    programId: number,
    serviceId: number
  ): Observable<UploadClusters> {
    return this.http.get<UploadClusters>(
      environment.mediaApiEndpoint +
      'Media/Upload/group?statusCode=' +
      status +
      '&officeId=' +
      officeId +
      '&uploadTypeCode=' +
      uploadType +
      '&programServiceId=' +
      serviceId +
      '&ProgramId=' +
      programId
    );
  }

  async getUploadClusterForLibSvc(officeId: number, status: string, uploadType: any, libServiceId: number) {
    return await this.http
      .get<UploadClusters>(
        environment.mediaApiEndpoint +
        'Media/Upload/group?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&uploadTypeCode=' +
        uploadType +
        '&libraryServiceCorrelationKey=' +
        libServiceId
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusterForLibSvc'))
      )
      .toPromise();
  }

  async getUploadClusterForLibSvcById(officeId: number, status: string, uploadType: any, libServiceId: number) {
    return await this.http
      .get<UploadClusters>(
        environment.mediaApiEndpoint +
        'Media/Upload/group?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&uploadTypeCode=' +
        uploadType +
        '&libraryServiceId=' +
        libServiceId
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusterForLibSvcById'))
      )
      .toPromise();
  }

  async getUploadClusterForSupplier(officeId: number, status: string, uploadType: any, supplierId: number) {
    return await this.http
      .get<UploadClusters>(
        environment.mediaApiEndpoint +
        'Media/Upload/group?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&uploadTypeCode=' +
        uploadType +
        '&supplierCorrelationKey=' +
        supplierId
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusterForSupplier'))
      )
      .toPromise();
  }

  async getUploadClusterForSupplierById(officeId: number, status: string, uploadType: any, supplierId: number) {
    return await this.http
      .get<UploadClusters>(
        environment.mediaApiEndpoint +
        'Media/Upload/group?statusCode=' +
        status +
        '&officeId=' +
        officeId +
        '&uploadTypeCode=' +
        uploadType +
        '&supplierId=' +
        supplierId
      )
      .pipe(
        tap(_ => this.log(`Got Cluster: ` + uploadType)),
        catchError(this.handleError<any>('getUploadClusterForSupplierById'))
      )
      .toPromise();
  }
  getUploadClusterForSupplierByIdObs(officeId: number, status: string, uploadType: any, supplierId: number): Observable<UploadClusters> {
    return this.http.get<UploadClusters>(
      environment.mediaApiEndpoint +
      'Media/Upload/group?statusCode=' +
      status +
      '&officeId=' +
      officeId +
      '&uploadTypeCode=' +
      uploadType +
      '&supplierId=' +
      supplierId
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string): void {
    console.log(message);
  }

  AddFileViewLog(id: number) {
      const usage = new MediaUsage();
      usage.fileName = 'unkown'; //Key is required by backend model, but not used in this case.
      usage.mediaId = id;
      usage.usageTypeCode = 'MLVIEW';
      usage.usageDateTime = new Date().toLocaleDateString();
      /**
       We ran into something weird here where this would fire twice withou tthe take(1)
       I'm not sure why and google was unable to provide answers. I confirmed we only subscribed one time and this method
       only fired once, but /shrug. Using take(1) fixes the dupe call - the bug also only occured under throttled network...
      **/
      return this.http.post<MediaUsage>(environment.mediaApiEndpoint + 'Media/Usage', usage, httpOptions).pipe(take(1));
    
  }
}
