import {Deserializable} from './deserializable.model';

export class AllowedUploadTypesModel {
    mediaTypeId: number;
    mediaTypeCode: string;
    mediaType: string;
    fileExtension: string;
    maxSizeInKb: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}

export class AllowedUploadType {
  mediaTypeId: number;
  fileExtension: string;
  maxSizeInKb: number;

  deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}
