import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Media } from '../../../models/media.model';
import { Office } from '../../../models/office.model';
import { MediaPatchPayLoad } from '../../../models/uploadCriteria.model';
import { CuratorTypes } from '../../../models/user.model';

@Component({
  selector: 'app-media-edit-upload-context-dialog',
  templateUrl: './media-edit-upload-context-dialog.component.html',
  styleUrls: ['./media-edit-upload-context-dialog.component.scss']
})
export class MediaEditUploadContextDialogComponent implements OnInit {
  private uploadContext: MediaPatchPayLoad;
  public media: Media;
  public offices: Office[];
  public allCuratorTypes: CuratorTypes[];
  public validForm: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<MediaEditUploadContextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.media = data.media;
    this.offices = data.offices;
    this.allCuratorTypes = data.allCuratorTypes;
  }

  ngOnInit() {

  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  save() {
    if (this.validForm) {
      this.dialogRef.close(this.uploadContext);
    }
  }

  validateForm(value) {
    let result = false;
    if (value.ProgramId || value.SupplierId) {
      if (value.ProgramId) {
        if (value.ProgramServiceId && value.ServiceVenueId) {
          result = true;
        }
      } else if (value.SupplierId) {
        result = true;
      }
    }
    return result;
  }

  formUpdate(value) {
    this.uploadContext = value;
    this.validForm = true;
    if (value.ProgramId || value.SupplierId) {
      if (value.ProgramId) {
        if (value.ProgramServiceId && value.ServiceVenueId) {
          this.validForm = true;
        }
      } else if (value.SupplierId) {
        this.validForm = true;
      }
    }
   // this.validForm = this.validateForm(value);
    
  }

} ``
