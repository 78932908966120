import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import { ContentCreateDialogComponent } from 'src/app/components/dialogs/content-create-dialog/content-create-dialog.component';
import { Album } from 'src/app/models/album.model';
import { Gallery } from 'src/app/models/gallery.model';
import { CuratorTypes } from 'src/app/models/user.model';
import { bulkAddMediaFromAlbum, createUserAlbum, getUserAlbums, getUserSharedAlbums } from 'src/app/ngrx/actions/albums.actions';
import { bulkAddMediaFromGallery, createUserGallery, getUserGalleries } from 'src/app/ngrx/actions/galleries.actions';
import { AlbumCreateCommand } from 'src/app/services/album.service';
import { GalleryCreateCommand } from 'src/app/services/gallery.service';
import { MediaTags } from '../../models/media-tags.model';
import { Media } from '../../models/media.model';
import { Office } from '../../models/office.model';
import { Permissions } from '../../models/permissions';
import { SearchQueryParams, SearchRequest } from '../../models/search.model';
import * as MediaActions from '../../ngrx/actions/media.actions';
import { AppState } from '../../ngrx/reducers';

import { selectSearchResults } from '../../ngrx/selectors/media.selector';
import { doesPermissionExist, selectUser } from '../../ngrx/selectors/user.selector';
import { OfficeService } from '../../services/office.service';
import { ProgramService } from '../../services/program.service';
import { SupplierService } from '../../services/supplier.service';
import { TagsService } from '../../services/tags.service';
//  TODO: Absolutely does not belong here. Fix this model
export interface MediaSearchOption {
  value: string;
  description: string;
}

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss'],
 
})
export class SearchViewComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  //  Permissions
  canFilterMediaByUploadDate$: Observable<boolean>;
  public canArchiveMedia: boolean = false;
  public userCuratorTypes: any[];
  public canSelectMedia: boolean = false;
  public selectedImageIds: number[] = [];
  public searchForm: FormGroup = new FormGroup({
    term: new FormControl(),
    isCreative: new FormControl(),
    status: new FormControl(),
    mediaType: new FormControl(),
    offices: new FormControl(),
    uploadDateFrom: new FormControl(),
    uploadDateTo: new FormControl(),
    program: new FormControl(),
    supplier: new FormControl(),
    venue: new FormControl(),
    client: new FormControl(),
    service: new FormControl(),
    accountExecutive: new FormControl(),
    serviceDateFrom: new FormControl(),
    serviceDateTo: new FormControl(),
    page: new FormControl(),
  });
  public tagsLoading: boolean = false;
  //Data
  private offices: Office[];
  private offices$: Observable<Office[]>;
  userOffices$: Observable<Office[]>;
  private curators: CuratorTypes[];
  private curators$: Observable<CuratorTypes[]>;
  albums: Album[];
  canAddMediaToGallery: boolean;
  selectedAlbums: Album[] = [];
  selectedGalleries: Gallery[] = [];
  userOffices: Office[];
  galleries: Gallery[];
  loggedUserId=0;
  private canViewMoreOptions$: Observable<boolean>;
  private canAddTags$: Observable<boolean>;
  private canAddTags: boolean = false;
  public mediaMatchesCuratorType = false;
  public userInput: FormControl;
  private userInput$: Observable<string>;
  public filteredSearchOptions$: Observable<any[]>;

  public isSearching: boolean = true;

  
  private defaultOffice: number = null;

  public queryParameters: SearchQueryParams;

  public readyToSearch: boolean = true;

  private lastFormValues = null; //  Used to store the last known search form state so we can compare on changes

  //  TODO: Clean up how they set all these options;
  public mediaStatuses: MediaSearchOption[] = [
    { value: 'DFT', description: 'Draft' },
    { value: 'PBH', description: 'Published' },
    { value: 'ARC', description: 'Archived' },
  ];
  public mediaTypes: MediaSearchOption[] = [
    { value: 'IMG', description: 'Image' },
    { value: 'VDO', description: 'Video' },
    { value: 'ADO', description: 'Audio' },
  ];

  public media$: Observable<Media[]>;

  media:Media[]=[];
  public endOfResults: boolean = false;
  //  Since lib service search requires a different endpoint,
  //  we use this variable to toggle off our basic searching.
  public hideSearchFields: boolean = false;

  constructor(
    private store$: Store<AppState>,
    private _tags: TagsService,
    private route: ActivatedRoute,
    private _officeService: OfficeService,
    private _supplierService: SupplierService,
    private _programService: ProgramService,
    public albumDialog: MatDialog,
    public galleryDialog: MatDialog,
  ) {}

  ngOnInit() {
    const loggedIn$ = this.store$.pipe(select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false ));
    loggedIn$.subscribe(res => { 
      this.getOffices();
     

  });
   //  Grab our query parameters. Done first to ensure we have them for hydration
  this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
    /**
     * Valid params
     * officeId: number,
     * supplierId: number
     **/
    this.queryParameters = {
      officeId: params.officeId ? +params.officeId : null,
      officeIds: params.officeIds ? params.officeIds : null,
      supplierId: params.supplierId ? +params.supplierId : null,
      programId: params.programId ? +params.programId : null,
      programServiceId: params.programServiceId ? +params.programServiceId : null,
      clientId: params.clientId ? +params.clientId : null,
      venueId: params.venueId ? +params.venueId : null,
      libraryServiceId: params.libraryServiceId ? +params.libraryServiceId : null,
    };
    if (this.queryParameters.libraryServiceId || this.queryParameters.programServiceId) {
      this.hideSearchFields = true;
      this.readyToSearch = false;
    }
    if (this.queryParameters.libraryServiceId) {
      this.readyToSearch = true;
      this.store$.dispatch(
        MediaActions.searchMediaByLibraryServiceCorrelationKey({ id: this.queryParameters.libraryServiceId })
      );
    }

    // Subscribe to search results
    this.media$ = this.store$.pipe(select(selectSearchResults));
    this.media$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (this.readyToSearch) {
        this.isSearching = false;
      }
      if (res.length % 100 != 0) {
        this.endOfResults = true;
      } else {
        this.endOfResults = false;
      }
      this.media=res;
    });

    //  Get Permissions
    //  TODO: Clean up permissions in this app overall
    this.store$
      .pipe(select(doesPermissionExist, { permission: Permissions.CanViewDraftMedia }), takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (!res) {
          this.mediaStatuses = this.mediaStatuses.filter(s => s.value !== 'DFT');
        }
      });
    this.store$
      .pipe(
        select(doesPermissionExist, { permission: Permissions.CanViewArchiveMedia }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(res => {
        if (!res) {
          this.mediaStatuses = this.mediaStatuses.filter(s => s.value !== 'ARC');
        } else {
          this.canArchiveMedia = true;
        }
      });
    // Surely all these permissions are not necessary
    this.canFilterMediaByUploadDate$ = this.store$.pipe(
      select(doesPermissionExist, { permission: Permissions.CanFilterMediaByUploadDate })
    );

    // Initialize Search Form
    this.userInput = new FormControl();
    this.initializeSearchForm();

    this.filteredSearchOptions$ = this.userInput.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      filter(s => typeof s === 'string'),
      switchMap(s => {
        if (s === '') {
          return of([]);
        }
        this.tagsLoading = true;
        return this._tags.getSearchResultTagByName(s).pipe(
          catchError(e => of([])),
          map(res => {
            this.tagsLoading = false;
            return res.slice(0, 20);
          })
        );
      })
    );
    this.store$.pipe(takeUntil(this.unsubscribe$), select(selectUser)).subscribe(res => {
      this.loggedUserId = res.userLoginId;
    });
    this.store$.pipe(takeUntil(this.unsubscribe$),select(state=>state.albums.albums)).subscribe(res => {
      this.albums = res;
    });
    this.store$.dispatch(getUserAlbums());
    this.store$.dispatch(getUserGalleries());
    
    this.store$
      .pipe(
        takeUntil(this.unsubscribe$),
        select(state => state.galleries.items)
      )
      .subscribe(res => (
        this.galleries = res
        ));

    this.store$
      .pipe(takeUntil(this.unsubscribe$), select(doesPermissionExist, { permission: Permissions.CanAddMediaToGallery }))
      .subscribe(res => {
        this.canAddMediaToGallery = res;
      });

      this.userOffices$ = this.store$.pipe(select(state => state.offices.offices));
      this.userOffices$.pipe(takeUntil(this.unsubscribe$)).subscribe(offices => {
        this.userOffices = offices;
      });
      this.curators$ = this.store$.pipe(select(state => state.user.curatorTypes));
      this.curators$.pipe(takeUntil(this.unsubscribe$)).subscribe(curatorTypes => {
        this.curators = curatorTypes;
      });
      this.canViewMoreOptions$ = this.store$.pipe(
        select(doesPermissionExist, { permission: Permissions.CanViewOptionsMenuOnMediaPage })
      );
      this.canAddTags$ = this.store$.pipe(select(doesPermissionExist, { permission: Permissions.CanAddTagsToMedia }));
      this.canAddTags$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.canAddTags = res;
      });
  });
   
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeSearchForm(clear: boolean = false) {
    this.searchForm = new FormGroup({
      term: new FormControl(),
      isCreative:new FormControl(),
      status: new FormControl(),
      mediaType: new FormControl(),
      offices: new FormControl(),
      uploadDateFrom: new FormControl(),
      uploadDateTo: new FormControl(),
      program: new FormControl(),
      supplier: new FormControl(),
      venue: new FormControl(),
      client: new FormControl(),
      service: new FormControl(),
      accountExecutive: new FormControl(),
      serviceDateFrom: new FormControl(),
      serviceDateTo: new FormControl(),
      page: new FormControl(),
    });
    this.userInput.setValue(null);

    this.handleFormChanges();
    this.hydrateForm(clear);
  }

  hydrateForm(clear: boolean = false) {
    // Grab last search so we can update our form.
    this.store$
      .pipe(
        select(state => state.media.lastSearchRequest),
        take(1)
      )
      .subscribe(res => {
        if (res != null && !clear) {
          // hydrate form with last search request
          this.searchForm.patchValue({
            term: res.term,
            status: res.mediaStatus,
            mediaType: res.mediaType,
            offices: res.offices,
            uploadDateFrom: res.uploadDateFrom,
            uploadDateTo: res.uploadDateTo,
            program: res.program,
            supplier: res.supplier,
            venue: res.venue,
            client: res.client,
            service: res.service,
            accountExecutive: res.accountExecutive,
            serviceDateFrom: res.serviceDateFrom,
            serviceDateTo: res.serviceDateTo,
            page: 1, // I think they'd want this, otherwise it'll jump ahead? TODO: ask barbara
          });
        } else if (!clear) {
          /**
           * Need to handle office and supplier individually because they might be query params, and due to the correlation
           * key issue, we need to translate them to actual ids
           **/

          //  OFFICE && OFFICE DEPENDENT IDS
          //  INCLUDES PROGRAM
          if (this.queryParameters.officeId) {
            this._officeService
              .getOfficeIdByCorrelationKey(this.queryParameters.officeId)
              .pipe(take(1))
              .subscribe(officeId => {
                const searchedOffices = [officeId];
                //  We must run the rest of these AFTER we get back our proper office ID since
                //  it's necessary to get these

                /**
                 *  PROGRAM
                 *  Kind of silly here. So correlation keys are not unique, and we could get back multiple hits here.
                 *  With no way of knowing which hit is correct. I've opted to just take whichever is newest by ID.
                 *  TODO: Make this correct
                 **/

                if (this.queryParameters.programId) {
                  this._programService
                    .getProgramsByCorrelationKey(officeId, this.queryParameters.programId)
                    .pipe(take(1))
                    .subscribe(programs => {
                      if (programs && programs.length) {
                        let program = programs[0];

                        /**
                         * Program Service
                         * Note: This requries the program ID - which means it also requires an officeId, and we have to run it
                         * after we get back our program.
                         * TODO: Issue exists because we have colliding correlation keys for programs, when we get the program above, if we have multiple
                         * hits, we have no way of knowing which hit actually contains the service they're looking for unless we query them all.
                         * Note: STATUS set to ALL because this comes from the Add-In ML button link, and should show all related images regardless of status
                         **/
                        if (this.queryParameters.programServiceId) {
                          this._programService
                            .getProgramServicesByCorrelationKey(program.id, this.queryParameters.programServiceId)
                            .pipe(take(1))
                            .subscribe(services => {
                              this.readyToSearch = true;
                              this.searchForm.patchValue({
                                program: program,
                                service: services[0],
                                offices: searchedOffices,
                                status: 'ALL',
                                page: 1,
                              });
                            });
                        } else {
                          this.readyToSearch = true;
                          this.searchForm.patchValue({
                            program: program,
                            offices: searchedOffices,
                            status: 'PBH',
                            page: 1,
                          });
                        }
                      }else{
                        this.searchForm.patchValue({
                          offices: searchedOffices,
                        });
                      }
                    });
                } else {
                  this.searchForm.patchValue({
                    offices: searchedOffices,
                  });
                }
              });
          } else {
            if (this.queryParameters.officeIds) {
              this.searchForm.patchValue({
                offices: this.queryParameters.officeIds.split(',').map(item => +item),
              });
            } else {
              if (this.defaultOffice > 0) {
                this.searchForm.patchValue({
                  offices: [this.defaultOffice],
                });
              }
            }
            
          }

          //  SUPPLIER
          if (this.queryParameters.supplierId) {
            this._supplierService.getSuppliersByCorrelationKey(this.queryParameters.supplierId).subscribe(res => {
              this.searchForm.patchValue({
                supplier: res[0],
              });
            });
          }
          /**
           * CLIENT
           *
           **/
          if (this.queryParameters.clientId) {
            this._programService.getClientsByCorrelationKey(this.queryParameters.clientId).subscribe(clients => {
              if (clients.length) {
                this.searchForm.patchValue({
                  client: clients[0],
                });
              }
            });
          }
          /**
           *  VENUE
           *  Note: Venues are actually just suppliers.
           **/
          if (this.queryParameters.venueId) {
            this._supplierService.getSuppliersByCorrelationKey(this.queryParameters.venueId).subscribe(res => {
              this.searchForm.patchValue({
                venue: res[0],
              });
            });
          }

          if (this.searchForm.value.status == null || this.searchForm.value.page !== 1) 
            // Add default status and page
            this.searchForm.patchValue({
              status: 'PBH',
              page: 1,
            });
           } else {
          // clear form with default values or query param values
          this.searchForm.patchValue({
            status: 'PBH',
            page: 1,
            mediaType: null,
            program: null,
          });
        }
      });
  }

  getOffices() {
    // Get Office Observable
    this.store$.pipe(select(state => state.offices)).subscribe(res => {
      if (res.offices.length === 1) {
        this.defaultOffice = res.offices[0].officeId;
      } else {
        this.defaultOffice = res.currentSelectedOfficeId;
      }
      if (this.searchForm.value.offices != null) {
        if (
          (this.searchForm.value.offices.length <= 0 || this.searchForm.value.offices[0] === undefined) &&
          !this.queryParameters.officeId
        ) {
          this.searchForm.patchValue({
            offices: [this.defaultOffice],
          });
        }
      } else {
        if (this.defaultOffice != null) {
          this.searchForm.patchValue({
            offices: [this.defaultOffice],
          });
        }
      }

      this.offices= res.allOffices;
    });

  }
  // Handles an "enter" press on the tag input. Will just dump the text in and search on it. Will
  // also split strings on space and create an array of tags for each word
  submitSearch() {
    this.searchForm.patchValue({
      term: this.userInput.value,
    });
  }

  // TODO: Implement
  changeSearchTerm(searchTerm: string) {
    this.searchForm.patchValue({
      term: searchTerm,
    });
  }
  // TODO: Check if this is needed after this backend call starts working
  searchTermDisplayFn(tag: MediaTags) {
    if (tag) {
      return tag;
    }
  }

  toggleSelectionMode() {
    this.canSelectMedia = !this.canSelectMedia;
    this.selectedImageIds = [];
  }
  onImageSelected($event) {
    if ($event.selected) {
      this.selectedImageIds.push(+$event.mediaId);
    } else {
      this.selectedImageIds = this.selectedImageIds.filter(i => i != $event.mediaId);
    }
  }
  bulkArchiveMedia() {
    // Note: id 6 = archived. yes I know, magic numbers. But we can't enum that due to the 3 way split.
    // Figured this comment is reasonable enough to indicate as such
    this.store$.dispatch(MediaActions.bulkUpdateMediaStatus({ mediaIds: this.selectedImageIds, statusId: 6 }));
    this.selectedImageIds = [];
    this.canSelectMedia = true;
    // this.searchForm.patchValue({page: 1});
  }

  // TypeAhead Selections
  programSelected($event) {
    this.searchForm.patchValue({ program: $event, service: null });
  }
  supplierSelected($event) {
    this.searchForm.patchValue({ supplier: $event });
  }
  venueSelected($event) {
    this.searchForm.patchValue({ venue: $event });
  }
  clientSelected($event) {
    this.searchForm.patchValue({ client: $event });
  }
  aeSelected($event) {
    this.searchForm.patchValue({ accountExecutive: $event });
  }
  serviceSelected($event) {
    this.searchForm.patchValue({ service: $event });
  }

  search() {
    //  If we are searching by library service Id, ignore regular search
    if (this.readyToSearch && !this.queryParameters.libraryServiceId) {
      if (this.searchForm.value.page == 1) {
        this.isSearching = true;
      }
      const searchRequest = this.buildSearchRequestObject();

      this.store$.dispatch(
        MediaActions.searchMedia({ searchRequest: searchRequest, page: this.searchForm.value.page })
      );
    }
  }
  buildSearchRequestObject() {
    const result: SearchRequest = {
      pageIndex: this.searchForm.value.page,
      isCreative:this.searchForm.value.isCreative,
      mediaStatus: this.searchForm.value.status,
      offices: this.searchForm.value.offices,
      term: this.searchForm.value.term,
      mediaType: this.searchForm.value.mediaType,
      program: this.searchForm.value.program,
      client: this.searchForm.value.client,
      accountExecutive: this.searchForm.value.accountExecutive,
      service: this.searchForm.value.service,
      supplier: this.searchForm.value.supplier,
      venue: this.searchForm.value.venue,
      serviceDateFrom: this.searchForm.value.serviceDateFrom,
      serviceDateTo: this.searchForm.value.serviceDateTo,
      uploadDateFrom: this.searchForm.value.uploadDateFrom,
      uploadDateTo: this.searchForm.value.uploadDateTo,
    };
    return result;
  }

  clearSearch() {
    //  If the user clears - make sure to flip off lib service search
    this.hideSearchFields = false;
    this.initializeSearchForm(true);
  }

  handleFormChanges(doSearch: boolean = true) {
    this.searchForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (this.lastFormValues) {
        //  This checks to see if we still have the same page value, and it's not 1 on a change
        //  this would mean that the user must have changed some other value, so we reset page to 1 to do a new search.
        //  We return here and do not search, because this handler will just fire again when we patch the form, but that
        //  wont trip anymore
        if (res.page === this.lastFormValues.page && res.page != 1) {
          this.searchForm.patchValue({ page: 1 });
          return;
        }
      }
      this.lastFormValues = res;

      this.search();
    });
  }

  addMediaToSelectedAlbums(selectedAlbumsObj: { addedAlbumIds: number[]; removedAlbumIds: number[] }) {
    if (selectedAlbumsObj) {
    
     

      this.store$.dispatch(
        bulkAddMediaFromAlbum({ mediaIds: this.selectedImageIds, selectedAlbumIds: selectedAlbumsObj.addedAlbumIds })
      );
      this.selectedImageIds = [];
      this.toggleSelectionMode();
    }
  }
  addMediaToSelectedGalleries(selectedGalleriesObj: { addedGalleryIds: number[]; removedGalleryIds: number[] }) {
    if (selectedGalleriesObj) {
     
      this.store$.dispatch(
        bulkAddMediaFromGallery({ mediaIds: this.selectedImageIds, galleryIds: selectedGalleriesObj.addedGalleryIds })
      );
      this.selectedImageIds = [];
      this.toggleSelectionMode();
    }
  }

  createAlbum(event: any) {
    const albumDialogRef = this.albumDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: true,
        pocket: this.media.filter(m=>this.selectedImageIds.includes(+m.mediaId,0)),
        albums: this.albums,
      },
    });

    albumDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          let request = new AlbumCreateCommand();
          request.Name = result.Name;
          request.Description = result.Description;
          request.CoverImage = result.CoverImage;
          request.UserId = this.loggedUserId;
          request.MediaId = +result.MediaId;
          request.MediaIds = this.selectedImageIds;

          this.store$.dispatch(createUserAlbum({ newAlbumDto: request }));
          this.store$.dispatch(getUserAlbums());
          this.store$.dispatch(getUserGalleries());
          this.selectedImageIds = [];
          this.canSelectMedia=false;
        }
      });
  }

  createGallery(event: any) {
    const galleryDialogRef = this.galleryDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: false,
        pocket: this.media.filter(m=>this.selectedImageIds.includes(+m.mediaId,0)),
        offices: this.userOffices,
      },
    });

    galleryDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          let gallery = new GalleryCreateCommand();
          (gallery.Name = result.Name), (gallery.Description = result.Description);
          gallery.CoverImage = result.CoverImage;
          gallery.CreatedByUserId = this.loggedUserId;
          gallery.UpdatedByUserId = this.loggedUserId;
          gallery.IsPublic = false;
          gallery.MediaId = +result.MediaId;
          gallery.MediaIds = this.selectedImageIds;
       
          gallery.OfficeId = +result.OfficeId;
          gallery.StatusId = 4; // This is "Draft" status
        
          this.store$.dispatch(createUserGallery({ galleryDto: gallery }));
          this.store$.dispatch(getUserAlbums());
          this.store$.dispatch(getUserSharedAlbums());
          this.store$.dispatch(getUserGalleries());
          this.selectedImageIds = [];
          this.canSelectMedia=false;
        }
      });
  }

  infiniteScroll($event) {
    if (!this.endOfResults) {
      let page = this.searchForm.value.page;
      this.searchForm.patchValue({ page: page + 1 });
    }
  }
}
