import { HttpClient } from '@angular/common/http';
import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { MediaMetadata } from '../models/media.model';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class PocketService {
  isOpen = false;
  private pocket = new BehaviorSubject([]);
  pocketList = this.pocket.asObservable();

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) { }

  toggle() {
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
  }

  addMediaToPocket(mediaId: number, userId: number): Observable<number> {
    const url = `${environment.mediaApiEndpoint}MediaPocket/${userId}/add/${mediaId}`;

    return this.http.post<number>(url, {});
  }

  removeMediaFromPocket(mediaIds: number[], userId: number): Observable<number> {
    const url = `${environment.mediaApiEndpoint}MediaPocket/${userId}/remove`;
    const data = {
      MediaIds: mediaIds
    }

    return this.http.put<number>(url, data);
  }

  getPocketMediaForUser(userId: number): Observable<MediaMetadata[]> {
    const url = `${environment.mediaApiEndpoint}MediaPocket?UserId=` + userId;

    return this.http.get<MediaMetadata[]>(url);
  }
}
