import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ofType } from "@ngrx/effects";
import { ActionsSubject, Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UploadContext } from "../../models/media.model";
import { Office } from "../../models/office.model";
import { SupplierUploadContext } from "../../models/supplier.model";
import { MediaPatchPayLoad, mediaPatch, mediaTagUpdate } from "../../models/uploadCriteria.model";
import { CuratorTypes } from "../../models/user.model";
import * as mediaActions from "../../ngrx/actions/media.actions";
import { AppState } from "../../ngrx/reducers";
import { MediaUploadState } from "../../ngrx/reducers/media.reducer";
import { selectUploadQueue } from "../../ngrx/selectors/media.selector";

@Component({
  selector: "app-drafted-media-overview-view",
  templateUrl: "./drafted-media-overview-view.component.html",
  styleUrls: ["./drafted-media-overview-view.component.scss"]
})
export class DraftedMediaOverviewViewComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  public title: string = "Loading";
  public uploadQueueItems: MediaUploadState[] = [];
  public uploadContext: UploadContext;
  public bulkTagAllMedia: boolean = true;
  public bulkTaggingList: number[] = [];
  public bulkTagsClear = []; // Used to wipe the bulk tagging type ahead
  private bulkSupplierClear = [];
  public source: string;
  public offices$: Observable<Office[]>;
  public curators$: Observable<CuratorTypes[]>;
  private mediaPatchPayload: MediaPatchPayLoad;
  public isMediaPatchPayloadValid: boolean = false;
  loading=false;
  publishing=false;

  constructor(private store$: Store<AppState>, private route: ActivatedRoute,private actionsListener$: ActionsSubject,) {}

  ngOnInit() {
    this.loading=true;
    //Get type from route
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.source = params.source;
    });
    //get offices for form
    this.offices$ = this.store$.pipe(select(state => state.offices.offices));
    this.curators$ = this.store$.pipe(select(state => state.user.curatorTypes));
    
    this.store$
      .pipe(select(selectUploadQueue), takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.uploadQueueItems = res;
        this.setQueueNameAndUploadContext();
        
      });

     

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  uploadQueueTrackFunction(index, item) {
    if (!item) return null;
    if (!item.media) return null;
    return (
      item.media.metaData.tags.length +
      item.media.metaData.suppliers.length +
      item.media.metaData.statusId + (item.id * 1000)
      // The above line will result in an integer that will change if the status changes, or tags are added or removed - which
      // is exactly when we want it to update...
    );
  }

  setQueueNameAndUploadContext() {
    if (this.uploadQueueItems.length) {
      let testItem = this.uploadQueueItems[0];
      if (testItem.media && testItem.media.metaData) {
        let uploadContext = testItem.media.metaData.uploadContext;
        this.uploadContext = uploadContext;
        if (this.source) {
          if (this.source == "LSVC") {
            this.title = uploadContext.libraryService.name;
          } else if (this.source == "PSR") {
            this.title = `${uploadContext.program.number} - ${uploadContext.program.name}`;
          } else if (this.source == "SUP") {
            let supplier = uploadContext.supplier as SupplierUploadContext;
            this.title = supplier.name ? supplier.name : "Name not found";
          } else if (this.source == "MISC") {
            this.title = "Miscellaneous Cluster";
          }
          this.loading=false;
        }
        
      }
    }
  }



  //Individual Item Actions
  helloTagsAddedToItem($event) {
    const MANUAL_TAG: any = 2; //TODO: Fix
    $event.tags.map(tag => {
      const criteria: mediaTagUpdate = {
        mediaAssets:[parseInt(<string>$event.mediaId)],
        tagTypeId: MANUAL_TAG,
        tagName: tag.tagName
      };
      this.store$.dispatch(
        mediaActions.addTagToMedia({ tag: tag, criteria: criteria })
      );
    });
  }
  helloTagsRemovedFromItem($event) {
    $event.tags.map(tag => {
      this.store$.dispatch(
        mediaActions.removeTagFromMedia({ tag: tag, mediaId: $event.mediaId })
      );
    });
  }
  supplierAddedToItem($event) {
    const supplier = $event.supplier;
    const mediaId = $event.mediaId;
    const addSupplierCmd: mediaPatch = {
      mediaId:parseInt(<string>mediaId),
      ops: "Add",
      path: "/Supplier",
      newValue: supplier.id.toString(),
      oldValue: "",
      userId:0
    };
    this.store$.dispatch(
      mediaActions.updateSupplierOnMedia({
        mediaId: parseInt(<string>mediaId),
        supplier: supplier,
        payload: addSupplierCmd
      })
    );
  }
  supplierRemovedFromItem($event) {
    const supplier = $event.supplier;
    const mediaId = $event.mediaId;
    const removeSupplierCmd: mediaPatch = {
      mediaId: parseInt(<string>mediaId),
      ops: "Remove",
      path: "/Supplier",
      newValue: supplier.id.toString(),
      oldValue: "",
      userId:0
    };
    this.store$.dispatch(
      mediaActions.updateSupplierOnMedia({
        mediaId: parseInt(<string>mediaId),
        supplier: supplier,
        payload: removeSupplierCmd
      })
    );
  }

  removeAzureTagFromItem($event) {
    const name = $event.name;
    const mediaId = $event.mediaId;
    const mediaItemArray = this.uploadQueueItems.filter(
      i => i.media && i.media.mediaId == mediaId
    );
    this.store$.dispatch(
      mediaActions.removeAzureTagFromMedia({
        media: mediaItemArray[0].media,
        name: name
      })
    );
  }

  updateBulkTaggingList($event) {
    const status = $event.status;
    const mediaId = +$event.mediaId;
    if (status) {
      this.bulkTaggingList.push(mediaId);
    } else {
      this.bulkTaggingList = this.bulkTaggingList.filter(i => i != mediaId);
    }

    if (this.bulkTaggingList.length > 0) {
      this.bulkTagAllMedia = false;
    }
  }

  checkIfMarkedForBulkTagging(id: number) {
    if(this.bulkTaggingList.indexOf(id) > -1) {
      return true;
    } else {
      return false;
    }
  }

  archiveMediaItem($event) {
    this.store$.dispatch(
      mediaActions.updateMediaStatus({ mediaId: +$event, status: "ARC" })
    );
  }

  //Bulk Actions
  publishMedia() {
    const saveTitle=this.title;
    this.publishing=true;
    this.title=`${this.title} (Publishing)`
    const mediaToUpdate = this.uploadQueueItems
      .filter(i => i.media)
      .filter(i => i.media.metaData.statusId == 4);
    const media=  mediaToUpdate.map(i=>+i.media.mediaId);
    this.actionsListener$
    .pipe(ofType('[media] bulkUpdateMediaStatusSuccess'))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data: any) => {
      this.uploadQueueItems
      .filter(i => i.media)
      .filter(i => i.media.metaData.statusId == 5).map(i=>{
        i.media.status = 'PBH';
        i.media.metaData.statusDescription = 'Published';
        i.media.metaData.statusId = 5;
      });
      this.store$.dispatch(mediaActions.getDraftedMedia());
      this.publishing=false;
      this.title=saveTitle;
    });
      this.store$.dispatch(mediaActions.bulkUpdateMediaStatus({ mediaIds: media, statusId: 5 }));
    // mediaToUpdate.map(media => {
    //   this.store$.dispatch(
    //     mediaActions.updateMediaStatus({
    //       mediaId: +media.media.mediaId,
    //       status: "PBH"
    //     })
    //   );
    // });
    
   
  }

  publishMediaId(mediaId){
    const saveTitle=this.title;
    this.publishing=true;
    this.title=`${this.title} (Publishing)`
    this.actionsListener$
    .pipe(ofType('[media] updateMediaStatusSuccess'))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data: any) => {
      this.publishing=false;
      this.title=saveTitle;
      this.store$.dispatch(mediaActions.getDraftedMedia());
     
    });
      this.store$.dispatch(
        mediaActions.updateMediaStatus({
          mediaId: +mediaId,
          status: "PBH"
        })
      );
    
  }
  bulkHelloTagsChanged(tags) {
    const tag = tags[0];
    let mediaIds = [];
    if (this.bulkTagAllMedia) {
      mediaIds = this.uploadQueueItems
        .filter(i => i.media)
        .filter(i => i.media.metaData.statusId != 6)
        .map(i => {
          return +i.media.mediaId;
        });
    } else {
      mediaIds = this.bulkTaggingList.slice();
    }

    const MANUAL_TAG: any = 2; //TODO: Fix

    const criteria: mediaTagUpdate = {
      mediaAssets: mediaIds,
      tagTypeId: MANUAL_TAG,
      tagName: tag.tagName
    };
    this.store$.dispatch(
      mediaActions.addTagToMedia({ tag: tag, criteria: criteria })
    );

    this.bulkTagsClear = [];
  }

  bulkSupplierTagsChanged(supplier) {
    let mediaIds = [];
    if (this.bulkTagAllMedia) {
      mediaIds = this.uploadQueueItems
        .filter(i => i.media)
        .filter(i => i.media.metaData.statusId != 6)
        .map(i => {
          return i.media.mediaId;
        });
    } else {
      mediaIds = this.bulkTaggingList.slice();
    }

    mediaIds.map(id => {
      const addSupplierCmd: mediaPatch = {
        mediaId: +id,
        ops: "Add",
        path: "/Supplier",
        newValue: supplier.id.toString(),
        oldValue: "",
        userId:0
      };
      this.store$.dispatch(
        mediaActions.updateSupplierOnMedia({
          mediaId: +id,
          supplier: supplier,
          payload: addSupplierCmd
        })
      );
    });

    this.bulkSupplierClear = [];
  }

  uploadContextChange(mediaPatchPayload: MediaPatchPayLoad) {
    this.mediaPatchPayload = mediaPatchPayload;
    this.validateMediaPatchPayload();
  }

  saveMediaPatchPayload() {
    let mediaPatchPayload = this.mediaPatchPayload;
    this.validateMediaPatchPayload();
    if (this.isMediaPatchPayloadValid) {
      this.uploadQueueItems.map(i => {
        this.store$.dispatch(
          mediaActions.patchMediaUploadContext({
            mediaId: +i.media.mediaId,
            payload: mediaPatchPayload
          })
        );
      });
    }
  }

  validateMediaPatchPayload() {
    if (
      this.mediaPatchPayload &&
      this.mediaPatchPayload.OfficeId &&
      (this.mediaPatchPayload.SupplierId ||
        (this.mediaPatchPayload.ProgramId &&
          this.mediaPatchPayload.ProgramServiceId &&
          this.mediaPatchPayload.ServiceVenueId))
    ) {
      this.isMediaPatchPayloadValid = true;
    } else {
      this.isMediaPatchPayloadValid = false;
    }
  }
}
