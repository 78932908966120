import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { checkUserLogin } from './ngrx/actions/user.actions';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public login$: Observable<string>;
  profile: any;
  
  isIframe = false;
  loginDisplay = false;
refresh=false;
  constructor(private router: Router, private store$: Store<any>
    , private authService: MsalService
    , private userService: UserService
    ,private broadcastService: MsalBroadcastService,
    private mlAuthService:AuthService) {
  }
  ngOnInit() {
    this.login$ = this.store$.pipe(select(state => state.user.user.username));
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.isIframe = window !== window.parent && !window.opener;
    // this.authService.loginRedirect()
    // .subscribe({
    //   next: (result) => {
    //     console.log(result);
    //     this.setLoginDisplay();
    //     this.login$ = this.store$.pipe(select(state => state.user.user.username));
    //   },
    //   error: (error) => console.log(error)
    // });
  

    this.broadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      console.log(result);
      this.setLoginDisplay();
    });

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    });

  
  }

  setLoginDisplay() {

    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay){
      this.profile = this.authService.instance.getAllAccounts()[0];

      this.userService.getUserByLogin(this.profile.username).subscribe(user => {
        this.mlAuthService.login(
          {userName: this.profile.username
            , password: ''
            , employeeOfficeId: user[0].defaultOfficeId === null ? 3:user[0].defaultOfficeId 
            , type: 0
            , userId: user[0].employeeId}
          ).subscribe(data => {
            this.store$.dispatch(checkUserLogin());
            //window.location.reload();
            this.refresh=true;
          });
        });
      }


    



  }
  // async checkAccount() {
    
  //   this.loggedIn = !!this.profile;
  //   if (this.profile) {
  //     this.user = { name: this.profile.name, id: this.profile.accountIdentifier, email: this.profile.userName };
  //     this._profileService.user = await this.userService.getUser().toPromise();
  //     if (!this._profileService.user.userId && this.loggedIn && !this.userCreated) {
  //       this.userCreated = true;
  //       await this.timeEntryService
  //         .createUser(this.user)
  //         .pipe(first(), delay(0))
  //         .toPromise()
  //         .then(data => {
  //           this.user.userId = data.userId;
  //           this._profileService.user.categoryDefaultId = data.categoryDefaultId;
  //           this._profileService.user.taskDefaultId = data.taskDefaultId;
  //           this._profileService.user.userId = data.userId;
  //           this._profileService.user.name = this.user.name;
  //         });
  //     } else if (this.loggedIn) {
  //       this.user.userId = this._profileService.user.userId;
  //       await this.timeEntryService
  //         .getUser(this.user.userId)
  //         .pipe(first(), delay(0))
  //         .toPromise()
  //         .then(data => {
  //           this._profileService.user.categoryDefaultId = data.categoryDefaultId;
  //           this._profileService.user.taskDefaultId = data.taskDefaultId;
  //         });
  //     }
  //   }
  // }

  ngOnDestroy() {
  }
}
