import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Office, officeModel } from '../models/office.model';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  constructor(private http: HttpClient) { }

  getUserOffices(userId: number): Observable<Office[]> {
    return this.http.get<Office[]>(environment.mediaApiEndpoint + 'user/' + userId + '/office');
    }
  
    
  getOfficeIdByCorrelationKey(correlationKey: number): Observable<number> {
    return this.http.get<number>(environment.adminApiEndpoint + "Office/id/" + correlationKey);
  }

  getAllOffices(): Observable<Office[]> {
    return this.http.get<Office[]>(environment.mediaApiEndpoint + 'Office/all');
  }

  async getOfficeByCorrelationKey(correlationKey: number) {
    return await this.http.get<officeModel[]>(environment.mediaApiEndpoint +
        'Office?correlationKey=' + correlationKey)
        .pipe(
          catchError(this.handleError<officeModel[]>())
        ).toPromise();
    }

    private handleError<T> (result?: T) {
      return (error: any): Observable<T> => {

        return of(result as T);
      };
    }
}
