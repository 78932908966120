import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { MediaService } from 'src/app/services/media.service';
import { GalleryService } from '../../services/gallery.service';
import * as galleryActions from '../actions/galleries.actions';
import * as mediaActions from '../actions/media.actions';

@Injectable()
export class GalleryEffects {
  constructor(
    private actions$: Actions,
    private _galleryService: GalleryService,
    private _mediaService: MediaService,
    private store$: Store<any>
  ) {}

  getUserGalleries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.getUserGalleries),
      withLatestFrom(this.store$),
      mergeMap(data => {
        return this._galleryService.getGalleriesByUserAccess(data[1].user.user.userLoginId).pipe(
          map(galleries => {
            return galleryActions.getUserGalleriesSuccess({ galleries: <any[]>galleries });
          }),
          catchError(error => {
            return of(error);
          })
        );
      })
    )
  );

  getTopViewedGalleries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.getTopViewGalleries),
      withLatestFrom(this.store$),
      mergeMap(data => {
        if (data  && data.length && data[0].officeId>0){
          return this._galleryService.getTopViewdGalleriesByOffice(data[0].officeId, data[0].pageSize).pipe(
            map(galleries => {
              return galleryActions.getTopViewGalleriesSuccess({ galleries: galleries });
            })
          );
        }
      
      })
    )
  );
  getAllGalleriesForAllOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.getAllGalleriesForAllOffices),
      withLatestFrom(this.store$),
      mergeMap(data => {
        return this._galleryService.getAllGalleries(data[0].pageSize).pipe(
          map(galleries => {
            return galleryActions.getAllGalleriesForAllOfficesSuccess({ galleries: galleries });
          })
        );
      })
    )
  );
  createUserGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.createUserGallery),
      withLatestFrom(this.store$),
      switchMap(data => {
        return this._galleryService.createGallery(data[0].galleryDto).pipe(
          map(gallery => {
            return galleryActions.createUserGallerySuccess({ newGallery: <any>gallery });
          }),
          catchError(async () => galleryActions.createUserGalleryFail)
        );
      })
    )
  );

  addMediaToGalleries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.addMediaToGalleries),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const obsArray$ = [];
        let selectedGalleries = data[0].selectedGalleryIds;
        if (data[0].selectedGalleryIds && data[0].selectedGalleryIds.length > 0) {
          selectedGalleries.forEach(id => {
            obsArray$.push(this._mediaService.addMediaToMediaGroup(data[0].mediaId, id).pipe(take(1)));
          });
          return forkJoin(obsArray$).pipe(
            switchMap(res => {
              return [
                galleryActions.addMediaToGalleriesSuccess({
                  mediaId: data[0].mediaId,
                  selectedGalleryIds: selectedGalleries,
                }),
                mediaActions.getMediaMetadataById({ mediaId: data[0].mediaId, getRelatedImages: false }),
              ];
            })
          );
        } else {
          return of(mediaActions.emptyAction());
        }
      }),
      catchError(error => {
        console.log(error);
        return of(error);
      })
    )
  );

  bulkAddMediaFromGallery$ = createEffect(() =>
  this.actions$.pipe(
    ofType(galleryActions.bulkAddMediaFromGallery),
    withLatestFrom(this.store$),
    mergeMap(data => {
      const obsArray$ = [];
      let selectedGalleries = data[0].galleryIds;
      if (data[0].galleryIds && data[0].galleryIds.length > 0) {
        selectedGalleries.forEach(id => {
          obsArray$.push(this._mediaService.bulkAddMediaToMediaGroup(data[0].mediaIds, id).pipe(take(1)));
        });
        return forkJoin(obsArray$).pipe(
          switchMap(res => {
            return [
              galleryActions.bulkAddMediaFromGallerySuccess({
                mediaIds: data[0].mediaIds,
                galleryIds: selectedGalleries,
              }),
              //mediaActions.getMediaMetadataById({ mediaId: data[0].mediaId, getRelatedImages: false }),
            ];
          })
        );
      } else {
        return of(mediaActions.emptyAction());
      }
    }),
    catchError(error => {
      console.log(error);
      return of(error);
    })
  )
);

  removeMediaFromGalleries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.removeMediaFromGalleries),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const obsArray$ = [];
        let selectedGalleryIds = data[0].selectedGalleryIds;
        if (data[0].selectedGalleryIds && data[0].selectedGalleryIds.length > 0) {
          selectedGalleryIds.forEach(id => {
            obsArray$.push(this._mediaService.removeMediaFromMediaGroup(data[0].mediaId, id).pipe(take(1)));
          });
          return forkJoin(obsArray$).pipe(
            switchMap(res => {
              return [
                galleryActions.removeMediaFromGalleriesSuccess({
                  mediaId: data[0].mediaId,
                  selectedGalleryIds: selectedGalleryIds,
                }),
                mediaActions.getMediaMetadataById({ mediaId: data[0].mediaId, getRelatedImages: false }),
              ];
            })
          );
        } else {
          return of(mediaActions.emptyAction());
        }
      }),
      catchError(error => {
        console.log(error);
        return of(error);
      })
    )
  );

  bulkRemoveMediaFromGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.bulkRemoveMediaFromGallery),
      mergeMap(data => {
        return this._mediaService.bulkRemoveMediaFromMediaGroup(data.mediaIds, data.galleryId).pipe(
          map(res => {
            return galleryActions.bulkRemoveMediaFromGallerySuccess({
              mediaIds: data.mediaIds,
              galleryId: data.galleryId,
            });
          })
        );
      })
    )
  );

  editGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.editGallery),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const action = data[0];
        const state = data[1];
        let dto = action.galleryEditDTO;
        dto.UpdatedByUserId = state.user.user.userLoginId;
        return this._galleryService.editGallery(dto).pipe(
          map(res => {
            return galleryActions.editGallerySuccess({ gallery: res });
          })
        );
      })
    )
  );

  updateGalleryStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.updateGalleryStatus),
      mergeMap(data => {
        return this._galleryService.updateGalleryStatus(data.id, data.statusId).pipe(
          map(res => {
            return galleryActions.updateGalleryStatusSuccess({ id: data.id, statusId: data.statusId });
          })
        );
      })
    )
  );
}
