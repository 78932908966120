import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserType } from '../models/user.model';

const SESSION_KEY = 'auth_isAuthenticated';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _http: HttpClient) {}

  clear() {
    // sessionStorage.removeItem(SESSION_KEY);
    sessionStorage.clear();
  }

  isAuthenticated(): Observable<boolean> {
    const isAuthenticated = JSON.parse(sessionStorage.getItem(SESSION_KEY));

    if (!isAuthenticated) {
      return this._http.get<boolean>('app/api/isauthenticated', { headers: { 'cache-control': 'no-cache,no-store' } }).pipe(
        tap(data => {
          sessionStorage.setItem(SESSION_KEY, JSON.stringify(data));
        })
      );
    }

    return of(true);
  }

  login(login: Login): Observable<any> {
    return this._http.post<boolean>('app/api/login', login);
  }

  logout(): Observable<void> {
    this.clear();
    return this._http.delete<void>('app/api/logout');
  }
}

export interface Login {
  userName: string;
  password: string;
  employeeOfficeId?: number;
  type: UserType;
  userId:number;
  
}
