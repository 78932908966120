import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, skipWhile, take, takeUntil } from 'rxjs/operators';
import { MediaEditDialogComponent } from 'src/app/components/media/media-edit-dialog/media-edit-dialog.component';
import {
  addMediaToAlbums,
  createUserAlbum,
  getUserAlbums,
  removeMediaFromAlbums
} from 'src/app/ngrx/actions/albums.actions';
import {
  addMediaToGalleries,
  createUserGallery,
  getUserGalleries,
  removeMediaFromGalleries
} from 'src/app/ngrx/actions/galleries.actions';
import { selectUserAlbums } from 'src/app/ngrx/selectors/albums.selector';
import { selectAllGalleriesForUserOffices } from 'src/app/ngrx/selectors/galleries.selector';
import { AlbumCreateCommand } from 'src/app/services/album.service';
import { GalleryCreateCommand } from 'src/app/services/gallery.service';
import { ContentCreateDialogComponent } from '../../components/dialogs/content-create-dialog/content-create-dialog.component';
import { MediaEditUploadContextDialogComponent } from '../../components/media/media-edit-upload-context-dialog/media-edit-upload-context-dialog.component';
import { Album } from '../../models/album.model';
import { Gallery } from '../../models/gallery.model';
import { Media } from '../../models/media.model';
import { Office, officeModel } from '../../models/office.model';
import { Permissions } from '../../models/permissions';
import { supplierModel } from '../../models/supplier.model';
import { tagItem, tagModel } from '../../models/tagModel';
import { mediaPatch, mediaTagUpdate } from '../../models/uploadCriteria.model';
import { CuratorTypes } from '../../models/user.model';
import * as mediaActions from '../../ngrx/actions/media.actions';
import { editMedia } from '../../ngrx/actions/media.actions';
import { addMediaToPocket } from '../../ngrx/actions/pocket.actions';
import { AppState } from '../../ngrx/reducers';
import { doesPermissionExist, selectUser } from '../../ngrx/selectors/user.selector';
import { TagsService } from '../../services/tags.service';

@Component({
  selector: 'app-media-detail-view',
  templateUrl: './media-detail-view.component.html',
  styleUrls: ['./media-detail-view.component.scss'],
})
export class MediaDetailViewComponent implements OnInit {
  isLiked: boolean;
  albums: Album[] = [];
  galleries: Gallery[] = [];
  selectedAlbums: Album[] = [];
  selectedGalleries: Gallery[] = [];
  loggedUserId: any;
  mediaId: number;

  sizeInMb: string;

  moreRelatedMediaShowing: boolean = false;
  relatedMedia: Media[] = [];
  private isLiked$: Observable<boolean>;
  public loading: boolean = true;
  public isInPocket: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private media$: Observable<Media>;
  private media: Media;
  private originalMediaid: number;

  // TODO: Fix this variable - it's for if audio is playing. Just feels messy
  isPlaying: boolean = false;
  canAddMediaToGallery: boolean = false;
  pocketIds: number[] = [];
  //Data
  private offices: Office[];
  private offices$: Observable<Office[]>;
  private curators: CuratorTypes[];
  private curators$: Observable<CuratorTypes[]>;

  //Permissions
  // TODO: Fix Permissions as a whole, they are terrible
  private canViewMoreOptions$: Observable<boolean>;
  private canAddTags$: Observable<boolean>;
  private canAddTags: boolean = false;
  public mediaMatchesCuratorType = false;

  private manualTags: tagModel[] = [];
  private ogManualTags: tagModel[] = [];
  private supplierTags: supplierModel[] = [];
  private azureTags: tagItem[] = [];

  private hasIncrementedViewCount: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public coverDialog: MatDialog,
    public uploadDataEditor: MatDialog,
    public detailsServices: MatDialog,
    public mediaEditDialog: MatDialog,
    public albumDialog: MatDialog,
    public galleryDialog: MatDialog,
    private store$: Store<AppState>,
    private _tagsService: TagsService
  ) {}

  ngOnInit() {
    const loggedIn$ = this.store$.pipe(select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false || res.user=== null || res.user.userLoginId === null || res.user.userLoginId===0));
    loggedIn$.subscribe(res => {
      this.store$.dispatch(getUserAlbums());
      this.store$.dispatch(getUserGalleries());
      this.store$.pipe(takeUntil(this.unsubscribe$), select(selectUserAlbums)).subscribe(res => (this.albums = res));
      this.store$
        .pipe(takeUntil(this.unsubscribe$), select(selectAllGalleriesForUserOffices))
        .subscribe(res => (this.galleries = res));
      this.store$.pipe(takeUntil(this.unsubscribe$), select(selectUser)).subscribe(res => {
        this.loggedUserId = res.userLoginId;
      });
      this.store$
        .pipe(takeUntil(this.unsubscribe$), select(doesPermissionExist, { permission: Permissions.CanAddMediaToGallery }))
        .subscribe(res => {
          this.canAddMediaToGallery = res;
        });
      this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
        if (params.mediaId) {
          this.originalMediaid = parseInt(params.mediaId);
        }
        this.media$ = this.store$.pipe(
          select(state => state.media.items),
          map(items => {
            const result = items.filter(item => item.mediaId == params.mediaId);
            if (result.length) {
              return result[0];
            } else {
              return null;
            }
          })
        );
        this.store$.dispatch(mediaActions.getIsMediaLiked({ mediaId: +params.mediaId }));
        this.store$
          .pipe(
            takeUntil(this.unsubscribe$),
            select(state => state.media.mediaPocketIds)
          )
          .subscribe(mediaIds => {
            if (mediaIds && mediaIds.length > 0) {
              this.pocketIds = mediaIds;
            } else {
              this.pocketIds = [];
            }
            if (this.media) {
              this.isInPocket = false;
              mediaIds.map(i => {
                if (i == this.media.mediaId) {
                  this.isInPocket = true;
                }
              });
            }
          });
        this.media$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
          if (!res || !res.metaData) {
            if (this.originalMediaid && !this.hasIncrementedViewCount) {
              this.hasIncrementedViewCount = true;
              this.incrementViewCountAndGetMetadata(this.originalMediaid);
            }
          } else {
            this.media = res;
            if (this.media){this.media.mediaId=+res.mediaId}
            this.selectedAlbums = this.media.metaData.albums;
            this.selectedGalleries = this.media.metaData.galleries;
            this.relatedMedia = _.take(this.media.relatedMedia, 5);
            this.sizeInMb = (parseFloat(this.media.metaData.fileSize) / 1000).toFixed(1).toString();
  
            this.checkCuratorTypeMatchesMedia();
  
            //pocket check.
            if (this.pocketIds) {
              this.isInPocket = false;
              this.pocketIds.map(i => {
                if (i == this.media.mediaId) {
                  this.isInPocket = true;
                }
              });
            }
            this.store$
              .pipe(
                takeUntil(this.unsubscribe$),
                select(state => state.media.isMediaLiked)
              )
              .subscribe(val => {
                if (val) {
                  let result = val.find(x => x.mediaId == res.mediaId);
                  if (result) {
                    this.isLiked = result.isLiked;
                  }
                }
              });
            this.manualTags = this.media.metaData.tags
              .filter(t => t.type == 2)
              .map(t => {
                const result: tagModel = {
                  tagId: t.tagId,
                  tagName: t.name,
                  id: t.tagId,
                  tagCategoryId: 0,
                  tagCategoryName: t.tagCategoryName,
                };
                return result;
              });
            this.ogManualTags = this.manualTags.slice();
            this.supplierTags = this.media.metaData.suppliers.map(t => {
              const result: supplierModel = {
                id: t.supplierId,
                name: t.supplierName,
                supplierCompanies: [],
              };
              return result;
            });
            this.azureTags = this.media.metaData.tags.filter(t => t.type == 1);
            this.loading = false;
          }
        });
        this.store$
          .pipe(
            takeUntil(this.unsubscribe$),
            select(state => state.media.editedImageId)
          )
          .subscribe(id => {
            if (id && id != params.mediaId) {
              window.location.href = `/media/${id}`;
            }
          });
      });
  
      //Data Set Up
      this.offices$ = this.store$.pipe(select(state => state.offices.offices));
      this.offices$.pipe(takeUntil(this.unsubscribe$)).subscribe(offices => {
        this.offices = offices;
      });
      this.curators$ = this.store$.pipe(select(state => state.user.curatorTypes));
      this.curators$.pipe(takeUntil(this.unsubscribe$)).subscribe(curatorTypes => {
        this.curators = curatorTypes;
      });
      this.canViewMoreOptions$ = this.store$.pipe(
        select(doesPermissionExist, { permission: Permissions.CanViewOptionsMenuOnMediaPage })
      );
      this.canAddTags$ = this.store$.pipe(select(doesPermissionExist, { permission: Permissions.CanAddTagsToMedia }));
      this.canAddTags$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.canAddTags = res;
      });
    });
   
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
    Handles incrementing the view count - broken out this way so that we can manage only
    firing this off a single time, even though we are working with observables/streams Will also get metadata for image
  */
  incrementViewCountAndGetMetadata(mediaId: number) {
    this.store$.dispatch(mediaActions.incrementMediaViewCount({ mediaId: mediaId, getRelatedImages: true }));
  }

  checkCuratorTypeMatchesMedia() {
    //Check user curator types to see if they match the "isCreative" on this piece of media
    //Users can only edit a piece of media if they has a matching curator type for the office this
    //media is uploaded to.
    this.store$
      .pipe(
        select(state => state.user.curatorTypes),
        take(1)
      )
      .subscribe(curatorTypes => {
        let filteredCuratorTypes = curatorTypes.filter(t => {
          if (t.officeId == (<officeModel>this.media.metaData.uploadContext.office).id) {
            let filteredItems = t.items.filter(i => i.isCreative == this.media.metaData.isCreative);
            if (filteredItems.length) {
              return true;
            }
          }
        });
        if (filteredCuratorTypes.length) {
          this.mediaMatchesCuratorType = true;
        } else {
          this.mediaMatchesCuratorType = false;
        }
      });
  }

  /**
   * View Actions
   */
  playAudio() {
    const audio = new Audio();
    audio.src = this.media.links.fileUrl;
    audio.load();
    audio.play();
    this.isPlaying = !this.isPlaying;
  }

  updateMediaStatus(value) {
    this.store$.dispatch(mediaActions.updateMediaStatus({ mediaId: +this.media.mediaId, status: value }));
  }

  /**
   * Various Dialogs
   */
  onPocketClick() {
    this.isInPocket = true;
    const itemExists = this.pocketIds.indexOf(+this.media.mediaId) == -1 ? false : true;
    if (!itemExists) {
      this.store$.dispatch(addMediaToPocket({ mediaId: +this.media.mediaId }));
    }
  }

  getMetaData(url: string): any {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }
  openEditDialog() {
    this.getMetaData(this.media.links.fileUrl).then(img => {
      // TODO: Implement Edit Dialog
      const mediaEditDialogRef = this.mediaEditDialog.open(MediaEditDialogComponent, {
        width: '100vw',
        panelClass: 'full-width-modal',
        data: {
          media: this.media,
          imgMetadata: img,
        },
      });
      mediaEditDialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.store$.dispatch(
            editMedia({
              file: res,
              media: this.media,
            })
          );
        }
      });
    });
  }
  onLikeClick() {
    let likesAlbum = this.albums.find(a => a.isFavorite || a.name === 'My Likes');
    if (likesAlbum) {
      this.isLiked = !this.isLiked;
      this.store$.dispatch(
        mediaActions.setIsMediaLiked({
          mediaId: +this.media.mediaId,
          likesAlbumId: likesAlbum.id,
          newValue: this.isLiked,
        })
      );
    }
  }
  openCoverDialog() {
    // TODO: Implement
  }
  openUploadDataEditorDialog() {
    const upLoadDateEditorRef = this.uploadDataEditor.open(MediaEditUploadContextDialogComponent, {
      data: {
        media: this.media,
        offices: this.offices,
        allCuratorTypes: this.curators,
      },
      width: '700px',
    });

    upLoadDateEditorRef.afterClosed().subscribe(result => {
      this.store$.dispatch(
        mediaActions.patchMediaUploadContext({ mediaId: +this.media.mediaId, payload: result })
      );
    });
  }
  addMediaToSelectedAlbums(selectedAlbumsObj: { addedAlbumIds: number[]; removedAlbumIds: number[] }) {
    if (selectedAlbumsObj) {
      let currentlySelectedAlbums = [];
      let unselectedAlbums = [];

      let currentlySelectedIds = this.media.metaData.albums.map(a => {
        return a.mediaGroupId;
      });
      // reconcile unselected albums with current album state
      let unselectedMatches = selectedAlbumsObj.removedAlbumIds.filter(id => currentlySelectedIds.indexOf(id) !== -1);
      if (unselectedMatches && unselectedMatches.length > 0) {
        unselectedAlbums = unselectedMatches;
      }
      // reconcile newly added albums from current state
      let selectedMatches = selectedAlbumsObj.addedAlbumIds.filter(id => currentlySelectedIds.indexOf(id) === -1);
      if (selectedMatches && selectedMatches.length > 0) {
        currentlySelectedAlbums = selectedMatches;
      }

      this.store$.dispatch(
        addMediaToAlbums({ mediaId: +this.media.mediaId, selectedAlbumIds: currentlySelectedAlbums })
      );
      this.store$.dispatch(removeMediaFromAlbums({ mediaId: +this.media.mediaId, selectedAlbumIds: unselectedAlbums }));
    }
  }
  addMediaToSelectedGalleries(selectedGalleriesObj: { addedGalleryIds: number[]; removedGalleryIds: number[] }) {
    if (selectedGalleriesObj) {
      let currentlySelectedGalleries = [];
      let unselectedGalleries = [];

      let currentlySelectedIds = this.media.metaData.galleries.map(a => {
        return a.mediaGroupId;
      });
      // reconcile unselected albums with current album state
      let unselectedMatches = selectedGalleriesObj.removedGalleryIds.filter(
        id => currentlySelectedIds.indexOf(id) !== -1
      );
      if (unselectedMatches && unselectedMatches.length > 0) {
        unselectedGalleries = unselectedMatches;
      }
      // reconcile newly added albums from current state
      let selectedMatches = selectedGalleriesObj.addedGalleryIds.filter(id => currentlySelectedIds.indexOf(id) === -1);
      if (selectedMatches && selectedMatches.length > 0) {
        currentlySelectedGalleries = selectedMatches;
      }

      this.store$.dispatch(
        addMediaToGalleries({ mediaId: +this.media.mediaId, selectedGalleryIds: currentlySelectedGalleries })
      );
      this.store$.dispatch(
        removeMediaFromGalleries({ mediaId: +this.media.mediaId, selectedGalleryIds: unselectedGalleries })
      );
    }
  }
  createAlbum(event: any) {
    const albumDialogRef = this.albumDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: true,
        pocket: [this.media],
        albums: this.albums,
      },
    });

    albumDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          let request = new AlbumCreateCommand();
          request.Name = result.Name;
          request.Description = result.Description;
          request.CoverImage = result.CoverImage;
          request.UserId = this.loggedUserId;
          request.MediaId = +result.MediaId;
          request.MediaIds=[ +result.MediaId]
          this.store$.dispatch(createUserAlbum({ newAlbumDto: request }));
          this.store$.dispatch(getUserAlbums());
          this.store$.dispatch(getUserGalleries());
        }
      });
  }
  createGallery(event: any) {
    const galleryDialogRef = this.galleryDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: false,
        pocket: [this.media],
        offices: this.offices,
      },
    });

    galleryDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          let gallery = new GalleryCreateCommand();
          (gallery.Name = result.Name), (gallery.Description = result.Description);
          gallery.CoverImage = result.CoverImage;
          gallery.CreatedByUserId = this.loggedUserId;
          gallery.UpdatedByUserId = this.loggedUserId;
          gallery.IsPublic = false;
          gallery.MediaId = +result.MediaId;
          gallery.OfficeId = +result.OfficeId;
          gallery.StatusId = 4; // This is "Draft" status

          this.store$.dispatch(createUserGallery({ galleryDto: gallery }));
        }
      });
  }
  helloTagsChanged(tags: tagModel[]) {
    //Dispatch Tag Update Request
    const MANUAL_TAG: any = 2; //TODO: Fix
    // Map over our tags to get the new ones.
    const newTags = tags.filter(t => {
      const exists = this.ogManualTags.filter(m => m.tagId == t.id);
      if (exists.length) {
        return false;
      } else {
        return true;
      }
    });
    const removedTags = this.ogManualTags.filter(t => {
      const exists = tags.filter(m => m.id == t.tagId);
      if (!exists.length) {
        return true;
      } else {
        return false;
      }
    });
    // For each new tag, dispatch the action to add this media to that tag (I know that's backwards...)
    newTags.map(tag => {
      const criteria: mediaTagUpdate = {
        mediaAssets: [parseInt(<string>this.media.mediaId)],
        tagTypeId: MANUAL_TAG,
        tagName: tag.tagName,
      };
      this.store$.dispatch(mediaActions.addTagToMedia({ tag: tag, criteria }));
    });

    removedTags.map(tag => {
      this.store$.dispatch(mediaActions.removeTagFromMedia({ tag: tag, mediaId: +this.media.mediaId }));
    });
  }
  supplierAdded(supplier) {
    const addSupplierCmd: mediaPatch = {
      mediaId: +this.media.mediaId,
      ops: 'Add',
      path: '/Supplier',
      newValue: supplier.id.toString(),
      oldValue: '',
    };
    this.store$.dispatch(
      mediaActions.updateSupplierOnMedia({
        mediaId: +this.media.mediaId,
        supplier: supplier,
        payload: addSupplierCmd,
      })
    );
  }
  supplierRemoved(supplier) {
    const removeSupplierCmd: mediaPatch = {
      mediaId: +this.media.mediaId,
      ops: 'Remove',
      path: '/Supplier',
      newValue: supplier.id.toString(),
      oldValue: '',
    };
    this.store$.dispatch(
      mediaActions.updateSupplierOnMedia({
        mediaId: +this.media.mediaId,
        supplier: supplier,
        payload: removeSupplierCmd,
      })
    );
  }

  removeAzureTag(tag) {
    this.store$.dispatch(mediaActions.removeAzureTagFromMedia({ media: this.media, name: tag.name }));
  }

  toggleRelatedMedia() {
    if (this.moreRelatedMediaShowing) {
      this.relatedMedia = _.take(this.media.relatedMedia, 5);
      this.moreRelatedMediaShowing = false;
    } else {
      this.relatedMedia = this.media.relatedMedia;
      this.moreRelatedMediaShowing = true;
    }
  }
}
