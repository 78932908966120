<div class="workspace-header-wrap">
    <p class="workspace-header">Manage Media Formats</p>
</div>
<div *ngIf="isMediaTypeSelected">
    <button mat-fab color="primary" class="format-floating-add" (click)="addFormat()" matTooltip="Add New File Format">
        <mat-icon *ngIf="!openAddFormat">add</mat-icon>
        <mat-icon *ngIf="openAddFormat">close</mat-icon>
    </button>
</div>

<div class="workspace-add-wrap" *ngIf="openAddFormat">
    <div class="workspace-title">{{addEditCaption}}</div>
    <form class="workspace-add-form">
        <div class="workspace-add-row">
            <mat-form-field class="form-field-sm">
                <mat-select placeholder="File Type" [value]="selectedType" [formControl]="fileType">
                    <mat-option *ngFor="let mediaType of mediaTypes" [value]="mediaType">
                        {{mediaType.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field class="form-field-xs">
                <input matInput placeholder="Maximum File Size"  [formControl]="size">
                <span matSuffix>KB</span>
            </mat-form-field>
        </div>

        <div class="workspace-add-row">    
            <mat-form-field class="form-field-sm">
                <input matInput placeholder="File Extension" [formControl]="extension">
            </mat-form-field>
        </div>

        <button class="button-purple button-text-white" (click)="onAddClicked()">{{addEditButtonCaption}}</button>
    </form>
</div>

<div class="workspace-content-wrap">
    <div class="workspace-content-row">
        <p class="workspace-title">Supported File Formats</p>

        <div class="workspace-filters-wrap">
            <div class="workspace-filter">
                <mat-select 
                    [value]="selectedType" 
                    class="workspace-filter-input" 
                    placeholder="Filter Type">
                    <mat-option 
                        (onSelectionChange)="getMediaType($event)" 
                        *ngFor="let mediaType of mediaTypes" 
                        [value]="mediaType">
                        {{mediaType.description}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>

    <div 
        class="section-empty"
        *ngIf="allowedTypes == null">
        <i class="material-icons section-empty-icon">list</i>
        <p class="section-empty-text">Filter by media type to view supported formats</p>
    </div>
    <div
        class="formats-wrap" 
        *ngFor="let type of allowedTypes; let Index = index; trackBy:trackByIndex;">
        <div class="formats-type-wrap">
            <div *ngIf="isImage(type)">
                <i class="material-icons">image</i>
            </div>
            <div *ngIf="isVideo(type)">
                <i class="material-icons">music_video</i>
            </div>
            <div *ngIf="isAudio(type)">
                <i class="material-icons">audiotrack</i>
            </div>

            <p class="formats-type">{{type.mediaType}}</p>

            <p class="formats-type">{{type.fileExtension}}</p>

            <div class="formats-size-wrap">
                <p class="formats-size">{{type.maxSizeInKb}} KB</p>
            </div>
        </div>

        <div class="formats-action-wrap">
            <a class="formats-action" matTooltip="Delete File Format">
                <i class="material-icons" (click)="onRemoveClicked(Index)">delete</i>
            </a>
            <a class="formats-action" matTooltip="Edit File Format">
                <i class="material-icons" (click)="onEditClicked(Index)">edit</i>
            </a>
            
        </div>
    </div>
</div>