import { createReducer, on, Action } from '@ngrx/store';
import { checkUserLoginResult, checkUserLoginDecorate, getUserCuratorTypesSuccess, setCuratorType } from '../actions/user.actions';
import { User, CuratorTypes, CuratorUserData } from '../../models/user.model';



export interface UserState {
    user?: User;
    curatorTypes?: CuratorTypes[];
    login?: boolean;
    currentSelectedCuratorType: CuratorUserData
}

const initialState: UserState = {
    user: null,
    login: null,
    curatorTypes: [],
    currentSelectedCuratorType: null
};

const userReducer = createReducer(initialState,
    on(checkUserLoginResult, (state, action) => {
        if(action.user) {
            //action.user.roleTypes = action.user.roleTypes.split(',');
            return ({...state, user: action.user, login: null});
        }
        else {
            return ({...state, user: null, login: false});
        }
    }),
    on(checkUserLoginDecorate, (state, action) => {
        return ({...state, user: action.user, login: true});
    }),
    on(getUserCuratorTypesSuccess, (state, action) => {
        let curatorTypes = action.items;
        let matchedType = curatorTypes.filter(t => {
            if(t.officeId == state.user.userOfficeId) {
                return true;
            }
        });
        let resultingCuratorType = null;
        if(matchedType.length) {
            //This gets us an object with the key for the office id and then nested curator types under items
            resultingCuratorType = matchedType[0].items[0];
        }
        return ({...state, curatorTypes: action.items, currentSelectedCuratorType: resultingCuratorType});
    }),
    on(setCuratorType, (state, action) => {
        return ({...state, currentSelectedCuratorType: action.curatorType});
    })
);

// Need to weirdly export this function call because of Angular's AOT compiler - https://ngrx.io/guide/store/reducers
export function user(state: UserState, action: Action) {
    return userReducer(state, action);
}