import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tagItem } from '../../../../models/tagModel';

@Component({
  selector: 'app-azure-tag-chip-list',
  templateUrl: './azure-tag-chip-list.component.html',
  styleUrls: ['./azure-tag-chip-list.component.scss']
})
export class AzureTagChipListComponent implements OnInit {
  @Input() tags: tagItem[] = [];
  @Input() canAddTags: boolean = true;

  @Output() removeTag = new EventEmitter<tagItem>();

  constructor() { }

  ngOnInit() {
  }

  remove(tag) {
    if (this.canAddTags) {
      this.tags = this.tags.filter(t => t.name != tag.name);
      this.removeTag.emit(tag);
    }
  }

}
