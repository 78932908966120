<!-- mu = media upload -->
<div class="mu-item-wrap">

  <div class="mu-display-wrap">
    <div class="mu-thumbnail-wrap">
      <div class="mu-thumbnail-icon"
           *ngIf="uploadItem.inProgress ||(!uploadItem.media &&
        !uploadItem.message)">
        <i class="material-icons">camera</i>
      </div>
      <!-- Hover actions -->
      <div *ngIf="!isMediaArchived && uploadItem.media"
           class="mu-thumbnail-hover">
        <button class="button-border-white media-hover-button
          mu-full-size-button"
                (click)="openFullSizeLightbox()">
          View Full Image
        </button>
      </div>
      <div *ngIf="isMediaArchived === true"
           class="mu-thumbnail-archived">
      </div>
      <img alt="" class="mu-thumbnail"
           *ngIf="!isMediaArchived && uploadItem.media"
           src="{{ uploadItem.media.metaData.links.thumbnailUrl }}" />
      <div class="mu-thumbnail-icon"
           *ngIf="!uploadItem.inProgress && uploadItem.message">
        <i class="material-icons">error_outline</i>
      </div>
      <div class="mu-thumbnail-icon"
           *ngIf="isMediaArchived === true">
        <i class="material-icons">info</i>
      </div>

      <div *ngIf="uploadItem.media && !isMediaArchived"
           class="mu-status-wrap mu-status-wrap-dft">
        <i class="material-icons">
          check_circle
        </i>
        <p class="mu-status-text">
          {{ uploadItem.media.metaData.statusDescription }}
        </p>
      </div>
      <div *ngIf="isMediaArchived"
           class="mu-status-wrap mu-status-wrap-archived">
        <i class="material-icons"
           *ngIf="isMediaArchived == true">
          info
        </i>
        <p class="mu-status-text">
          Archived
        </p>
      </div>
      <div *ngIf="!uploadItem.inProgress && uploadItem.message"
           class="mu-status-wrap mu-status-wrap-error">
        <i class="material-icons">
          error_outline
        </i>
        <p class="mu-status-text">
          {{ uploadItem.message }}
        </p>
      </div>
    </div>
    <p class="detail-image-info"
       *ngIf="sizeInMb">
      <span>File Size: {{ sizeInMb }} MB</span>
    </p>
  </div>

  <div class="mu-meta-wrap">
    <div class="mu-meta-row">
      <p class="mu-meta-file">
        {{ uploadItem.fileName }}
      </p>

      <div class="mu-menu"
           *ngIf="!isMediaArchived && !uploadItem.inProgress
        &&!uploadItem.errorCode">
        <mat-icon [matMenuTriggerFor]="muMenu">
          more_vert
        </mat-icon>
        <mat-menu #muMenu>
          <button mat-menu-item
                  (click)="archiveMedia()">
            Archive
          </button>
          <button mat-menu-item *ngIf="!published"
                  (click)="publishMedia()">
            Publish
          </button>
        </mat-menu>
      </div>
    </div>
    <div *ngIf="uploadItem.inProgress && !(uploadItem.uploadProgressPercent ==
      1)"
         class="mu-meta-tag-wrap">
      <mat-progress-bar [value]="uploadItem.uploadProgressPercent * 100"></mat-progress-bar>
      <p class="upload-overlay-message"> Uploading File</p>
    </div>
    <div *ngIf="!uploadItem.message && !isMediaArchived && !uploadItem.media &&
      (uploadItem.uploadProgressPercent != 1)"
         class="meta-tag-error-wrap">
      <p class="meta-tag-error-text">
        Processing Media
      </p>
    </div>

    <div *ngIf="!uploadItem.inProgress"
         class="mu-meta-tag-wrap">
      <div *ngIf="uploadItem.message"
           class="meta-tag-error-wrap">
        <p class="meta-tag-error-text"
           *ngIf="uploadItem.errorCode &&
          uploadItem.errorCode === 500">
          Server Error:{{uploadItem.message}}
        </p>
        <p class="meta-tag-error-text"
           *ngIf="uploadItem.errorCode &&
          uploadItem.errorCode === 400">
          Bad Request: {{uploadItem.message}}
        </p>
        <a *ngIf="uploadItem.errorCode && uploadItem.errorCode === 409"
           class="upload-409"
           routerLink="/media/{{uploadItem.id}}"
           routerLinkActive="active"
           target="_blank">
          <p>
            <mat-icon>open_in_new</mat-icon> View Existing Media In New Tab<p>
        </a>
      </div>


      <div *ngIf="isMediaArchived == true"
           class="meta-tag-error-wrap">
        <p class="meta-tag-error-text">
          Tagging unavailable for archived media
        </p>
      </div>
      <ng-container *ngIf="!uploadItem.message && !isMediaArchived">
        <mat-checkbox class="mu-tagging-check"
                      color="primary"
                      [(ngModel)]="selectiveBulkTagging"
                      (change)="selectiveBulkTaggingClick()">
          Selective Bulk Tagging
        </mat-checkbox>
        <app-tag-type-ahead [canAddTags]="true"
                            [tags]="this.manualTags"
                            (tagsChanged)="helloTagsChanged($event)"></app-tag-type-ahead>
        <app-supplier-tag-type-ahead [canAddTags]="true"
                                     (supplierAdded)="supplierAdded($event)"
                                     (supplierRemoved)="supplierRemoved($event)"
                                     [tags]="supplierTags">
        </app-supplier-tag-type-ahead>

        <div class="mu-azure-tag-wrap">
          <p class="mu-meta-tag-label">
            Azure Tags
          </p>
          <app-azure-tag-chip-list [tags]="azureTags"
                                   (removeTag)="removeAzureTag($event)">
          </app-azure-tag-chip-list>

        </div>
      </ng-container>
    </div>
  </div>
</div>