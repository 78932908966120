import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Media } from "../../models/media.model";
import * as pocketActions from "../../ngrx/actions/pocket.actions";
import { selectPocketMedia } from "../../ngrx/selectors/media.selector";
import { ConfirmDialogComponent } from './../../components/dialogs/confirm-dialog/confirm-dialog.component';


@Component({
  selector: "pocket-container",
  templateUrl: "./pocket-container.component.html",
  styleUrls: ["./pocket-container.component.scss"]
})
export class PocketContainerComponent implements OnInit {
  @Input() isOpen;
  @Output() pocketClosed = new EventEmitter();
  pocketMedia = [];
  message: string;
  public pocketMedia$: Observable<Media[]>;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.store.pipe(select(selectPocketMedia)).subscribe(res => {
      this.pocketMedia = res;
    });
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onRemoveFileClicked(mediaId: any) {
    this.store.dispatch(
      pocketActions.removeMediaFromPocket({ mediaId: mediaId })
    );
  }

  closePocket() {
    this.isOpen = false;
    this.pocketClosed.emit(true);
  }

  highlightForDrag(media: Media) {
    const idString = media.mediaId.toString();
    const copyImage = document.getElementById(idString);
    const s = window.getSelection();
    const r = document.createRange();
    r.selectNode(copyImage);
    s.removeAllRanges();
    s.addRange(r);
  }
  copyToClipboard(media: Media) {
    const s = window.getSelection();
    const r = document.createRange();
    const copyTarget = document.createElement("img");
    copyTarget.src = media.links.fileUrl;
    (<any>copyTarget).style = {
      position: "fixed",
      left: "-9999px",
      display: "block"
    };
    document.body.appendChild(copyTarget);
    r.selectNode(copyTarget);
    s.removeAllRanges();
    s.addRange(r);
    document.execCommand("copy");
    document.body.removeChild(copyTarget);
  }
  copyImageUrl(url) {
    const hiddenText = document.createElement("textarea");
    hiddenText.value = url;
    hiddenText.setAttribute("readonly", "");
    (<any>hiddenText).style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(hiddenText);
    hiddenText.select();
    document.execCommand("copy");
    document.body.removeChild(hiddenText);
  }
  clearPocket(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '125px',
      width: '400px',
      data: { message: 'Are you sure you want to remove all the images in your pocket?' }

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(pocketActions.clearPocket());
      }
    })
  }
}
