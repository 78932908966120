import { Action, createReducer, on } from '@ngrx/store';
import { unionBy } from 'lodash';
import { Album, AlbumMediaMapping } from '../../models/album.model';
import * as albumActions from '../actions/albums.actions';

export interface AlbumsState {
    albums?: Album[];
    albumMediaMapping: AlbumMediaMapping[];
}

const initialState: AlbumsState = {
    albums: null,
    albumMediaMapping: []
};

export const albumsReducer = createReducer(initialState,
    on(albumActions.getUserAlbumsSuccess, (state, action) => {
        let albums = [];
        if(state.albums != null){
            albums = state.albums.slice();
        }
        const newAlbums = unionBy(albums, action.albums, 'id');
        return ({ ...state, albums: newAlbums });
    }),
    on(albumActions.getMediaForAlbumSuccess, (state, action) => {
        const mappings = state.albumMediaMapping.slice();
        if (action.albumMedia) {
            let mappingObj = mappings.filter(x => x.albumId == action.albumId);
            mappingObj=JSON.parse(JSON.stringify(mappingObj));
            if (mappingObj && mappingObj.length > 0) {
                let newMediaIds = action.albumMedia.map(item => { return +item.mediaId });
                mappingObj[0].mediaIds = newMediaIds;
            }
            else {
                let newMediaMapping: AlbumMediaMapping = new AlbumMediaMapping();
                newMediaMapping.albumId = action.albumId;
                newMediaMapping.mediaIds = action.albumMedia.map(item => { return +item.mediaId });
                mappings.push(newMediaMapping);
            }
        }

        return ({ ...state, albumMediaMapping: mappings });
    }),
    on(albumActions.createUserAlbumsSuccess, (state, action) => {
        const albumsState = state.albums.slice();
        let newAlbums = unionBy(albumsState, [action.newAlbum], 'id');

        return ({ ...state, albums: newAlbums })
    }),
    on(albumActions.addMediaToAlbumsSuccess, (state, action) => {
        const mappings = state.albumMediaMapping.slice();
        let currentAlbums = <Album[]>state.albums;
        action.selectedAlbumIds.forEach(id => {
            const currentAlbum = currentAlbums.find(a => a.id == id)
            if (currentAlbum) {
                currentAlbum.totalMediaCount += 1;
            }
            const selectedAlbumMapping = mappings.find(x => x.albumId === id);
            if (selectedAlbumMapping) {
                let mediaAlreadyExists = selectedAlbumMapping.mediaIds.find(m => m == +action.mediaId);
                if (!mediaAlreadyExists) {
                    selectedAlbumMapping.mediaIds.push(+action.mediaId);
                }
            }
            else {
                let newMediaMapping: AlbumMediaMapping = new AlbumMediaMapping();
                newMediaMapping.albumId = id;
                newMediaMapping.mediaIds = [+action.mediaId];
                mappings.push(newMediaMapping);
            }
        })
        return ({ ...state, albumMediaMapping: mappings });

    }),
    on(albumActions.bulkAddMediaFromAlbumSuccess, (state, action) => {
        const mappings = state.albumMediaMapping.slice();
        let currentAlbums = <Album[]>state.albums;
        action.selectedAlbumIds.forEach(id => {
            const currentAlbum = currentAlbums.find(a => a.id == id)
            if (currentAlbum) {
                currentAlbum.totalMediaCount += action.mediaIds.length;
            }
            const selectedAlbumMapping = mappings.find(x => x.albumId === id);
            if (selectedAlbumMapping) {
                action.mediaIds.forEach(m=>{
                    let mediaAlreadyExists = selectedAlbumMapping.mediaIds.find(i => i === +m);
                    if (!mediaAlreadyExists) {
                        selectedAlbumMapping.mediaIds.push(+m);
                    }
                });
               
            }
          
        })
        return ({ ...state, albumMediaMapping: mappings });

    }),
    on(albumActions.removeMediaFromAlbumsSuccess, (state, action) => {
        const mappings = state.albumMediaMapping.slice();
        let currentAlbums = <Album[]>state.albums;
        action.selectedAlbumIds.forEach(id => {
            const currentAlbum = currentAlbums.find(a => a.id == id)
            currentAlbum.totalMediaCount -= 1;
            const selectedAlbumMapping = mappings.find(x => x.albumId === id);
            if (selectedAlbumMapping) {
                let mediaIndex = selectedAlbumMapping.mediaIds.indexOf(action.mediaId);
                selectedAlbumMapping.mediaIds.splice(mediaIndex, 1);
            }

        });
        return ({ ...state, albumMediaMapping: mappings });
    }),
    on(albumActions.bulkRemoveMediaFromAlbumSuccess, (state: AlbumsState, action) => {
        let newAlbum = state.albums.filter(g => g.id == action.albumId);
        let newalbumMediaMappings = state.albumMediaMapping.map(m => {
            if (m.albumId == action.albumId) {
                for (let i = 0; i < action.mediaIds.length; i++) {
                    let mediaIndex = m.mediaIds.indexOf(+action.mediaIds[i]);
                    m.mediaIds.splice(mediaIndex, 1);
                }
                //Update the media count on the gallery as well
                newAlbum[0].totalMediaCount = m.mediaIds.length;
            }
            return m;
        })
        let returnAlbums = updateExistingAlbums(newAlbum, state.albums);
        return ({ ...state, albums: returnAlbums, albumMediaMapping: newalbumMediaMappings });
    }),
    on(albumActions.editAlbumSuccess, (state: AlbumsState, action) => {
        let newAlbums = updateExistingAlbums(state.albums, [action.album]);
        return ({ ...state, albums: newAlbums });
    }),
    on(albumActions.updateAlbumStatusSuccess, (state: AlbumsState, action) => {
        let matchingAlbum = state.albums.filter(a => a.id == action.id);
        matchingAlbum[0].isPublic = action.isPublic;
        let newAlbums = updateExistingAlbums(state.albums, matchingAlbum);
        return ({ ...state, albums: newAlbums });
    }),
    on(albumActions.deleteAlbumSuccess, (state: AlbumsState, action) => {
        let albums = state.albums.filter(a => a.id != action.id);
        return ({ ...state, albums: albums });
    })
);

// Need to weirdly export this function call because of Angular's AOT compiler - https://ngrx.io/guide/store/reducers
export function albums(state: AlbumsState, action: Action) {
    return albumsReducer(state, action);
}

const updateExistingAlbums = (currentItems, newItems) => {
    const result = currentItems.map(item => {
        const matchedItem = newItems.filter(newItem => newItem.id == item.id);
        if (matchedItem.length) {
            return Object.assign(item, matchedItem[0]);
        } else {
            return item;
        }
    });
    const itemIds = extractIds(result, 'id');
    const remainingNewItems = newItems.filter(item => itemIds.indexOf(parseInt(item.id)) < 0);
    remainingNewItems.map(item => {
        result.push(item);
    })
    return result;

}

const extractIds = (items: any[], key: string) => {
    return items.map(item => {
        return parseInt(item[key]);
    });
}