
export class programModel {
    id: number;
    correlationKey: number;
    number: string;
    name: string;
    city: string;
    state: string;
    zipcode: string;
    hotel: string;
    officeId: string;
    office: string;
    activeFlag: boolean;
}
export enum SearchType {
    Program = 1,
    Service = 2,
    Supplier = 3,
    Office = 4,
    Venue = 5,
  }
export class InfiniteScrollHelper<T> {
    searchType: SearchType;
    buffer: T[];
    baseSearch: string;
    take: number;
    useBuffer: boolean;
    isMatch(value: string): boolean {
      if (!this.baseSearch || this.baseSearch.length === 0) {
          return false;
      } else if (value.toLowerCase().startsWith(this.baseSearch.toLowerCase())) {
        return true;
      }
      return false;
    }
    constructor(type: SearchType, useBuffer: boolean) {
        this.searchType = type;
        this.useBuffer = useBuffer;
    }
  }
export class EditMediaModel {
    sourceMediaId: number;
    userId: number;
    isEdit: boolean;
    fileName: string;
    clientId: string;
}
export class serviceModel {
    id: number;
    correlationKey: number;
    number: string;
    name: string;
    programId: number;
    city: string;
    state: string;
    zipcode: string;
    serviceTypeId: number;
    program: string;
    serviceType: string;
    activeFlag: boolean;
    serviceDate: Date;
}

export class supplierModel {
    id: number;
    correlationKey: number;
    number: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    officeId: number;
    activeFlag: boolean;
    office: string;
}

export class mediaTagUpdate {
    tagTypeId: number;
    tagName: string;
    mediaAssets: number[];
}

export class mediaPatch {
        mediaId: number;
        ops: string;
        path: string;
        newValue: string;
        oldValue: string;
        userId?:number;
}

export class MediaPatchPayLoad {
    protected static mediaPatchPayLoad: MediaPatchPayLoad;
      OfficeId: any;
      SupplierId?: any;
      ProgramId?: any;
      ProgramServiceId?: any;
      LibraryServiceId?: any;
      ServiceVenueId?: any;
      Note?:string;

    static getInstance(): MediaPatchPayLoad {
        if (!this.mediaPatchPayLoad) {
         this.mediaPatchPayLoad = new MediaPatchPayLoad();
        }
        return this.mediaPatchPayLoad;
    }
}

export class  mediaModel {
    protected static mediaM: mediaModel;
    id: number;
    name: string;
    clientId: string;
    description: string;
    statusId: number;
    sourceId: number;
    fileName: string;
    mediaType: string;
    officeId: number;
    userId: number;
    programId: number;
    programServiceId: number;
    supplierId: number;
    serviceVenueId: any; // supplier name if not default, now number

    static getInstance(): mediaModel {
        if (!this.mediaM) {
         this.mediaM = new mediaModel();
        }
        return this.mediaM;
    }
}

export class  MediaModelV2 {
    protected static mediaM: MediaModelV2;
    id: number;
    name: string;
    clientId: string;
    description: string;
    statusId: number;
    uploadSourceCode: string;
    fileName: string;
    mediaType: string;
    officeId: number;
    userId: number;
    programId: number;
    programServiceId: number;
    supplierId: number;
    serviceVenueId: number;
    libraryServiceId: number;

    static getInstance(): MediaModelV2 {
        if (!this.mediaM) {
         this.mediaM = new MediaModelV2();
        }
        return this.mediaM;
    }
}


