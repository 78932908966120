<div class="dialog-wrap">
  <div class="dialog-title-wrap">
    <p class="dialog-title">
      Delete Album?
    </p>
  </div>

  <div class="dialog-content-wrap">
    <p class="dialog-text-standard">
      Media in album will no longer be saved to your content. This action cannot be undone.
      <!-- for galleries I wonder if its worth noting the followers -->
    </p>
  </div>

  <div class="dialog-actions">
    <a 
      class="dialog-action dialog-action-cancel"
      (click)="closeDialog()">
      Cancel
    </a>
    <a 
      class="dialog-action dialog-action-create"
      (click)="deleteContent()">
      Delete Album
    </a>
  </div>
</div>