<mat-form-field>
  <mat-label class="quick-look-label"><i class="material-icons">
      photo_album
    </i> Add to Album</mat-label>
    <div  [class]="classValue">
      <mat-select #albumSelect
      class="quick-look-select"
        matNativeControl
        (openedChange)="updateAlbums($event)"
        [formControl]="selectedAlbumsForm"
        multiple>
<mat-select-trigger>
{{(selectedAlbumNames && selectedAlbumNames.length)? selectedAlbumNames[0] : ''}}
<span *ngIf=" selectedAlbumNames && selectedAlbumNames.length > 1"
      class="additional-content">
  (+{{ selectedAlbumNames ? selectedAlbumNames.length - 1 : ''}}
  {{selectedAlbumNames && selectedAlbumNames.length === 2 ? 'other' : 'others'}})
</span>
</mat-select-trigger>
<mat-option class="create-option"
          (click)="createAlbum($event)"
          [value]="null">+ Create New Album</mat-option>
<mat-option *ngFor="let album of albums"
          [value]="album.id">{{album.name}}</mat-option>
</mat-select>
    </div>
 
</mat-form-field>