import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { MediaState } from '../reducers/media.reducer';

export const selectMedia = (state: AppState) => state.media;

export const selectRecentlyPublishedMedia = createSelector(selectMedia,
    (media: MediaState, props) => {
        const ids = media.recentlyPublishedMediaIds;
        let result = null;
        if(ids){
            result = ids.map(i => {
                let mediaMatch = media.items.filter(m => m.mediaId == i);
                if(mediaMatch.length) {
                    return mediaMatch[0];
                }
            })
        }
        return result;
    });

export const selectMediaById = createSelector(selectMedia,
    (media: MediaState, props) => {
        const result = media.items.filter(item => item.mediaId == props.mediaId);
        return result[0];
    })
export const selectMediaByGalleryId = createSelector(selectMedia,
    (media: MediaState, props) => {
        let mediaToReturn = []
        mediaToReturn = props.mediaIds.map(id => {
            let mediaItem = media.items.filter(item => item.mediaId === id)
            if (mediaItem && mediaItem.length > 0) {
                return mediaItem[0];
            }
        });
        return mediaToReturn;
    })
export const selectMostActiveMedia = createSelector(selectMedia,
    (media: MediaState, props) => {
        let recentIds = media.mostActiveMediaIds;
        let recentMedia = null;
        if(recentIds){
            recentMedia = recentIds.map(id => {
                let match = media.items.filter(item => item.mediaId === id);
                if (match && match.length > 0) {
                    return match[0];
                }
            });
        }
        
        return recentMedia
    })
export const selectRecentlyAddedMedia = createSelector(selectMedia,
    (media: MediaState, props) => {
        let recentIds = media.recentlyAddedMediaIds;
        let recentMedia = null;
        if(recentIds){
            recentMedia = recentIds.map(id => {
                let match = media.items.filter(item => item.mediaId === id);
                if (match && match.length > 0) {
                    return match[0];
                }
            })
        }
        
        return recentMedia;
    }
)
export const selectMostViewedMedia = createSelector(selectMedia,
    (media: MediaState, props) => {
        let mostViewedIds = media.mostViewMediaIds;
        let mostViewedMedia = null;
        if(mostViewedIds){
            mostViewedMedia = mostViewedIds.map(id => {
                let match = media.items.filter(item => item.mediaId === id);
                if (match && match.length > 0) {
                    return match[0];
                }
            })
        }
        
        return mostViewedMedia;
    }
);

export const selectSearchResults = createSelector(selectMedia,
    (media: MediaState, props) => {
        const searchResults = media.searchResultIds.map(id => {
            const result = media.items.filter(media => {
                return media.mediaId == id
            });
            return result[0];
        })
        return searchResults;
    });
export const selectPocketMedia = createSelector(selectMedia,
    (media: MediaState, props) => {
        const results = media.mediaPocketIds.map(pocketId => {
            const result = media.items.filter(media => {
                return media.mediaId == pocketId || media.mediaId == pocketId.toString()
            })
            return result[0];
        })
        return results;
    });

export const selectUploadQueue = createSelector(selectMedia,
    (media: MediaState, props) => {
        const uploadQueue = media.uploadQueue.map(q => {
            if (q.id) {
                const qMedia = media.items.filter(m => m.mediaId == q.id);
                if (qMedia.length) {
                    q.media = qMedia[0];
                }
            }
            return q;
        });
        uploadQueue.sort((a, b) => {
            if(a.order > b.order) return -1;
            if(b.order > a.order) return 1;
            else return 0;
        });
        return uploadQueue;
    });
