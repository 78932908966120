<img title="{{title}}" class="folder-item-image"
     src="{{thumbNailUrl}}">

<div class="folder-hover">
    <button class="button-border-white folder-hover-button"
            (click)="viewMediaList()">View {{type}}</button>

     <button *ngIf="this.canEditShare" class="button-border-white folder-hover-button2"
    (click)="openAlbumSettings()">Share Settings</button>
</div>


<div class="folder-meta-wrap">

    <div class="folder-meta-info">
        <div class="folder-meta-title">{{ title }} <mat-icon *ngIf="this.isAblum && this.album.sharedUserIds?.length>0" >folder_shared</mat-icon></div>
       
        <div class="folder-meta-created">{{ created }}</div>
    </div>

    <div class="folder-meta-count">
        {{ count }}
    </div>
</div>