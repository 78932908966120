<div mat-dialog-content class="message">
    <p>{{message}}</p>
</div>

<div mat-dialog-actions class="dialog-buttons">
    <button mat-button
            (click)="onDismiss()">No</button>
    <button mat-raised-button
            color="primary"
            (click)="onConfirm()">Yes</button>
</div>