import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {
	showFormats: boolean = false;
	showTags: boolean = true;
  constructor() { }

  ngOnInit() {
  }

  mediaFormats() {
  	this.showFormats = true;
  	this.showTags = false;
  }

  tagManager() {
  	this.showTags = true;
  	this.showFormats = false;
  }

}
