import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { accountExecutiveModel } from '../models/account-executive.model';
import { clientModel } from '../models/client.model';
import { programModel } from '../models/program.model';
import { programServiceModel } from '../models/programService.model';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private http: HttpClient) {}
  pm: Observable<programModel[]> = null;

  getProgramsByOffice(officeIds: string, searchterm: any) {
    return this.http
      .get<programModel[]>(
        environment.mediaApiEndpoint + 'Program?OfficeIds=' + officeIds + '&SearchText=' + searchterm
      )
      .pipe(
        tap(_ => this.log('found tags')),
        catchError(this.handleError<programModel[]>(`gettagSearchResultByName search term = ${searchterm}`))
      );
  }

  getProgramsByCorrelationKey(officeId: number, correlationKey: number) {
    return this.http
      .get<programModel[]>(
        environment.mediaApiEndpoint + 'Program?OfficeId=' + officeId + '&correlationKey=' + correlationKey
      )
      .pipe(
        tap(_ => this.log('found program')),
        catchError(this.handleError<programModel[]>(`getProgramsByCorrelationKey correlationKey = ${correlationKey}`))
      );
  }

  getProgramServicesByCorrelationKey(programId: number, correlationKey: number) {
    return this.http
      .get<programServiceModel[]>(
        environment.mediaApiEndpoint + 'Service?programId=' + programId + '&correlationKey=' + correlationKey
      )
      .pipe(
        tap(_ => this.log('found program Service')),
        catchError(
          this.handleError<programModel[]>(`getProgramServicesByCorrelationKey correlationKey = ${correlationKey}`)
        )
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string): void {
    console.log(message);
  }

  getAllPrograms(): Observable<programModel[]> {
    return this.http.get<programModel[]>(environment.mediaApiEndpoint + 'Program');
  }

  getAEByProgram(programId: number): Observable<accountExecutiveModel[]> {
    return this.http.get<accountExecutiveModel[]>(environment.mediaApiEndpoint + 'AE?programId=' + programId);
  }

  searchAEByProgram(programId: number, searchTerm): Observable<accountExecutiveModel[]> {
    let url = environment.mediaApiEndpoint + 'AE?name=' + searchTerm;
    if (programId !== undefined && programId > 0) {
      url += '&programId=' + programId;
    }
    return this.http.get<accountExecutiveModel[]>(url).pipe(
      tap(_ => this.log('found Account Executives')),
      catchError(this.handleError<accountExecutiveModel[]>(`searchAEByProgram search term = ${searchTerm}`))
    );
  }

  getProgramServices(
    programId: number,
    serviceTypeId: number,
    includeAllServices: boolean
  ): Observable<programServiceModel[]> {
    let params = new HttpParams();
    if (programId > 0) {
      params = params.append('programId', String(programId));
    }
    if (serviceTypeId > 0) {
      params = params.append('serviceTypeId', String(serviceTypeId));
    }
    if (includeAllServices === false) {
      params = params.append('isAddedToContract', String('true'));
    }
    return this.http.get<programServiceModel[]>(environment.mediaApiEndpoint + 'Service', { params: params });
  }

  searchServicesByProgram(programId: number, searchTerm, isCreative: boolean): Observable<programServiceModel[]> {
    let url = environment.mediaApiEndpoint + 'Service?name=' + searchTerm;
    if (programId !== undefined && programId > 0) {
      url += '&programId=' + programId + '&isAddedToContract=true&serviceTypeId=0';

      if (isCreative !== undefined) {
        url += '&IsCreative=' + isCreative;
      }
    }
    return this.http.get<programServiceModel[]>(url).pipe(
      tap(_ => this.log('found Program Services')),
      catchError(this.handleError<programServiceModel[]>(`searchServicesByProgram search term = ${searchTerm}`))
    );
  }

  getAllAEList(): Observable<accountExecutiveModel[]> {
    return this.http.get<accountExecutiveModel[]>(environment.mediaApiEndpoint + 'AE');
  }

  searchClientsByProgram(programId: number, searchTerm: any): Observable<clientModel[]> {
    let url = environment.mediaApiEndpoint + 'Client?name=' + searchTerm;
    if (programId !== undefined && programId > 0) {
      url += '&programId=' + programId;
    }

    return this.http.get<clientModel[]>(url).pipe(
      tap(_ => this.log('found clients')),
      catchError(this.handleError<clientModel[]>(`searchClientsByProgram search term = ${searchTerm}`))
    );
  }

  getClients(): Observable<clientModel[]> {
    return this.http.get<clientModel[]>(environment.mediaApiEndpoint + 'Client');
  }

  getClientsByCorrelationKey(correlationKey): Observable<clientModel[]> {
    return this.http.get<clientModel[]>(environment.mediaApiEndpoint + 'Client?CorrelationKey=' + correlationKey);
  }
}
