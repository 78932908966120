import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { programServiceModel } from '../../../../models/programService.model';
import { MediaUploadToolbarService } from '../../../../services/media-upload-toolbar.service';

@Component({
  selector: 'app-service-select',
  templateUrl: './service-select.component.html',
  styleUrls: ['./service-select.component.scss']
})
export class ServiceSelectComponent implements OnInit {
  @Input() programId: number;

  @Input() isCreative?: boolean = null;
  @Input() value: programServiceModel = null;
  @Input() restrictToContract: boolean = true;
  @Input() onlyShowWithMedia: boolean=false;
  @Output() serviceSelected = new EventEmitter<programServiceModel>();

  public services$: Observable<programServiceModel[]>;
  public services: programServiceModel[] = [];
  public service: FormControl;

  public originalService: programServiceModel = null;
  public originalProgramId: number;

  constructor(
    private _mediaUploadToolbarService: MediaUploadToolbarService
  ) { }

  ngOnInit() {
    this.service = new FormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    /**
      Save our original values
    **/
    if(changes.value && changes.value.firstChange) {
      this.originalService = changes.value.currentValue;
    }
    if(changes.programId && changes.programId.firstChange) {
      this.originalProgramId = changes.programId.currentValue;
    }


    if(changes.value && changes.value.currentValue == null) {
      if(this.service){
        this.service.setValue(null);
      }
    }

    else if (this.service && this.services && this.value) {
      const result = this.services.filter(s => s.id == this.value.id);
      this.service.setValue(result[0]);
      this.serviceSelected.emit(this.value);
    }
    if(this.programId){
      this.getServices();
    }
  }

  getServices() {
    this.services$ = this._mediaUploadToolbarService.getServiceModels(this.programId, this.isCreative, this.restrictToContract)
      .pipe(catchError(error => of([])));
    this.services$.pipe(take(1)).subscribe(res => {
      this.services = res;
      if (this.onlyShowWithMedia){
        this.services=res.filter(s=>s.mediaCount>0);
      }
      /**
      * Because we are calling for services that explicitly have "isAddedToContract = true"
      * We need to check if our value is still our original service. If it is, we need to add that service to the array
      * of services, because it would not have come back from the api.
      **/
      if(this.value != null && this.originalService &&  this.value.id == this.originalService.id && this.programId && this.programId == this.originalProgramId) {
        const exists = this.services.filter(s => s.id == this.value.id);
        if(exists.length) {
          this.service.setValue(exists[0]);
        } else {
          this.services.push(this.originalService);
          this.service.setValue(this.originalService);
        }
      }
      else if (this.value != null) {
        const result = this.services.filter(s => s.id == this.value.id);
        this.service.setValue(result[0]);
      }
    })
  }

  serviceSelectedEvent(service) {
    this.serviceSelected.emit(service)
  }

}
