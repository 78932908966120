<mat-form-field class="tag-chip-list">
  <mat-chip-list #chipList class="azure-tag-chip-list">
    <mat-chip
      matChipRemove
      *ngFor="let tag of tags"
      [selectable]="selectable"
      [removable]="canAddTags"
      (removed)="remove(tag)">
      {{tag.name}}
      <mat-icon matChipRemove *ngIf="canAddTags">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</mat-form-field>

