import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MediaTagsList } from '../models/media-tags-list.model';
import { Media, MediaMetadata } from '../models/media.model';
import { searchResult } from '../models/searchResult.model';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public imageData: searchResult;

  isLiked = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  searchMedia(term: string): Observable<searchResult[]> {
    return this.http.get<searchResult[]>(environment.mediaApiEndpoint + 'Media?' + term).pipe(
      tap(searchResults => this.log('Got the search results')),
      catchError(this.handleError('searchMedia', []))
    );
  }

  searchMediaByLibraryServiceCorrelationKey(id: number): Observable<searchResult[]> {
    return this.http
      .get<searchResult[]>(environment.mediaApiEndpoint + `Media/getMediaByLibraryServiceCorrelationKey?id=${id}`)
      .pipe(catchError(this.handleError('searchMediaByLibService', [])));
  }

  getRelatedMedia(mediaId: number): Observable<searchResult[]> {
    return this.http.get<searchResult[]>(environment.mediaApiEndpoint_V2 + `Media/${mediaId}/related`).pipe(
      tap(searchResults => this.log('Got the search results')),
      catchError(this.handleError('searchMedia', []))
    );
  }

  searchMostViewedByOffice(term: string): Observable<searchResult[]> {
    return this.http.get<searchResult[]>(environment.mediaApiEndpoint + 'Media/Viewed' + term).pipe(
      tap(searchResults => this.log('Got the search results most viewed')),
      catchError(this.handleError('searchMostViewedByOffice', []))
    );
  }

  searchRecentlyUploadedMedia(term: string): Observable<Media[]> {
    return this.http.get<Media[]>(environment.mediaApiEndpoint + 'Media/Recent' + term).pipe(
      tap(searchResults => this.log('Got the search results')),
      catchError(this.handleError('searchRecentlyUploadedMedia', []))
    );
  }

  searchRecentlyAddedAnyMediaGroup(term: any): Observable<searchResult[]> {
    return this.http.get<searchResult[]>(environment.mediaApiEndpoint + 'MediaGroup/Media' + term).pipe(
      tap(searchResults => this.log('Got the Recently Added Any MediaGroup results')),
      catchError(this.handleError('searchRecentlyAddedAnyMediaGroup', []))
    );
  }

  getMedia(id: number): Observable<MediaMetadata> {
    return this.http.get<MediaMetadata>(environment.mediaApiEndpoint_V2 + 'Media/' + id).pipe(
      tap(mediaResult => this.log('Got media result')),
      catchError(this.handleError<MediaMetadata>('searchMedia'))
    );
  }

  searchTags(tag: string): Observable<MediaTagsList> {
    return this.http.get<MediaTagsList>(environment.mediaApiEndpoint + 'Tags?tag=' + tag).pipe(
      tap(searchTagsResults => this.log('Got the search tags results')),
      catchError(this.handleError<MediaTagsList>('searchTags'))
    );
  }

  SetMediaFavorite(mediaId: number) {
    this.http
      .post(environment.mediaApiEndpoint + 'Media/' + mediaId + '/Favorite', {})
      .toPromise()
      .then(response => {})
      .catch(error => {
        console.log(error);
      });
    console.log('service function');
    this.isLiked = !this.isLiked;
    this.change.emit(this.isLiked);
  }

  IsMediaFavorite(mediaId: number): Observable<boolean> {
    return this.http.get<boolean>(environment.mediaApiEndpoint + 'Media/' + mediaId + '/Favorite').pipe(
      tap(mediaResult => this.log('Got media result')),
      catchError(this.handleError<boolean>('IsMediaFavorite'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string): void {
    console.log(message);
  }
}
