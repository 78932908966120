<div class="dialog-wrap">
  <div class="dialog-title-wrap">
    <p class="dialog-title">
      {{actionString}} {{typeString}}
    </p>
  </div>

  <div class="dialog-content-wrap">
    <form class="dialog-form">
      <mat-form-field>
        <input matInput
               placeholder="Title"
               name="name"
               [(ngModel)]="Name"
               (change)="onParameterChange()" />
      </mat-form-field>
      <p class="content_create_errors"
         *ngIf="errors.name && errors.name.hasError">
        {{errors.name.message}}
      </p>
      <!-- only display for gallery creation -->
      <mat-form-field *ngIf="!isAlbum">
        <mat-select placeholder="Office"
                    name="officeModel"
                    [(ngModel)]="selectedOffice"
                    (selectionChange)="onParameterChange()">
          <mat-option *ngFor="let officeModel of officemodels"
                      [value]="officeModel">
            {{ officeModel.officeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <textarea matInput
                  placeholder="Description"
                  [(ngModel)]="Description"
                  name="description"
                  (change)="onParameterChange()">
        </textarea>
      </mat-form-field>
    </form>

    <div class="dialog-image-pick-wrap">
      <p class="dialog-image-pick-title">
        Album/Gallery Cover
        <span>(Add images to your pocket to select a cover)</span>
      </p>
      <div class="dialog-image-picker-wrapper">
        <div class="dialog-image-picker">
          <!-- loop through pocket images here, should be empty if no photos -->
          <div *ngFor="let image of pocket"
               class="dialog-image-wrap"
               (click)="selectCoverImage(image)">
            <img class="dialog-image"
                 [src]="image.links.thumbnailUrl">

            <mat-icon *ngIf="!selectedIamge || (image.mediaId !== selectedImage.mediaId)"
                      class="dialog-image-check">
              <!-- should be check_box when selected-->
              check_box_outline_blank
            </mat-icon>

            <mat-icon *ngIf="selectedImage && image.mediaId == selectedImage.mediaId"
                      class="dialog-image-check">
              <!-- should be check_box when selected-->
              check_box
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-actions">
    <button mat-button
            class="dialog-action dialog-action-cancel"
            (click)="closeDialog()">Cancel</button>
    <!-- should be disabled until title and image are selected -->
    <button mat-button
            class="dialog-action dialog-action-create"
            [disabled]="!formIsValid"
            (click)="onSubmit()">{{actionString}} {{typeString}}</button>
  </div>
</div>