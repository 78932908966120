import {
  HttpClient,
  HttpEventType,
  HttpHeaderResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, skipWhile, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Media } from 'src/app/models/media.model';
import { environment } from '../../../environments/environment';
import { officeModel } from '../../models/office.model';
import { SupplierMediaModel, SupplierUploadContext } from '../../models/supplier.model';
import { UploadClusters } from '../../models/upload-clusters.model';
import { EditMediaModel, mediaPatch } from '../../models/uploadCriteria.model';
import { DashBoardService } from '../../services/dashboard.service';
import { MediaUploadToolbarService } from '../../services/media-upload-toolbar.service';
import { MediaService } from '../../services/media.service';
import { SearchService } from '../../services/search.service';
import { TagsService } from '../../services/tags.service';
import * as albumActions from '../actions/albums.actions';
import * as galleryActions from '../actions/galleries.actions';
import * as mediaActions from '../actions/media.actions';
import * as officeActions from '../actions/offices.actions';
import * as userActions from '../actions/user.actions';
import { AppState } from '../reducers';
import { MediaUploadState } from '../reducers/media.reducer';

@Injectable()
export class MediaEffects {
  constructor(
    private actions$: Actions,
    private search: SearchService,
    private dashboardService: DashBoardService,
    private _mediaUploadToolbarService: MediaUploadToolbarService,
    public _mediaService: MediaService,
    private store$: Store<AppState>,
    private _http: HttpClient,
    private _tagsService: TagsService
  ) {}

  getRecentlyPublishedMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getRecentlyPublishedMedia),
      withLatestFrom(this.store$),
      skipWhile(data => data[1].offices.currentSelectedOfficeId === null),
      mergeMap(data => {
        const state = data[1];
        /**
         * TODO: Should probably clean up this endpoint. It's weird to hardcode the status and count here
         * But I don't know where in the UI you'd change it so...
         */
        const term = `?OfficeId=${state.offices.currentSelectedOfficeId}&mediaStatus=PBH&Count=${environment.dashboardSeeMoreSize}`;
        return this.search.searchRecentlyUploadedMedia(term).pipe(
          map(media => {
            return mediaActions.getRecentlyPublishedMediaSuccess({ media: media });
          })
        );
      })
    )
  );
  searchMediaByLibraryServiceCorrelationKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.searchMediaByLibraryServiceCorrelationKey),
      switchMap(data => {
        return this.search.searchMediaByLibraryServiceCorrelationKey(data.id).pipe(
          map(media => {
            return mediaActions.searchMediaByLibraryServicecorrelationKeySuccess({ media: media });
          })
        );
      })
    )
  );
  selectOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(officeActions.selectOffice),
      switchMap(() => [mediaActions.getDraftedMedia()])
    )
  );

  getMediaMetadataById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getMediaMetadataById),
      mergeMap(data => {
        return this.search.getMedia(data.mediaId).pipe(
          switchMap(metaData => {
            let actions = [];
            actions.push(mediaActions.getMediaMetadataByIdSuccess({ metaData: metaData }));
            if (data.getRelatedImages) {
              actions.push(mediaActions.getRelatedMedia({ tags: metaData.tags, mediaId: +metaData.id }));
            }
            return actions;
          })
        );
      })
    )
  );

  getRelatedMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getRelatedMedia),
      mergeMap(data => {
        return this.search.getRelatedMedia(data.mediaId).pipe(
          map(media => {
            return mediaActions.getRelatedMediaSuccess({ media: media, mediaId: +data.mediaId });
          })
        );
      })
    )
  );
  getMediaByGalleryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getMediaByGalleryId),
      mergeMap(data => {
        const pageSize = 100;

        return this.dashboardService.getMediaByGallery(data.galleryId, pageSize).pipe(
          switchMap(media => {
            return [
              galleryActions.getAllMediaForGallerySuccess({ galleryId: data.galleryId, media: media }),
              mediaActions.getMediaByGalleryIdSuccess({ media: media }),
            ];
          })
        );
      })
    )
  );
  getMediaByAlbumId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getMediaByAlbumId),
      withLatestFrom(this.store$),
      mergeMap(data => {
        return this.dashboardService.getMediaByAlbum(data[0].albumId, data[0].pageSize).pipe(
          switchMap(media => {
            return [
              mediaActions.getMediaByAlbumIdSuccess({ media: media }),
              albumActions.getMediaForAlbumSuccess({ albumId: data[0].albumId, albumMedia: media }),
            ];
          })
        );
      })
    )
  );
  getRecentlyAddedMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getRecentlyAddedMedia),
      withLatestFrom(this.store$),
      mergeMap(data => {
        return this.search
          .searchRecentlyUploadedMedia(data[0].queryString)
          .pipe(map(media => mediaActions.getRecentlyAddedMediaSuccess({ media: media })));
      })
    )
  );
  getMostActiveMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getMostActiveMedia),
      mergeMap(data => {
        return this.search.searchRecentlyAddedAnyMediaGroup(data.queryString).pipe(
          map(media => {
            return mediaActions.getMostActiveMediaSuccess({ media: media });
          })
        );
      })
    )
  );
  getMostViewedMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getMostViewedMedia),
      withLatestFrom(this.store$),
      mergeMap(data => {
        return this.search.searchMostViewedByOffice(data[0].queryString).pipe(
          map(media => {
            return mediaActions.getMostViewedMediaSuccess({ media: media });
          })
        );
      })
    )
  );

  editUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.editMedia),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const action = data[0];
        const state = data[1];

        const requestParams = new HttpParams().set('source', 'UPD');
        const formData = new FormData();
        formData.append('file', action.file, action.file.name);
        formData.append('Name', action.file.name);
        formData.append('FileName', action.file.name);
        const office = action.media.metaData.uploadContext.office as officeModel;
        formData.append('OfficeId', office.id.toString());
        formData.append('UserId', state.user.user.userLoginId.toString());
        if (action.media.metaData.uploadContext.program) {
          formData.append('ProgramId', action.media.metaData.uploadContext.program.id.toString());
        }
        if (action.media.metaData.uploadContext.programService) {
          formData.append('ProgramServiceId', action.media.metaData.uploadContext.programService.id.toString());
        }
        if (action.media.metaData.uploadContext.libraryService) {
          formData.append('LibraryServiceId', action.media.metaData.uploadContext.libraryService.id.toString());
        }
        if (action.media.metaData.uploadContext.serviceVenue) {
          formData.append('ServiceVenueId', action.media.metaData.uploadContext.serviceVenue.id.toString());
        }
        if (action.media.metaData.uploadContext.supplier) {
          const supplier = action.media.metaData.uploadContext.supplier as SupplierUploadContext;
          formData.append('SupplierId', supplier.id.toString());
        }
        if (action.media.metaData.uploadContext.supplier) {
          formData.append('UploadSourceCode', 'SUP');
        } else {
          formData.append('UploadSourceContext', 'PSR');
        }

        const url = environment.mediaApiEndpoint_V2 + 'File';
        return this._http
          .post(url, formData, {
            headers: new HttpHeaders(),
            observe: 'events',
            params: requestParams,
            reportProgress: true,
            responseType: 'text',
          })
          .pipe(
            catchError(error => of(error)),
            concatMap(event => {
              const mediaUploadState: MediaUploadState = {
                fileName: action.file.name,
              };
              if (event instanceof HttpResponse) {
                const body = JSON.parse(event.body);
                Object.assign(mediaUploadState, {
                  inProgress: false,
                  id: body.id,
                });
                let resultingMedia: Media = _.cloneDeep(action.media);
                resultingMedia.mediaId = parseInt(body.id);
                resultingMedia.fileName = body.fileName;

                return [
                  mediaActions.getMediaMetadataById({ mediaId: +body.id, getRelatedImages: false }),
                  mediaActions.editUploadSuccess({
                    media: resultingMedia,
                    originalMediaId: +action.media.mediaId,
                  }),
                ];
              } else if (event.status == 400) {
                Object.assign(mediaUploadState, {
                  inProgress: false,
                  message: 'Bad Request',
                });
              } else if (event.status == 500) {
                Object.assign(mediaUploadState, {
                  inProgress: false,
                  message: 'Server Error',
                });
              } else {
                return of(mediaActions.emptyAction());
              }
            }),
            catchError(error => of(error))
          );
      })
    )
  );

  editMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.editUploadSuccess),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const action = data[0];
        const state = data[1];

        const editMediaModel = new EditMediaModel();
        let fileName = action.media.fileName;

        editMediaModel.fileName = fileName;
        editMediaModel.isEdit = true;
        editMediaModel.sourceMediaId = +action.originalMediaId;
        editMediaModel.userId = state.user.user.userLoginId;
        editMediaModel.clientId = null;

        return this._mediaUploadToolbarService.mediaEdited(editMediaModel).pipe(
          map(res => {
            return mediaActions.editMediaSuccess({ media: res, originalMediaId: +action.originalMediaId });
          })
        );
      })
    )
  );

  updateMediaStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.updateMediaStatus),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const action = data[0];
        const state = data[1];
        const updateStatusCmd: mediaPatch = {
          mediaId: +action.mediaId,
          ops: 'Modify',
          path: '/Status',
          newValue: action.status,
          oldValue: '',
          userId: state.user.user.userLoginId
        };
        return this._mediaService.updateSingleProperty(action.mediaId, updateStatusCmd).pipe(
          map(res => {
            // Because status is so wonky and doesn't even return anything, we need to re-update this object
            // manually here
            // TODO: Fix how we handle status
            const matchedMedia = state.media.items.filter(item => item.mediaId == action.mediaId);
            if (matchedMedia.length) {
              const result = matchedMedia[0];
              let niceStatus = '';
              let statusId = 0;
              switch (action.status) {
                case 'DFT': {
                  niceStatus = 'Draft';
                  statusId = 4;
                  break;
                }
                case 'PBH': {
                  niceStatus = 'Published';
                  statusId = 5;
                  break;
                }
                case 'ARC': {
                  niceStatus = 'Archived';
                  statusId = 6;
                  break;
                }
              }
              result.status = niceStatus;
              result.metaData.statusDescription = niceStatus;
              result.metaData.statusId = statusId;

              return mediaActions.updateMediaStatusSuccess({ media: result });
            } else {
              return mediaActions.emptyAction();
            }
          })
        );
      })
    )
  );

  /**
   * It's a bit awkward to just return the get metadatabyID action as that just loops the media object again.
   * If we wanted to be fancy we could pass the payload to the reducer and manually patch the media to cut an http call.
   */
  patchMediaUploadContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.patchMediaUploadContext),
      mergeMap(data => {
        const payload = JSON.stringify(data.payload);
        const updateUploadCmd: mediaPatch = {
          mediaId: +data.mediaId,
          ops: 'Modify',
          path: '/uploadcontext',
          newValue: payload,
          oldValue: '',
          userId:0
        };
        return this._mediaService.updateSingleOrObject(+data.mediaId, updateUploadCmd).pipe(
          map(res => {
            return mediaActions.getMediaMetadataById({ mediaId: +data.mediaId, getRelatedImages: false });
          })
        );
      })
    )
  );

  updateSupplierOnMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.updateSupplierOnMedia),
      concatMap(data => {
        const suppliermediamodel: SupplierMediaModel = {
          supplierId: parseInt(data.payload.newValue),
          supplierName: data.supplier.name,
          supplierNumber: data.payload.newValue,
        };
        this._mediaService.updateSingleProperty(data.mediaId, data.payload).subscribe();
        return [
          mediaActions.updateSupplierOnMediaSuccess({
            mediaId: +data.mediaId,
            supplier: suppliermediamodel,
            add: data.payload.ops == 'Add' ? true : false,
          }),
        ];
      })
    )
  );

  addTagToMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.addTagToMedia),
      concatMap(data => {
        this._tagsService.updateMediaTag(data.tag.id, data.criteria).subscribe();
        // return of(mediaActions.emptyAction());
        return of(mediaActions.addTagToMediaOptimisticUpdate({ criteria: data.criteria, tag: data.tag }));
      })
    )
  );

  removeTagFromMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.removeTagFromMedia),
      concatMap(data => {
        this._tagsService.removeTag(data.tag.id, data.mediaId, '').subscribe();
        return of(mediaActions.removeTagFromMediaOptimisticUpdate({ tag: data.tag, mediaId: +data.mediaId }));
      })
    )
  );

  removeAzureTagFromMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.removeAzureTagFromMedia),
      concatMap(data => {
        this._tagsService.removeTag(null, +data.media.mediaId, data.name).subscribe();
        return [
          mediaActions.removeAzureTagFromMediaSuccess({
            mediaId: +data.media.mediaId,
            name: data.name,
          }),
        ];
      })
    )
  );

  searchMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.searchMedia),
      switchMap(data => {
        const searchRequest = data.searchRequest;
        let queryString = `pageIndex=${searchRequest.pageIndex}`;
        queryString += `&MediaStatus=${searchRequest.mediaStatus}`;
        if (searchRequest.offices != null) {
          const offices = encodeURIComponent(searchRequest.offices.join('|'));
          queryString += `&Offices=${offices}`;
        }

        const terms: string[] = searchRequest.term ? searchRequest.term.split(' ') : [];

        for (let i = 0; i < terms.length; i++) {
          queryString += `&Tags=${terms[i].trim()}`;
        }
        queryString += searchRequest.isCreative ? `&IsCreative=${searchRequest.isCreative}` : '';
        queryString += searchRequest.mediaType ? `&Mediatype=${searchRequest.mediaType}` : '';
        queryString += searchRequest.program ? `&ProgramId=${searchRequest.program.id}` : '';
        queryString += searchRequest.client ? `&ClientId=${searchRequest.client.id}` : '';
        queryString += searchRequest.accountExecutive ? `&AccountExecutive=${searchRequest.accountExecutive.id}` : '';
        queryString += searchRequest.service ? `&ServiceId=${searchRequest.service.id}` : '';
        queryString += searchRequest.supplier ? `&Supplier=${searchRequest.supplier.id}` : '';
        queryString += searchRequest.venue ? `&Venue=${searchRequest.venue.id}` : '';
        queryString += searchRequest.serviceDateFrom
          ? `&ServiceDateBegin=${searchRequest.serviceDateFrom.toISOString()}`
          : '';
        queryString += searchRequest.serviceDateTo
          ? `&ServiceDateEnd=${searchRequest.serviceDateTo.toISOString()}`
          : '';
        queryString += searchRequest.uploadDateFrom
          ? `&UploadDateBegin=${searchRequest.uploadDateFrom.toISOString()}`
          : '';
        queryString += searchRequest.uploadDateTo ? `&UploadDateEnd=${searchRequest.uploadDateTo.toISOString()}` : '';

        return this.search.searchMedia(queryString).pipe(
          map(media => {
            let clear = false;
            if (data.page == 1) {
              clear = true;
            }
            return mediaActions.searchMediaSuccess({ media: media, searchRequest: searchRequest, clear: clear });
          }),
          catchError(error => {
            let clear = false;
            if (data.page == 1) {
              clear = true;
            }
            return of(mediaActions.searchMediaSuccess({ media: [], searchRequest: searchRequest, clear: clear }));
          })
        );
      })
    )
  );

  uploadMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.uploadMedia),
      withLatestFrom(this.store$),
      mergeMap(data => {
        const action = data[0];
        const state = data[1];
        // TODO: Move this call to service?
        const requestParams = new HttpParams().set('source', action.uploadContext);

        const formData = new FormData();
        formData.append('file', action.file, action.file.name);
        formData.append('Name', action.file.name);
        formData.append('FileName', action.file.name);
        formData.append('OfficeId', action.payload.OfficeId);
        if (action.payload.ProgramServiceId) {
          formData.append('ProgramId', action.payload.ProgramId);
          formData.append('ProgramServiceId', action.payload.ProgramServiceId);
        }
        formData.append('UploadSourcecode', action.uploadContext);
        if (action.payload.SupplierId) {
          formData.append('SupplierId', action.payload.SupplierId);
        }
        if (action.payload.ServiceVenueId) {
          formData.append('ServiceVenueId', action.payload.ServiceVenueId);
        }
        formData.append('UserId', state.user.user.userLoginId.toString());

        formData.append('Note',action.payload.Note);

        const url = environment.mediaApiEndpoint_V2 + 'File';
        return this._http
          .post(url, formData, {
            headers: new HttpHeaders(),
            observe: 'events',
            params: requestParams,
            reportProgress: true,
            responseType: 'text',
          })
          .pipe(
            catchError(error => of(error)),
            concatMap(event => {
              const mediaUploadState: MediaUploadState = {
                fileName: action.file.name,
              };
              if (event.type === HttpEventType.UploadProgress) {
                Object.assign(mediaUploadState, {
                  inProgress: true,
                  uploadProgressPercent: event.loaded / event.total,
                });
                return of(mediaActions.uploadMediaMessage({ mediaUploadState: mediaUploadState }));
              } else if (event instanceof HttpResponse) {
                const body = JSON.parse(event.body);
                Object.assign(mediaUploadState, {
                  inProgress: false,
                  id: body.id,
                });
                return [
                  mediaActions.getMediaMetadataById({ mediaId: +body.id, getRelatedImages: false }),
                  mediaActions.uploadMediaMessage({ mediaUploadState: mediaUploadState }),
                ];
              } else if (event instanceof HttpHeaderResponse) {
                if (event.status == 400) {
                  Object.assign(mediaUploadState, {
                    inProgress: false,
                    message: 'Bad Request',
                    errorCode: 400,
                  });
                  return of(mediaActions.uploadMediaMessage({ mediaUploadState: mediaUploadState }));
                } else if (event.status == 500) {
                  Object.assign(mediaUploadState, {
                    inProgress: false,
                    message: 'Server Error',
                    errorCode: 500,
                  });
                  return of(mediaActions.uploadMediaMessage({ mediaUploadState: mediaUploadState }));
                } else {
                  return of(mediaActions.emptyAction());
                }
              }
              //HANDLES A 409 EXISTING CONFLICT.
              else if (event.type === HttpEventType.DownloadProgress) {
                let data;
                try {
                  data = JSON.parse(event.partialText);
                } catch (e) {
                  data = null;
                }
                const existingId = !data ? 0 :data.existingId;
                if (data && existingId > 0) {
                  Object.assign(mediaUploadState, {
                    inProgress: false,
                    message: data.message,
                    errorCode: 409,
                    id: +existingId,
                  });
                  //We both get the metadata for the existing image, as well as pass a new upload message\
                  //to signal that this media already existed
                  return [
                    mediaActions.getMediaMetadataById({ mediaId: +existingId, getRelatedImages: false }),
                    mediaActions.uploadMediaMessage({ mediaUploadState: mediaUploadState }),
                  ];
                } else if (event.type === HttpEventType.DownloadProgress && event.partialText) {
                  Object.assign(mediaUploadState, {
                    errorCode: 400,
                    inProgress: false,
                    message: event.partialText,
                  });
                  return of(mediaActions.uploadMediaMessage({ mediaUploadState: mediaUploadState }));
                }
              } else {
                return of(mediaActions.emptyAction());
              }
            }),
            catchError(error => {
              console.log(error);
              return of(error);
            })
          );
      })
    )
  );
  getIsMediaLiked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getIsMediaLiked),
      withLatestFrom(this.store$),
      mergeMap(data => {
        return this.search.IsMediaFavorite(data[0].mediaId).pipe(
          mergeMap(isLiked => {
            let mediaLiked = false;
            if (isLiked === undefined) {
              mediaLiked = false;
            } else {
              mediaLiked = isLiked;
            }
            return of(mediaActions.getIsMediaLikedSuccess({ mediaId: +data[0].mediaId, isLiked: mediaLiked }));
          }),
          catchError(error => {
            console.log(error);
            return of(mediaActions.getIsMediaLikedFail({ mediaId: +data[0].mediaId }));
          })
        );
      })
    )
  );
  setIsMediaLiked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.setIsMediaLiked),
      withLatestFrom(this.store$),
      mergeMap(data => {
        this.search.SetMediaFavorite(data[0].mediaId);
        if (data[0].newValue) {
          return [
            mediaActions.setIsMediaLikedSuccess({ mediaId: +data[0].mediaId, isLiked: data[0].newValue }),
            albumActions.addMediaToAlbumsSuccess({
              mediaId: +data[0].mediaId,
              selectedAlbumIds: [data[0].likesAlbumId],
            }),
          ];
        } else {
          return [
            mediaActions.setIsMediaLikedSuccess({ mediaId: +data[0].mediaId, isLiked: data[0].newValue }),
            albumActions.removeMediaFromAlbumsSuccess({
              mediaId: +data[0].mediaId,
              selectedAlbumIds: [data[0].likesAlbumId],
            }),
          ];
        }
      })
    )
  );
  setCuratorType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setCuratorType),
      switchMap(action => [mediaActions.getDraftedMedia()])
    )
  );
  getDraftedMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getDraftedMedia),
      withLatestFrom(this.store$),
      switchMap(data => {
        const action = data[0];
        const state = data[1];
        const isCreative = state.user.currentSelectedCuratorType
          ? state.user.currentSelectedCuratorType.isCreative
          : false;
        const obsArray$ = [];
        obsArray$.push(
          this._mediaService.getUploadClusters(state.offices.currentSelectedOfficeId, 'DFT', isCreative, 'SUP').pipe(
            take(1),
            catchError(error => of([]))
          )
        );
        obsArray$.push(
          this._mediaService.getUploadClusters(state.offices.currentSelectedOfficeId, 'DFT', isCreative, 'PSVC').pipe(
            take(1),
            catchError(error => of([]))
          )
        );
        obsArray$.push(
          this._mediaService.getUploadClusters(state.offices.currentSelectedOfficeId, 'DFT', isCreative, 'LSVC').pipe(
            take(1),
            catchError(error => of([]))
          )
        );
        obsArray$.push(
          this._mediaService.getUploadClusters(state.offices.currentSelectedOfficeId, 'DFT', isCreative, '').pipe(
            take(1),
            catchError(error => of([]))
          )
        );
        obsArray$.push(
          this._mediaService.getUploadClusters(state.offices.currentSelectedOfficeId, 'DFT', isCreative, 'IPAD').pipe(
            take(1),
            catchError(error => of([]))
          )
        );
        return forkJoin(obsArray$).pipe(
          map(res => {
            const result = {
              supplierDraftedMedia: res[0] ? res[0] : [],
              programDraftedMedia: res[1] ? res[1] : [],
              libraryServiceDraftedMedia: res[2] ? res[2] : [],
              miscDraftedMedia: res[3] ? res[3] : [],
              iPadDraftedMedia: res[4] ? res[4] : [],
            };
            return result;
          })
        );
      }),
      switchMap(data => [
        mediaActions.getDraftedMediaSuccess({
          supplierDraftedMedia: <UploadClusters[]>data.supplierDraftedMedia,
          programDraftedMedia: <UploadClusters[]>data.programDraftedMedia,
          libraryServiceDraftedMedia: <UploadClusters[]>data.libraryServiceDraftedMedia,
          miscDraftedMedia: <UploadClusters[]>data.miscDraftedMedia,
          iPadMigrationMedia: <UploadClusters[]>data.iPadDraftedMedia,
        }),
      ])
    )
  );
  getDraftedMediaBySupplierId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getDraftedMediaBySupplierId),
      withLatestFrom(this.store$),
      switchMap(data => {
        const action = data[0];
        const state = data[1];
        return this._mediaService
          .getUploadClusterForSupplierByIdObs(state.offices.currentSelectedOfficeId, 'DFT', 'SUP', action.supplierId)
          .pipe(
            map(res => {
              const uploadQueue: MediaUploadState[] = [];
              if (res.mediaIds && res.mediaIds.length) {
                res.mediaIds.map(id => {
                  const newUploadState: MediaUploadState = {
                    fileName: null,
                    id: id,
                    inProgress: false,
                  };
                  uploadQueue.push(newUploadState);
                });
                return mediaActions.setUploadQueue({ uploadQueue: uploadQueue });
              } else {
                return mediaActions.setUploadQueue({ uploadQueue: [] });
              }
            })
          );
      })
    )
  );
  getDraftedMediaByProgramAndSupplierId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.getDraftedMediaByProgramAndServiceId),
      withLatestFrom(this.store$),
      switchMap(data => {
        const action = data[0];
        const state = data[1];
        return this._mediaService
          .getUploadClusterForPSvcByIdObs(
            state.offices.currentSelectedOfficeId,
            'DFT',
            'PSVC',
            action.programId,
            action.programServiceId
          )
          .pipe(
            map(res => {
              let uploadQueue: MediaUploadState[];
              if (res.mediaIds && res.mediaIds.length) {
                res.mediaIds.map(id => {
                  const newUploadState: MediaUploadState = {
                    fileName: null,
                    id: id,
                    inProgress: false,
                  };
                  uploadQueue.push(newUploadState);
                  return mediaActions.setUploadQueue({ uploadQueue: uploadQueue });
                });
              } else {
                return mediaActions.setUploadQueue({ uploadQueue: [] });
              }
            })
          );
      })
    )
  );
  incrementMediaViewCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.incrementMediaViewCount),
      mergeMap(data => {
        return this._mediaService.AddFileViewLog(data.mediaId).pipe(
          map(res => {
            return mediaActions.getMediaMetadataById({
              mediaId: +data.mediaId,
              getRelatedImages: data.getRelatedImages,
            });
          })
        );
      })
    )
  );
  bulkUpdateMediaStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaActions.bulkUpdateMediaStatus),
      withLatestFrom(this.store$),
      switchMap(data => {
        const action = data[0];
        const state = data[1];
        return this._mediaService.bulkUpdateMediaStatus(action.mediaIds, action.statusId, state.user.user.userLoginId).pipe(
          map(res => {
            return mediaActions.bulkUpdateMediaStatusSuccess({ mediaIds: action.mediaIds, statusId: action.statusId });
          })
        );
      })
    )
  );
}
