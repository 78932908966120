import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PocketService } from '../../services/pocket.service';
import * as pocketActions from '../actions/pocket.actions';

@Injectable()
export class PocketEffects {
  constructor(
    private actions$: Actions,
    private _pocketService: PocketService,
    private store$: Store<any>
  ) { }

  addMediaToPocket = createEffect(() => this.actions$.pipe(
    ofType(pocketActions.addMediaToPocket),
    withLatestFrom(this.store$),
    mergeMap((data) => {
      let addedMediaId = data[0].mediaId;
      let userId = data[1].user.user.userId;
      return this._pocketService.addMediaToPocket(addedMediaId, userId).pipe(
        map(media => {
          return pocketActions.addMediaToPocketSuccess({ mediaId: addedMediaId });
        })
      )
    })
  ));

  removeMediaFromPocket = createEffect(() => this.actions$.pipe(
    ofType(pocketActions.removeMediaFromPocket),
    withLatestFrom(this.store$),
    mergeMap((data) => {
      let removedMediaId = data[0].mediaId;
      let userId = data[1].user.user.userId;
      return this._pocketService.removeMediaFromPocket([data[0].mediaId], userId).pipe(map(media => {
        return pocketActions.removeMediaFromPocketSuccess({ mediaId: data[0].mediaId });
      }))
    })
  ))

  getPocketMediaForUser = createEffect(() => this.actions$.pipe(
    ofType(pocketActions.getMediaForPocket),
    withLatestFrom(this.store$),
    mergeMap((data) => {
      let userId = data[1].user.user.userId;
      return this._pocketService.getPocketMediaForUser(userId).pipe(
        map(pocketMedia => {
          return pocketActions.getMediaForPocketSuccess({ media: pocketMedia });
        }))
    })
  ))

  clearPocket = createEffect(() => this.actions$.pipe(
    ofType(pocketActions.clearPocket),
    withLatestFrom(this.store$),
    mergeMap((data) => {
      let userId = data[1].user.user.userId;
      let mediaIds = data[1].media.mediaPocketIds;
      return this._pocketService.removeMediaFromPocket(mediaIds, userId).pipe(map(res => {
        return pocketActions.clearPocketSuccess();
      }))
    })

    ))
}