import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { delay, first, map, startWith } from 'rxjs/operators';
import { UserUploadContext } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { dashboardResult } from '../../../models/dashboardResult.model';
import { Media } from '../../../models/media.model';
import { Office } from '../../../models/office.model';


@Component({
  selector: 'app-share-album-dialog',
  templateUrl: './share-album-dialog.component.html',
  styleUrls: ['./share-album-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareAlbumDialogComponent implements OnInit {
  @HostBinding('class.content-type')

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl('');
  subject = new FormControl('');
  body = new FormControl(''); 
  filteredUsers: Observable<UserUploadContext[]>;
  users: UserUploadContext[] =[];
  foundUsers: UserUploadContext[];
  allUsers: UserUploadContext[] =[];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  officemodels: Office[];
  selectedOffice: Office;

  Name: string;
  Description: string;

  isAlbum = false;
  typeString = '';
  actionString = 'Save';

  pocket: Media[];
  selectedImage: Media;
  formIsValid = false;
  albums: dashboardResult[];

  originalAlbumName: string = null; // Necessary so we don't whine at a user for not altering an album name on edit

  // Error Object
  errors = {
    name: {
      hasError: false,
      message: '',
    },
  };

  constructor(
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ShareAlbumDialogComponent>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
   
  }

  ngOnInit() {
    this.users=[];
    this.subject=new FormControl(this.data.editData.subject);
    this.body=new FormControl(this.data.editData.body);
    this.userService.getAllActiveUsers().pipe(first(), delay(0)).subscribe(users=>{
      this.allUsers=users;
      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((user: string | null) => (user ? this._filter(user) : this.allUsers.slice())),
      );
      this.data.editData.userIds.map(su=>{
        const foundUser=this.allUsers.find(u=>u.id===su);
        if (foundUser){
            this.users.push(foundUser);

      }});
      const user = JSON.parse(sessionStorage.getItem('profile_user'));
    this.body.setValue(this.allUsers.find(u=>u.id===user.userLoginId).fullName+this.data.editData.body);
      

    });
  
   
   
   
  }

  onParameterChange() {
    this.isFormValid();
  }

  isFormValid() {
    // Validate that the name is present and properly unique
    // if (this.Name) {
    //   this.errors.name.hasError = false;
    //   this.errors.name.message = '';
    
    // }

    // // Finally validate all required fields are present
    // if (this.Name && this.Description && this.selectedImage 
    //   && ((!this.isAlbum && this.selectedOffice) || this.isAlbum)) {
    //   this.formIsValid = true;
    // } else {
    //   this.formIsValid = false;
    // }
    this.formIsValid = true;
    this.cdRef.markForCheck();
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (!this.formIsValid) {
      return;
    }
  
    this.submitAlbum();
 
  }

  submitAlbum() {
    const result = {
     isShared:true,
     users:this.users,
     body:this.body.value,
     subject:this.subject.value
    };
    this.dialogRef.close(result);
  }
  add(event: MatChipInputEvent): void {
    const value = event.value;

    
    if (value && this.foundUsers.length==1) {
      this.users.push(this.foundUsers[0]);
       // Clear the input value
    event.input!.value='';

    this.userCtrl.setValue(null);
    }

    this.isFormValid();
  }

  remove(user: UserUploadContext): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
    this.isFormValid();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const foundUser=this.allUsers.find(u=>u.id===event.option.value.id);
    if (foundUser){
        this.users.push(foundUser);
        this.userInput.nativeElement.value = '';
        this.userCtrl.setValue(null);
    }
    this.isFormValid();
  }
  private _filter(value: string): UserUploadContext[] {
    let filterValue = '';
    if (typeof(value)==='string'){
        filterValue = value.toLowerCase();
    }
   
    this.foundUsers=this.allUsers.filter(user => user.fullName.toLowerCase().includes(filterValue));
    return  this.foundUsers;
  }
}
