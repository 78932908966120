<mat-form-field
  class="type-ahead-component ae-type-ahead-component">
    <input matInput
           placeholder="Account Executive"
           #programSearchBox
           id="ae-search-box"
           [formControl]="aeFormControl"
           [matAutocomplete]="autoae">
    <mat-autocomplete #autoae="matAutocomplete"
                      (optionSelected)="selectedAEEvent($event.option.value)"
                      [displayWith]="aeDisplayFn">
        <mat-option *ngIf="aeLoading"
                    class="is-loading">
            <ng-container class="loading-indicator">
                <mat-spinner class="spin-center">
                </mat-spinner>
            </ng-container>
        </mat-option>
        <div>
            <mat-option class="uploader-auto-option"
                        *ngFor="let ae of filteredAEOptions$ | async"
                        [value]="ae">
                <ng-container *ngIf="ae">
                    <p class="filter-complete-text">{{ ae.name }}</p>
                </ng-container>
            </mat-option>
        </div>
    </mat-autocomplete>
</mat-form-field>
