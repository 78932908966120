import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { programModel } from '../models/program.model';
import { programServiceModel } from '../models/programService.model';
import { RolePermissions } from '../models/role-permissions';
import { supplierModel } from '../models/supplier.model';
import { CuratorUserMetaData, RoleFilteringOption, User, UserUploadContext } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userLoginId = new BehaviorSubject<number>(0);
  currentLoginId = this.userLoginId.asObservable();

  public userLogin = new BehaviorSubject('');
  currentLogin = this.userLogin.asObservable();

  public userOfficeId = new BehaviorSubject<number>(0);
  currentOfficeId = this.userOfficeId.asObservable();

  public userRoleId = new BehaviorSubject<number>(0);
  currentRoleId = this.userRoleId.asObservable();

  public userName = new BehaviorSubject('');
  currentUserName = this.userName.asObservable();

  public rolePermissions = new BehaviorSubject<RolePermissions[]>(null);
  rolePermissionList = this.rolePermissions.asObservable();

  public userProgram = new BehaviorSubject<programModel>(null);
  currentProgram = this.userProgram.asObservable();

  public userService = new BehaviorSubject<programServiceModel>(null);
  currentService = this.userService.asObservable();

  public userSupplier = new BehaviorSubject<supplierModel>(null);
  currentSupplier = this.userSupplier.asObservable();

  constructor(private http: HttpClient) {}

  changeRole(roleId: number) {
    this.userRoleId.next(roleId);
  }

  IsPermissionExists(permission: string): boolean {
    if (this.rolePermissions.value == null) {
      return false;
    }
    const rolePerm = this.rolePermissions.value.filter(rp => rp.permission === permission);
    if (rolePerm !== undefined && rolePerm !== null && rolePerm.length > 0 && rolePerm[0].permission === permission) {
      return true;
    } else {
      return false;
    }
  }

  loadPermissions(perms: RolePermissions[]) {
    this.rolePermissions.next(perms);
  }

  setUserProgram(program: programModel) {
    this.userProgram.next(program);
  }

  setUserService(service: programServiceModel) {
    this.userService.next(service);
  }

  setUserSupplier(supplier: supplierModel) {
    this.userSupplier.next(supplier);
  }

  getUserCuratorTypes(userId: number, officeId: number): Observable<CuratorUserMetaData[]> {
    return this.http.get<CuratorUserMetaData[]>(environment.adminApiEndpoint + 'User/' + userId + '/curatorTypes?officeId=' + officeId);
  }

  getUserIdByCorrelationKey(correlationKey: number): Observable<number> {
    return this.http.get<number>(environment.adminApiEndpoint + 'User/id/' + correlationKey);
  }

  getUserIdByLogin(login: string): Observable<number> {
    return this.http.get<number>(environment.adminApiEndpoint + 'User/id/login/' + login);
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<User>(environment.adminApiEndpoint + 'User/' + userId);
  }
  getUserByLogin(login: string): Observable<any> {
    return this.http.get<any>(environment.adminApiEndpoint + 'User/login/' + login);
  }


  getUserFromDmcPro(): Observable<User> {
    return this.http.get<User>(`/app/api/user`);
  }

  loginUser(userName:string): Observable<User> {
    return this.http.post<User>(`/app/api/login`, {UserName: userName});
  }


  async getUserWithCorrelationKey(userKey: number) {
    return await this.http
      .get<User[]>(environment.adminApiEndpoint + 'User?userKey=' + userKey)
      .pipe(catchError(this.handleError<User[]>()))
      .toPromise();
  }

   getAllActiveUsers() {
    return this.http
      .get<UserUploadContext[]>(environment.adminApiEndpoint + 'User?userKey=0');
      
  }

  getUserPermissions(userId: number): Observable<RolePermissions[]> {
    return this.http.get<RolePermissions[]>(environment.mediaApiEndpoint + 'User/' + userId + '/roles/permissions');
  }
  getUserRoleAndPermissionsByCorrelationKey(correlationKey: number): Observable<RolePermissions[]> {
    return this.http.get<RolePermissions[]>(environment.mediaApiEndpoint + 'User/role/' + correlationKey);
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  getRoleFilteringOptions(userId: number, roleId: number): Observable<RoleFilteringOption> {
    return this.http.get<RoleFilteringOption>(`user/${userId}/roleFilteringOption?roleId=${roleId}`, SESSION_CACHE_HTTP_OPTIONS);
  }
}

export const CACHE_TYPE_KEY = 'appCacheType';

export enum CacheType {
  local = 'local',
  session = 'session',
}

export const SESSION_CACHE_HTTP_OPTIONS = {
  headers: new HttpHeaders().set(CACHE_TYPE_KEY, CacheType.session),
};
