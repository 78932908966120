<mat-spinner *ngIf="loading"></mat-spinner>
<ng-container *ngIf="!loading">
  <div class="page-header-row">
    <div class="breadcrumb-wrap">
      <ul class="breadcrumbs">
        <li class="breadcrumb">Detailed View</li>
      </ul>
    </div>

    <div class="page-actions-wrap">
      <div class="page-action" *ngIf="(canViewMoreOptions$ | async) && mediaMatchesCuratorType">
        <mat-icon class="page-action" matTooltip="More Options" [matMenuTriggerFor]="detailMenu"> more_vert </mat-icon>
        <mat-menu #detailMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item class="page-menu-item" (click)="openEditDialog()" *ngIf="media && media.type === 'IMG'">Edit Image</button>

          <button
            mat-menu-item
            class="page-menu-item"
            (click)="updateMediaStatus('DFT')"
            *ngIf="media && media.metaData.statusDescription === 'Archived'"
          >
            Draft
          </button>

          <button
            mat-menu-item
            class="page-menu-item"
            (click)="updateMediaStatus('PBH')"
            *ngIf="media && media.metaData.statusDescription === 'Draft'"
          >
            Publish
          </button>

          <button
            mat-menu-item
            class="page-menu-item"
            (click)="updateMediaStatus('ARC')"
            *ngIf="media && media.metaData.statusDescription === 'Published'"
          >
            Archive
          </button>

          <button mat-menu-item class="page-menu-item" (click)="openCoverDialog()" *ngIf="media && media.type !== 'IMG'">
            Cover Image
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="page-content-row row-analytics-wrap" *ngIf="(canViewMoreOptions$ | async) && mediaMatchesCuratorType">
    <div class="analytic-wrap">
      <div class="analytic-title">Status</div>
      <div class="analytic-info">
        <div class="status-circle status-{{ media.metaData.statusDescription }}"></div>
        <div class="analytic-info-text-sm">{{ media.metaData.statusDescription }}</div>
      </div>
    </div>

    <div class="analytic-wrap">
      <div class="analytic-title">Programs Used In</div>
      <div class="analytic-info">
        <div
          class="analytic-info-text"
          [ngClass]="{ 'analytic-info-text-hover': media.metaData.programServices.length !== 0 }"
          (click)="openDetailsServices()"
        >
          {{ media.metaData.programServices.length }}
        </div>
      </div>
    </div>

    <div class="analytic-wrap">
      <div class="analytic-title">Likes</div>
      <div class="analytic-info">
        <div class="analytic-info-text">
          {{ media.metaData.liked }}
        </div>
      </div>
    </div>

    <div class="analytic-wrap">
      <div class="analytic-title">Views</div>
      <div class="analytic-info">
        <div class="analytic-info-text">
          {{ media.metaData.viewed }}
        </div>
      </div>
    </div>
  </div>
  <!-- TODO: Fix these type switches here to not be magic strings -->
  <div class="page-content-row">
    <div class="detail-display-wrap">
      <div *ngIf="media && media.type.toLowerCase() === 'img'" class="detail-image-wrap">
        <img src="{{ media.links.fileUrl }}" title="{{ media.links.fileUrl }}" class="detail-image" />
      </div>

      <div *ngIf="media.type.toLowerCase() === 'vdo'" class="detail-image-wrap">
        <video controls id="videoPlayer">
          <source src="{{ media.links.fileUrl }}" type="video/mp4" />
          Browser not supported
        </video>
      </div>

      <div *ngIf="media.type.toLowerCase() === 'ado'" class="detail-audio-wrap">
        <img src="{{ media.links.thumbnailUrl }}" title="{{ media.links.thumbnailUrl }}" class="detail-image" />
        <button class="button-playback" (click)="playAudio()">
          <i *ngIf="!isPlaying" class="material-icons">play_arrow</i>
          <i *ngIf="isPlaying" class="material-icons">pause</i>
        </button>
      </div>

      <p class="detail-image-info">
        <span>File Size: {{ sizeInMb }} MB</span>
      </p>
      <div class="gallery-actions-group gallery-actions-group-media-page">
        <div>
          <button class="gallery-action gallery-action-media-page" (click)="onLikeClick()">
            <span class="favorite-wrap" *ngIf="isLiked === false">
              <i class="material-icons"> favorite_border </i>
              Like
            </span>

            <span class="favorite-wrap" *ngIf="isLiked !== false">
              <i class="material-icons"> favorite </i>
              Liked
            </span>
          </button>
          <button class="gallery-action gallery-action-media-page" (click)="onPocketClick()">
            <i class="material-icons"> collections_bookmark </i>
            <ng-container *ngIf="!isInPocket">Add to Pocket</ng-container>
            <ng-container *ngIf="isInPocket">In Pocket</ng-container>
          </button>
          <a class="media-download-link" href="{{ media.links.fileUrl }}" download>
            <button class="gallery-action gallery-action-media-page">
              <i class="material-icons"> cloud_download </i>
              Download Media
            </button>
          </a>
        </div>
        <div class="gallery-album-col">
          <div *ngIf="selectedAlbums">
            <app-set-album-select
              [albums]="albums"
              [selectedAlbums]="selectedAlbums"
              (selectedAlbumsEvent)="addMediaToSelectedAlbums($event)"
              (createAlbumEvent)="createAlbum($event)"
            >
            </app-set-album-select>
          </div>
          <div>
            <app-set-gallery-select
              [galleries]="galleries"
              [selectedGalleries]="selectedGalleries"
              [canAddMediaToGallery]="canAddMediaToGallery"
              [userOffices]="offices"
              (selectedGalleriesEvent)="addMediaToSelectedGalleries($event)"
              (createGalleryEvent)="createGallery($event)"
            ></app-set-gallery-select>
          </div>
        </div>
      </div>
      <!-- TODO: Add Gallery and Album Actions -->
    </div>
    <div class="detail-meta-wrap">
      <mat-expansion-panel [expanded]="canAddTags && mediaMatchesCuratorType" class="card detail-panel">
        <mat-expansion-panel-header class="card-header">
          <p class="card-header-title">Tags</p>
        </mat-expansion-panel-header>

        <div class="detail-panel-body">
          <div>
            <app-tag-type-ahead
              [canAddTags]="canAddTags && mediaMatchesCuratorType"
              (tagsChanged)="helloTagsChanged($event)"
              [tags]="manualTags"
            >
            </app-tag-type-ahead>
            <app-supplier-tag-type-ahead
              [canAddTags]="canAddTags && mediaMatchesCuratorType"
              (supplierAdded)="supplierAdded($event)"
              (supplierRemoved)="supplierRemoved($event)"
              [tags]="supplierTags"
            >
            </app-supplier-tag-type-ahead>
          </div>
          <p class="detail-tag-title">Azure Tags</p>
          <app-azure-tag-chip-list
            [tags]="azureTags"
            [canAddTags]="canAddTags && mediaMatchesCuratorType"
            (removeTag)="removeAzureTag($event)"
          >
          </app-azure-tag-chip-list>
        </div>
      </mat-expansion-panel>

      <div class="card">
        <div class="card-header card-header-row">
          <div class="card-header-title">Image Info</div>
          <i class="material-icons" matTooltip="Edit Upload Context" (click)="openUploadDataEditorDialog()"> edit </i>
        </div>
        <div class="card-body">
          <div class="detail-panel-body">
            <div class="detail-context-item">
              <div class="detail-context-title">Uploaded By</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.user ? media.metaData.uploadContext.user.fullName : 'NO DATA' }}
              </div>
            </div>

            <div class="detail-context-item">
              <div class="detail-context-title">Uploaded Date</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.uploadDate | date : 'shortDate' }}
              </div>
            </div>

            <div class="detail-context-item">
              <div class="detail-context-title">Office</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.office ? media.metaData.uploadContext.office.name : 'NO DATA' }}
              </div>
            </div>

            <div *ngIf="media.metaData.uploadContext.program" class="detail-context-item">
              <div class="detail-context-title">Program</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.program.number }}-{{ media.metaData.uploadContext.program.name }}
              </div>
            </div>

            <div *ngIf="media.metaData.uploadContext.programService" class="detail-context-item">
              <div class="detail-context-title">Program Service</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.programService.name }}
              </div>
            </div>

            <div *ngIf="media.metaData.uploadContext.programService" class="detail-context-item">
              <div class="detail-context-title">Program Service Date</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.programService.date | date : 'shortDate' }}
              </div>
            </div>

            <div *ngIf="media.metaData.uploadContext.programService" class="detail-context-item">
              <div class="detail-context-title">Service Venue</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.serviceVenue ? media.metaData.uploadContext.serviceVenue.name : 'Venue Missing' }}
              </div>
            </div>

            <div class="detail-context-item" *ngIf="media.metaData.uploadContext.supplier">
              <div class="detail-context-title">Supplier</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.supplier.name }}
              </div>
            </div>

            <div class="detail-context-item" *ngIf="media.metaData.uploadContext.libraryService">
              <div class="detail-context-title">Service Library</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.libraryService.name }}
              </div>
            </div>

            <div class="detail-context-item" *ngIf="media.metaData.uploadContext.note">
              <div class="detail-context-title">Note</div>
              <div class="detail-context">
                {{ media.metaData.uploadContext.note }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-border"></div>

  <div class="media-content-wrap">
    <div class="media-section-header">
      <p class="media-section-title">Related Media</p>
    </div>

    <div class="media-grid-wrap">
      <app-media-item *ngFor="let item of relatedMedia" [media]="item"> </app-media-item>
    </div>

    <div class="media-section-footer">
      <a class="media-section-footer-view" (click)="toggleRelatedMedia()">{{ moreRelatedMediaShowing ? 'Show Less' : 'Show More' }}</a>
    </div>
  </div>

  <scroll-to-top></scroll-to-top>
</ng-container>
