import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Album, AlbumEditDTO } from '../models/album.model';
import { dashboardResult } from '../models/dashboardResult.model';

export class AlbumCreateCommand {
  Name: string;
  Description: string;
  CoverImage: string;
  UserId: number;
  MediaId: number;
  MediaIds:number[];
}

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('ebe8c704c287faf6d3fc4bd7959c9ca6:$c00byD00'),
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AlbumService {
  /**
   * Awkwardly storing this here because this is the pattern the rest of the app uses.
   * Functioning as a very bad and side-effect prone state container.
   */
  public currentAlbum: dashboardResult; // confusing model name. Represents all media collection types

  constructor(private http: HttpClient) {}

  createAlbum(request: AlbumCreateCommand) {
    const url = `${environment.mediaApiEndpoint}Album`;
    return this.http.post<any>(url, request);
  }

  editAlbum(request: AlbumEditDTO) {
    const url = `${environment.mediaApiEndpoint}Album`;
    return this.http.patch<Album>(url, request);

  }

  deleteAlbum(id: number) {
    const url = `${environment.mediaApiEndpoint}Album/${id}`;
    return this.http.delete<boolean>(url);
  }

  updateAlbumStatus(id: number, isPublic: boolean)
  {
    const url = `${environment.mediaApiEndpoint}Album/${id}/${isPublic}`;
    return this.http.patch<boolean>(url, {});
  }

  getAlbumsByUser(userId: number, pageSize: number): Observable<dashboardResult[]> {
    const url = `${environment.mediaApiEndpoint}Album?UserId=` + userId + '&PageSize=' + pageSize;
    return this.http.get<dashboardResult>(url).pipe(
      tap(_ => this.log(`get albums by user`)),
      catchError(this.handleError<any>('get albums by user has a error!'))
    );
  }

  getSharedAlbumsByUser(userId: number, pageSize: number): Observable<dashboardResult[]> {
    const url = `${environment.mediaApiEndpoint}Album?UserId=` + userId + '&PageSize=' + pageSize+'&Shared=true';
    return this.http.get<dashboardResult>(url).pipe(
      tap(_ => this.log(`get shared albums`)),
      catchError(this.handleError<any>('get shared albums error!'))
    );
  }

  queueAlbumShareEmail(email:string,subject:string,body:string): Observable<any> {
    const url = `${environment.dmcApiurl}/email/queue`;
    return this.http.post<any>(url,{recipients:email,body,subject},httpOptions).pipe(
      tap(_ => this.log(`queue email`)),
      catchError(this.handleError<any>('queue email error!'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string): void {
    console.log(message);
  }
}
