import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Gallery } from "../../models/gallery.model";
import { Office } from "../../models/office.model";
import * as GalleryActions from "../../ngrx/actions/galleries.actions";
import { AppState } from "../../ngrx/reducers";
import { selectGalleries } from "../../ngrx/selectors/galleries.selector";
import { selectUser } from "../../ngrx/selectors/user.selector";
import { GalleryResult } from "../../services/gallery.service";

@Component({
  selector: "app-all-galleries-view",
  templateUrl: "./all-galleries-view.component.html",
  styleUrls: ["./all-galleries-view.component.scss"]
})
export class AllGalleriesViewComponent implements OnInit {
  galleries: Gallery[];
  loggedUserId: number;
  officemodels: Office[];
  userOfficeId: number;
  selectedOfficeId: number;
  public officeId: any;
  user$: Observable<any>;
  offices$: Observable<any>;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.user$ = this.store$.pipe(select(selectUser));
    // TODO: unsubscribe
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.loggedUserId = res.userLoginId;
      this.userOfficeId = res.userOfficeId;
    });
    this.store$.dispatch(
      GalleryActions.getAllGalleriesForAllOffices({ pageSize: 10000 })
    );
    this.store$
      .pipe(
        select(state => state.offices.allOffices),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(res => {
        this.officemodels = res;
      });
    this.store$
      .pipe(select(selectGalleries), takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.galleries = res.filter(g => g.statusCode === "PBH");
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onGallerySelect($event: GalleryResult) {
    this.router.navigate([`/gallery/${$event.id}`]);
  }
}
