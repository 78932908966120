import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectUser = (state: AppState) => state.user.user;
export const doesPermissionExist = createSelector(selectUser,
    (user, props) => {
        if (user){
            if(user.rolePermissions === null || user.rolePermissions === undefined) {
                return null;
            }
            const permission = user.rolePermissions.filter(rp => rp.permission === props.permission);
            if(permission !== undefined && permission !== null && permission.length > 0) {
                return true;
            } else {
                return false;
            }
        }else{
            return false;
        }
       
    })