<div *ngIf="login"
    class="dashboard-wrap">
    <div class="page-header-row page-header-row-right">
        <div class="page-context-wrap">
            <div class="page-context">
                <mat-select class="page-context-filter"
                            placeholder="Office"
                            [(ngModel)]="selectedOfficeValue"
                            name="officeModel">
                    <mat-option *ngFor="let officeModel of offices"
                                [value]="officeModel.officeId">
                        {{officeModel.officeName}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>

    <!-- dash content sections -->
    <app-dashboard-section [sectionTitle]="'My Albums'"
                        [canViewDashboardItem]="true"
                        [type]="'album'"
                        [userId]="this.user.userLoginId"
                        [items]="dashboardAlbums"
                        [mode]="'album'"
                        [filter]="true"
                        [emptyText]="'You do not have any albums'">
    </app-dashboard-section>

<app-dashboard-section [sectionTitle]="'Albums Shared with Me'"
    [canViewDashboardItem]="true"
    [type]="'album'"
    [items]="sharedAlbums"
    [mode]="'album'"
    [emptyText]="'No Albums have been shared'">
</app-dashboard-section>

    <app-dashboard-section [canViewDashboardItem]="true"
                        [sectionTitle]="'Featured Galleries'"
                        [type]="'gallery'"
                        [items]="filteredDashboardGalleries"
                        [mode]="'gallery'"
                        [emptyIcon]="'business'"
                        [emptyText]="'Your office does not have any galleries featured'">
    </app-dashboard-section>

    <app-media-group [sectionTitle]="'Recently Published'"
                    [mediaGroup]="recentlyPublishedMedia$ | async"></app-media-group>

    <app-media-group    *ngIf="canViewDashboardItems" 
                    [sectionTitle]="'Recently Added'"
                    [mediaGroup]="recentlyAddedMedia$ | async">
    </app-media-group>

    <app-dashboard-section [type]="'gallery'"
                        [canViewDashboardItem]="canViewDashboardItems"
                        [sectionTitle]="'Top Viewed Galleries'"
                        [items]="topViewedGalleries$ | async"
                        [mode]="'topviewedgalliers'"
                        [emptyIcon]="'folder_special'"
                        [emptyText]="'Top View Galleries are unavailable'">
    </app-dashboard-section>

    <app-media-group *ngIf="canViewDashboardItems"
                    [sectionTitle]="'Most Active Media'"
                    [mediaGroup]="topViewMedia$ |async">
    </app-media-group>

    <app-media-group *ngIf="canViewDashboardItems" 
                     [sectionTitle]="'Most Viewed Media'"
                    [mediaGroup]="mostViewedMedia$ | async">
    </app-media-group>

    <scroll-to-top></scroll-to-top>

    <app-dashboard-section [type]="'album'"
                        [canViewDashboardItem]="canViewDashboardItems"
                        [sectionTitle]="'Following'"
                        [items]="[]"
                        [mode]="'following'"
                        [emptyIcon]="'group_add'"
                        [emptyText]="'Follow some albums to see them here'">
    </app-dashboard-section>
</div>
