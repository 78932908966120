import {
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY
} from "@angular/cdk/scrolling";
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Office } from '../../models/office.model';
import { UploadClusters } from '../../models/upload-clusters.model';
import { CuratorUserData } from '../../models/user.model';
import * as mediaActions from '../../ngrx/actions/media.actions';
import { selectOffice } from '../../ngrx/actions/offices.actions';
import * as userActions from '../../ngrx/actions/user.actions';
import { AppState } from '../../ngrx/reducers';
import { MediaUploadState } from '../../ngrx/reducers/media.reducer';


const ROW_HEIGHT = 30;
/**
 * Virtual Scroll Strategy
 */
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(ROW_HEIGHT, 1000, 2000);
  }

  attach(viewport: CdkVirtualScrollViewport): void {
    this.onDataLengthChanged();
  }
}

@Component({
  selector: 'app-drafted-media-view',
  templateUrl: './drafted-media-view.component.html',
  styleUrls: ['./drafted-media-view.component.scss'],
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }
  ]
})
export class DraftedMediaViewComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  public supplierDraftedMedia: UploadClusters[];
  supplierDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);

  programDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
  libraryServiceDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
  miscDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
  iPadMigrationMediaSource= new MatTableDataSource<UploadClusters>([]);

  
  public programDraftedMedia: UploadClusters[];
  public libraryServiceDraftedMedia: UploadClusters[];
  public miscDraftedMedia: UploadClusters[];
  public iPadMigrationMedia: UploadClusters[];

  public suppliersExpanded: boolean = false;
  public programsExpanded: boolean = false;
  public serviceLibExpanded: boolean = false;
  public miscellaneousLibExpanded: boolean = false;
  public iPadMigrationExpanded: boolean = false;

  public officeName: string = '';

  public officeList: Office[];
  public officeFormControl: FormControl;

  public userCuratorTypes: any[];
  public allCuratorTypes: any[];
  public curatorTypeFormControl: FormControl;

  public currentlySelectedCuratorType: CuratorUserData = null;

  supplierNameFilter = new FormControl('');
  supplierSourceLocationFilter = new FormControl('');
  programSourceLocationFilter = new FormControl('');
  ipadSourceLocationFilter = new FormControl('');
programNameFilter = new FormControl('');
ipadProgramNameFilter = new FormControl('');
programServiceFilter = new FormControl('');
ipadProgramServiceFilter = new FormControl('');

serviceLibraryFilter = new FormControl('');

officeNameFilter = new FormControl('');
  
  filterValues = {
    supplierName: '',
    sourceLocation: '',
    programName: '',
    programService: '',
    officeName:''
  };

loading=false;
  displayedSupplierColumns: string[] = ['date','sourceLocation', 'supplierName', 'count','isArchived','menu'];
  displayedProgramColumns: string[] = ['date','sourceLocation', 'programName','programServiceName','programServiceDate', 'count','isArchived','menu'];

  displayedIpadColumns: string[] = ['date','sourceLocation', 'programName','programServiceName', 'count','isArchived','menu'];

  displayedServiceLibraryColumns: string[] = ['date','sourceLocation', 'libServiceName', 'count','isArchived','menu'];

  displayedMiscColumns: string[] = ['date','sourceLocation','count','isArchived','menu'];
  
  @ViewChild('supplierPaginator') supplierPaginator:MatPaginator;
  @ViewChild('programPaginator') programPaginator:MatPaginator;
  @ViewChild('servicePaginator') servicePaginator:MatPaginator;
  @ViewChild('ipadPaginator') ipadPaginator:MatPaginator;
  @ViewChild('miscPaginator') miscPaginator:MatPaginator;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  
  constructor(private store$: Store<AppState>, private _router: Router,private actionsListener$: ActionsSubject) {}

  ngOnInit() {
    this.loading=false;
    //subscribe and map drafted media
    
    this.store$
      .pipe(
        select(state => state.media),  takeUntil(this.unsubscribe$),
      )
      .subscribe(data => {
        this.supplierDraftedMedia = data.supplierDraftedMedia;
        this.programDraftedMedia = data.programDraftedMedia;
        this.libraryServiceDraftedMedia = data.libraryServiceDraftedMedia;
        this.miscDraftedMedia = data.miscDraftedMedia;
        this.iPadMigrationMedia = data.iPadMigrationMedia;
       
        this.supplierDraftedMediaSource= new MatTableDataSource<UploadClusters>(this.supplierDraftedMedia);
  
        this.supplierDraftedMediaSource.filterPredicate=this.createSupplierFilter();
  
        //this.supplierDraftedMediaSource.paginator=this.supplierPaginator;
      
        this.programDraftedMediaSource= new MatTableDataSource<UploadClusters>(this.programDraftedMedia);
  
        this.programDraftedMediaSource.filterPredicate=this.createProgramFilter();
        //this.programDraftedMediaSource.paginator=this.programPaginator;
  
        this.libraryServiceDraftedMediaSource= new MatTableDataSource<UploadClusters>(this.libraryServiceDraftedMedia);
  
        this.libraryServiceDraftedMediaSource.filterPredicate=this.createServiceLibraryFilter();
       // this.libraryServiceDraftedMediaSource.paginator=this.servicePaginator;
  
        this.miscDraftedMediaSource= new MatTableDataSource<UploadClusters>(this.miscDraftedMedia);
  
        //this.miscDraftedMediaSource.paginator=this.miscPaginator;
  
        this.iPadMigrationMediaSource= new MatTableDataSource<UploadClusters>(this.iPadMigrationMedia);
  
        this.iPadMigrationMediaSource.filterPredicate=this.createProgramFilter();
       // this.iPadMigrationMediaSource.paginator=this.ipadPaginator;
        // if(data.supplierDraftedMedia.length || data.programDraftedMedia.length || data.libraryServiceDraftedMedia.length || data.miscDraftedMedia.length) {
        //   this.loading = false;
        // }
      });
    //Get Offices
    this.officeFormControl = new FormControl();

    this.store$
      .pipe(
        takeUntil(this.unsubscribe$),
        select(state => state.offices)
      )
      .subscribe(res => {
        this.officeList = res.offices;
        if (this.officeList.length) {
          let currentlySelectedOffice = this.officeList.filter(o => o.officeId == res.currentSelectedOfficeId);
          /**
        Check and see if the currently selected office is one our user has permissions to
        if not, then just pick whatever the first office they have perms to is
        **/
          if (!currentlySelectedOffice.length) {
            this.store$
              .pipe(
                take(1),
                select(state => state.user)
              )
              .subscribe(res => {
                let officeId = res.user.userOfficeId;
                this.store$.dispatch(selectOffice({ id: officeId }));
                let currentlySelectedOffice = [{ officeId: officeId }];
              });
          }

          if (!this.officeFormControl.value && currentlySelectedOffice.length>0 ) {
            this.officeFormControl.setValue(currentlySelectedOffice[0].officeId);
          }
          res.offices.map(o => {
            if (o.officeId == res.currentSelectedOfficeId) {
              this.officeName = o.officeName;
            }
          });
        }
      });
    this.officeFormControl.valueChanges.subscribe(res => {
      //this.loading=true;
      
      this.supplierDraftedMedia=[];
      this.programDraftedMedia=[];
      this.libraryServiceDraftedMedia=[];
      this.miscDraftedMedia=[];
      this.iPadMigrationMedia=[];
      this.supplierDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
      this.programDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
      this.libraryServiceDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
      this.miscDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
      this.iPadMigrationMediaSource= new MatTableDataSource<UploadClusters>([]);

      this.store$.dispatch(selectOffice({ id: res }));
      this.filterCuratorTypes();
    });

    //Get currently selected curator type

    //Get Curator Types
    this.curatorTypeFormControl = new FormControl();

   
    
    
    this.store$
      .pipe(
        select(state => state.user),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(res => {
        this.allCuratorTypes = res.curatorTypes;
        this.currentlySelectedCuratorType = res.currentSelectedCuratorType;
        this.filterCuratorTypes();
      });

      this.curatorTypeFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.supplierDraftedMedia=[];
        this.programDraftedMedia=[];
        this.libraryServiceDraftedMedia=[];
        this.miscDraftedMedia=[];
        this.iPadMigrationMedia=[];
        this.supplierDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
        this.programDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
        this.libraryServiceDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
        this.miscDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
        this.iPadMigrationMediaSource= new MatTableDataSource<UploadClusters>([]);
  
        this.store$.dispatch(userActions.setCuratorType({ curatorType: res }));
      });

    // this.curatorTypeFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
    //   this.loading=true;
    //   this.supplierDraftedMedia=[];
    //   this.programDraftedMedia=[];
    //   this.libraryServiceDraftedMedia=[];
    //   this.miscDraftedMedia=[];
    //   this.iPadMigrationMedia=[];
    //   this.supplierDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
    //   this.programDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
    //   this.libraryServiceDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
    //   this.miscDraftedMediaSource= new MatTableDataSource<UploadClusters>([]);
    //   this.iPadMigrationMediaSource= new MatTableDataSource<UploadClusters>([]);
    //   this.actionsListener$
    // .pipe(ofType('[media] getDraftedMediaSuccess'))
    // .subscribe((data: any) => {
    //   this.supplierDraftedMedia = data.supplierDraftedMedia;
    //   this.programDraftedMedia = data.programDraftedMedia;
    //   this.libraryServiceDraftedMedia = data.libraryServiceDraftedMedia;
    //   this.miscDraftedMedia = data.miscDraftedMedia;
    //   this.iPadMigrationMedia = data.iPadMigrationMedia;
    //   this.supplierDraftedMediaSource= new MatTableDataSource<UploadClusters>(data.supplierDraftedMedia);
  
    //     this.supplierDraftedMediaSource.filterPredicate=this.createSupplierFilter();
  
    //    // this.supplierDraftedMediaSource.paginator=this.supplierPaginator;
      
    //     this.programDraftedMediaSource= new MatTableDataSource<UploadClusters>(data.programDraftedMedia);
  
    //     this.programDraftedMediaSource.filterPredicate=this.createProgramFilter();
    //     //this.programDraftedMediaSource.paginator=this.programPaginator;
  
    //     this.libraryServiceDraftedMediaSource= new MatTableDataSource<UploadClusters>(data.libraryServiceDraftedMedia);
  
    //     this.libraryServiceDraftedMediaSource.filterPredicate=this.createServiceLibraryFilter();
    //     //this.libraryServiceDraftedMediaSource.paginator=this.servicePaginator;
  
    //     this.miscDraftedMediaSource= new MatTableDataSource<UploadClusters>(data.miscDraftedMedia);
  
    //     //this.miscDraftedMediaSource.paginator=this.miscPaginator;
  
    //     this.iPadMigrationMediaSource= new MatTableDataSource<UploadClusters>(data.iPadMigrationMedia);
  
    //     this.iPadMigrationMediaSource.filterPredicate=this.createProgramFilter();
    //     //this.iPadMigrationMediaSource.paginator=this.ipadPaginator;
    //   this.loading=false;
    // });

    //   this.store$.dispatch(userActions.setCuratorType({ curatorType: res }));
    // });

    this.supplierNameFilter.valueChanges
    .subscribe(
      name => {
        this.filterValues.supplierName = name;
        this.supplierDraftedMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );
    this.supplierSourceLocationFilter.valueChanges
    .subscribe(
      source => {
        this.filterValues.sourceLocation = source;
        this.supplierDraftedMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    this.programSourceLocationFilter.valueChanges
    .subscribe(
      source => {
        this.filterValues.sourceLocation = source;
        this.programDraftedMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    this.programNameFilter.valueChanges
    .subscribe(
      name => {
        this.filterValues.programName = name;
        this.programDraftedMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    this.programServiceFilter.valueChanges
    .subscribe(
      service => {
        this.filterValues.programService = service;
        this.programDraftedMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    this.ipadSourceLocationFilter.valueChanges
    .subscribe(
      source => {
        this.filterValues.sourceLocation = source;
        this.iPadMigrationMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    this.ipadProgramNameFilter.valueChanges
    .subscribe(
      name => {
        this.filterValues.programName = name;
        this.iPadMigrationMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    this.ipadProgramServiceFilter.valueChanges
    .subscribe(
      service => {
        this.filterValues.programService = service;
        this.iPadMigrationMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );


    this.serviceLibraryFilter.valueChanges
    .subscribe(
      service => {
        this.filterValues.programService = service;
        this.libraryServiceDraftedMediaSource.filter = JSON.stringify(this.filterValues);
      }
    );

    
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  filterCuratorTypes() {
    let officeId = this.officeFormControl.value;
    if (officeId && this.allCuratorTypes) {
      let matchingCuratorTypes = this.allCuratorTypes.filter(i => i.officeId == officeId);
      if (matchingCuratorTypes.length) {
        this.userCuratorTypes = matchingCuratorTypes[0].items;
        if (this.currentlySelectedCuratorType) {
          if (
            !this.curatorTypeFormControl.value ||
            this.curatorTypeFormControl.value.id != this.currentlySelectedCuratorType.id
          ) {
            this.curatorTypeFormControl.setValue(this.currentlySelectedCuratorType);
          }
        } else {
          this.curatorTypeFormControl.setValue(this.userCuratorTypes[0]);
        }
      }
    }
  }

  expandDraftSuppliers() {
    this.suppliersExpanded = !this.suppliersExpanded;
    this.programsExpanded = false;
    this.serviceLibExpanded = false;
    this.miscellaneousLibExpanded = false;
    this.iPadMigrationExpanded = false;
  }
  expandDraftPrograms() {
    this.programsExpanded = !this.programsExpanded;
    this.suppliersExpanded = false;
    this.serviceLibExpanded = false;
    this.miscellaneousLibExpanded = false;
    this.iPadMigrationExpanded = false;
  }
  expandDraftServiceLib() {
    this.serviceLibExpanded = !this.serviceLibExpanded;
    this.programsExpanded = false;
    this.suppliersExpanded = false;
    this.miscellaneousLibExpanded = false;
    this.iPadMigrationExpanded = false;
  }
  expandDraftMiscellaneousLib() {
    this.miscellaneousLibExpanded = !this.miscellaneousLibExpanded;
    this.programsExpanded = false;
    this.serviceLibExpanded = false;
    this.suppliersExpanded = false;
    this.iPadMigrationExpanded = false;
  }
  expandIPadMigrations() {
    this.iPadMigrationExpanded = !this.iPadMigrationExpanded;
    this.programsExpanded = false;
    this.serviceLibExpanded = false;
    this.miscellaneousLibExpanded = false;
    this.suppliersExpanded = false;
  }

  //Loops through a cluster's media and publishes them all
  publishCluster(cluster) {
    if (cluster.mediaIds) {
      cluster.mediaIds.map(id => {
        this.store$.dispatch(mediaActions.updateMediaStatus({ mediaId: +id, status: 'PBH' }));
      });
    }
    this.cleanDraftedMedia(cluster);
    cluster.isArchived = false;
  }

  archiveCluster(cluster) {
    if (cluster.mediaIds) {
      cluster.mediaIds.map(id => {
        this.store$.dispatch(mediaActions.updateMediaStatus({ mediaId: +id, status: 'ARC' }));
      });
    }
    cluster.isArchived = true;
    //this.cleanDraftedMedia(cluster);
  }

  unarchiveCluster(cluster) {
    if (cluster.mediaIds) {
      cluster.mediaIds.map(id => {
        this.store$.dispatch(mediaActions.updateMediaStatus({ mediaId: +id, status: 'DFT' }));
      });
    }
    cluster.isArchived = false;
  }

  cleanDraftedMedia(cluster) {
    if (cluster.programName) {
      this.programDraftedMedia = this.programDraftedMedia.filter(i => {
        if (
          i.date != cluster.date ||
          i.programName != cluster.programName ||
          i.count != cluster.count ||
          i.sourceLocation != cluster.sourceLocation
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.programDraftedMediaSource.data=this.programDraftedMedia;
    } else if (cluster.libServiceName) {
      this.libraryServiceDraftedMedia = this.libraryServiceDraftedMedia.filter(i => {
        if (
          i.date != cluster.date ||
          i.libServiceName != cluster.libServiceName ||
          i.count != cluster.count ||
          i.sourceLocation != cluster.sourceLocation
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.libraryServiceDraftedMediaSource.data=this.libraryServiceDraftedMedia;
    } else if (cluster.supplierName) {
      this.supplierDraftedMedia = this.supplierDraftedMedia.filter(i => {
        if (
          i.date != cluster.date ||
          i.supplierName != cluster.supplierName ||
          i.count != cluster.count ||
          i.sourceLocation != cluster.sourceLocation
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.supplierDraftedMediaSource.data=this.supplierDraftedMedia;
    } else {
      this.miscDraftedMedia = this.miscDraftedMedia.filter(i => {
        if (i.date != cluster.date || i.count != cluster.count || i.sourceLocation != cluster.sourceLocation) {
          return true;
        } else {
          return false;
        }
      });
      this.miscDraftedMediaSource.data=this.miscDraftedMedia;
    }
  }

  editCluster(cluster, type) {
    let uploadQueue: MediaUploadState[] = [];
    //Tell our store to load the metadata for these so they can be edited.
    if (cluster.mediaIds.length) {
      cluster.mediaIds.map(id => {
        this.store$.dispatch(mediaActions.getMediaMetadataById({ mediaId: +id, getRelatedImages: false }));
        let newUploadState: MediaUploadState = {
          fileName: null,
          id: +id,
          inProgress: false,
        };
        uploadQueue.push(newUploadState);
      });
      //Also create the MediaUploadState for each item
    }
    //Now populate the uploadQueue so the overview page knows what to render.
    this.store$.dispatch(mediaActions.setUploadQueue({ uploadQueue: uploadQueue }));
    
    this._router.navigate(['/drafted-media/overview', type]);
  }
  createProgramFilter(): (data: any, filter: string) => boolean { 
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if (searchTerms.programService && searchTerms.programService.length>0){
        return data.programName.toLowerCase().indexOf(searchTerms.programName) !== -1
        && (data.programServiceName && data.programServiceName.toString().toLowerCase().indexOf(searchTerms.programService) !== -1)
        && data.sourceLocation.toString().toLowerCase().indexOf(searchTerms.sourceLocation) !== -1
      }else{
        return data.programName.toLowerCase().indexOf(searchTerms.programName) !== -1
        && data.sourceLocation.toString().toLowerCase().indexOf(searchTerms.sourceLocation) !== -1
      }
     
    }
    return filterFunction;
  }

  

  createSupplierFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return data.supplierName.toLowerCase().indexOf(searchTerms.supplierName) !== -1
        && data.sourceLocation.toString().toLowerCase().indexOf(searchTerms.sourceLocation) !== -1
     
    }
    return filterFunction;
  }

  createServiceLibraryFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return data.libServiceName.toLowerCase().indexOf(searchTerms.programService) !== -1
        && data.sourceLocation.toString().toLowerCase().indexOf(searchTerms.sourceLocation) !== -1
     
    }
    return filterFunction;
  }
  setPage(){
       this.programDraftedMediaSource.paginator=this.programPaginator;
        this.supplierDraftedMediaSource.paginator=this.supplierPaginator;
          this.libraryServiceDraftedMediaSource.paginator=this.servicePaginator;
  
       
         this.miscDraftedMediaSource.paginator=this.miscPaginator;
   
     
        this.iPadMigrationMediaSource.paginator=this.ipadPaginator;
  }

  isLoading(){
    if (this.supplierDraftedMedia.length===0 &&
      this.programDraftedMedia.length===0 &&
      this.libraryServiceDraftedMedia.length===0 &&
      this.miscDraftedMedia.length===0 &&
      this.iPadMigrationMedia.length===0){
        return true;
      }else{
        false;}
  }
}
