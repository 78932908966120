<!-- <ng-container *ngIf="media">
  <div *ngIf="media.type === 'IMG'"
       class="quick-look-image-wrap">
    <img class="quick-look-image"
         src={{media.links.fileUrl}}
         title={{media.name}}}>
  </div>

  <div *ngIf="media.type === 'VDO'"
       class="quick-look-image-wrap">
    
    <video controls  id="videoPlayer">
      <source src="{{media.links.fileUrl}}" type="video/mp4" />
      Browser not supported
  </video>
  </div>

  <div *ngIf="media.type === 'ADO'"
       class="quick-look-audio-wrap">
    <img class="quick-look-image"
         src={{media.links.thumbnailUrl}}
         title={{media.name}}}>
    <button class="button-playback"
            (click)="playAudio()">
      <i *ngIf="!isPlaying"
         class="material-icons">play_arrow</i>
      <i *ngIf="isPlaying"
         class="material-icons">pause</i>
    </button>
  </div>

  <div class="gallery-actions-wrap">
    <div class="gallery-actions-group">
      <button class="button-border-white"
              (click)="openDetailedView()">Detailed View</button>
    </div>

    <div class="gallery-actions-group">
      <button class="gallery-action gallery-action-quick-look"
              (click)="likeImage()">
        <span class="favorite-wrap"
              *ngIf="!isLiked">
          <i class="material-icons">favorite_border</i>
          Like
        </span>

        <span class="favorite-wrap"
              *ngIf="isLiked">
          <i class="material-icons">favorite</i>
          Liked
        </span>
      </button>

      <button class="gallery-action gallery-action-quick-look"
              (click)="addToPocket()">
        <i class="material-icons">collections_bookmark</i>
        <ng-container *ngIf="!isInPocket">Add to Pocket</ng-container>
        <ng-container *ngIf="isInPocket">In Pocket</ng-container>
      </button>
    </div>

    <div class="gallery-actions-group">
      <app-set-album-select [albums]="albums"
                            [selectedAlbums]="selectedAlbums"
                            (selectedAlbumsEvent)="addMediaToSelectedAlbums($event)"
                            (createAlbumEvent)="createAlbum($event)"></app-set-album-select>
      <app-set-gallery-select [galleries]="galleries"
                              [selectedGalleries]="selectedGalleries"
                              [canAddMediaToGallery]="canAddMediaToGallery"
                              [userOffices]="userOffices"
                              (selectedGalleriesEvent)="addMediaToSelectedGalleries($event)"
                              (createGalleryEvent)="createGallery($event)"></app-set-gallery-select>
    </div>
  </div>
</ng-container> -->
<ng-container *ngIf="!media">
  <mat-spinner></mat-spinner>
</ng-container>
<swiper *ngIf="galleryMedia.length>0" (slideChange)="onSlideChange($event)" [pagination]="{type: 'fraction'}" [initialSlide]="slideIndex" [allowTouchMove]="true" [slidesPerView]="1" [navigation]="true" [freeMode]="false" >
  <ng-template swiperSlide *ngFor="let media of galleryMedia; index as i">
    <div class="swiper-slide-container">
      <ng-container *ngIf="media">
        <div *ngIf="media.type === 'IMG'"
             class="quick-look-image-wrap">
          <img class="quick-look-image"
               src={{media.links.fileUrl}}
               title={{media.name}}}>
        </div>
      
        <div *ngIf="media.type === 'VDO'"
             class="quick-look-image-wrap">
          
          <video controls  id="videoPlayer">
            <source src="{{media.links.fileUrl}}" type="video/mp4" />
            Browser not supported
        </video>
        </div>
      
        <div *ngIf="media.type === 'ADO'"
             class="quick-look-audio-wrap">
          <img class="quick-look-image"
               src={{media.links.thumbnailUrl}}
               title={{media.name}}}>
          <button class="button-playback"
                  (click)="playAudio()">
            <i *ngIf="!isPlaying"
               class="material-icons">play_arrow</i>
            <i *ngIf="isPlaying"
               class="material-icons">pause</i>
          </button>
        </div>
      
        <div class="gallery-actions-wrap">
          <div class="gallery-actions-group">
            <button class="button-border-white"
                    (click)="openDetailedView()">Detailed View</button>
          </div>
      
          <div class="gallery-actions-group">
            <button class="gallery-action gallery-action-quick-look"
                    (click)="likeImage()">
              <span class="favorite-wrap"
                    *ngIf="!isLiked">
                <i class="material-icons">favorite_border</i>
                Like
              </span>
      
              <span class="favorite-wrap"
                    *ngIf="isLiked">
                <i class="material-icons">favorite</i>
                Liked
              </span>
            </button>
      
            <button class="gallery-action gallery-action-quick-look"
                    (click)="addToPocket()">
              <i class="material-icons">collections_bookmark</i>
              <ng-container *ngIf="!isInPocket">Add to Pocket</ng-container>
              <ng-container *ngIf="isInPocket">In Pocket</ng-container>
            </button>
          </div>
      
          <div class="gallery-actions-group">
            <div class="white">

           
            <app-set-album-select [albums]="albums"
                                  [classValue]="white"
                                  [selectedAlbums]="selectedAlbums"
                                  (selectedAlbumsEvent)="addMediaToSelectedAlbums($event)"
                                  (createAlbumEvent)="createAlbum($event)"></app-set-album-select>
            <app-set-gallery-select [galleries]="galleries"
                                    [selectedGalleries]="selectedGalleries"
                                    [canAddMediaToGallery]="canAddMediaToGallery"
                                    [userOffices]="userOffices"
                                    [classValue]="white"
                                    (selectedGalleriesEvent)="addMediaToSelectedGalleries($event)"
                                    (createGalleryEvent)="createGallery($event)"></app-set-gallery-select>
                                  </div>
                                  </div>
        </div>
      </ng-container>
    </div>
   
  </ng-template>
</swiper>