export const Permissions = {
    CanViewDraftMedia: 'CanViewDraftMedia',
    CanViewArchiveMedia: 'CanViewArchiveMedia',
    CanFilterMediaByUser: 'CanFilterMediaByUser',
    CanFilterMediaByUploadDate: 'CanFilterMediaByUploadDate',
    CanViewMediaNameInDetailsPage: 'CanViewMediaNameInDetailsPage',
    CanViewAdminArea: 'CanViewAdminArea',
    CanAddMedia: 'CanAddMedia',
    CanViewDraftMediaPage: 'CanViewDraftMediaPage',
    CanAddMediaToGallery: 'CanAddMediaToGallery',
    CanViewAnalyticsOnMediaPage: 'CanViewAnalyticsOnMediaPage',
    CanViewOptionsMenuOnMediaPage: 'CanViewOptionsMenuOnMediaPage',
    CanAddTagsToMedia: 'CanAddTagsToMedia',
    CanViewDashBoardItem: 'CanViewDashBoardItem'
};
