<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb">
        Galleries
      </li>
    </ul>
  </div>
</div>
<app-galleries [galleries]="galleries"
               [offices]="officemodels"
               [userOfficeId]="userOfficeId"
               [showStatusFilter]="false"
               (selectGallery)="onGallerySelect($event)"></app-galleries>