<div class="pocket">
    <div class="pocket-header">
        <p class="pocket-header-text">
            My Pocket
        </p>


        <div class="pocket-header-actions">
            <!-- <mat-icon class="pocket-header-action" matTooltip="Remove Selected from Pocket">delete</mat-icon> -->
            <mat-icon class="pocket-header-action"
                      matTooltip="Refresh Pocket">refresh</mat-icon>
            <mat-icon class="pocket-header-action"
                      matTooltip="Close Pocket"
                      (click)="closePocket()">close</mat-icon>
        </div>
    </div>
    <div class="pocket-empty"
         *ngIf="pocketMedia.length === 0">
        <i class="material-icons pocket-empty-icon">collections_bookmark</i>
        <p class="pocket-empty-text">Your pocket is empty. <br> Search the Media Library to add items here</p>
    </div>
    <a class="pocket-clear"
       *ngIf="pocketMedia.length > 0"
       (click)="clearPocket()">CLEAR POCKET</a>
    <div class="pocket-body">
        <div class="pocket-image-wrap"
             *ngFor="let media of pocketMedia">
            <!-- ;let Index = index;trackBy:trackByIndex;"> -->
            <img [id]="media.mediaId"
                 src="{{media.links.thumbnailUrl}}"
                 class="pocket-image"
                 title="{{media.name}}"
                 (mousedown)="highlightForDrag(media)">
            <div class="pocket-image-overlay"></div>
            <mat-icon class="pocket-image-delete"
                      matTooltip="Remove from Pocket"
                      (click)="onRemoveFileClicked(media.mediaId)">
                delete
            </mat-icon>
            <mat-icon class="pocket-image-copy-url"
                      matTooltip="Copy to Swap Image"
                      (click)="copyImageUrl(media.links.fileUrl)">
                link
            </mat-icon>
            <mat-icon class="pocket-image-copy"
                      matTooltip="Copy To Clipboard"
                      (click)="copyToClipboard(media)">
                filter_none
            </mat-icon>
        </div>
    </div>
</div>