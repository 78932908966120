import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AllowedUploadType, AllowedUploadTypesModel } from '../models/allowed-upload-types.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  constructor(private http: HttpClient) { }

  getAllowedUploadTypes(): Observable<AllowedUploadTypesModel[]> {
    return this.http.get<AllowedUploadTypesModel[]>(environment.mediaApiEndpoint +
          'Admin/media/allowedTypes');
    }

  getAllowedUploadTypesByMedia(mediaTypeId: number): Observable<AllowedUploadTypesModel[]> {
    return this.http.get<AllowedUploadTypesModel[]>(environment.mediaApiEndpoint +
          'Admin/media/allowedTypes?mediaTypeId=' + mediaTypeId);
    }

  deleteAllowedUploadTypes(mediaTypeId: number, extension: string): Observable<{}> {
    return this.http.delete(environment.mediaApiEndpoint +
          'Admin/media/allowedTypes/' + mediaTypeId + '/' + extension, httpOptions);
    }

  postAllowedUploadTypes(typeModel: AllowedUploadTypesModel): Observable<{}> {
    let typeToAdd = new AllowedUploadType();
    typeToAdd.fileExtension = typeModel.fileExtension;
    typeToAdd.maxSizeInKb = typeModel.maxSizeInKb;
    typeToAdd.mediaTypeId = typeModel.mediaTypeId;
    console.log('POsting ..' + typeToAdd);
    return this.http.post<AllowedUploadType>(environment.mediaApiEndpoint +
          'Admin/media/allowedTypes', typeToAdd, httpOptions);
    }
}
