export const environment = {
  production: true,
  hmr: false,
  mediaApiEndpoint: 'https://dmcmediaservice-prod.azurewebsites.net/api/v1.0/',
  mediaApiEndpoint_V2: 'https://dmcmediaservice-prod.azurewebsites.net/api/v2.0/',
  adminApiEndpoint: 'https://dmcmediaservice-prod.azurewebsites.net/api/v1.0/',
  dmcUrl: 'https://www.dmcpro.net',
  dmcApiurl:'https://www.dmcpro.net/dmc-api',
  redirectUri:'https://ml.dmcpro.net/login/',
  dashboardSeeMoreSize: 75
};