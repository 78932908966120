import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Permissions } from '../../models/permissions';
import { doesPermissionExist } from '../../ngrx/selectors/user.selector';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public login: string;
  loggedIn=false;
  public isPocketOpen: boolean = false;
  canViewAdmin$: Observable<boolean> = of(true);
  canAddMedia$: Observable<boolean> = of(true);
  canViewDraft$: Observable<boolean> = of(true);
  draftedMediaExists: boolean = false;

  canViewAdmin: boolean;
  canViewDraft: boolean;
  canAddMedia: boolean;
_refresh:boolean;
  private unsubscribe$ = new Subject<void>();

  @Input()
  get refreshHeader(){
    return this._refresh;
  }
  set refreshHeader(value){
    this._refresh=value;
   if (value){
    this.refresh();
   }
  }
  private user$;

  constructor(
    private store$: Store<any>
  ) {
  }
  ngOnInit() {
    this.refresh();
  }

  pocketToggle() {
    this.isPocketOpen = !this.isPocketOpen;
  }

    ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refresh(){
    const loggedIn$ = this.store$.pipe(select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false));
    loggedIn$.subscribe(res => {
      if (res.login){
        this.login = res.user.userName;
        this.loggedIn = true;
        this.canViewAdmin$ = this.store$.pipe(select(doesPermissionExist,
           { permission: Permissions.CanViewAdminArea }));
        this.canViewAdmin$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.canViewAdmin = res);
  
        this.canAddMedia$ = this.store$.pipe(select(doesPermissionExist,
           { permission: Permissions.CanAddMedia }));
        this.canAddMedia$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.canAddMedia = res);
  
        this.canViewDraft$ = this.store$.pipe(select(doesPermissionExist, 
          { permission: Permissions.CanViewDraftMediaPage }));
        this.canViewDraft$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.canViewDraft = res);
      }
     
    });

    //Also grab drafted media so we can show an alert to let the user know drafted media exists.
    this.store$.pipe(
      select(state => state.media))
    .subscribe(res => {
      if(res.supplierDraftedMedia.length || res.programDraftedMedia.length || res.libraryServiceDraftedMedia.length || res.miscDraftedMedia.length) {
        this.draftedMediaExists = true;
      } else {
        this.draftedMediaExists = false;
      }
    });
  }
}
