import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TagCategoryModel, TagItemModel, TagItems } from '../../../models/tag-category.model';
import { TagsService } from '../../../services/tags.service';

@Component({
	selector: 'app-admin-tags-management',
	templateUrl: './admin-tags-management.component.html',
	styleUrls: ['./admin-tags-management.component.scss']
})
export class AdminTagsManagementComponent implements OnInit {

	public isAddTagCategoryOpen: boolean = false;
	public addEditCaption = 'Add New Tag Category';
	public addEditButtonCaption = 'ADD';

	public activeTagCategories: TagCategoryModel[] = [];
	public inActiveTagCatergories: TagCategoryModel[] = [];

	public categoryFormControl: FormControl;
	public searchCategoryFormControl: FormControl;
	public tagCtrl: FormControl;
	public togglePanel: boolean[] = [];
	public editTagCategory: TagCategoryModel;
	public allCategories: TagCategoryModel[];
	addEditTagButtonCaption = 'ADD TAG';
	editTagItem: TagItems;

	errorMessage='';
	categories;
	newTagValue: string;
	addingTag: boolean = false;

	constructor(private tagsService: TagsService, ) { }

	ngOnInit() {
		this.categoryFormControl = new FormControl();
		this.searchCategoryFormControl = new FormControl();
		this.tagCtrl = new FormControl();
		this.editTagItem = null;
		this.newTagValue = null;
		this.searchCategoryFormControl.valueChanges.subscribe(value => {
			this.errorMessage='';
			var categories=[];
			
			categories=JSON.parse(JSON.stringify(this.categories))
			if (value && value.length>0) {
				//this.activeTagCategories = this.allCategories.filter(c => c.name !== null && c.name.toUpperCase().match(value.toUpperCase()) && c.name.toUpperCase().match(value.toUpperCase())[0] && c.activeFlag);
				//this.inActiveTagCatergories = this.allCategories.filter(c => c.name !== null && c.name.toUpperCase().match(value.toUpperCase()) && c.name.toUpperCase().match(value.toUpperCase())[0] && c.activeFlag === false);
				//this.activeTagCategories=	this.allCategories.filter(c=>{c.tags.filter(t=>{ t.tag !== null && t.tag.toUpperCase().match(value.toUpperCase()) && t.tag.toUpperCase().match(value.toUpperCase())[0] && c.activeFlag}).length>0})
			
				this.activeTagCategories = categories.filter(c=>c.activeFlag);
				this.activeTagCategories=this.activeTagCategories.map(function(ComparisonResult) {
					ComparisonResult.tags = ComparisonResult.tags.filter(t => ( t.tag !== null && t.tag.toUpperCase().match(value.toUpperCase()) && t.tag.toUpperCase().match(value.toUpperCase())[0]));
					return ComparisonResult;
				  }).filter(t=>t.tags.length>0);

				  this.inActiveTagCatergories = categories.filter(c=>!c.activeFlag);
				  this.inActiveTagCatergories=  this.inActiveTagCatergories.map(function(ComparisonResult) {
					ComparisonResult.tags = ComparisonResult.tags.filter(t =>( t.tag !== null && t.tag.toUpperCase().match(value.toUpperCase()) && t.tag.toUpperCase().match(value.toUpperCase())[0]));
					return ComparisonResult;
				  }).filter(t=>t.tags.length>0);
			}
			else {
				this.activeTagCategories = categories.filter(c => c.activeFlag);
				this.inActiveTagCatergories = categories.filter(c => c.activeFlag === false);
			}
		})
		
		this.hydrateTagCategories();
	}

	hydrateTagCategories() {
		this.tagsService.getTagCategories('')
			.subscribe(res => {
				res.map(function(sortResult) {
					sortResult.tags = sortResult.tags.sort((a,b)=>(a.activeFlag ? 0:1)-(b.activeFlag ? 0:1))
					return sortResult;
				  });
				this.categories=res;
				this.allCategories = res;
				this.activeTagCategories = res.filter(t => t.activeFlag);
				this.inActiveTagCatergories = res.filter(c => c.activeFlag === false);
				this.isAddTagCategoryOpen = false;
				this.activeTagCategories.forEach(c => this.togglePanel.push(false));
				if (this.addingTag) {
					this.addingTag = false;
					this.addEditTagButtonCaption = 'Add TAG';
				}
			})
	}

	onAddClicked() {
		if (this.editTagCategory) {
			this.editTagCategory.name = this.categoryFormControl.value;
			this.editTagCategory.activeFlag = true;
			this.tagsService.UpdateTagCategory(this.editTagCategory.id, this.editTagCategory).subscribe(d => {
				this.hydrateTagCategories();
			});
		}
		else {
			let newTag: TagCategoryModel = {
				activeFlag: true,
				name: this.categoryFormControl.value,
			}
			this.tagsService.AddTagCategory(newTag).subscribe(res => {
				this.hydrateTagCategories();
			})
		}

	}
	trackByIndex(index: number, obj: any): any {
		return index;
	}
	onAddTagClicked(index: number) {
		this.errorMessage='';
		var value=this.tagCtrl.value.toUpperCase();
		var test=[];
		test=JSON.parse(JSON.stringify(this.categories))
		var tags =test.filter(c=>c.tags.length>0).map(function(ComparisonResult) {
			ComparisonResult.tags = ComparisonResult.tags.filter(t =>  t.tag.toUpperCase()==value);
			return ComparisonResult;
		  }).filter(c=>c.tags.length>0);
		  
		  if (tags.length>0){
			this.errorMessage=`Duplicate tag found in the ${tags[0].name} category. Tags can be used anywhere regardless of their category.`
		  }else{
			if (this.editTagItem) {
				const tagToEdit = new TagItemModel();
				tagToEdit.id = this.editTagItem.id;
				tagToEdit.tagCategory = this.editTagItem.tagCategoryId;
				tagToEdit.tagName = this.tagCtrl.value;
				tagToEdit.activeFlag = true;
				this.tagsService.UpdateTag(this.editTagItem.id, tagToEdit).subscribe(a => {
	
					this.editTagItem = null;
					this.addEditTagButtonCaption = 'Add TAG';
					this.newTagValue = null;
					this.hydrateTagCategories();
				});
			}
			else {
			
				
					const cat = this.activeTagCategories[index];
					if (cat !== null && cat !== undefined && this.tagCtrl.value !== null && this.tagCtrl.value !== '') {
						const tagToAdd = new TagItemModel();
						tagToAdd.tagCategory = cat.id;
						tagToAdd.tagName = this.tagCtrl.value;
						tagToAdd.activeFlag = true;
						this.addingTag = true;
						this.addEditTagButtonCaption = 'Adding Tag...';
						this.tagsService.AddTag(tagToAdd).subscribe(a => {
							this.hydrateTagCategories();
						});
					}
				  }
				
			}
		  
		
	}
	onEditTagClicked(index: number, tagIndex: number, tagValue: string) {
		const cat = this.activeTagCategories[index];
		if (cat !== null && cat !== undefined) {
			const tag = cat.tags[tagIndex];
			if (tag !== null && tag !== undefined) {
				this.editTagItem = tag;
				this.tagCtrl.setValue(this.editTagItem.tag);
				this.newTagValue = this.editTagItem.tag;
				this.addEditTagButtonCaption = 'SAVE TAG';
			}
		}
	}
	onArchiveTagClicked(index: number, tagIndex: number) {
		const cat = this.activeTagCategories[index];
		if (cat !== null && cat !== undefined) {
			const tag = cat.tags[tagIndex];
			if (tag !== null && tag !== undefined) {
				const tagToArchive = new TagItemModel();
				tagToArchive.id = tag.id;
				tagToArchive.tagCategory = cat.id;
				tagToArchive.tagName = tag.tag;
				tagToArchive.activeFlag = !tag.activeFlag;
				this.tagsService.UpdateTag(tag.id, tagToArchive).subscribe(a => {
					this.hydrateTagCategories();
				});
			}
		}
	}

	expandTagCategory(index) {
		this.togglePanel[index] = !this.togglePanel[index];
	}

	onEditCategoryClicked(index, catName) {
		this.isAddTagCategoryOpen = true;

		this.editTagCategory = this.activeTagCategories[index];
		this.addEditCaption = 'Edit Tag Category ' + this.editTagCategory.name;
		this.addEditButtonCaption = 'SAVE';
		this.categoryFormControl.setValue(this.editTagCategory.name);

	}

	onArchiveCategoryClicked(index) {
		const cat = this.activeTagCategories[index];
		if (cat !== null && cat !== undefined) {
			cat.activeFlag = false;
			this.tagsService.UpdateTagCategory(cat.id, cat).subscribe(d => {
				this.hydrateTagCategories();
			});
		}
	}
	onActivateCategoryClicked(index: number) {
		const cat = this.inActiveTagCatergories[index];
		if (cat !== null && cat !== undefined) {
			cat.activeFlag = true;
			this.tagsService.UpdateTagCategory(cat.id, cat).subscribe(d => {
				this.hydrateTagCategories();
				this.expandTagCategory(index);
			});
		}

	}

}
