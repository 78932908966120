import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { tagModel } from '../../../../models/tagModel';
import { TagsService } from '../../../../services/tags.service';

@Component({
	selector: 'app-tag-type-ahead',
	templateUrl: './tag-type-ahead.component.html',
	styleUrls: ['./tag-type-ahead.component.scss']
})
export class TagTypeAheadComponent implements OnInit {
	@Input() tags: tagModel[] = [];
	@Input() canAddTags: boolean = false;

	@Output() tagsChanged = new EventEmitter<tagModel[]>();

	public filteredTagOptions$: Observable<tagModel[]>;
	public taggingCtrl: FormControl;

	@ViewChild('tagsearchBox') tagSearchBox: ElementRef;

	constructor(
		private _tagService: TagsService) { }

	ngOnInit() {
		this.initFormControl();
	}

	initFormControl() {
		this.taggingCtrl = new FormControl();
		this.filteredTagOptions$ = this.taggingCtrl.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			filter(s => typeof s === 'string'),
			switchMap(s => {
				//return call for tags here
				return this._tagService.gettagSearchResultByName(s);
			}),
			map(res => {
				if (res) {
					return res.slice(0, 50);
				}
				else {
					return [];
				}
			}))
	}

	addTag(value: tagModel) {
		this.tagSearchBox.nativeElement.value = null;
		this.taggingCtrl.setValue('');
		this.tags.push(value);
		this.tagsChanged.emit(this.tags);
	}
	removeTag(value: tagModel) {
		this.tags = this.tags.filter(tag => tag.id != value.id);
		this.tagsChanged.emit(this.tags);
	}

}
