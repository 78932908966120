<div class="page-header-row page-header-row-right">
  <div class="page-context-wrap">
    <div class="page-context">
      <mat-select *ngIf="showStatusFilter === true"
                  class="page-context-filter filter"
                  placeholder="Status"
                  [(ngModel)]="selectedGalleryStatus"
                  name="statusModel"
                  [formControl]="statusFormControl">
        <mat-option *ngFor="let status of statusModel"
                    [value]="status.id">
          {{status.statusName}}
        </mat-option>
      </mat-select>
      <mat-select class="page-context-filter filter"
                  placeholder="Office"
                  [(ngModel)]="selectedOfficeValue"
                  name="officeModel"
                  [formControl]="officeFormControl">
        <mat-option *ngFor="let officeModel of offices"
                    [value]="officeModel.officeId">
          {{officeModel.officeName}}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>
<div class="media-grid-wrap">
  <!-- loop through user's galleries in folder-item-wrap class -->
  <div *ngFor="let gallery of filteredGalleries"
       class="folder-item-wrap">
    <img class="folder-item-image"
         [src]="gallery.coverImageUrl">

    <div class="folder-hover">
      <button class="button-border-white folder-hover-button"
              (click)="viewGallery(gallery);">
        View Gallery
      </button>
    </div>

    <div class="folder-meta-wrap">
      <div class="folder-meta-info">
        <div class="folder-meta-title">{{gallery.name}}</div>
        <div class="folder-meta-created">{{gallery.createdByUserName}}</div>
      </div>

      <div class="folder-meta-count">
        {{gallery.totalMediaCount}}
      </div>
    </div>
  </div>

</div>