<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb">Admin Workspace</li>
    </ul>
  </div>
</div>

<div class="admin-workspace-wrap">
  <div class="admin-workspace-nav">
    <div class="workspace-nav-item-wrap" (click)="mediaFormats()" [ngClass]="{'workspace-nav-item-wrap-active': showFormats == true}">
      <p class="workspace-nav-item">Manage Media Formats</p>
    </div>

    <div class="workspace-nav-item-wrap" (click)="tagManager()" [ngClass]="{'workspace-nav-item-wrap-active': showTags == true}">
      <p class="workspace-nav-item">Manage Tags</p>
    </div>
  </div>

  <div class="admin-workspace">
  	<app-admin-tags-management *ngIf="showTags"></app-admin-tags-management>
    <app-admin-media-formats-management *ngIf="showFormats"></app-admin-media-formats-management>

  </div>
</div>

<scroll-to-top></scroll-to-top>
