import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

// Effects
import { EffectsModule } from '@ngrx/effects';
// Store Imports
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
//import { ToastUiImageEditorModule } from 'ngx-tui-image-editor';
import { SharedModule } from '../app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BytesPipe } from './bytes.pipe';
import { AdminMediaFormatsManagementComponent } from './components/admin/admin-media-formats-management/admin-media-formats-management.component';
import { AdminTagsManagementComponent } from './components/admin/admin-tags-management/admin-tags-management.component';
import { AlbumsComponent } from './components/albums/albums.component';
import { CollectionTileComponent } from './components/collection-tile/collection-tile.component';
import { DashboardSectionComponent } from './components/dashboard/dashboard-section/dashboard-section.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { ContentCreateDialogComponent } from './components/dialogs/content-create-dialog/content-create-dialog.component';
import { ContentDeleteDialogComponent } from './components/dialogs/content-delete-dialog/content-delete-dialog.component';
import { AzureTagChipListComponent } from './components/forms/chip-list/azure-tag-chip-list/azure-tag-chip-list.component';
import { ServiceSelectComponent } from './components/forms/selects/service-select/service-select.component';
import { SetAlbumSelectComponent } from './components/forms/set-album-select/set-album-select.component';
import { SetGallerySelectComponent } from './components/forms/set-gallery-select/set-gallery-select.component';
import { AccountExecutiveTypeAheadComponent } from './components/forms/type-aheads/account-executive-type-ahead/account-executive-type-ahead.component';
import { ClientTypeAheadComponent } from './components/forms/type-aheads/client-type-ahead/client-type-ahead.component';
import { ProgramTypeAheadComponent } from './components/forms/type-aheads/program-type-ahead/program-type-ahead.component';
import { ServiceTypeAheadComponent } from './components/forms/type-aheads/service-type-ahead/service-type-ahead.component';
import { SupplierTagTypeAheadComponent } from './components/forms/type-aheads/supplier-tag-type-ahead/supplier-tag-type-ahead.component';
import { SupplierTypeAheadComponent } from './components/forms/type-aheads/supplier-type-ahead/supplier-type-ahead.component';
import { TagTypeAheadComponent } from './components/forms/type-aheads/tag-type-ahead/tag-type-ahead.component';
import { GalleriesComponent } from './components/galleries/galleries/galleries.component';
// Dialogs
import { EditorModule } from '@progress/kendo-angular-editor';
import { MediaEditDialogComponent } from './components/media/media-edit-dialog/media-edit-dialog.component';
import { MediaEditUploadContextDialogComponent } from './components/media/media-edit-upload-context-dialog/media-edit-upload-context-dialog.component';
import { MediaGroupComponent } from './components/media/media-group/media-group.component';
import { MediaItemComponent } from './components/media/media-item/media-item.component';
import { MediaLightboxDialogComponent } from './components/media/media-lightbox-dialog/media-lightbox-dialog.component';
import { MediaUploadContextFormComponent } from './components/media/media-upload-context-form/media-upload-context-form.component';
import { MediaUploadItemComponent } from './components/media/media-upload-item/media-upload-item.component';
import { ScrollToTopComponent } from './components/utilities/scroll-to-top/scroll-to-top.component';
import { HeaderComponent } from './containers/header/header.component';
import { NavbarComponent } from './containers/navbar/navbar.component';
import { PocketContainerComponent } from './containers/pocket-container/pocket-container.component';
import { QuickLookDialogComponent } from './containers/quick-look-dialog/quick-look-dialog.component';
import { MLDropzoneDirective } from './directives/ml-dropzone-directive';
import { ErrorInterceptor } from './http-Interceptor/error-interceptor';
import { AlbumEffects } from './ngrx/effects/albums.effects';
import { GalleryEffects } from './ngrx/effects/galleries.effects';
import { MediaEffects } from './ngrx/effects/media.effects';
import { OfficeEffects } from './ngrx/effects/offices.effects';
import { PocketEffects } from './ngrx/effects/pocket.effects';
import { UserEffects } from './ngrx/effects/user.effects';
import { reducers } from './ngrx/reducers/index';
import { FirstwordPipe } from './services/firstword.pipe';
import { UserService } from './services/user.service';
import { MatOptionSelectAllModule } from './shared/mat-option-select-all/mat-option-select-all.module';
import { AdminViewComponent } from './views/admin-view/admin-view.component';
import { AlbumViewComponent } from './views/album-view/album-view.component';
import { AllGalleriesViewComponent } from './views/all-galleries-view/all-galleries-view.component';
import { DashboardViewComponent } from './views/dashboard-view/dashboard-view.component';
import { DraftedMediaOverviewViewComponent } from './views/drafted-media-overview-view/drafted-media-overview-view.component';
import { DraftedMediaViewComponent } from './views/drafted-media-view/drafted-media-view.component';
import { GalleryViewComponent } from './views/gallery-view/gallery-view.component';
import { MediaDetailViewComponent } from './views/media-detail-view/media-detail-view.component';
import { MyMediaViewComponent } from './views/my-media-view/my-media-view.component';
import { SearchViewComponent } from './views/search-view/search-view.component';
import { UploadViewComponent } from './views/upload-view/upload-view.component';

import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';
import { ShareAlbumDialogComponent } from './components/dialogs/share-album-dialog/share-album-dialog.component';
import { AuthInterceptor } from './http-Interceptor/auth-interceptor';
import { checkUserLogin } from './ngrx/actions/user.actions';
import { LoginViewComponent } from './views/login/login.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

@NgModule({
  imports: [
    BrowserModule,
    InfiniteScrollModule,
    FormsModule,
    MatTableModule,
    EditorModule,
    //ToastUiImageEditorModule,
    
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatMenuModule,
    MatTooltipModule,
    SharedModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    SwiperModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    EffectsModule.forRoot([AlbumEffects, UserEffects, PocketEffects, OfficeEffects, MediaEffects, GalleryEffects]),
    MatOptionSelectAllModule,
    MsalModule
  ],
  providers: [
    UserService, httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<any>) => {
        return () => {
          // This little provider kicks off our login check on app load
          store.dispatch(checkUserLogin());
        };
      },
      multi: true,
      deps: [Store],
    },    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true, // Add this line when using multiple interceptors.
    // },
  ],
  declarations: [
    AppComponent,
    ContentDeleteDialogComponent,
    PocketContainerComponent,
    BytesPipe,
    HeaderComponent,
    NavbarComponent,
    FirstwordPipe,
    MLDropzoneDirective,
    ContentCreateDialogComponent,
    ScrollToTopComponent,
    MediaGroupComponent,
    MediaItemComponent,
    MediaDetailViewComponent,
    AlbumsComponent,
    AlbumViewComponent,
    MediaUploadContextFormComponent,
    MediaEditUploadContextDialogComponent,
    MyMediaViewComponent,
    GalleriesComponent,
    GalleryViewComponent,
    AllGalleriesViewComponent,
    DashboardViewComponent,
    DashboardSectionComponent,
    CollectionTileComponent,
    SupplierTypeAheadComponent,
    ProgramTypeAheadComponent,
    ServiceSelectComponent,
    UploadViewComponent,
    TagTypeAheadComponent,
    SupplierTagTypeAheadComponent,
    SearchViewComponent,
    ClientTypeAheadComponent,
    AccountExecutiveTypeAheadComponent,
    ServiceTypeAheadComponent,
    MediaUploadItemComponent,
    QuickLookDialogComponent,
    SetGallerySelectComponent,
    SetAlbumSelectComponent,
    AzureTagChipListComponent,
    MediaUploadItemComponent,
    MediaEditDialogComponent,
    DraftedMediaViewComponent,
    DraftedMediaOverviewViewComponent,
    AdminViewComponent,
    AdminTagsManagementComponent,
    AdminMediaFormatsManagementComponent,
    MediaLightboxDialogComponent,
    ConfirmDialogComponent,
    LoginViewComponent,
    ShareAlbumDialogComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ContentDeleteDialogComponent,
    ContentCreateDialogComponent,
    MediaEditUploadContextDialogComponent,
    MediaEditDialogComponent,
    QuickLookDialogComponent,
    MediaLightboxDialogComponent,
  ],
  bootstrap: [AppComponent,MsalRedirectComponent],
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    const replacer = (key, value) => (typeof value === 'function' ? value.name : value);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '0f1d4b77-455a-4286-8a63-4adbe3715486',
      authority: 'https://login.microsoftonline.com/94bf53b4-9f4b-40bc-916a-fa8901bbba70',
      redirectUri: environment.redirectUri,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },

  });
 }

 // provides authRequest configuration
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}
