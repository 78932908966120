import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { ContentCreateDialogComponent } from '../../components/dialogs/content-create-dialog/content-create-dialog.component';
import { Album } from '../../models/album.model';
import { Gallery } from '../../models/gallery.model';
import { Media } from '../../models/media.model';
import { Permissions } from '../../models/permissions';
import { createUserAlbum, getUserAlbums } from '../../ngrx/actions/albums.actions';
import { createUserGallery, getUserGalleries } from '../../ngrx/actions/galleries.actions';
import { selectUserGalleries } from '../../ngrx/selectors/galleries.selector';
import { selectPocketMedia } from '../../ngrx/selectors/media.selector';
import { selectUserCurrentOffice, selectUserOffices } from '../../ngrx/selectors/offices.selector';
import { doesPermissionExist, selectUser } from '../../ngrx/selectors/user.selector';
import { AlbumCreateCommand, AlbumService } from '../../services/album.service';
import { GalleryCreateCommand, GalleryResult } from '../../services/gallery.service';
import { selectUserAlbums } from './../../ngrx/selectors/albums.selector';

@Component({
  selector: 'app-users-content',
  templateUrl: './my-media-view.component.html',
  styleUrls: ['./my-media-view.component.scss']
})
export class MyMediaViewComponent implements OnInit {
  showAlbums = true;
  showGalleries = false;
  contentType: any;
  canViewDraft = true;
  loggedUserId: number;
  albums: Album[];
  galleries: GalleryResult[];
  offices;
  userOfficeId: number;

  albums$: Observable<Album[]>;
  galleries$: Observable<Gallery[]>;
  offices$: Observable<any[]>;
  currentOffice$: Observable<number>;
  user$: Observable<any>;
  pocketMedia$: Observable<Media[]>;
  pocketMedia: Media[];
  unsubscribe$ = new Subject<void>();
  constructor(
    public albumDialog: MatDialog,
    public galleryDialog: MatDialog,
    private albumService: AlbumService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store<any>
  ) { }

  ngOnInit() {
    const loggedIn$ = this.store$.pipe(select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false || res.user=== null || res.user.userLoginId === null || res.user.userLoginId===0));
    loggedIn$.subscribe(reslogin => {
      this.user$ = this.store$.pipe(select(selectUser));

    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.loggedUserId = res.userLoginId;
    })
    this.store$.dispatch(getUserAlbums());
    this.albums$ = this.store$.pipe(select(selectUserAlbums));

    this.albums$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.albums = res
    });
    this.store$.dispatch(getUserGalleries());
    this.galleries$ = this.store$.pipe(select(selectUserGalleries, { createdByUserId: this.loggedUserId }));

    this.offices$ = this.store$.pipe(select(selectUserOffices));
    this.offices$.pipe(takeUntil(this.unsubscribe$)).subscribe(o => this.offices = o)
    this.currentOffice$ = this.store$.pipe(select(selectUserCurrentOffice));

    this.store$.pipe(takeUntil(this.unsubscribe$), select(doesPermissionExist, { permission: Permissions.CanViewDraftMedia })).subscribe(res => {
      this.canViewDraft = res;
    });

    this.store$.pipe(takeUntil(this.unsubscribe$), select(selectPocketMedia)).subscribe(res => {
      this.pocketMedia = res

    });
    });
    

  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  newAlbumDialog(): void {
    const albumDialogRef = this.albumDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: true,
        pocket: this.pocketMedia,
        albums: this.albums
      }
    });

    albumDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {

      if (result) {
        let request = new AlbumCreateCommand();
        request.Name = result.Name;
        request.Description = result.Description;
        request.CoverImage = result.CoverImage;
        request.UserId = this.loggedUserId;
        request.MediaId = +result.MediaId;
        request.MediaIds =[+result.MediaId];
        this.store$.dispatch(createUserAlbum({ newAlbumDto: request }));
        this.store$.dispatch(getUserAlbums());
        
      } 
    });
  }

  newGalleryDialog(): void {

    const galleryDialogRef = this.galleryDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: false,
        pocket: this.pocketMedia,
        offices: this.offices
      }
    });

    galleryDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result) {
        let gallery = new GalleryCreateCommand();
        gallery.Name = result.Name,
          gallery.Description = result.Description;
        gallery.CoverImage = result.CoverImage;
        gallery.CreatedByUserId = this.loggedUserId;
        gallery.UpdatedByUserId = this.loggedUserId;
        gallery.IsPublic = false;
        gallery.MediaId = +result.MediaId;
        gallery.MediaIds=[+result.MediaId];
        gallery.OfficeId = +result.OfficeId;
        gallery.StatusId = 4; // This is "Draft" status

        this.store$.dispatch(createUserGallery({ galleryDto: gallery }));
        this.store$.dispatch(getUserGalleries());
      }
    })
  }

  albumsToggle(): void {
    this.showAlbums = true;
    this.showGalleries = false;
  }

  galleriesToggle(): void {
    this.showAlbums = false;
    this.showGalleries = true;
  }

  onAlbumSelect($event) {
    const selectedAlbum = <Album>$event;
    this.router.navigate([`/album/${selectedAlbum.id}`]);
  }
  onGallerySelect($event) {
    const selectedGallery = <GalleryResult>$event;
    this.router.navigate([`/gallery/${selectedGallery.id}`]);
  }
}
