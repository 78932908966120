import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from '../../../models/media.model';

@Component({
  selector: 'app-media-lightbox-dialog',
  templateUrl: './media-lightbox-dialog.component.html',
  styleUrls: ['./media-lightbox-dialog.component.scss']
})
export class MediaLightboxDialogComponent implements OnInit {
	public media: Media;
  constructor(
  	public dialogRef: MatDialogRef<MediaLightboxDialogComponent>,
  	@Inject(MAT_DIALOG_DATA) data) { 

  	this.media = data.media;
  }

  ngOnInit() {
  }

}
