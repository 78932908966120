<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb"
          routerLink="/drafted-media">
        Drafted Media
      </li>
      <li class="breadcrumb">
        {{title}}
      </li>
      <ng-container *ngIf="loading || publishing">
        <div >
            <mat-spinner diameter="30" strokeWidth="3px;"></mat-spinner>
        </div>
      </ng-container>
    </ul>
  </div>

  <div *ngIf="!loading && !publishing" class="page-actions-wrap">
    <button class="button-purple button-text-white page-action"
            (click)="publishMedia()">
      Publish Media
    </button>
  </div>
</div>

<div *ngIf="!loading" class="page-content-row-wrap">

  <div class="page-content-row-wrap">
    <div class="media-meta-wrap">
      <mat-expansion-panel *ngIf="source != 'LSVC'"
                           [expanded]="true"
                           class="card">
        <mat-expansion-panel-header class="card-header">
          <p class="card-header-title">Upload Context</p>
        </mat-expansion-panel-header>
        <app-media-upload-context-form [offices]="offices$ | async"
                                       [allCuratorTypes]="curators$ | async"
                                       (formOutput)="uploadContextChange($event)"
                                       [uploadContext]="uploadContext">

        </app-media-upload-context-form>
        <button mat-button
                (click)="saveMediaPatchPayload()"
                [disabled]="!isMediaPatchPayloadValid">Save Context</button>
      </mat-expansion-panel>

      <div class="card">
        <div class="card-header">
          <p class="card-header-title">
            Bulk Tags
          </p>
        </div>

        <div class="card-body">
          <div class="card-row">
            <mat-slide-toggle color="primary"
                              [(ngModel)]="bulkTagAllMedia"
                              checked="true"
                              class="mu-tag-toggle">
              Apply to all media
            </mat-slide-toggle>
          </div>
          <app-tag-type-ahead [tags]="bulkTagsClear"
                              [canAddTags]="true"
                              (tagsChanged)="bulkHelloTagsChanged($event)"></app-tag-type-ahead>
          <div *ngIf="uploadContext && uploadContext.program || source == 'LSVC'">
            <app-supplier-tag-type-ahead [canAddTags]="true"
                                         [tags]="bulkSupplierClear"
                                         (supplierAdded)="bulkSupplierTagsChanged($event)">
            </app-supplier-tag-type-ahead>
          </div>
        </div>
      </div>
    </div>


    <div class="media-listing-wrap">
      <app-media-upload-item *ngFor="let uploadItem of uploadQueueItems; trackBy: uploadQueueTrackFunction"
                             [uploadItem]="uploadItem"
                             [media]="uploadItem.media"
                             [selectiveBulkTagging]="checkIfMarkedForBulkTagging(uploadItem.id)"
                             (helloTagsAdded)="helloTagsAddedToItem($event)"
                             (helloTagsRemoved)="helloTagsRemovedFromItem($event)"
                             (supplierAdd)="supplierAddedToItem($event)"
                             (supplierRemove)="supplierRemovedFromItem($event)"
                             (removeAzureTagFromMedia)="removeAzureTagFromItem($event)"
                             (selectiveBulkTaggingChange)="updateBulkTaggingList($event)"
                             (archiveMediaItem)="archiveMediaItem($event)"
                             (publishMediaItem)="publishMediaId($event)"></app-media-upload-item>
    </div>

  </div>