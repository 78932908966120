import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminViewComponent } from './views/admin-view/admin-view.component';
import { AlbumViewComponent } from './views/album-view/album-view.component';
import { AllGalleriesViewComponent } from './views/all-galleries-view/all-galleries-view.component';
import { DashboardViewComponent } from './views/dashboard-view/dashboard-view.component';
import { DraftedMediaOverviewViewComponent } from './views/drafted-media-overview-view/drafted-media-overview-view.component';
import { DraftedMediaViewComponent } from './views/drafted-media-view/drafted-media-view.component';
import { GalleryViewComponent } from './views/gallery-view/gallery-view.component';
import { LoginViewComponent } from './views/login/login.component';
import { MediaDetailViewComponent } from './views/media-detail-view/media-detail-view.component';
import { MyMediaViewComponent } from './views/my-media-view/my-media-view.component';
import { SearchViewComponent } from './views/search-view/search-view.component';
import { UploadViewComponent } from './views/upload-view/upload-view.component';
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
    canActivate: [MsalGuard],
  },
  {
    path: 'login',
    component: LoginViewComponent,
    canActivate: [MsalGuard],
    
  },
  {
    path: 'dashboard',
    component: DashboardViewComponent,
    canActivate: [MsalGuard],
    
  },
  {
    path: 'upload',
    component: UploadViewComponent,
    canActivate: [MsalGuard],
  },
  // {
  //   path: 'upload/user/:userId/office/:officeId/supplier/:supplierId',
  //   component: MediaUploaderComponent,
  //   canActivate: [UploadGuardService]
  // },
  {
    path: 'search',
    component: SearchViewComponent,
    canActivate: [MsalGuard],
  },
  // {
  //   path: 'search/user/:userId/office/:officeId/program/:programId/service/:serviceId',
  //   component: SearchComponent,
  //   canActivate: [SearchGuardService]
  // },
  // {
  //   path: 'search/user/:userId/office/:officeId/supplier/:supplierId',
  //   component: SearchComponent,
  //   canActivate: [SearchGuardService]
  // },
  {
    path: 'media/:mediaId',
    component: MediaDetailViewComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'drafted-media',
    canActivate: [MsalGuard],
    component: DraftedMediaViewComponent
  },
  {
    path: 'drafted-media/overview/:source',
    canActivate: [MsalGuard],
    component: DraftedMediaOverviewViewComponent
  },
  // {
  //   path: 'tagMedia/user/:userId/office/:officeId/program/:programId/service/:serviceId',
  //   canActivate: [TaggingGuardService],
  //   component: DraftedOverviewComponent
  // },
  // {
  //   path: 'tagMedia/user/:userId/office/:officeId/serviceLibrary/:libSrvId',
  //   canActivate: [TaggingGuardService],
  //   component: DraftedOverviewComponent
  // },
  {
    path: 'admin',
    canActivate: [MsalGuard],
    component: AdminViewComponent
  },
  {
    path: 'users-content',
    canActivate: [MsalGuard],
    component: MyMediaViewComponent
  },
  {
    path: 'album/:id',
    component: AlbumViewComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'gallery/:galleryId',
    canActivate: [MsalGuard],
    component: GalleryViewComponent
  },
  {
    path: 'galleries',
    canActivate: [MsalGuard],
    component: AllGalleriesViewComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

