import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { QuickLookDialogComponent } from "../../../containers/quick-look-dialog/quick-look-dialog.component";
import { Media } from "../../../models/media.model";
@Component({
  selector: "app-media-item",
  templateUrl: "./media-item.component.html",
  styleUrls: ["./media-item.component.scss"]
})
export class MediaItemComponent implements OnInit {
  _selectable= false;
  @Input() media: Media;
  
  @Input()
  get selectable(): boolean {
    return this._selectable;
  }
  set selectable(value: boolean) {
    this._selectable=value;
    this.isSelected=false;
  }
  @Input() gallery: Media[] = [];

@Input() slideIndex=0;
  @Output() onImageSelected = new EventEmitter();

  isLiked: boolean = false;
  isSelected: boolean = false;
  isImage: boolean = true;
  urlToolTip: string = "Copy To Swap Image";
  componentKey: string; //Id that is set on the image. used for copy to clipboard
  public Index: number;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.componentKey = `thumb-${this.media.mediaId}`;

    if (this.media.type === "VDO" || this.media.type === "ADO") {
      this.isImage = false;
      this.urlToolTip = "Copy URL";
    }
  }

  imageSelected() {
    this.isSelected = !this.isSelected;
    this.onImageSelected.emit({
      mediaId: +this.media.mediaId,
      selected: this.isSelected
    });
  }

  openDialog(index) {
    this.dialog.open(QuickLookDialogComponent, {
      closeOnNavigation: true,
      data: {
        media: this.media,
        mediaGallery: this.gallery,
        slideIndex: this.slideIndex,
        mediaIds: [this.media]
      }
    });
  }

  copyImageUrl(url) {
    const hiddenText = document.createElement("textarea");
    hiddenText.value = url;
    hiddenText.setAttribute("readonly", "");
    (<any>hiddenText).style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(hiddenText);
    hiddenText.select();
    document.execCommand("copy");
    document.body.removeChild(hiddenText);
  }

  copyToClipboard(media: Media) {
    const s = window.getSelection();
    const r = document.createRange();
    const copyTarget = document.createElement("img");
    copyTarget.src = media.links.fileUrl;
    (<any>copyTarget).style = {
      position: "fixed",
      left: "-9999px",
      display: "block"
    };
    document.body.appendChild(copyTarget);
    r.selectNode(copyTarget);
    s.removeAllRanges();
    s.addRange(r);
    document.execCommand("copy");
    document.body.removeChild(copyTarget);
  }
}
