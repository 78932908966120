import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Album } from '../../models/album.model';
import { Gallery } from '../../models/gallery.model';
import { Media } from '../../models/media.model';
import { Office } from '../../models/office.model';
import { Permissions } from '../../models/permissions';
import { getUserAlbums, getUserSharedAlbums } from '../../ngrx/actions/albums.actions';
import { getTopViewGalleries, getUserGalleries } from '../../ngrx/actions/galleries.actions';
import { getMostActiveMedia, getMostViewedMedia, getRecentlyAddedMedia, getRecentlyPublishedMedia } from '../../ngrx/actions/media.actions';
import { selectOffice } from '../../ngrx/actions/offices.actions';
import { AppState } from '../../ngrx/reducers';
import { selectTopViewedGalleries } from '../../ngrx/selectors/galleries.selector';
import { selectMostActiveMedia, selectMostViewedMedia, selectRecentlyAddedMedia, selectRecentlyPublishedMedia } from '../../ngrx/selectors/media.selector';
import { selectCurrentOffice } from '../../ngrx/selectors/offices.selector';
import { doesPermissionExist } from '../../ngrx/selectors/user.selector';

const INITIAL_PAGE_SIZE = 4;
const SEE_MORE_PAGE_SIZE = environment.dashboardSeeMoreSize;
@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit {

  userId: any;
  officeFormControl = new FormControl();
  public userOfficeId: number;
  public officeId: any;
  officemodels: Office[];
  canViewGalleries: boolean;
  topViewedGalleries$: Observable<Gallery[]>;
  public login: boolean;
  public albumsLoading: boolean = true;
  public galleriesloading: boolean = true;
  public publishedLoading: boolean = true;
  public addedLoading: boolean = true;
  public topGalleriesLoading: boolean = true;
  public activeMediaLoading: boolean = true;
  public viewedMediaLoading: boolean = true;
  public followingLoading: boolean = true;

  private user;
  private offices;
  private user$;
  private offices$;
  //private albums$;
  selectedOfficeId: number;
  private unsubscribe$ = new Subject<void>();
  private recentlyPublishedMedia$: Observable<Media[]>;
  private recentlyAddedMedia$: Observable<Media[]>;
  private topViewMedia$: Observable<Media[]>;
  private mostViewedMedia$: Observable<Media[]>;
  private canViewDashboardItems$: Observable<boolean>;
  canViewDashboardItems: boolean;



  dashboardGalleries: Gallery[];
  filteredDashboardGalleries: Gallery[] = null;
  dashboardAlbums: Album[] = null;

  sharedAlbums: Album[] = null;
  recentlyAddedMedia: Media[];

  constructor(
    private store$: Store<AppState>,
    private _changeDetector: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    
    const loggedIn$ = this.store$.pipe(takeUntil(this.unsubscribe$),select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false || res.user=== null || res.user.userLoginId === null || res.user.userLoginId===0));
    loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {

      this.store$.dispatch(getUserAlbums());

      this.store$.dispatch(getUserSharedAlbums());
      this.store$.dispatch(getUserGalleries());
  
      this.store$.pipe(takeUntil(this.unsubscribe$),select(state=>state.albums.albums)).subscribe(res => {
        this.dashboardAlbums = res;
     
      });

      this.store$.pipe(takeUntil(this.unsubscribe$),select(state=>state.sharedAlbums.albums)).subscribe(res => {
        this.sharedAlbums = res;
      });
  
      this.store$.pipe(takeUntil(this.unsubscribe$), select(selectCurrentOffice),skipWhile(res=>!res)).subscribe(res => {
        this.selectedOfficeId = res;
        this.store$.dispatch(getTopViewGalleries({ officeId: this.selectedOfficeId, pageSize: 100 }));
        this.topViewedGalleries$ = this.store$.pipe(select(selectTopViewedGalleries));
        this.getRecentlyAddedMedia(this.selectedOfficeId);
        this.getMostViewedByOffice(this.selectedOfficeId);
        this.getMostActiveMedia(this.selectedOfficeId);
        this.store$.dispatch(getRecentlyPublishedMedia());
      });
  
      this.store$.pipe(takeUntil(this.unsubscribe$), select(state => state.galleries.items)).subscribe(res => {
  
        this.dashboardGalleries = res;
        if (!res.length) {
          this.filteredDashboardGalleries = null;
        }
        else {
          this.filteredDashboardGalleries = this.dashboardGalleries.filter(g => g.statusCode === "PBH" && g.officeId === this.selectedOfficeId);
        }
  
      });
      this.user$ = this.store$.pipe(select(state => state.user));
      this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.user = res.user;
        this.login = res.login;
      });
      this.offices$ = this.store$.pipe(select(state => state.offices));
      this.offices$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.offices = res.allOffices;
      })
  
      this.canViewDashboardItems$ = this.store$.pipe(select(doesPermissionExist, { permission: Permissions.CanViewDashBoardItem }));
      this.canViewDashboardItems$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => { this.canViewDashboardItems = res });
  
      this.recentlyPublishedMedia$ = this.store$.pipe(select(selectRecentlyPublishedMedia));
    });


  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // TODO: Run this off proper state
  // ------------------------ Setter for Office---------------
  public set selectedOfficeValue(value) {
    if (value !== undefined && this.selectedOfficeId !== value) { }

    this.store$.dispatch(selectOffice({ id: value }));
    this.store$
      .pipe(takeUntil(this.unsubscribe$), select(state => state.offices.currentSelectedOfficeId))
      .subscribe(res => {
        this.selectedOfficeId = res;
        this.filteredDashboardGalleries = this.dashboardGalleries.filter(g => g.officeId == this.selectedOfficeId);
      });
  }

  // -------------------------- Getter for Office -------------------------------------
  public get selectedOfficeValue() {
    this._changeDetector.markForCheck();
    return this.selectedOfficeId;
  }
  getMostActiveMedia(officeId) {

    let term: any = '?officeId=';
    term += officeId;
    term += '&count=';
    term += SEE_MORE_PAGE_SIZE;

    this.store$.dispatch(getMostActiveMedia({ queryString: term }));

    this.topViewMedia$ = this.store$.pipe(takeUntil(this.unsubscribe$), select(selectMostActiveMedia));
  }
  getRecentlyAddedMedia(officeId) {
    let term: any;
    if (officeId !== undefined && officeId > 0) {
      term = '?OfficeId=' + officeId;
    }
    // if (mediaStatus !== undefined && mediaStatus !== null && mediaStatus !== '' ) {
    //   term = term + '&mediaStatus=' + mediaStatus;
    // }
    term += '&Count=' + SEE_MORE_PAGE_SIZE;
    this.store$.dispatch(getRecentlyAddedMedia({ queryString: term }));

    this.recentlyAddedMedia$ = this.store$.pipe(takeUntil(this.unsubscribe$), select(selectRecentlyAddedMedia));
  }
  getMostViewedByOffice(officeId) {

    let term: any = '?Count=';
    term += SEE_MORE_PAGE_SIZE;

    term += '&OfficeId=' + officeId;
    this.store$.dispatch(getMostViewedMedia({ queryString: term }));
    this.mostViewedMedia$ = this.store$.pipe(takeUntil(this.unsubscribe$), select(selectMostViewedMedia));
  }

}
