import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UploadContext } from '../../../models/media.model';
import { Office } from '../../../models/office.model';
import { Option } from '../../../models/upload-option.model';
import { MediaPatchPayLoad } from '../../../models/uploadCriteria.model';
import { CuratorTypes, CuratorUserData } from '../../../models/user.model';

const PROGRAM = 'PSR';
const SUPPLIER = 'SUP';

@Component({
  selector: 'app-media-upload-context-form',
  templateUrl: './media-upload-context-form.component.html',
  styleUrls: ['./media-upload-context-form.component.scss'],
})
export class MediaUploadContextFormComponent implements OnInit {
  public uploadContextForm: FormGroup;
  public userCuratorTypes: CuratorUserData[];

  optionTypes: Option[] = [
    { value: PROGRAM, description: 'Program' },
    { value: SUPPLIER, description: 'Supplier' },
  ];

  @Input() showCurators: boolean = false;
  @Input() editMode: boolean = false;
  @Input() uploadContext: UploadContext = null;
  @Input() offices: Office[];
  @Input() allCuratorTypes: CuratorTypes[];
  @Input() ignoreCuratorType = false;

  @Output() formOutput = new EventEmitter<MediaPatchPayLoad>();

  constructor() {}

  ngOnInit() {
    this.uploadContextForm = this.initializeFormGroup();
    this.hydrateForm();
  }

  ngOnChanges() {
    this.hydrateForm();
  }

  initializeFormGroup() {
    return new FormGroup({
      office: new FormControl(),
      curatorType: new FormControl(),
      optionValue: new FormControl(),
      supplier: new FormControl(),
      program: new FormControl(),
      service: new FormControl(),
      venue: new FormControl(),
      note:new FormControl()
    });
  }

  hydrateForm() {
    if (!this.uploadContextForm) {
      this.uploadContextForm = this.initializeFormGroup();
    }
    if (!this.uploadContext) {
      this.uploadContext = {
        office: null,
        uploadDate: null,
        program: null,
        programService: null,
        libraryService: null,
        supplier: null,
        serviceVenue: null,
        sourceId: null,
        user: null,
        source: null,
        note:null
      };
    }
    // Set Up Curator Types
    this.filterCuratorTypes();

    // Set Option Value
    // This is an awkward variable used to flip between program or supplier uploads
    let optionValue = PROGRAM;
    if (this.uploadContext.supplier) {
      optionValue = SUPPLIER;
    }
    let curatorType = null;
    if (!this.ignoreCuratorType) {
      curatorType = this.userCuratorTypes.length ? this.userCuratorTypes[0] : null;
    }
    this.uploadContextForm.patchValue({
      office: this.uploadContext.office
        ? typeof this.uploadContext.office === 'number'
          ? this.uploadContext.office
          : this.uploadContext.office.id
        : null,
      curatorType: curatorType,
      optionValue: optionValue,
      supplier: this.uploadContext.supplier,
      program: this.uploadContext.program,
      service: this.uploadContext.programService,
      venue: this.uploadContext.serviceVenue,
      note:  this.uploadContext.note
    });

    this.uploadContextForm.valueChanges.subscribe(val => {
      let result: MediaPatchPayLoad;
      result = {
        OfficeId: this.uploadContextForm.value.office,
        SupplierId:
          this.uploadContextForm.value.optionValue == SUPPLIER && this.uploadContextForm.value.supplier
            ? typeof this.uploadContextForm.value.supplier === 'number'
              ? this.uploadContextForm.value.supplier
              : this.uploadContextForm.value.supplier.id
            : null,
        ProgramId:
          this.uploadContextForm.value.optionValue == PROGRAM && this.uploadContextForm.value.program
            ? this.uploadContextForm.value.program.id
            : null,
        LibraryServiceId: null,
        ProgramServiceId:
          this.uploadContextForm.value.optionValue == PROGRAM &&
          this.uploadContextForm.value.program &&
          this.uploadContextForm.value.service
            ? this.uploadContextForm.value.service.id
            : null,
        ServiceVenueId:
          this.uploadContextForm.value.optionValue == PROGRAM && this.uploadContextForm.value.venue
            ? this.uploadContextForm.value.venue.id
            : null,
        Note:this.uploadContextForm.value.note
      };
      this.formOutput.emit(result);
    });
  }

  officeSelected(office) {
    // Using "this.uploadContextForm.patchValue" caused office's DD to appear empty,
    // so assigning the value this way instead... which works...

    this.uploadContextForm.value.office = office;

    this.filterCuratorTypes();
  }
  filterCuratorTypes() {
    if (this.uploadContextForm && this.uploadContextForm.value && this.uploadContextForm.value.office && this.allCuratorTypes.length) {
      let curatorTypes;
      if (typeof this.uploadContextForm.value.office === 'number') {
        curatorTypes = this.allCuratorTypes.filter(type => type.officeId == this.uploadContextForm.value.office);
      } else {
        curatorTypes = this.allCuratorTypes.filter(type => type.officeId == this.uploadContextForm.value.office.value);
      }
      const curatorType = curatorTypes[0].items[0];
      this.userCuratorTypes = curatorTypes[0].items;
      this.uploadContextForm.patchValue({
        curatorType: curatorType,
      });
    } else {
      this.userCuratorTypes = [];
    }
  }

  supplierSelected(supplier) {
    this.uploadContextForm.patchValue({
      supplier: supplier,
    });
  }

  programSelected(program) {
    this.uploadContextForm.patchValue({
      program: program,
    });
  }

  serviceSelected(serviceId) {
    this.uploadContextForm.patchValue({
      service: serviceId,
    });
  }

  venueSelected(venue) {
    this.uploadContextForm.patchValue({
      venue: venue,
    });
  }

  programSupplierSelected(value) {
    if (value === 'SUP') {
      this.uploadContextForm.patchValue({
        program: null,
        service: null,
        venue: null,
      });
    } else {
      this.uploadContextForm.patchValue({
        supplier: null,
      });
    }
  }

  onSubmit() {}
}
