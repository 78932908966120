import { createAction, props } from '@ngrx/store';
import { Media, MediaMetadata } from '../../models/media.model';
import { SearchRequest } from '../../models/search.model';
import { SupplierMediaModel, supplierModel } from '../../models/supplier.model';
import { tagItem } from '../../models/tagModel';
import { UploadClusters } from '../../models/upload-clusters.model';
import { mediaPatch, MediaPatchPayLoad, mediaTagUpdate } from '../../models/uploadCriteria.model';
import { MediaUploadState } from '../reducers/media.reducer';

// GET FULL OBJECT
export const getMediaMetadataById = createAction(
  '[media] getMediaMetadataById',
  props<{ mediaId: number; getRelatedImages: boolean }>()
);
export const getMediaMetadataByIdSuccess = createAction(
  '[media] getMediaMetadataByIdSuccess',
  props<{ metaData: MediaMetadata }>()
);

// INCREMENT VIEW COUNT
export const incrementMediaViewCount = createAction(
  '[media] incrementMediaViewCount',
  props<{ mediaId: number; getRelatedImages: boolean }>()
);

// DRAFTED MEDIA
export const getDraftedMedia = createAction('[media] getDraftedMedia');
export const getDraftedMediaSuccess = createAction(
  '[media] getDraftedMediaSuccess',
  props<{
    supplierDraftedMedia: UploadClusters[];
    programDraftedMedia: UploadClusters[];
    libraryServiceDraftedMedia: UploadClusters[];
    miscDraftedMedia: UploadClusters[];
    iPadMigrationMedia: UploadClusters[];
  }>()
);

export const getDraftedMediaBySupplierId = createAction(
  '[media] getDraftedMediaBySupplierId',
  props<{ supplierId: number }>()
);
export const getDraftedMediaByProgramAndServiceId = createAction(
  '[media] getDraftedMediaByProgramAndServiceId',
  props<{ programId: number; programServiceId: number }>()
);

// GALLERY
export const getMediaByGalleryId = createAction(
  '[media] getMediaByGalleryId',
  props<{ galleryId: number; pageSize: number }>()
);
export const getMediaByGalleryIdSuccess = createAction('[media] getMediaByGalleryIdSuccess', props<{ media: any[] }>());

// ALBUM
export const getMediaByAlbumId = createAction(
  '[media] getMediaByAlbumId',
  props<{ albumId: number; pageSize: number }>()
);
export const getMediaByAlbumIdSuccess = createAction('[media] getMediaByAlbumIdSuccess', props<{ media: any[] }>());

// GET SPECIFIC COLLECTION OF MEDIA
export const getRecentlyPublishedMedia = createAction('[media] getRecentlyPublishedMedia');
export const getRecentlyPublishedMediaSuccess = createAction(
  '[media] getRecentlyPublishedMediaSuccess',
  props<{ media: Media[] }>()
);
export const getRecentlyPublishedMediaFail = createAction('[media] getRecentlyPublishedMediaFail');
export const getRecentlyAddedMedia = createAction('[media] getRecentlyAddedMedia', props<{ queryString: any }>());
export const getRecentlyAddedMediaSuccess = createAction(
  '[media] getRecentlyAddedMediaSuccess',
  props<{ media: any[] }>()
);
export const getMostActiveMedia = createAction('[media] getMostActiveMedia', props<{ queryString: any }>());
export const getMostActiveMediaSuccess = createAction('[media] getMostActiveMediaSuccess', props<{ media: any[] }>());
export const getMostViewedMedia = createAction('[media] getMostViewedMedia', props<{ queryString: any }>());
export const getMostViewedMediaSuccess = createAction('[media] getMostViewedMediaSuccess', props<{ media: any[] }>());
export const getRelatedMedia = createAction('[media] getRelatedMedia', props<{ mediaId: number; tags: tagItem[] }>());
export const getRelatedMediaSuccess = createAction(
  '[media] getRelatedMediaSuccess',
  props<{ media: Media[]; mediaId: number }>()
);

// EDIT
export const editMedia = createAction('[media] editMedia', props<{ media: Media; file: File }>());
export const editUploadSuccess = createAction(
  '[media] editUploadSuccess',
  props<{ media: Media; originalMediaId: number }>()
);
export const editMediaSuccess = createAction(
  '[media] editMediaSuccess',
  props<{ media: Media; originalMediaId: number }>()
);

// UPDATE STATUS
export const updateMediaStatus = createAction(
  '[media] updateMediaStatus',
  props<{ mediaId: number; status: string }>()
);
export const updateMediaStatusSuccess = createAction('[media] updateMediaStatusSuccess', props<{ media: Media }>());

// PATCH MEDIA ITEM
export const patchMediaUploadContext = createAction(
  '[media] patchMediaUploadContext',
  props<{ mediaId: number; payload: MediaPatchPayLoad }>()
);

export const updateSupplierOnMedia = createAction(
  '[media] updateSupplierOnMedia',
  props<{ mediaId: number; supplier: supplierModel; payload: mediaPatch }>()
);
export const updateSupplierOnMediaSuccess = createAction(
  '[media] updateSupplierOnMediaSuccess',
  props<{ mediaId: number; supplier: SupplierMediaModel; add: boolean }>()
);

export const removeAzureTagFromMedia = createAction(
  '[media] removeAzureTagFromMedia',
  props<{ media: Media; name: string }>()
);
export const removeAzureTagFromMediaSuccess = createAction(
  '[media] removeAzureTagFromMediaSuccess',
  props<{ mediaId: number; name: string }>()
);
// PATCH BULK MEDIA
export const addTagToMedia = createAction('[media] addTagToMedia', props<{ tag: any; criteria: mediaTagUpdate }>());
export const addTagToMediaOptimisticUpdate = createAction(
  '[media] addTagToMedia Optimistic Update',
  props<{ tag: any; criteria: mediaTagUpdate }>()
);

export const removeTagFromMedia = createAction('[media] removeTagFromMedia', props<{ tag: any; mediaId: number }>());
export const removeTagFromMediaOptimisticUpdate = createAction(
  '[media] removeTagFromMedia Optimistic Update',
  props<{ tag: any; mediaId: number }>()
);

// SEARCH
export const searchMedia = createAction('[media] searchMedia', props<{ searchRequest: SearchRequest; page: number }>());
export const searchMediaSuccess = createAction(
  '[media] searchMediaSuccess',
  props<{ media: Media[]; searchRequest: SearchRequest; clear: boolean }>()
);

// UPLOAD
export const uploadMedia = createAction(
  '[media] uploadMedia',
  props<{ file: File; payload: MediaPatchPayLoad; uploadContext: string }>()
);
export const uploadMediaMessage = createAction(
  '[media] uploadMediaMessage',
  props<{ mediaUploadState: MediaUploadState }>()
);
export const emptyAction = createAction('[media] Empty');

// Fill Upload Queue so Drafted Media OverView works
export const setUploadQueue = createAction('[media] setUploadQueue', props<{ uploadQueue: MediaUploadState[] }>());

// LIKED MEDIA
export const getIsMediaLiked = createAction('[media] getIsMediaLiked', props<{ mediaId: number }>());
export const getIsMediaLikedSuccess = createAction(
  '[media] getIsMediaLikedSuccess',
  props<{ mediaId: number; isLiked: boolean }>()
);
export const getIsMediaLikedFail = createAction('[media] getIsMediaLikedFail', props<{ mediaId: number }>());

export const setIsMediaLiked = createAction(
  '[media] setIsMediaLiked',
  props<{ mediaId: number; likesAlbumId: number; newValue: boolean }>()
);
export const setIsMediaLikedSuccess = createAction(
  '[media] setIsMediaLikedSuccess',
  props<{ mediaId: number; isLiked: boolean }>()
);

export const clearUploadQueue = createAction('[media] Clear Upload Queue');

export const bulkUpdateMediaStatus = createAction(
  '[media] bulkUpdateMediaStatus',
  props<{ mediaIds: number[]; statusId: number }>()
);
export const bulkUpdateMediaStatusSuccess = createAction(
  '[media] bulkUpdateMediaStatusSuccess',
  props<{ mediaIds: number[]; statusId: number }>()
);

export const searchMediaByLibraryServiceCorrelationKey = createAction(
  '[media] searchMediaByLibraryServiceCorrelationKey',
  props<{ id: number }>()
);
export const searchMediaByLibraryServicecorrelationKeySuccess = createAction(
  '[media] searchMediaByLibraryServiceCorrelationKeySuccess',
  props<{ media: Media[] }>()
);
