<div class="search-wrap" *ngIf="!hideSearchFields">
  <!-- Search input -->
  <div class="tag-search-wrap">
    <div class="search-row">
      <div class="search-row-icon" (click)="submitSearch()" matTooltip="Search">
        <i class="material-icons search">search</i>
      </div>
      <input
        matInput
        class="tag-search-input"
        type="search"
        placeholder="Key Word Search"
        name="searchTerm"
        [formControl]="userInput"
        (keyup.enter)="submitSearch()"
      />

      <div class="search-row-icon" (click)="clearSearch()" matTooltip="Clear Search">
        <i class="material-icons search-clear">close</i>
      </div>
    </div>
  </div>

  <!-- Search Filters -->
  <form [formGroup]="searchForm" class="filters-wrap">
    <!-- Status -->
    <div *ngIf="mediaStatuses.length > 1">
      <mat-select class="search-filter" placeholder="Status" formControlName="status">
        <mat-option *ngFor="let statusMedia of mediaStatuses" [value]="statusMedia.value">
          {{ statusMedia.description }}
        </mat-option>
      </mat-select>
    </div>
    <mat-select class="page-context-filter" placeholder="Curator Types" name="CuratorUserMetaData" formControlName="isCreative">
      <mat-option value="">All</mat-option>
      <mat-option value="true">Creative</mat-option>
      <mat-option value="false">Product Development</mat-option>
    </mat-select>
    <!-- Media Type -->
    <mat-select class="search-filter" placeholder="Media Type" formControlName="mediaType">
      <mat-option *ngFor="let typeMedia of mediaTypes" [value]="typeMedia.value">
        {{ typeMedia.description }}
      </mat-option>
    </mat-select>
    <!-- Office -->

    <mat-select class="search-filter" placeholder="Office" formControlName="offices" multiple>
      <app-mat-option-select-all>All</app-mat-option-select-all>
      <mat-option *ngFor="let office of offices" [value]="office.officeId">
        {{ office.officeName }}
      </mat-option>
    </mat-select>

    <!-- #### user filter was here. Removed as it never had any data set ##### -->
    <!-- upload date - from -->
    <div *ngIf="canFilterMediaByUploadDate$ | async">
      <input
        matInput
        [matDatepicker]="uploadpickerfrom"
        placeholder="Upload Date from"
        class="search-filter-datepicker"
        formControlName="uploadDateFrom"
        type="text"
      />
      <mat-datepicker-toggle class="search-filter-datepicker-toggle" matSuffix [for]="uploadpickerfrom">
        <mat-icon class="search-filter-datepicker-icon" matDatepickerToggleIcon>calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #uploadpickerfrom></mat-datepicker>
    </div>
    <div *ngIf="canFilterMediaByUploadDate$ | async">
      <!-- upload date - to -->
      <input
        matInput
        [matDatepicker]="uploadpickerto"
        formControlName="uploadDateTo"
        placeholder="Upload Date to"
        class="search-filter-datepicker"
        type="text"
      />
      <mat-datepicker-toggle class="search-filter-datepicker-toggle" matSuffix [for]="uploadpickerto">
        <mat-icon class="search-filter-datepicker-icon" matDatepickerToggleIcon>calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #uploadpickerto></mat-datepicker>
    </div>
    <!-- Program -->
    <app-program-type-ahead
      [value]="searchForm.value.program"
      [officeIds]="searchForm.value.offices"
      (filterCleared)="programSelected(null)"
      (programSelected)="programSelected($event)"
    ></app-program-type-ahead>
    <!-- Client -->
    <app-client-type-ahead
      [value]="searchForm.value.client"
      (filterCleared)="clientSelected(null)"
      [programId]="searchForm.value.program ? searchForm.value.program.id : null"
      (clientSelected)="clientSelected($event)"
    >
    </app-client-type-ahead>
    <!-- Account EXEECUTIVE -->
    <app-account-executive-type-ahead
      [value]="searchForm.value.accountExecutive"
      (filterCleared)="aeSelected(null)"
      [programId]="searchForm.value.program ? searchForm.value.program.id : null"
      (aeSelected)="aeSelected($event)"
    >
    </app-account-executive-type-ahead>
    <!-- Service: -->
    <app-service-select
      class="app-service-select"
      [value]="searchForm.value.service"
      [restrictToContract]="false"
      [programId]="searchForm.value.program ? searchForm.value.program.id : null"
      [isCreative]="null"
      [onlyShowWithMedia]="true"
      (serviceSelected)="serviceSelected($event)"
    ></app-service-select>
    <!-- Supplier -->
    <app-supplier-type-ahead
      [value]="searchForm.value.supplier"
      (filterCleared)="supplierSelected(null)"
      (supplierSelected)="supplierSelected($event)"
    >
    </app-supplier-type-ahead>
    <!-- Service Venue (Venues are actually suppliers) -->
    <app-supplier-type-ahead
      [value]="searchForm.value.venue"
      placeholder="Hotel/Venue"
      (filterCleared)="venueSelected(null)"
      (supplierSelected)="venueSelected($event)"
    >
    </app-supplier-type-ahead>
    <!-- Service Date from -->
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="Service Date from"
      class="search-filter-datepicker"
      formControlName="serviceDateFrom"
      type="text"
    />
    <mat-datepicker-toggle class="search-filter-datepicker-toggle" matSuffix [for]="picker">
      <mat-icon class="search-filter-datepicker-icon mat-icon-button-hotfix" matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <!-- Service date to -->
    <input
      matInput
      [matDatepicker]="picker1"
      placeholder="Service Date to"
      class="search-filter-datepicker"
      formControlName="serviceDateTo"
      type="text"
    />
    <mat-datepicker-toggle class="search-filter-datepicker-toggle" matSuffix [for]="picker1">
      <mat-icon class="search-filter-datepicker-icon mat-icon-button-hotfix" matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </form>
</div>
<div class="page-action-wrap search-actions">
  <div *ngIf="searchForm && searchForm.value.status != 'ARC' && canArchiveMedia">
    <mat-icon class="page-action" matTooltip="Media Options" [matMenuTriggerFor]="searchArchiveMenu"> more_vert </mat-icon>
    <mat-menu #searchArchiveMenu>
      <button mat-menu-item (click)="bulkArchiveMedia()">Bulk Archive</button>
      <!-- <button mat-menu-item
            *ngIf="imagesAreSelectable"
            (click)="removeSelectedMedia()">
      Remove Selected Media
    </button> -->
    </mat-menu>
  </div>
  <mat-icon class="page-action" (click)="toggleSelectionMode()" matTooltip="Select Media"> select_all </mat-icon>
</div>
<!-- Media Grid Note: This uses ngx-infinite-scroll npm package -->
<div *ngIf="selectedImageIds.length > 0" class="gallery-actions-group gallery-actions-group-media-page">
  <div class="gallery-album-col">
    <div *ngIf="selectedAlbums">
      <app-set-album-select
        [albums]="albums"
        (selectedAlbumsEvent)="addMediaToSelectedAlbums($event)"
        (createAlbumEvent)="createAlbum($event)"
      >
      </app-set-album-select>
    </div>
    <div>
      <app-set-gallery-select
        [galleries]="galleries"
        [canAddMediaToGallery]="canAddMediaToGallery"
        [userOffices]="userOffices"
        (selectedGalleriesEvent)="addMediaToSelectedGalleries($event)"
        (createGalleryEvent)="createGallery($event)"
      ></app-set-gallery-select>
    </div>
  </div>
</div>
<div
  class="media-grid-wrap search-grid-wrap"
  *ngIf="!isSearching"
  style="height: 100%"
  infinite-scroll
  [infiniteScrollDistance]="3"
  [infiniteScrollUpDistance]="1.5"
  (scrolled)="infiniteScroll($event)"
>
  <app-media-item
    *ngFor="let m of media$ | async; index as i"
    [selectable]="canSelectMedia"
    [gallery]="this.media"
    [slideIndex]="i"
    (onImageSelected)="onImageSelected($event)"
    [media]="m"
  ></app-media-item>
</div>
<!-- Nothing found message -->
<div *ngIf="(!media || media.length === 0) && !isSearching" class="page-empty">
  <i class="material-icons page-empty-icon">broken_image</i>
  <p class="page-empty-text">No Results Found</p>
</div>
<div *ngIf="isSearching" class="search-spinner-wrapper">
  <mat-spinner></mat-spinner>
</div>
<scroll-to-top></scroll-to-top>
