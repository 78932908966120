<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb">
        Add Media
      </li>
    </ul>
  </div>
  <div class="page-actions-wrap">
    <a class="button-purple button-text-white page-action"
       *ngIf="canViewAdmin$ | async"
       (click)="publishMedia()"
       [ngClass]="{'button-purple-disabled': isUploadDisabled()}">
      Publish Media
    </a>
    <a class="button-purple button-text-white page-action"
       (click)="showUploadOverView()"
       [ngClass]="{'button-purple-disabled': isUploadDisabled()}">
      Overview
    </a>
  </div>
</div>

<div class="page-content-row-wrap">
  <div class="media-meta-wrap">
    <mat-expansion-panel [expanded]="true"
                         class="card">
      <mat-expansion-panel-header class="card-header">
        <p class="card-header-title">Upload Context</p>
      </mat-expansion-panel-header>
      <app-media-upload-context-form [showCurators]="true"
                                      [uploadContext]="uploadContextDefault"
                                     [offices]="offices$ | async"
                                     [allCuratorTypes]="curators$ | async"
                                     (formOutput)="uploadContextChange($event)">

      </app-media-upload-context-form>
    </mat-expansion-panel>

    <div class="card">
      <div class="card-header">
        <p class="card-header-title">
          Bulk Tags
        </p>
      </div>

      <div class="card-body">
        <div class="card-row">
          <mat-slide-toggle color="primary"
                            [(ngModel)]="bulkTagAllMedia"
                            class="mu-tag-toggle">
            Apply to all media
          </mat-slide-toggle>
        </div>
        <app-tag-type-ahead [tags]="bulkTagsClear"
                            [canAddTags]="true"
                            (tagsChanged)="bulkHelloTagsChanged($event)"></app-tag-type-ahead>
        <div *ngIf="uploadContext && uploadContext.ProgramId">
          <app-supplier-tag-type-ahead [canAddTags]="true"
                                       [tags]="bulkSupplierClear"
                                       (supplierAdded)="bulkSupplierTagsChanged($event)"></app-supplier-tag-type-ahead>
        </div>
      </div>
    </div>
  </div>



  <div class="media-listing-wrap">
    <div class="drag-drop-wrap drag-drop-wrap-lg"
         [ngClass]="{'drag-drop-wrap-disabled': isUploadDisabled()}"
         mlDropzone
         [disabled]="isUploadDisabled()"
         [allowMultiple]="true"
         (fileChangeEmitter)="onFileDrop($event)"
         (invalidFileEmitter)="onInvalidFileDrop($event)">
      <input id="uploadFiles"
             class="drag-drop-hidden"
             [disabled]="isUploadDisabled()"
             (change)="onFileSelectedEvent($event)"
             type="file"
             multiple />
      <i class="material-icons">file_upload</i>
      <span class="drag-drop-text"
            *ngIf="isUploadDisabled()">
        Include upload context before uploading media
      </span>
      <span class="drag-drop-text"
            *ngIf="!isUploadDisabled()">
        Drag and Drop or
        <label for="uploadFiles"
               class="drag-drop-upload">
          upload
        </label>
      </span>
      <p class="drag-drop-support-text"
         *ngIf="!isUploadDisabled">
        One or more media files
      </p>
      <div class="allowed-extensions-caption">
        <p>Supported Formats: <span *ngFor="let ext of validExtensions; let index=index">{{ext.toLowerCase()}}<span
                  *ngIf="index < validExtensions.length -1">, </span> </span></p>
      </div>
    </div>
    <div class="upload-error-wrap">
      <div *ngFor="let file of invalidFiles; let index = index"
           class="upload-error-item">
        <div class="upload-error-message-wrap">
          <mat-icon>error_outline</mat-icon>
          <p><b>FileName:</b> {{file.name}}
            <b>Extension:</b> {{file.ext}}
            <b>Size:</b> {{file.sizeKB}}KB |
            {{file.message}}</p>
        </div>
        <mat-icon class="upload-error-close"
                  (click)="removeError(index)">close</mat-icon>
      </div>
    </div>

    <app-media-upload-item *ngFor="let uploadItem of uploadQueueItems; trackBy: uploadQueueTrackFunction"
                           [uploadItem]="uploadItem"
                           [media]="uploadItem.media"
                           [forceUpdate]="forceQueueUpdateHack"
                           (helloTagsAdded)="helloTagsAddedToItem($event)"
                           (helloTagsRemoved)="helloTagsRemovedFromItem($event)"
                           (supplierAdd)="supplierAddedToItem($event)"
                           (supplierRemove)="supplierRemovedFromItem($event)"
                           (removeAzureTagFromMedia)="removeAzureTagFromItem($event)"
                           (selectiveBulkTaggingChange)="updateBulkTaggingList($event)"
                           (archiveMediaItem)="archiveMediaItem($event)"></app-media-upload-item>
  </div>

  <scroll-to-top></scroll-to-top>
</div>