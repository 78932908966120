import { createAction, props } from '@ngrx/store';
import { MediaMetadata } from 'src/app/models/media.model';


export const addMediaToPocket = createAction('[pocket] addMediaToPocket', props<{ mediaId: number }>());
export const addMediaToPocketSuccess = createAction('[pocket] addMediaToPocketSuccess', props<{ mediaId: number }>());
export const addMediaToPocketFailed = createAction('[pocket] addMediaToPocketFailed');

export const removeMediaFromPocket = createAction('[pocket] removeMediaFromPocket', props<{ mediaId: number }>());
export const removeMediaFromPocketSuccess = createAction('[pocket] removeMediaFromPocketSuccess', props<{ mediaId: number }>());
export const removeMediaToPocketFailed = createAction('[pocket] removeMediaToPocketFailed');

export const getMediaForPocket = createAction('[pocket] getMediaForPocket');
export const getMediaForPocketSuccess = createAction('[pocket] getMediaForPocketSuccess', props<{ media: MediaMetadata[] }>());
export const getMediaForPocketFailed = createAction('[pocket] getMediaForPocketFailed');

export const clearPocket = createAction('[pocket] clearPocket');
export const clearPocketSuccess = createAction('[pocket] clearPocketSuccess');
export const clearPocketFailed = createAction('[pocket] clearPocketFailed');

