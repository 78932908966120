import * as albums from './albums.reducer';
import * as galleries from './galleries.reducer';
import * as media from './media.reducer';
import * as offices from './offices.reducer';
import * as sharedAlbums from './sharedAlbums.reducer';
import * as user from './user.reducer';

export const reducers = {
    albums: albums.albums,
    sharedAlbums:sharedAlbums.albums,
    galleries: galleries.galleries,
    user: user.user,
    offices: offices.offices,
    media: media.media
}

export interface AppState {
    albums: albums.AlbumsState;
    sharedAlbums: sharedAlbums.SharedAlbumsState;
    galleries: galleries.GalleriesState;
    user: user.UserState;
    offices: offices.OfficeState;
    media: media.MediaState;
}

