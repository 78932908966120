import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Album } from '../../models/album.model';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent implements OnInit {

  @Input() albums: Album[];
  @Output() selectAlbum: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  viewAlbum(album: Album) {
    this.selectAlbum.emit(album);
  }

  
}
