import { Deserializable } from './deserializable.model';

export interface User {
  userId: number;
  userName: string;
  employeeOfficeId: number;
  email: string;
  isCreative?: boolean;
  roleTypes?: string;
  roleId?: number;
  type: UserType;
  isInternal: boolean;
  vendorId?: number;
  apiUrl: string;
  apiUserName: string;
  apiPassword: string;
  dmcUrl?: string;
  documentsUrl: string;
  searchUrl: string;
  userLoginId?: number;
  userOfficeId?: number;
}

export class UserUploadContext {
  id: any;
  login?: any;
  fullName?: any;
  firstName?: any;
  lastName?: any;
}

export class CuratorUserData  {
  id: number;
  typeName: any;
  isCreative: boolean;
}

export interface CuratorTypes {
  officeId: number;
  items: CuratorUserData[];
}

export class CuratorUserMetaData implements Deserializable {
  id: number;
  typeName: any;
  isCreative: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export enum UserType {
  Internal = 0,
  External = 1,
}

export interface RoleFilteringOption {
  filteringProgramRoleId: number;
  offices: string;
}
