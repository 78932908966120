export class UploadStatusMessage {
  fileName: string;
  clientId: string;
  status: string;
  mediaId: string;

  constructor(fileName: string = '',
              clientId: string = '',
              status: string = '',
              mediaId: string = ''
              ) {
    this.fileName = fileName;
    this.clientId = clientId;
    this.status = status;
    this.mediaId = mediaId;
  }
}
