import { createAction, props } from '@ngrx/store';
import { GalleryEditDTO } from '../../models/gallery.model';
import { Media } from '../../models/media.model';
import { GalleryCreateCommand, GalleryResult } from '../../services/gallery.service';


export const getUserGalleries = createAction('[galleries] getAllGalleries');
export const getUserGalleriesSuccess = createAction('[galleries] getAllGalleriesSuccess', props<{ galleries: any[] }>());
export const getUserGalleriesFail = createAction('galleries] getAllGalleriesFail');

export const getAllMediaForGallerySuccess = createAction('[galleries] getAllMediaForGallerySuccess', props<{ galleryId: number, media: Media[] }>());

export const getTopViewGalleries = createAction('[galleries] getTopViewGalleries', props<{ officeId: number, pageSize: number }>());
export const getTopViewGalleriesSuccess = createAction('[galleries] getTopViewedGalleries', props<{ galleries: any[] }>());

export const getAllGalleriesForAllOffices = createAction('[galleries] getAllGalleriesForAllOffices', props<{ pageSize: number }>());
export const getAllGalleriesForAllOfficesSuccess = createAction('[galleries] getAllGalleriesForAllOfficesSuccess', props<{ galleries: any[] }>());

export const createUserGallery = createAction('[galleries] createUserGallery', props<{ galleryDto: GalleryCreateCommand }>());
export const createUserGallerySuccess = createAction('[galleries] createUserGallerySuccess', props<{ newGallery: any }>());
export const createUserGalleryFail = createAction('[galleries] createUserGalleryFail');

export const addMediaToGalleries = createAction('[galleries] addMediaToGalleries', props<{ mediaId: number, selectedGalleryIds: number[] }>());
export const addMediaToGalleriesSuccess = createAction('[galleries] addMediaToGalleriesSuccess', props<{ mediaId: number, selectedGalleryIds: number[] }>());
export const bulkAddMediaFromGallery = createAction('[galleries] bulkAddMediaFromGallery',
	props<{mediaIds: number[], galleryIds: number[]}>());
export const bulkAddMediaFromGallerySuccess = createAction('[galleries] bulkAddMediaFromGallerySuccess',
	props<{mediaIds: number[], galleryIds: number[]}>());
export const removeMediaFromGalleries = createAction('[galleries] removeMediaFromGalleries', props<{ mediaId: number, selectedGalleryIds: number[] }>());
export const removeMediaFromGalleriesSuccess = createAction('[galleries] removeMediaFromGalleriesSuccess', props<{ mediaId: number, selectedGalleryIds: number[] }>());

export const bulkRemoveMediaFromGallery = createAction('[galleries] bulkRemoveMediaFromGallery',
	props<{mediaIds: number[], galleryId: number}>());
export const bulkRemoveMediaFromGallerySuccess = createAction('[galleries] bulkRemoveMediaFromGallerySuccess',
	props<{mediaIds: number[], galleryId: number}>());

export const editGallery = createAction('[galleries] editGallery',
	props<{galleryEditDTO: GalleryEditDTO}>())
export const editGallerySuccess = createAction('[galleries] editGallerySuccess',
	props<{gallery: GalleryResult}>());

export const updateGalleryStatus = createAction('[galleries] updateGalleryStatus',
	props<{id: number, statusId: number}>());
export const updateGalleryStatusSuccess = createAction('[galleries] updateGalleryStatusSuccess',
	props<{id: number, statusId: number}>());