import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserType } from '../../models/user.model';
import { OfficeService } from '../../services/office.service';
import { UserService } from '../../services/user.service';
import * as mediaActions from '../actions/media.actions';
import { getAllOffices, getUserOffices, getUserOfficesSuccess } from '../actions/offices.actions';
import { getMediaForPocket } from '../actions/pocket.actions';
import { checkUserLogin, checkUserLoginDecorate, checkUserLoginResult, getUserCuratorTypesSuccess } from '../actions/user.actions';
import { AppState } from '../reducers';

const SESSION_KEY = 'profile_user';
const HardCodedUser = {
  employeeOfficeId: 3,
  roleTypes: [
    'Accounting_AccountsPayable',
    'Accounting_AccountsRecievable',
    'Accounting_AeGoals_Edit',
    'Accounting_AeHotels_Edit',
    'Accounting_Amex_Biller',
    'Accounting_Amex_Final',
    'Accounting_EmployeeCommission_Process',
    'Accounting_EmployeeCommission_Setup',
    'Accounting_Export_Invoices',
    'Accounting_GP_Visible',
    'Accounting_ManageChartOfAccounts',
    'Accounting_MearsRevenue_Edit',
    'Accounting_Program_Locked_Edit',
    'Accounting_Program_Sold_Invoiced_Edit',
    'Accounting_Programs_VendorPayment',
    'Accounting_Search',
    'Accounting_SetupManagement',
    'Accounting_Update_Program_Status',
    'Administrator',
    'Clients_Contacts_Create',
    'Clients_Contacts_Deactivate',
    'Clients_Contacts_Edit',
    'Clients_Convert',
    'Clients_Create',
    'Clients_Deactivate',
    'Clients_Edit',
    'Clients_ManageHouseAccount',
    'Clients_ManageInternalClientCommission',
    'Event_Library',
    'Hotels_Contacts_Create',
    'Hotels_Contacts_Deactivate',
    'Hotels_Contacts_Edit',
    'Hotels_Create',
    'Hotels_Deactivate',
    'Hotels_Edit',
    'Hotels_EditFinancial',
    'Hotels_EditSolomon',
    'ImpersonateUser',
    'Layout_Designer',
    'ManageOffices',
    'Mange restricted login roles',
    'ProductLibrary_CsManage',
    'ProductLibrary_Manage',
    'ProgramDocumentTemplate_Create',
    'ProgramDocumentTemplate_Delete',
    'ProgramDocumentTemplate_Edit',
    'Programs_Change_Contacts_Sold_Invoiced',
    'Programs_Change_Sold_Invoiced',
    'Programs_Change_Sold_Operated',
    'Programs_Change_Status',
    'Programs_Change_Type',
    'Programs_Create_Invoice',
    'Report_ForecastByClient_AllOffices',
    'Report_ForecastByNationalAe_ViewAllNAEs',
    'Reports_Export_ViewAll',
    'Reports_Parameters_ViewAll',
    'Reports_Types_ViewAccounting',
    'Reports_Types_ViewForecast',
    'Security_Roles_Create',
    'Security_Roles_Deactivate',
    'Security_Roles_Edit',
    'Security_Users_Create',
    'Security_Users_Deactivate',
    'Security_Users_Edit',
    'SelectionFiltering_View_All',
    'Service_Search_Modify',
    'ServiceTemplate_Activate',
    'ServiceTemplate_Create',
    'ServiceTemplate_Delete',
    'ServiceTemplate_Edit',
    'Shortcuts_CreativeWebTools',
    'Shortcuts_WebsiteAdmin',
    'Vendors_Contacts_Create',
    'Vendors_Contacts_Deactivate',
    'Vendors_Contacts_Edit',
    'Vendors_Create',
    'Vendors_Deactivate',
    'Vendors_Edit',
    'Vendors_EditFinancial',
    'Vendors_EditSolomon',
    'Accounting_Admin_Reports',
    'Accounting_All_Reports',
    'Accounting_AR_Reports',
    'Accounting_GL_Reports',
    'Report_Accounting_MonthEnd',
    'Report_AccountingMgmt',
    'Report_AccountsPayable',
    'Report_Adjustments',
    'Report_AeByLocation',
    'Report_AEMonthlyGoalsByCity',
    'Report_CancelledCosts',
    'Report_ConvenienceFee',
    'Report_CreativeRFPLog',
    'Report_DepositsDue',
    'Report_EmployeeByOfficeAndRole',
    'Report_ForecastByAe',
    'Report_ForecastByAll',
    'Report_ForecastByBusSource',
    'Report_ForecastByClient',
    'Report_ForecastByClient_AllOffices',
    'Report_ForecastByHotel',
    'Report_ForecastByHotel_AllOffices',
    'Report_ForecastByNationalAe',
    'Report_ForecastCommissionByHotel',
    'Report_GrossProfitByState',
    'Report_OperationProgramListing',
    'Report_OtherCommissions',
    'Report_ProgramsByCancellation',
    'Report_ProgramsByNationalAE',
    'Report_ProgramsByThirdParty',
    'Report_ProgramsByType',
    'Report_ProgramTypeHistory',
    'Report_Prospects',
    'Report_QuarterlyGPComparison',
    'Report_Receivables',
    'Report_RfpListing',
    'Report_Security',
    'Report_SLFilesNotImported',
    'Report_TaxableCostingItems',
    'Report_UpcomingPrograms',
    'Report_VendorCostVsPaid',
    'Report_VendorExpenditures',
    'Report_VendorsOnHold',
  ],
  type: 0,
  isInternal: true,
  vendorId: null,
  apiUrl: 'http://localhost:4065/api',
  apiUserName: 'ebe8c704c287faf6d3fc4bd7959c9ca6',
  apiPassword: '$c00byD00',
  dmcUrl: 'http://localhost:4066/dmc/',
  documentsUrl: 'https://localhost:44335',
  searchUrl: 'https://localhost:44371',
  userId: 2079,
  email: 'agilethought@hello-dmc.com',
  userName: 'agilethought',
  userLoginId: 807,
  userOfficeId: 6,
  rolePermissions: [
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 1,
      role: 'CS Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 2,
      role: 'PD Curator',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 6,
      permission: 'CanViewAdminArea',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 1,
      permission: 'CanViewDraftMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 2,
      permission: 'CanViewArchiveMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 3,
      permission: 'CanFilterMediaByUser',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 4,
      permission: 'CanFilterMediaByUploadDate',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 5,
      permission: 'CanViewMediaNameInDetailsPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 6,
      permission: 'CanViewAdminArea',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 7,
      permission: 'CanAddMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 8,
      permission: 'CanViewDraftMediaPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 9,
      permission: 'CanAddMediaToGallery',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 10,
      permission: 'CanViewAnalyticsOnMediaPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 11,
      permission: 'CanViewOptionsMenuOnMediaPage',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 12,
      permission: 'CanAddTagsToMedia',
    },
    {
      roleId: 3,
      role: 'Admin',
      permissionId: 13,
      permission: 'CanViewDashBoardItem',
    },
  ],
};

@Injectable()
export class UserEffects {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private officeService: OfficeService,
    private actions$: Actions,
    private store$: Store<AppState>
  ) {}

  checkUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkUserLogin),
      switchMap(action => {
        //Comment out this line if you don't want the user hardcoded.
        //return of(checkUserLoginResult({ user: HardCodedUser }));

        //Check the session storage for our user object
        const user = JSON.parse(sessionStorage.getItem(SESSION_KEY));

        if (user && Object.keys(user).length && user.userLoginId>0) {
          return of(checkUserLoginResult({ user: user }));
        } else {
          //If that object doesn't exist see if we have the cookie, if not our reducer will handle thing
          return this.userService.getUserFromDmcPro().pipe(
            take(1),
            map(res => {
              if (res){
                sessionStorage.setItem(SESSION_KEY, JSON.stringify(res));
              }
           
              return checkUserLoginResult({ user: res });
            })
          );
          // return this.authService.login(action.user).pipe(
          //   take(1),
          //   map(res => {
          //     sessionStorage.setItem(SESSION_KEY, JSON.stringify(res));
          //     return checkUserLoginResult({ user: res });
          //   })
          // );


         
          
        }
      })
    )
  );

  decorateUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkUserLoginResult),
      switchMap(action => this.decorateInternalUser(action.user)),
      // Using switchmap here to return 2 actions (to set state in 2 reducers)
      switchMap(data => [checkUserLoginDecorate({ user: data }), getAllOffices(), getUserOffices(), getMediaForPocket()])
    )
  );

  /**
   * This exists because a weird "permission" is based upon the users curator types by office
   * I know... this should just be part of the user object, but we need office IDS to map over
   * and call it so here's this awkward effect.
   * Note also that we ignore errors and just bubble up an empty array for that office. This is because the backend
   * returns 404 for a legitimate empty results...
   */
  getUserOfficesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserOfficesSuccess),
      withLatestFrom(this.store$),
      switchMap(data => {
        const action = data[0];
        const state = data[1];
        const obsArray$ = action.offices.map(office => {
          return this.userService.getUserCuratorTypes(state.user.user.userLoginId, office.officeId).pipe(
            take(1),
            map(res => {
              return { officeId: office.officeId, items: res };
            }),
            catchError(error => of({ officeId: office.officeId, items: [] }))
          );
        });
        return forkJoin(obsArray$).pipe(
          take(1),
          map(res => {
            return getUserCuratorTypesSuccess({ items: res });
          })
        );
      })
    )
  );

  getUserCuratorTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserCuratorTypesSuccess),
      withLatestFrom(this.store$),
      switchMap(data => [mediaActions.getDraftedMedia()])
    )
  );

  decorateInternalUser = user => {
    if (user.type === UserType.Internal) {
      const obsArray$ = [];
      obsArray$.push(this.userService.getUserIdByCorrelationKey(user.userId).pipe(take(1)));
      obsArray$.push(this.officeService.getOfficeIdByCorrelationKey(user.employeeOfficeId).pipe(take(1)));
      obsArray$.push(this.userService.getUserRoleAndPermissionsByCorrelationKey(user.userId).pipe(take(1)));
      //obsArray$.push(this.userService.getUserByLogin(user.userName).pipe(take(1)));
      return forkJoin(obsArray$).pipe(
        map(res => {
          let u={...user,userLoginId:res[0],userOfficeId:res[1],rolePermissions:res[2]};
          // user.userLoginId = res[0];
          // user.userOfficeId = res[1];
          // user.rolePermissions = res[2];
          sessionStorage.setItem(SESSION_KEY, JSON.stringify(u));
          return u;
        }),
        catchError(error => {
          return of(error);
        })
      );
    } else return of(user);
  };
}
