import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { supplierModel } from '../../../../models/supplier.model';
import { SupplierService } from '../../../../services/supplier.service';

@Component({
  selector: 'app-supplier-tag-type-ahead',
  templateUrl: './supplier-tag-type-ahead.component.html',
  styleUrls: ['./supplier-tag-type-ahead.component.scss'],
})
export class SupplierTagTypeAheadComponent implements OnInit {
  @Input() tags: supplierModel[] = [];
  @Input() canAddTags: boolean = false;

  @Output() tagsChanged = new EventEmitter<supplierModel[]>();
  @Output() supplierRemoved = new EventEmitter<supplierModel>();
  @Output() supplierAdded = new EventEmitter<supplierModel>();

  public filteredSupplierTagOptions$: Observable<supplierModel[]>;
  public supplierTaggingCtrl: FormControl;
  isSearching = false;
  constructor(private _supplierService: SupplierService) {}

  ngOnInit() {
    this.supplierTaggingCtrl = new FormControl();
    this.filteredSupplierTagOptions$ = this.supplierTaggingCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(s => typeof s === 'string'),
      switchMap(s => {
        this.isSearching=true;
        return this._supplierService.searchSupplierByOffice(null, s, null).pipe(catchError(error => of([])));
      }),
      map(items => {
        this.isSearching=false;
        if (items) {
          return items.slice(0, 100);
        } else {
          return [];
        }
      })
    );
  }

  selectedSupplier(value) {
    value = value.option.value;
    this.tags.push(value);
    this.supplierAdded.emit(value);
    /**
		using class to clear the input because there could be 2 on the page and ids will collide
		**/
    let inputs = document.getElementsByClassName('supplier-tag-input');
    for (var i = 0; i < inputs.length; i++) {
      (<HTMLInputElement>inputs[i]).value = '';
    }
  }

  removeTag(value) {
    this.tags = this.tags.filter(tag => tag.id != value.id);
    this.supplierRemoved.emit(value);
  }
}
