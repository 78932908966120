import { createAction, props } from '@ngrx/store';
import { Office } from '../../models/office.model';

export const getUserOffices = createAction('[offices] getUserOffices');
export const getUserOfficesSuccess = createAction('[offices] getUserOfficesSuccess', props<{offices: Office[]}>());
export const getUserOfficesFail = createAction('[offices] getUserOfficesFail');

export const selectOffice = createAction('[offices] selectOffice', props<{id: number}>());

export const getAllOffices = createAction('[offices] getAllOffices');
export const getAllOfficesSuccess = createAction('[offices] getAllOfficesSuccess',
	props<{offices: Office[]}>())