import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
//import { IOptions, ToastUiImageEditorComponent } from 'ngx-tui-image-editor';
import { Media } from '../../../models/media.model';
import { searchResult } from '../../../models/searchResult.model';
import { UploadStatusMessage } from '../../../models/upload-status-message.model';
const customTheme = {
  'common.bi.image': '',
  'common.bisize.width': '251px',
  'common.bisize.height': '21px',
  'common.backgroundImage': '',
  'common.backgroundColor': 'white',
  'common.border': 'none',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': '#FAFAFA',
  'header.border': '0px',

  // load button
  'loadButton.visibility': 'hidden',
  'loadButton.display': 'none',

  // download button
  'downloadButton.visibility': 'hidden',
  'downloadButton.display': 'none',
  // main icons
  'menu.normalIcon.path': '../../../../../assets/svg/icon-d.svg',
  'menu.normalIcon.name': 'icon-d',
  'menu.activeIcon.path': '../../../../../assets/svg/icon-b.svg',
  'menu.activeIcon.name': 'icon-b',
  'menu.disabledIcon.path': '../../../../../assets/svg/icon-a.svg',
  'menu.disabledIcon.name': 'icon-a',
  'menu.hoverIcon.path': '../../../../../assets/svg/icon-c.svg',
  'menu.hoverIcon.name': 'icon-c',
  'menu.iconSize.width': '24px',
  'menu.iconSize.height': '24px',

  // submenu primary color
  'submenu.backgroundColor': '#444444',
  'submenu.partition.color': '#e3e3e3',

  // submenu icons
  'submenu.normalIcon.path': '../../../../../assets/svg/icon-d.svg',
  'submenu.normalIcon.name': 'icon-d',
  'submenu.activeIcon.path': '../../../../../assets/svg/icon-b.svg',
  'submenu.activeIcon.name': 'icon-b',
  'submenu.iconSize.width': '32px',
  'submenu.iconSize.height': '32px',

  // submenu labels
  'submenu.normalLabel.color': '#858585',
  'submenu.normalLabel.fontWeight': 'normal',
  'submenu.activeLabel.color': '#000',
  'submenu.activeLabel.fontWeight': 'normal',

  // checkbox style
  'checkbox.border': '1px solid #ccc',
  'checkbox.backgroundColor': '#fff',

  // rango style
  'range.pointer.color': '#666',
  'range.bar.color': '#fafafa',
  'range.subbar.color': '#f5f5f5',

  'range.disabledPointer.color': '#d3d3d3',
  'range.disabledBar.color': 'rgba(85,85,85,0.06)',
  'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

  'range.value.color': '#000',
  'range.value.fontWeight': 'normal',
  'range.value.fontSize': '11px',
  'range.value.border': '0',
  'range.value.backgroundColor': '#f5f5f5',
  'range.title.color': '#000',
  'range.title.fontWeight': 'lighter',

  // colorpicker style
  'colorpicker.button.border': '0px',
  'colorpicker.button.height': '16px',
  'colorpicker.button.width': '16px',
  'colorpicker.title.color': '#000',
};
@Component({
  selector: 'app-media-edit-dialog',
  templateUrl: './media-edit-dialog.component.html',
  styleUrls: ['./media-edit-dialog.component.scss'],
})
export class MediaEditDialogComponent implements OnInit {
  //@ViewChild(ToastUiImageEditorComponent, { static: true }) editorComponent: ToastUiImageEditorComponent;
  //editorInstance: tuiImageEditor.ImageEditor;
  //options: IOptions;
  fileName: string;
  isUploading = false;
  isDisabled = false;
  displaymessage: string;
  @Input() multiplier = 100;

  @Input()
  fileAlias: any = 'file';
  @Output()
  editedFile: EventEmitter<File>;
  canvasSize = { width: 0, height: 0 };
  imgSize = { width: 0, height: 0 };
  media: Media;
  includeUI: boolean;
  userId: number;
  uploadStatusMessage: UploadStatusMessage;
  statusDescription: string;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private router: Router,
    private ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<MediaEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.uploadStatusMessage = new UploadStatusMessage();
    this.media = data.media;
    this.updateStatusDisplay(this.media.status);
    this.fileName = this.media.name;

    this.imgSize.height = data.imgMetadata.height;
    this.imgSize.width = data.imgMetadata.width;
    this.canvasSize.height = data.imgMetadata.height;
    this.canvasSize.width = data.imgMetadata.width;

    this.resizeWrapper();

    this.includeUI = true;
    // this.options = {
    //   usageStatistics: false,
    //   includeUI: {
    //     loadImage: {
    //       path: this.media.links.fileUrl,
    //       name: this.media.name,
    //     },
    //     menuBarPosition: 'right',
    //     theme: customTheme,
    //   },
    //   cssMaxHeight: 4000,
    //   cssMaxWidth: 4000,
    //   selectionStyle: {
    //     cornerSize: 20,
    //     rotatingPointOffset: 70,
    //   },
    // };
  }
  ngAfterViewInit(): void {
    this.injectControls();
  }
  scaleChanged($event: MatSlideToggle) {
    if ($event.checked) {
      this.canvasSize = { width: this.imgSize.width / 2, height: this.imgSize.height / 2 };
      // this.editorComponent.editorInstance
      //   .resizeCanvasDimension({ width: this.canvasSize.width, height: this.canvasSize.height })
      //   .then(() => this.resizeWrapper());
    } else {
      // this.editorComponent.editorInstance
      //   .resizeCanvasDimension({ width: this.canvasSize.width, height: this.canvasSize.height })
      //   .then(() => this.resizeWrapper());
      // });
    }
  }

  resizeWrapper() {
    const wrap = this._document.querySelector<HTMLElement>('.edit-image-wrap');

    if (wrap) {
      wrap.style.height = `${this.canvasSize.height + 200}px`;
    }
  }

  forceDetectChange(event: any) {
    this.ref.detectChanges();
  }
  ngOnInit() {}
  disabledSave(): boolean {
    // if (this.editorComponent.editorInstance) {
    //   return this.isDisabled || this.editorComponent.editorInstance.isEmptyUndoStack();
    // }
    return true;
  }
  objectActivated($event: any) {
    console.log($event);
  }
  btnCancelClick() {
    this.dialogRef.close(null);
  }
  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  updateStatusDisplay(status: any) {
    switch (status) {
      case 'DFT': {
        this.statusDescription = 'Draft';
        break;
      }
      case 'PBH': {
        this.statusDescription = 'Published';

        break;
      }
      case 'ARC': {
        this.statusDescription = 'Archived';
        break;
      }
    }
  }
  // save should emit an event its parent component with the imgBlob passed as an arg
  // the parent component will then dispatch the upload action
  btnSaveClick() {
   // this.editorComponent.editorInstance.clearUndoStack();
    this.isUploading = true;
    this.displaymessage = '';
    const ext = this.fileName.substr(this.fileName.lastIndexOf('.') + 1);
    let multiplierLocal = this.multiplier / 100;
    if (multiplierLocal <= 0.0) {
      multiplierLocal = 1;
    }
    // const imagBase = this.editorComponent.editorInstance.toDataURL({
    //   multiplier: multiplierLocal,
    // });

   // const imgBlob = this.dataURItoBlob(imagBase.replace(/^data:image\/(png|jpg|gif|jpeg);base64,/, ''));

    //this.dialogRef.close(new File([imgBlob], this.fileName, { type: 'img/' + ext }));
  }

  injectControls() {
    const bottomMenu = this._document.querySelector('.tui-image-editor-menu') as HTMLElement;
    bottomMenu.style.backgroundColor = 'primary';

    const imgHeader = this._document.querySelector('.tui-image-editor-header-logo') as HTMLElement;
    imgHeader.hidden = true;

    const bynDiv = this._document.querySelector('.tui-image-editor-header-buttons') as HTMLElement;
    const btnCancel = this._document.querySelector('#btnCancel') as HTMLElement;
    btnCancel.remove();
    bynDiv.appendChild(btnCancel);

    const btnSave = this._document.querySelector('#btnSave') as HTMLElement;
    btnSave.remove();
    bynDiv.appendChild(btnSave);
  }

  addMedia(result: searchResult) {
    this.isDisabled = false;
    if (result) {
      this.displaymessage = 'Uploaded';
      this.dialogRef.close(result);
    } else {
      this.displaymessage = 'Error occurred processing the request.';
      this.isDisabled = false;
    }
  }
}
