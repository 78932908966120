import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { supplierModel } from '../../../../models/supplier.model';
import { SupplierService } from '../../../../services/supplier.service';

@Component({
  selector: 'app-supplier-type-ahead',
  templateUrl: './supplier-type-ahead.component.html',
  styleUrls: ['./supplier-type-ahead.component.scss'],
})
export class SupplierTypeAheadComponent implements OnInit {
  @Input() placeholder: string = 'Supplier';
  @Input() value: supplierModel;
  @Input() isCreative: boolean;
  @Output() supplierSelected = new EventEmitter<supplierModel>();
  @Output() filterCleared = new EventEmitter<boolean>();
  public filteredSupplierOptions$: Observable<supplierModel[]>;
  public supplierFormControl: FormControl;
  public suppliersLoading: boolean = false;

  constructor(private _supplierService: SupplierService) {}

  ngOnInit() {
    this.supplierFormControl = new FormControl();
    if (this.value) {
      this.supplierFormControl.setValue(this.value);
    }
    this.filteredSupplierOptions$ = this.supplierFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(s => typeof s === 'string'),
      switchMap(s => {
        if (s === '') {
          this.filterCleared.emit(true);
          return of([]);
        }
        this.suppliersLoading = true;
        return this._supplierService.searchSupplierByOffice(null, s, this.isCreative).pipe(catchError(error => of([])));
      }),
      map(items => {
        this.suppliersLoading = false;
        if (items) {
          return items.slice(0, 100);
        } else {
          return [];
        }
      })
    );
  }

  ngOnChanges(simpleChanges) {
    if (this.supplierFormControl) {
      this.supplierFormControl.setValue(this.value);
    }
  }

  selectedSupplierEvent(supplier) {
    this.supplierSelected.emit(supplier);
  }

  supplierDisplayFn(supplier?: supplierModel): string | undefined {
    const supplierName = supplier && supplier.name ? supplier.name : '';
    return supplier ? supplierName : undefined;
  }
}
