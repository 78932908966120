import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Album } from '../../../models/album.model';
import { AlbumsState } from '../../../ngrx/reducers/albums.reducer';

@Component({
  selector: 'app-set-album-select',
  templateUrl: './set-album-select.component.html',
  styleUrls: ['./set-album-select.component.css'],
  
})
export class SetAlbumSelectComponent implements OnInit {
  albums$: Observable<AlbumsState>;
  _selectedAlbums;
  @Input()
  albums: Album[];
  @Input()
  get selectedAlbums(){
    return this._selectedAlbums;
  }
  set selectedAlbums(value){
    this._selectedAlbums=value;
    if (this._selectedAlbums && this._selectedAlbums.length > 0) {
      this.selectedAlbumNames = this._selectedAlbums.map(a => { return a.name });
      let albumIds = Array.from(new Set(this._selectedAlbums.map(a => { return +a.mediaGroupId })));
      this.selectedAlbumsForm.setValue(albumIds);
    }else{
      this.selectedAlbumsForm.setValue([]);
    }
  }
  selectedAlbumsForm: FormControl = new FormControl();
  placeHolderText: string = "Add To Albums";
  selectedAlbumNames: string[];
  @Input()
  classValue = '';

  @Output() selectedAlbumsEvent: EventEmitter<{ addedAlbumIds: number[], removedAlbumIds: number[] }> = new EventEmitter();
  @Output() createAlbumEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('albumSelect') albumSelect;
  constructor() { }

  ngOnInit() {
    // Can only add media to my likes album by liking media
    if (this.albums) {
      this.albums = this.albums.filter(ab => ab.isFavorite != true);
    }
   
  }
  ngOnChanges(changes) {
    // Can only add media to my likes album by liking medias
    if (this.albums) {
      this.albums = this.albums.filter(ab => ab.isFavorite != true);
    }
    if (changes.selectedAlbums && changes.selectedAlbums.currentValue && changes.selectedAlbums.currentValue.length > 0) {
      this.selectedAlbums = changes.selectedAlbums.currentValue;
      let albumIds = Array.from(new Set(this.selectedAlbums.map(a => { return +a.mediaGroupId })));
      let matches = this.albums.filter(ab => albumIds.indexOf(+ab.id) !== -1);
      if (matches && matches.length) {
        this.selectedAlbumNames = matches.map(a => { return a.name });
        this.selectedAlbumsForm.setValue(albumIds);
      }
    }
  }
  createAlbum(event) {
    let currentValue = this.selectedAlbumsForm.value
    if (currentValue.indexOf(null) !== -1) {
      this.createAlbumEvent.emit(true);
      this.albumSelect.close();
    }
  }

  updateAlbums(isOpen: boolean) {
    if (!isOpen) {

      if (this.selectedAlbumsForm.value) {
        if (this.selectedAlbumsForm.value.indexOf(null) === -1) {
          this.selectedAlbumNames = [];
          let selectedAlbumIds = <number[]>this.selectedAlbumsForm.value.filter(x => x !== null);
          let unselectedAlbums = this.albums.filter(a => selectedAlbumIds.indexOf(a.id) === -1);
          let unselectedAlbumIds = unselectedAlbums.map(album => { return album.id });
          selectedAlbumIds.forEach(id => {
            let album = this.albums.find(a => a.id == id);
            this.selectedAlbumNames.push(album.name);
          })
          this.selectedAlbumsEvent.emit({ addedAlbumIds: selectedAlbumIds, removedAlbumIds: unselectedAlbumIds });
        }
      }
    }
  }
}
