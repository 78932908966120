<div class="page-header-row">
  <div class="breadcrumb-wrap">
    <ul class="breadcrumbs">
      <li class="breadcrumb">
        My Media
      </li>
    </ul>
  </div>

  <div class="page-actions-wrap">
    <mat-icon *ngIf="canViewDraft !== true"
              class="page-action"
              matToolTip="Add New Album"></mat-icon>
    <mat-icon *ngIf="canViewDraft == true"
              class="page-action"
              matTooltip="Create New..."
              [matMenuTriggerFor]="collectionMenu">
      add
    </mat-icon>
    <mat-menu #collectionMenu>
      <button mat-menu-item
              (click)="newAlbumDialog()">
        Create New Album
      </button>
      <button mat-menu-item
              (click)="newGalleryDialog()">
        Create New Gallery
      </button>
    </mat-menu>
  </div>
</div>

<div class="page-content-row toggle-nav-wrap">
  <a class="toggle-nav-button"
     (click)="albumsToggle()"
     [ngClass]="{'toggle-nav-button-active': showAlbums == true}">
    Albums
  </a>
  <a *ngIf="canViewDraft"
     class="toggle-nav-button"
     (click)="galleriesToggle()"
     [ngClass]="{'toggle-nav-button-active': showGalleries == true}">
    Galleries
  </a>
</div>

<app-albums *ngIf="showAlbums === true"
            [albums]="albums"
            (selectAlbum)="onAlbumSelect($event)">
</app-albums>

<app-galleries *ngIf="showGalleries === true"
               [galleries]="galleries$ | async"
               [offices]="offices"
               [userOfficeId]="currentOffice$ | async"
               [showStatusFilter]="true"
               (selectGallery)="onGallerySelect($event)"></app-galleries>

<scroll-to-top></scroll-to-top>