import { createSelector } from '@ngrx/store';

import { Office } from '../../models/office.model';
import { AppState } from '../reducers';

export const selectOffices = (state : AppState) => state.offices.offices;
export const selectUserOffices = createSelector(selectOffices,
    (offices : Office[], props) => {
          return offices;
    })
export const selectCurrentOffice = (state: AppState) => state.offices.currentSelectedOfficeId;

export const selectUserCurrentOffice = createSelector(selectCurrentOffice,
    (office, props) => {
        return office;
    });
