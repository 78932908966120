import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public login$: Observable<string>;

  constructor(private store$: Store<any>) { }

  ngOnInit() {
    this.login$ = this.store$.pipe(select(state => state.user.user), 
    map(res => {
      if(res) {
        
        return res.userName;

      } else {
        return false;
      }
    }));
  }

}
