export class Gallery {
  name: string;
  description: string;
  id: number;
  isPublic: boolean;
  status: string;
  statusCode: string;
  totalMediaCount: number;
  coverImage: string;
  coverImageUrl: string;
  officeId: number;
  officeName: string;
  createdByUserId: number;
  createdByUserName: string;
}

export class GalleryMediaMapping {
  galleryId: number;
  mediaIds: number[];
}

export class GalleryEditDTO {
  MediaGroupId: number;
  Name: string;
  Description: string;
  CoverImage: string;
  UpdatedByUserId?: number;
  OfficeId: number;
}