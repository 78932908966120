import { createAction, props } from '@ngrx/store';
import { Album, AlbumEditDTO } from '../../models/album.model';
import { Media } from '../../models/media.model';
import { AlbumCreateCommand } from '../../services/album.service';



export const getUserSharedAlbums = createAction('[albums] getUserSharedAlbums');
export const getUserSharedAlbumsSuccess = createAction('[albums] getUserSharedAlbumsSuccess', props<{ sharedAlbums: any[] }>());
export const getUserSharedAlbumsFail = createAction('[albums] getUserSharedAlbumsFail');

export const getMediaForAlbum = createAction('[albums] getMediaForSharedAlbum', props<{ albumId: number }>());
export const getMediaForAlbumSuccess = createAction('[albums] getMediaForSharedAlbumSuccess', props<{ albumId: number, albumMedia: Media[] }>());
export const getMediaForAlbumFail = createAction('[albums] getMediaForSharedAlbumFail');

export const createUserAlbum = createAction('[albums] createUserSharedAlbum', props<{ newAlbumDto: AlbumCreateCommand }>());
export const createUserAlbumsSuccess = createAction('[albums] createUserSharedAlbumSuccess', props<{ newAlbum: Album }>());
export const createUserAlbumsFail = createAction('[albums] createUserSharedAlbumFail');

export const addMediaToAlbums = createAction('[albums] addMediaToSharedAlbums', props<{ mediaId: number | string, selectedAlbumIds: number[] }>());
export const addMediaToAlbumsSuccess = createAction('[albums] addMediaToSharedAlbumsSuccess', props<{ mediaId: number | string, selectedAlbumIds: number[] }>());

export const removeMediaFromAlbums = createAction('[albums] removeMediaFromSharedAlbums', props<{ mediaId: number, selectedAlbumIds: number[] }>());
export const removeMediaFromAlbumsSuccess = createAction('[albums] removeMediaFromSharedAlbumsSuccess', props<{ mediaId: number, selectedAlbumIds: number[] }>());

export const bulkRemoveMediaFromAlbum = createAction('[albums] bulkRemoveMediaFromSharedAlbum',
	props<{mediaIds: number[], albumId: number}>());
export const bulkRemoveMediaFromAlbumSuccess = createAction('[albums] bulkRemoveMediaFromSharedAlbumSuccess',
	props<{mediaIds: number[], albumId: number}>());

	export const bulkAddMediaFromAlbum = createAction('[albums] bulkAddMediaFromSharedAlbum',
	props<{mediaIds: number[], selectedAlbumIds: number[]}>());
export const bulkAddMediaFromAlbumSuccess = createAction('[albums] bulkAddMediaFromSharedAlbumSuccess',
	props<{mediaIds: number[], selectedAlbumIds: number[]}>());

export const editAlbum = createAction('[albums] editSharedAlbum',
	props<{albumEditDTO: AlbumEditDTO}>());
export const editAlbumSuccess = createAction('[albums] editSharedAlbumSuccess',
	props<{album: Album}>());

export const updateAlbumStatus = createAction('[album] updateSharedAlbumStatus',
	props<{id: number, isPublic: boolean}>());
export const updateAlbumStatusSuccess = createAction('[album] updateSharedAlbumStatusSuccess',
	props<{id: number, isPublic: boolean}>());

export const deleteAlbum = createAction('[album] deleteSharedAlbum',
	props<{id: number}>());
export const deleteAlbumSuccess = createAction('[album] deleteSharedAlbumSuccess',
	props<{id: number}>());